(function () {
'use strict';

angular.module('hyresgastApp').controller('ShowHelpProcessCtrl', ShowHelpProcessCtrl);

function ShowHelpProcessCtrl() {
  var vm = this;

  vm.toggleActive = toggleActive;

  /////////

  function toggleActive(state) {
    vm.active = vm.active !== state ? state : null;
  }
}

})();