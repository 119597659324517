(function () {
'use strict';

angular.module('httpAuthBuffer', []).factory('httpBuffer', httpBuffer);

function httpBuffer($injector) {
  // Holds all the requests, so they can be re-requested in future.
  var buffer = [];

  // Service initialized later because of circular dependency problem.
  var $http;

  var service = {
    append: append,
    retryAll: retryAll,
    rejectAll: rejectAll
  };

  return service;

  ////////////

  // Appends HTTP request configuration object with deferred response
  // attached to buffer.
  //   @return {Number} The new length of the buffer.
  function append(config, deferred) {
    return buffer.push({
      config: config,
      deferred: deferred
    });
  }

  // Retries all the buffered requests clears the buffer.
  function retryAll(updater) {
    for (var i = 0; i < buffer.length; ++i) {
      var _cfg = updater(buffer[i].config);
      if (_cfg !== false) {
        retryHttpRequest(_cfg, buffer[i].deferred);
      }
    }
    buffer = [];
  }

  // Abandon or reject (if reason provided) all the buffered requests.
  function rejectAll(reason) {
    if (reason) {
      for (var i = 0; i < buffer.length; ++i) {
        buffer[i].deferred.reject(reason);
      }
    }
    buffer = [];
  }

  function retryHttpRequest(config, deferred) {
    function successCallback(response) {
      deferred.resolve(response);
    }
    function errorCallback(response) {
      deferred.reject(response);
    }

    $http = $http || $injector.get('$http');
    $http(config).then(successCallback, errorCallback);
  }
}

})();