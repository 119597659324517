(function () {
'use strict';

angular.module('hyresgastApp').controller('EditObjectsModalCtrl', EditObjectsModalCtrl);

function EditObjectsModalCtrl($uibModalInstance,
  objects, oldObjects,
  RealEstate) {
  var vm = this;

  RealEstate.getObjects({}).$promise.then(result => {
    vm.objectRoot = {
      label: 'STANDARD FÖRÄNDRING',
      subitems: result.objects.map(x => ({
        label: x.name,
        data: x.code,
        subitems: x.objects.map(x => ({
          label: x.name,
          data: x.code,
        })),
      })),
    };
  });

  const sum = money => money.reduce((acc, el) => { acc += el; return acc }, 0);
  vm.recalculate = () => {
    vm.sumOld = sum(vm.objects.filter(x => !x.isNew).map(o => o.value));
    vm.sum = sum(vm.objects.map(o => o.value));
  };

  let sortObjects = () => {
    vm.objects = vm.objects.sort((a, b) =>
      b.isNew === a.isNew
        ? a.code.localeCompare(b.code)
        : b.isNew ? -1 : 1
    );
    vm.objects.forEach(x => x.separator = false);
    let firstNew = vm.objects.find(x => x.isNew);
    if (firstNew) {
      firstNew.separator = true;
    }
  };

  vm.objects = objects.map(x => Object.assign({
    isNew: !oldObjects.find(o => o.code === x.code),
    quantity: x.quantity ? x.quantity : 1,
  }, x));
  sortObjects();
  vm.canDelete = !!objects && objects.length > 0;
  vm.recalculate();

  vm.addObjects = () => {
    let newObjects = vm.treeViewNodes
      .filter(x => x.level > 1)
      .filter(x => !vm.objects.find(o => o.code === x.data))
      .map(x => ({
        code: x.data,
        name: x.label,
        value: 0,
        quantity: 1,
        isNew: !oldObjects.find(o => o.code === x.data),
      }));
    vm.objects.push(...newObjects);
    sortObjects();
  };

  vm.deleteObject = object => {
    vm.objects = vm.objects.filter(o => o !== object);
    vm.recalculate();
  };

  vm.delete = () => $uibModalInstance.close({ delete: true });
  vm.ok = () => $uibModalInstance.close({
    objects: vm.objects.map(o => ({
      code: o.code,
      name: o.name,
      parameter: o.parameter,
      value: o.value,
      quantity: o.quantity,
    }))});
  vm.cancel = () => $uibModalInstance.dismiss();
}
})();