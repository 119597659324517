(function () {
'use strict';

angular.module('hyresgastApp').controller('EditBlockingOverviewModalCtrl', EditBlockingOverviewModalCtrl);

function EditBlockingOverviewModalCtrl($uibModalInstance, blockingObject, Blocking, Flash, $location) {
  var vm = this;
  vm.blocking = angular.copy(blockingObject);

  vm.cancel = cancel;
  vm.save = save;

  /////////

  function save() {
    vm.saving = true;
    Blocking.save(vm.blocking)
      .$promise.then(
        function(response) {
          angular.extend(blockingObject, vm.blocking);
          $uibModalInstance.close();
        })
      .catch(function(reason) {
        $uibModalInstance.dismiss('error');
        if(reason.status === 403) {
          $location.path('/403')
        } else {
          Flash.set('Misslyckades att spara', 'error');
        }
      })
      .finally(function() {
        vm.saving = false;
      });
  }

  function cancel() {
    $uibModalInstance.dismiss('cancel');
  }
}

})();