(function () {
'use strict';

angular.module('hyresgastApp').directive('hyGobutton', goButton);

function goButton($location) {
  var directive = {
    scope: {
      class: '@',
      destination: '@',
      caption: '@',
    },
    restrict: 'E',
    templateUrl: 'components/gobutton/gobutton.html',
    link: link
  };

  return directive;

  function link(scope) {
    scope.go = function() {
      $location.path(scope.destination);
    }
  }
}

})();