(function () {
'use strict';

angular.module('hyresgastApp').factory('Artifacts', function($resource) {
  return $resource(
    '/api/artifacts',
    { id: '@id', uuid: '@uuid' },
    {
      list: {
        url: '/api/artifacts',
        isArray: true,
        method: 'GET',
      },
      download: {
        url: '/api/artifacts/:uuid',
        method: 'GET',
        responseType: 'arraybuffer',
        transformResponse: (data, headers) => ({ data, headers })
      },
      destroy: {
        url: '/api/artifacts',
        method: 'DELETE',
      },
    }
  );
});

})();