(function () {
'use strict';

angular.module('hyresgastApp').controller('ListUserNegotiationCallsFinishedCtrl', ListUserNegotiationCallsFinishedCtrl);

function ListUserNegotiationCallsFinishedCtrl(
  $scope, $location,
  Negotiation, NegotiationStates,
  Auth, ErrorHandler,
  FeatureConfiguration
) {
  var vm = this;
  vm.myFinishedNegotiationCalls = [];
  vm.getMyFinishedNegotiationCalls = getMyFinishedNegotiationCalls;
  vm.FeatureConfiguration = FeatureConfiguration;
  vm.gotoShow = gotoShow;
  vm.pageLimit = 30;
  vm.pageOffset = 0;
  vm.sortColumn = $location.search().order || 'updatedAt'; // default
  vm.sortDirection = $location.search().direction || 'DESC'; // default
  vm.listColumns = [
    { title: 'Id', column: 'caseNumber' },
    { title: 'Namn', column: 'name' },
    { title: 'Organisation', column: 'counterpart.name' },
    { title: 'Hyresändring', column: 'rentEffectiveFrom' },
    { title: 'Status', column: 'state' },
    { title: 'Senast ändrad', column: 'updatedAt' }
  ];

  $scope.$on('$routeUpdate', function() {
    if ($location.search().order) {
      // reset offset when sorting
      vm.pageOffset = 0;
      getMyFinishedNegotiationCalls();
    }
  });

  vm.Auth = Auth;

  /////////

  getMyFinishedNegotiationCalls();

  function gotoShow(id) {
    $location.search({});
    $location.path('/negotiations/' + id);
  }

  function getMyFinishedNegotiationCalls(resetOffset) {
    vm.ready = false;
    if (resetOffset) {
      vm.pageOffset = 0;
    }
    return Negotiation.query({
      order: vm.sortColumn,
      direction: vm.sortDirection,
      mine: 'true',
      'filters[state]': NegotiationStates.FINISHED,
      'filters[caseNumber]': vm.inputTextId,
      'search[name]': vm.inputTextName,
      'search[counterpart.name]': vm.inputTextOrganisation
    })
      .$promise.then(function(data) {
        vm.myFinishedNegotiationCalls = data.rows;
        vm.totalCount = data.count;
      })
      .catch(ErrorHandler('Misslyckades att hämta lista.'))
      .finally(function() {
        vm.ready = true;
      });
  }
}

})();