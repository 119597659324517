(function () {
'use strict';

// @desc handle roles

angular.module('httpRoleLookup', []).value('RoleLookup', [
  { key: 'USER', value: 100 }, // TODO - remove duplication (see RoleSvc)
  { key: 'ADMIN', value: 200 }
]);

})();