(function () {
'use strict';

angular.module('hyresgastApp').controller('NegotiationTableCtrl', NegotiationTableCtrl);

function NegotiationTableCtrl(
  $scope, $location, $routeParams,
  FeatureConfiguration, User,
  ErrorHandler
) {
  var vm = this;

  vm.getNegotiationTables = getNegotiationTables;
  vm.FeatureConfiguration = FeatureConfiguration;
  vm.gotoShow = gotoShow;
  vm.pageLimit = 30;
  vm.pageOffset = 0;
  vm.sortDirection = $location.search().direction || 'DESC'; // default
  vm.listColumns = [
    { title: 'Person', column: 'firstname' },
    { title: 'Organisation', column: 'organizationName' },
    { title: 'Gemensamma förhandlingar', column: 'numNegotiations' },
    { title: 'Antal fastigheter', column: 'numProperties' }
  ];
  if (vm.FeatureConfiguration.ENABLE_MEETINGS) {
    vm.listColumns.splice(2, 0, { title: 'Nästa möte', column: 'nextMeeting' });
  }

  vm.sortColumn = vm.listColumns.some(function(c) {
    return c.column === $location.search().order;
  })
    ? $location.search().order
    : vm.FeatureConfiguration.ENABLE_MEETINGS ? 'nextMeeting': 'numNegotiations'; // default

  getNegotiationTables();

  $scope.$on('$routeUpdate', function() {
    if ($location.search().order) {
      // reset offset when sorting
      vm.pageOffset = 0;
      getNegotiationTables();
    }
  });

  function gotoShow(negotiatorUserId, organizationId) {
    $location.path('/users/' + $routeParams.id + '/negotiator/' + negotiatorUserId + '/organization/' + organizationId + '/welcome/negotiation-table/negotiator');
  } //placeholder for directing to single negotiation table

  function getNegotiationTables(resetOffset) {
    vm.ready = false;
    if (resetOffset) {
      vm.pageOffset = 0;
    }

    return User.queryNegotiationTables({
      limit: vm.pageLimit,
      offset: vm.pageOffset,
      order: vm.sortColumn,
      direction: vm.sortDirection
    })
      .$promise.then(function(data) {
        vm.negotiators = data.rows;
        vm.totalCount = data.count;
      })
      .catch(ErrorHandler('Misslyckades att hämta lista.'))
      .finally(function() {
        vm.ready = true;
      });
  }
}

})();