(function () {
'use strict';

// @desc Part of the layout
//
// @example
//   <body>
//      <hy-footer></hy-footer>
//   </body>

angular.module('hyresgastApp').directive('hyFooter', footer);

function footer(PortalSettings) {
  var directive = {
    templateUrl: 'components/footer/footer.html',
    scope: {
      email: '@',
      phone: '@',
      phoneDeGapped: '@',
      hours: '@'
    },
    link: function (scope) {
      if ((!scope.email) || (!scope.phone) || (!scope.hours)) {
        scope.email = 'forhandlarportalen@hyresgastforeningen.se';
        scope.phone = '0046104591050';
        scope.hours = 'öppet Mån-Tors kl 08:00-15:00, Fre kl 08:00-13:00';

        PortalSettings.getFooterInfo(response => {
          scope.email = response.footerEmail;
          scope.phone = response.footerPhone;
          scope.phoneDeGapped = scope.phone.replace(/-/g, '');
          scope.hours = response.footerHours;
        });
      }
    },
    restrict: 'E'
  };
  return directive;
}

})();