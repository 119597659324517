(function () {
'use strict';

// @desc
//   Manage deletion blocking users belonging to an organization.
//
//
// @example
//   <list-blocking-negotiations
//      organization="vm.organization">
//      list="vm.negotiations">
//      cancel="vm.cancel()">
//   </list-blocking-negotiations>

angular.module('hyresgastApp').directive('listBlockingNegotiations', listBlockingNegotiations);

function listBlockingNegotiations($uibModal, Flash, UserByOrganization, Auth, HyUtils, Roles) {
  var directive = {
    restrict: 'E',
    templateUrl: 'resources/organization/show/blocking-data/list-blocking-negotiations.html',
    scope: {
      organization: '=',
      list: '=',
      cancel: '='
    },
    link: link
  };

  return directive;

  function link(scope) {
    scope.delete = destroy;
    scope.Auth = Auth;
    scope.roles = Roles;
    ////////////
    function destroy(negotiation) {
      HyUtils.askQuestion('Are you sure you want to delete this negotiation?', null, 2)
        .then(pRes => {
          //TODO: DELETE NEGOTIATIONS
        });
    }
  }
}



})();