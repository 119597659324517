(function () {
'use strict';

angular.module('hyresgastApp').controller('ListNegotiationCallCtrl', ListNegotiationCallCtrl);

function ListNegotiationCallCtrl($scope, $location, $routeParams, Auth, SubPage) {
  var vm = this;

  vm.headerButtons = [
    {
      title: 'Skapa en framställan',
      url: '/negotiation-calls/new',
      isVisible: () => (Auth && Auth.canDo('createCall')),
      icon: 'call',
    }
  ];
  vm.tabs = Auth.isHgfUser() ? [
    { title: 'Nya', path: '/negotiation-calls' },
    { title: 'Alla', path: '/negotiation-calls?view=list-all' }
  ] : [];

  getCurrentSubpage();

  $scope.$on('$routeUpdate', function() {
    // avoid unecessary calling of getCurrentSubpage(); when sorting
    if (!$location.search().order) {
      // reset offset when sorting
      getCurrentSubpage();
    }
  });

  /////////

  function getCurrentSubpage() {
    var defaultSubPage = 'list-new';
    // create a validation list against manually change of URL '?view=' parameter
    var whiteList = ['list-all'];
    vm.currentSubpage = SubPage.set($routeParams.view, whiteList, defaultSubPage);
  }
}

})();