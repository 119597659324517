(function () {
'use strict';

angular.module('hyresgastApp').controller('ReturnNegotiationCallModal', ReturnNegotiationCallModal);

function ReturnNegotiationCallModal($uibModalInstance) {
  var vm = this;
  vm.cancel = cancel;
  vm.send = send;
  vm.message = '';

  /////////

  function send() {
    $uibModalInstance.close(vm.message);
  }

  function cancel() {
    $uibModalInstance.dismiss('cancel');
  }
}

})();