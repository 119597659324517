(function () {
'use strict';

angular.module('hyresgastApp').controller('ListNegotiationMyCtrl', ListNegotiationMyCtrl);

function ListNegotiationMyCtrl(
  $scope, $location,
  Negotiation,
  CombinedNegotiationStates, NegotiationTypes, Parties, Limits,
  HyUtils, Auth, ErrorHandler) {
  var vm = this;

  Auth.heartbeat().then(function () {
    vm.isHgfUser = Auth.currentUser().party === Parties.HGF;
    vm.listColumns = [
      {
        title: 'Id',
        column: 'caseNumber'
      },
      {
        title: 'Namn',
        column: 'name'
      },
      {
        title: 'Organisation',
        column: 'counterpart.name'
      },
      {
        title: 'Förhandlingstyp',
        column: 'type'
      },
      {
        title: 'Status',
        column: 'combinedState'
      },
      {
        title: 'Begärt ändringsdatum',
        column: 'rentEffectiveFrom'
      },
      {
        title: 'Önskat datum för förhandling',
        column: 'callForNegotiation.negotiationDate'
      },
      {
        title: 'Oläst',
        column: 'seenByMe',
      },
    ];
    vm.responsibleNegotiator = vm.isHgfUser ? {
      title: 'Ansvarig motpart',
      column: 'extNegotiator.firstname'
    } : {
      title: 'Ansvarig HGF',
      column: 'hgfNegotiator.firstname'
    };
    vm.listColumns.push(vm.responsibleNegotiator);
  });

  vm.negotiations = [];
  vm.getMyNegotiations = getMyNegotiations;
  vm.gotoShow = gotoShow;
  vm.statusChoices = angular.extend({
    ALL: ''
  }, CombinedNegotiationStates);
  vm.typeChoices = angular.extend({}, NegotiationTypes);
  vm.pageLimit = 30;
  vm.pageOffset = 0;
  vm.auth = Auth;
  vm.sortColumn = $location.search().order || 'seenByMe'; //default
  vm.sortDirection = $location.search().direction || 'ASC'; //default

  $scope.$on('$routeUpdate', function () {
    if ($location.search().order) {
      // reset offset when sorting
      vm.pageOffset = 0;
      getMyNegotiations();
    }
  });

  ////////////

  function gotoShow(id) {
    $location.search({});
    $location.path('/negotiations/' + id);
  }


  let getParams = (all, includeProperties) => {
    let statusValues = vm.statusId ? vm.statusId.split('|') : [null, null];
    return {
      limit: all ? Limits.InMemoryMiddleLimit : vm.pageLimit,
      offset: all ? 0 : vm.pageOffset,
      order: vm.sortColumn,
      direction: vm.sortDirection,
      'filters[caseNumber]': vm.inputTextId,
      'filters[state]': statusValues[0],
      'filters[blockingAuthority]': statusValues[1],
      'filters[type]': vm.typeId,
      'search[name]': vm.inputTextName,
      'search[counterpart.name]': vm.inputTextOrganisation,
      includeProperties,
      mine: true
    }
  };

  vm.getAllNegotiations = (cb, includeProperties) => {
    Negotiation.query(getParams(true, includeProperties), {})
      .$promise.then(function (data) { cb(data) });
  };
  function getMyNegotiations(resetOffset) {
    vm.ready = false;
    if (resetOffset) {
      vm.pageOffset = 0;
    }
    return Negotiation.query(getParams(false), {})
      .$promise.then(function (data) {
        vm.negotiations = data.rows;
        vm.negotiationsCount = data.count;
      })
      .catch(ErrorHandler('Misslyckades att hämta lista.'))
      .finally(function () {
        vm.ready = true;
      });
  }

  getMyNegotiations();
}
})();