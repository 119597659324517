(function () {
'use strict';

angular
  .module('hyresgastApp')
  .controller('ShowNegotiationArtifactsCtrl', ShowNegotiationArtifactsCtrl);

function ShowNegotiationArtifactsCtrl($routeParams, ObjectTypes) {
  var vm = this;
  vm.objectId = $routeParams.id;
  vm.objectType = ObjectTypes.NEGOTIATION.code;
  vm.fileLabels = [
    { id: 1, name: 'Protokoll internt' },
    { id: 2, name: 'Hyreslistor' },
    { id: 3, name: 'Arbetsdokument' },
    { id: 4, name: 'Övriga dokument' },
  ];
}
})();