(function () {
'use strict';

angular.module('hyresgastApp').filter('daysLeft', function(NegotiationStates) {
  return function(days, state, daysLimit) {
    if (days === null || state === NegotiationStates.BLOCKED) {
      return '&ndash;';
    }

    if (days > daysLimit) {
      return daysLimit + '+';
    }

    return days;
  };
});

})();