(function () {
'use strict';

angular.module('hyresgastApp').controller('ListUserCtrl', ListUserCtrl);

function ListUserCtrl($scope, $location, $routeParams, $timeout, $document, $route,
  Parties,
  SpecUser,
  SubPage, Auth, HyUtils, Flash, ErrorHandler) {
  var vm = this;
  vm.userParties = Parties;
  vm.tablist = [
    { title: 'Hyresgästföreningen', path: '/users' },
    { title: 'Organisation', path: '/users?view=external' },
    { title: 'Inbjudna representanter', path: '/users?view=delegate' }
  ];
  vm.child = null;

  getCurrentSubpage();

  $timeout(function() {
    setButtons();
  }, 150);

  $scope.$on('$routeUpdate', function() {
    setButtons();
    // avoid unecessary calling of getCurrentSubpage(); when sorting
    if (!$location.search().order) {
      // reset offset when sorting
      getCurrentSubpage();
    }
  });

  /////////

  function getCurrentSubpage() {
    var defaultSubPage = 'hgf';
    // create a validation list against manually change of URL '?view=' parameter
    var whiteList = ['external', 'delegate'];
    vm.currentSubpage = SubPage.set($routeParams.view, whiteList, defaultSubPage);
  }

  function setButtons() {
    if ($location.search().view === 'external') {
      if (!vm.child) {
        vm.child = HyUtils.bruteSearch($scope, 'userExternalVM');
      }

      vm.buttons = [
        {
          title: 'Ny användare',
          url: '#',
          click: () => { vm.child.newUser() },
          isVisible: () => (Auth && Auth.canDo('createUser')),
          icon: 'newuser',
        }
      ];
    } else {
      vm.buttons = [];
    }
  }

  vm.beginEditEmail = user => {
    vm.editedUser = user;
    vm.newEmail = user.email;
    $timeout(() => {
      let el = $document[0].querySelector('#emailEdit'+user.id);
      el.focus();
    }, 300);
  };
  vm.endEditEmail = () => {
    SpecUser.updateUser({
      id: vm.editedUser.id,
      data: { email: vm.newEmail },
    // eslint-disable-next-line no-unused-vars
    }).$promise.then(response => {
      vm.editedUser.email = vm.newEmail;
      vm.editedUser = null;
      Flash.set('E-post sparad.', 'info');
    }).catch(ErrorHandler('Misslyckades att spara e-post.'));
  };

  vm.deleteUser = userId => {
    HyUtils.showDefaultModal('ConfirmationModalWithMessageCtrl',
      'components/modal/confirmation/confirmationModalWithMessage.html',
      { message: `Vill du verkligen ta bort användare?` },
      // eslint-disable-next-line no-unused-vars
      result => {
        SpecUser.deleteUser({ id: userId })
          // eslint-disable-next-line no-unused-vars
          .$promise.then(response => { $route.reload() })
          .catch(ErrorHandler('Användaren har organisationer eller förhandlingar.'));
      }, null, null, { size: 'md' });
  };
}

})();