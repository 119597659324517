(function () {
'use strict';

// Used in App.js to redirect users depending on their party
// if they have access rights to a given route/page

angular.module('hyresgastApp').factory('UserPermissions', function(Auth) {
  return function(permissions) {
    return Auth.heartbeat(true).then(function() {
      if (!Auth.userHasPermissionForView(permissions)) {
        return '/403';
      }
    });
  };
});

})();