(function () {
'use strict';

angular.module('hyresgastApp').factory('HelperService', function() {
  function dynamicSortSwe(property, order) {
    return function(a, b) {
      const aValue = getPropertyValue(a, property);
      const bValue = getPropertyValue(b, property);
      if (aValue == null || bValue == null) {
        return 0;
      }
      return order.toLowerCase() === 'asc' ? compareMixed(aValue, bValue) : compareMixed(bValue, aValue);
    };
  }

  // Compares mixed strings with numbers and letters
  function compareMixed(a, b) {
    const re = /(\d+|\D+)/g;
    const aParts = String(a).match(re);
    const bParts = String(b).match(re);
    if (!aParts || !bParts) {
      return !!aParts - !!bParts;
    } else {
      while (aParts.length && bParts.length) {
        const aPart = aParts.shift();
        const bPart = bParts.shift();
        const aPartNum = parseInt(aPart, 10);
        const bPartNum = parseInt(bPart, 10);
        if (!isNaN(aPartNum) && !isNaN(bPartNum)) {
          if (aPartNum !== bPartNum) return aPartNum - bPartNum;
        } else if (aPart !== bPart) {
          return aPart.localeCompare(bPart, 'sv');
        }
      }
      return aParts.length - bParts.length;
    }
  }

  // Helper function to get the value of a nested property
  function getPropertyValue(obj, property) {
    return property.split('.').reduce((o, i) => (o ? o[i] : null), obj);
  }

  return {
    dynamicSortSwe: dynamicSortSwe,
  };
});
})();