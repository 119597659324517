(function () {
'use strict';

angular.module('hyresgastApp').filter('hyFilterObjectValuesByKeysEndWith', function() {
  return function(negotiationTypes, category) {
    var ret = [];
    for (var key in negotiationTypes) {
      if (key.match(new RegExp(category + '$'))) {
        ret.push(negotiationTypes[key]);
      }
    }
    return ret;
  };
});

})();