(function () {
'use strict';

angular.module('hyresgastApp').factory('User', function($resource) {
  return $resource(
    '/api/users/:id',
    { id: '@id', organizationId: '@organizationId', email: '@email' },
    {
      query: {
        url: '/api/users',
        isArray: false
      },
      queryOp: {
        url: '/api/users/op',
        isArray: false
      },
      queryByRegion: {
        url: '/api/users/by-region/:regionId'
      },
      queryRequestedByOrganization: {
        url: '/api/users/requested-by-organization/:organizationId',
        isArray: false
      },
      setPassword: {
        url: '/api/users/:id/set-password',
        method: 'POST'
      },
      resetPassword: {
        url: '/api/users/reset-password',
        method: 'POST'
      },
      setPasswordWithToken: {
        url: '/api/users/:id/set-password-with-token',
        method: 'POST'
      },
      queryOrganization: {
        url: '/api/users/:id/organizations',
        isArray: true
      },
      queryNegotiationTables: {
        url: '/api/users/negotiation-tables',
        method: 'GET'
      },
      queryNegotiationPartners: {
        url: '/api/users/me/negotiation-partners',
        method: 'GET'
      },
      queryNegotiationsWithMe: {
        url: '/api/negotiations/mine/with-user/:partnerId',
        method: 'GET'
      },
      verifyEmail: {
        url: '/api/users/verify-email',
        method: 'POST'
      },
      getPhoneByInvite: {
        url: '/api/users/get-phone-by-invite',
        method: 'GET'
      },
      checkEmail: {
        url: '/api/users/check-email',
        method: 'POST'
      },
      hgfOverview: {
        url: '/api/users/hgf-overview',
        method: 'GET',
      },
      exportProperties: {
        url: '/api/users/export-properties',
        method: 'POST',
      },
      getMyCounters: {
        url: '/api/users/my-counters',
        method: 'GET',
      },
    }
  );
});

})();