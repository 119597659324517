(function () {
'use strict';

angular.module('hyresgastApp').controller('ListUserNegotiationCallsCurrentCtrl', ListUserNegotiationCallsCurrentCtrl);

function ListUserNegotiationCallsCurrentCtrl($scope, $location, Negotiation, NegotiationStates, ErrorHandler, FeatureConfiguration) {
  var vm = this;
  vm.getMyCurrentNegotiations = getMyCurrentNegotiations;
  vm.FeatureConfiguration = FeatureConfiguration;
  vm.gotoShow = gotoShow;
  vm.pageLimit = 30;
  vm.pageOffset = 0;
  vm.sortColumn = $location.search().order || 'updatedAt'; // default
  vm.sortDirection = $location.search().direction || 'DESC'; // default
  vm.listColumns = [
    { title: 'Id', column: 'caseNumber' },
    { title: 'Namn', column: 'name' },
    { title: 'Organisation', column: 'counterpart.name' },
    { title: 'Hyresändring', column: 'rentEffectiveFrom' },
    { title: 'Status', column: 'state' },
    { title: 'Senast ändrad', column: 'updatedAt' }
  ];

  $scope.$on('$routeUpdate', function() {
    if ($location.search().order) {
      // reset offset when sorting
      vm.pageOffset = 0;
      getMyCurrentNegotiations();
    }
  });

  /////////

  getMyCurrentNegotiations();

  function gotoShow(id) {
    $location.search({});
    $location.path('/negotiations/' + id);
  }

  function getMyCurrentNegotiations(resetOffset) {
    vm.ready = false;
    if (resetOffset) {
      vm.pageOffset = 0;
    }
    return Negotiation.query({
      limit: vm.pageLimit,
      offset: vm.pageOffset,
      order: vm.sortColumn,
      direction: vm.sortDirection,
      mine: 'true',
      'filters[state]': [
        NegotiationStates.IN_PREPARATION,
        NegotiationStates.IN_PROGRESS,
        NegotiationStates.PARTIALLY_BLOCKED,
        NegotiationStates.SETTLEMENT
      ],
      'filters[caseNumber]': vm.inputTextId,
      'search[name]': vm.inputTextName,
      'search[counterpart.name]': vm.inputTextOrganisation
    })
      .$promise.then(function(data) {
        vm.myCurrentNegotiations = data.rows;
        vm.totalCount = data.count;
      })
      .catch(ErrorHandler('Misslyckades att hämta lista.'))
      .finally(function() {
        vm.ready = true;
      });
  }
}

})();