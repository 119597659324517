(function () {
'use strict';

angular.module('hyresgastApp').filter('documentBelongsTo', function() {
  return function(value) {
    return documentBelongsTo(value);
  };
});

function documentBelongsTo(value) {
  switch (value) {
    case 1:
      return 'agreements';
    case 2:
      return 'meetings';
    default:
      return 'undefined';
  }
}

})();