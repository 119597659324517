(function () {
'use strict';

angular
  .module('hyresgastApp')
  .controller('ShowRealEstateFinishedNegotiationsCtrl', ShowRealEstateFinishedNegotiationsCtrl);

function ShowRealEstateFinishedNegotiationsCtrl(
  $scope, $filter, $location, $routeParams,
  NegotiationStates, NegotiationTypes, RealEstate, Negotiation,
  HelperService, HyUtils,
  ErrorHandler) {

  var vm = this;
  vm.typeSelected = null
  vm.typeChoices = angular.extend({}, NegotiationTypes);
  vm.sortColumn = $location.search().order || 'caseNumber';
  vm.sortDirection = $location.search().direction || 'ASC';

  vm.listColumns = [
    {
      title: 'Id',
      column: 'caseNumber'
    },
    {
      title: 'Namn',
      column: 'name'
    },
    {
      title: 'Organisation',
      column: 'counterpartOrganization.name'
    },
    {
      title: 'Förhandlingstyp',
      column: 'type'
    },
    {
      title: 'Ansvarig hos HGF',
      column: 'hgfNegotiatior.fullName'
    },
    {
      title: 'Ansvarig hos organisation',
      column: 'extNegotiator.fullName'
    },
    {
      title: 'Avslutad datum',
      column: 'finishDate'
    },
  ];

  $scope.$parent.$watch('vm.property', property => {
    if (property) {
      getNegotiationsForProperty(property);
    }
  });

  vm.editComment = negotiation => {
    HyUtils.showDefaultModal('EditCommentModalCtrl',
      'resources/agreement/rentlist/edit/edit-comment-modal.html',
      { comment: negotiation.comment, multipleComments: '' },
      result => {
        if (result.delete || result.comment) {
          negotiation.comment = result.delete ? '' : result.comment;
          Negotiation.saveComments([{
            negotiationId: negotiation.id,
            comment: negotiation.comment,
          }]);
          vm.isDirty = true;
        }
      }, null, null,
      { size: 'md' });
  };

  $scope.$watch(function() {
    return vm.typeSelected;
  }, function(newValues, oldValues) {
    if (!angular.equals(newValues, oldValues)) {
      vm.applyDropdownFilters();
    }
  });

  $scope.$on('$routeUpdate', function() {
    if ($location.search().order) {
      vm.filteredNegotiations.sort(HelperService.dynamicSortSwe(vm.sortColumn, vm.sortDirection));
    }
  });

  ///
  function getNegotiationsForProperty(property) {
    RealEstate.queryNegotiationsForProperty({
      id: $routeParams.id,
      'filters[state]': [NegotiationStates.FINISHED, NegotiationStates.FINISHED_OUTSIDE_PORTAL],
      newPropertyIds: property.newPropertyIds,
      order: vm.sortColumn
    })
      .$promise.then(function(response) {
        vm.finishedNegotiations = response.rows;
        vm.filteredNegotiations = response.rows;
        vm.finishedCount = response.count;
      })
      .catch(ErrorHandler('Misslyckades att hämta förhandlingar.'))
      .finally(function() {
        vm.ready = true;
      });
  }

  vm.setTypeChoicesModel = function() {
    vm.typeChoicesModel = [{
      id: null,
      name: "All",
      displayName: "Alla"
    }];

    for (var key in vm.typeChoices) {
      if (Object.hasOwnProperty.call(vm.typeChoices, key)) {
        vm.typeChoicesModel.push({
          id: vm.typeChoices[key],
          name: key,
          displayName: $filter('hyNegotiationTypes')(vm.typeChoices[key])
        });
      }
    }
  };

  // This filter works in combination with the filter pipe used in the html
  vm.applyDropdownFilters = function() {
    vm.filteredNegotiations = vm.finishedNegotiations.filter(function(x) {
      return vm.typeSelected && vm.typeSelected.id != null ? x.type === vm.typeSelected.id : true
    });
  };

  vm.setTypeChoicesModel();
}
})();