(function () {
'use strict';

angular
  .module('hyresgastApp')
  .controller('ShowArtifactsCtrl', ShowArtifactsCtrl);

function ShowArtifactsCtrl($routeParams, ObjectTypes) {
  var vm = this;
  vm.objectId = $routeParams.id;
  vm.objectType = ObjectTypes.PROPERTY.name;
  vm.fileLabels = [
    { id: 1, name: 'Allmänna utrymmen' },
    { id: 2, name: 'Lägenheter' },
    { id: 3, name: 'Övrig dokumentation rörande fastigheten' },
  ];
}
})();