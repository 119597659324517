(function () {
'use strict';

angular.module('hyresgastApp').controller('OtherUserSelectModalCtrl', OtherUserSelectModalCtrl);

function OtherUserSelectModalCtrl($uibModalInstance, users) {
  var vm = this;
  vm.users = users;

  vm.select = user => { $uibModalInstance.close(user) };
  vm.cancel = () => { $uibModalInstance.close() };
}
})();