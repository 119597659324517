(function () {
'use strict';

angular.module('hyresgastApp').factory('ManualChange', function($resource) {
  return $resource(
    '/api/specops/manual-change', {},
    {
      queryProperty: {
        url: '/api/specops/manual-change/properties',
        method: 'GET'
      },
      queryPropertiesInMyNegotiations: {
        url: '/api/specops/manual-change/my-properties',
        method: 'GET'
      },
      queryPropertyWithApartments: {
        url: '/api/specops/manual-change/properties-with-apartments',
        method: 'GET'
      },
      queryPropertiesInMyNegotiationsWithApartments: {
        url: '/api/specops/manual-change/my-properties-with-apartments',
        method: 'GET'
      },
      queryApartments: {
        url: '/api/specops/manual-change/apartments',
        method: 'GET'
      },
    }
  );
});

})();