(function () {
'use strict';

angular.module('hyresgastApp').controller('ListOrganizationCtrl', ListOrganizationCtrl);

function ListOrganizationCtrl($scope, $location, Organization, Flash, Auth, Office, ErrorHandler) {
  var vm = this;
  vm.Auth = Auth;
  vm.user = {};
  vm.organizations = [];
  vm.getOrganizations = getOrganizations;
  vm.gotoShow = gotoShow;
  vm.pageLimit = 30;
  vm.pageOffset = 0;
  vm.sortColumn = $location.search().order || 'name'; // default
  vm.sortDirection = $location.search().direction || 'ASC'; // default

  vm.headerButtons = [
    {
      title: 'Lägg till',
      url: '/organizations/new',
      icon: 'landlord',
      isVisible: () => (Auth && Auth.canDo('createOrganization'))
    }
  ];
  vm.listColumns = [
    { title: 'Företag', column: 'name' },
    { title: 'Organisationsnummer', column: 'organizationNumber' },
    { title: 'Godkänd av HGF', column: 'approved' },
    { title: 'HGF kontor', column: 'office.name' }
  ];

  vm.approvedChoices = {
    YES: true,
    NO: false
  };

  getOrganizations();
  getOffices();

  $scope.$on('$routeUpdate', function() {
    if ($location.search().order) {
      // reset offset when sorting
      vm.pageOffset = 0;
      getOrganizations();
    }
  });

  ////////////

  function gotoShow(id) {
    $location.search({});
    $location.path('/organizations/' + id);
  }

  function getOrganizations(resetOffset) {
    vm.ready = false;
    if (resetOffset) {
      vm.pageOffset = 0;
    }
    return Organization.query({
      limit: vm.pageLimit,
      offset: vm.pageOffset,
      order: vm.sortColumn,
      direction: vm.sortDirection,
      'search[name]': vm.inputTextOrgName,
      'search[organizationNumber]': vm.inputTextOrgNumber,
      'filters[approved]': vm.approved,
      'filters[office.id]': vm.officeId
    })
      .$promise.then(function(data) {
        vm.organizations = data.rows;
        vm.totalCount = data.count;
      })
      .catch(ErrorHandler('Misslyckades att hämta organisationer.'))
      .finally(function() {
        vm.ready = true;
      });
  }

  function getOffices() {
    Office.query().$promise.then(function(response) {
      vm.offices = response;
    })
      .catch(ErrorHandler('Misslyckades att hämta kontor.'));
  }
}

})();