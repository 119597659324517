(function () {
'use strict';

angular.module('hyresgastApp').filter('regionTypeToDescription', function(Regions) {
  return function(value) {
    return regionTypeToDescription(value, Regions);
  };
});

function regionTypeToDescription(value, Regions) {
  switch (value) {
    case Regions.NORRLAND:
      return 'Norrland';
    case Regions.AROS_GEVLE:
      return 'Aros - Gävle';
    case Regions.STOCKHOLM:
      return 'Stockholm';
    case Regions.MIDDLE:
      return 'Mitt';
    case Regions.WEST_SWEDEN:
      return 'Västra Sverige';
    case Regions.BESK:
      return 'Bäsk';
    case Regions.SOUTH_EAST:
      return 'Sydost';
    case Regions.NORTH_SKONE:
      return 'Norra Skåne';
    case Regions.SOUTH_SKONE:
      return 'Södra Skåne';
    default:
      return 'Alla regioner';
  }
}

})();