(function () {
'use strict';
angular.module('rangeInput', [])
  .directive('rangeInput', () => {
    return {
      scope: {
        min: '=',
        max: '=',
        changed: '&',
        changedInternal: '&',
      },
      restrict: 'E',
      templateUrl: 'components/range-input/range-input.html',
      link: scope => {
        scope.changedInternal = () => { scope.changed({min: scope.min, max: scope.max}) };
      }
    }
  });
})();