(function () {
'use strict';

// Manages users belonging to an organization.
//
// Note: Users are not fetched from one but two API-endpoints because
// there are two types of users that can belong to an organization:
//   - Users
//   - Invited users (not having a user account setup yet)
//
// This service is responsible to abstract away the 'two resource'-complexity
// to consumers of this service by returning the two resources as one.

angular.module('hyresgastApp').factory('UserByOrganization', UserByOrganization);

function UserByOrganization($q, Organization, ErrorHandler) {
  var service = {
    query: query,
    delete: destroy
  };

  return service;

  ////////////

  // Users and Invited users are fetched in parallel and returned as
  // one list. Invited users are differentiated to users with a flag
  // named invited set to TRUE
  function query(organizationId, ommitInvitation) {
    var promises = {
      users: queryUser(organizationId),
      usersInvited: ommitInvitation ? [] : queryInvite(organizationId)
    };

    return $q.all(promises).then(function(response) {
      response.usersInvited.map(function(user) {
        user.invited = true;
        return user;
      });
      return response.users.concat(response.usersInvited);
    });
  }

  function destroy(organizationId, userId, invited, killOrphan) {
    if (invited) {
      return deleteInvite(organizationId, userId);
    } else {
      return deleteUser(organizationId, userId, killOrphan);
    }
  }

  function queryUser(organizationId) {
    return Organization.queryUser({ id: organizationId })
      .$promise.then(function(users) {
        return users;
      })
      .catch(ErrorHandler('Misslyckades att hämta användare.'));
  }

  function deleteUser(organizationId, userId, killOrphan) {
    return Organization.deleteUser({
      organizationId: organizationId,
      id: userId,
      killOrphan
    }).$promise.then(function(response) {
      return response;
    }).catch(err => { throw err });
  }

  function queryInvite(organizationId) {
    return Organization.queryInvite({ id: organizationId })
      .$promise.then(function(invites) {
        return invites;
      })
      .catch(ErrorHandler('Misslyckades att hämta organisation.'));
  }

  function deleteInvite(organizationId, userId) {
    return Organization.deleteInvite({
      organizationId: organizationId,
      id: userId
    }).$promise.then(function(response) {
      return response;
    })
      .catch(ErrorHandler('Misslyckades att ta bort organisation.'));
  }
}

})();