(function () {
'use strict';

/* jshint -W018 */
/* jshint -W071 */
/* jshint -W101 */
/* jshint -W106 */

angular
  .module('uiTabindex', [])

  .directive('uibTabindexToggle', function() {
    return {
      restrict: 'A',
      link: function(scope, elem, attrs) {
        attrs.$observe('disabled', function(disabled) {
          attrs.$set('tabindex', disabled ? -1 : null);
        });
      }
    };
  });

/* jshint +W018 */
/* jshint +W071 */
/* jshint +W101 */
/* jshint +W106 */

})();