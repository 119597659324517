(function () {
'use strict';

angular.module('hyresgastApp').controller('ChangeNegotiatorModalCtrl', ChangeNegotiatorModalCtrl);

function ChangeNegotiatorModalCtrl($uibModalInstance, $filter, $document,
  negotiations,
  Parties, NegotiationRoles, NegotiationStates, Limits,
  User,
  ErrorHandler) {
  var vm = this;
  vm.Limits = Limits;
  vm.NegotiationRoles = NegotiationRoles;
  vm.selectedHgfParticipantRole = null;
  vm.selectedExtParticipantRole = null;
  let badStates = [NegotiationStates.FINISHED, NegotiationStates.FINISHED_OUTSIDE_PORTAL];
  let rows = negotiations.rows.filter(x => !badStates.includes(x.state));
  let hasNegotiator = false;
  let getFullname = negotiator => `${negotiator.firstname} ${negotiator.lastname}`;
  rows.forEach(row => {
    row.type = $filter('hyNegotiationTypes')(row.type);
    if (row.extNegotiator) {
      row.extNegotiator = getFullname(row.extNegotiator);
      hasNegotiator = true;
    }
    if (row.hgfNegotiator) {
      row.hgfNegotiator = getFullname(row.hgfNegotiator);
      hasNegotiator = true;
    }
  });
  let count = Math.min(rows.length, 1000);
  vm.negotiations = { rows, count };

  vm.columns = [
    { title: 'Id',                    column: 'caseNumber' },
    { title: 'Namn',                  column: 'name' },
    { title: 'Förhandlingstyp',       column: 'type' },
  ];
  if (hasNegotiator) {
    vm.columns.push({ title: 'Förhandlare HGF',       column: 'hgfNegotiator' });
    vm.columns.push({ title: 'Förhandlare motpart',   column: 'extNegotiator' });
  }
  //no type, we need space
  vm.confirmationColumns = vm.columns.filter((x, i) => i !== 2);
  vm.fGetData = function () {
    // eslint-disable-next-line no-undef
    return new Promise(resolve => {
      resolve(vm.negotiations);
    });
  }
  vm.newHgf = false;
  vm.newExt = false;
  vm.fGetConfirmation = function () {
    // eslint-disable-next-line no-undef
    return new Promise(resolve => {
      if (!vm.selectedNegotiations || !vm.selectedNegotiations.length) {
        resolve({ rows: [], count: 0 });
      } else {
        let confirmation = vm.selectedNegotiations.map(x => {
          let row = Object.assign({}, x);
          row.newHgfNegotiator = vm.newHgf ? `${vm.selectedHgfParticipant.firstname} ${vm.selectedHgfParticipant.lastname}`: '';
          row.newExtNegotiator = vm.newExt ? `${vm.selectedExtParticipant.firstname} ${vm.selectedExtParticipant.lastname}`: '';
          return row;
        });
        resolve({ rows: confirmation, count: confirmation.length } );
      }
    });
  }

  vm.beforeGotoNext = (cb, currentStepNumber) => {
    let step = parseInt(currentStepNumber);
    switch (step) {
      case 1:
        // eslint-disable-next-line no-undef
        vm.currentHgfNegotiators = Array.from(new Set(vm.selectedNegotiations.map(x => x.hgfNegotiator))).join(', ');
        // eslint-disable-next-line no-undef
        vm.currentExtNegotiators = Array.from(new Set(vm.selectedNegotiations.map(x => x.extNegotiator))).join(', ');
        break;
      case 2:
        vm.newHgf = vm.selectedHgfParticipantRole !== null;
        vm.newExt = vm.selectedExtParticipantRole !== null;
        vm.confirmationColumns.length = vm.columns.length - 1;
        if (vm.newHgf) {
          vm.confirmationColumns.push({ title: 'Ny förhandlare HGF',          column: 'newHgfNegotiator' });
        }
        if (vm.newExt) {
          vm.confirmationColumns.push({ title: 'Ny förhandlare motpart',      column: 'newExtNegotiator' });
        }

        angular.element($document[0].querySelector("#tableauConfirmation")).scope().$broadcast('refresh');

        break;
      default:
        break;
    }
    cb();
  };

  vm.selectHgfParticipant = role => {
    vm.selectedHgfParticipant = vm.hgfParticipant;
    vm.selectedHgfParticipantRole = role;

    vm.hgfParticipant = null;
  }
  vm.selectExtParticipant = role => {
    vm.selectedExtParticipant = vm.extParticipant;
    vm.selectedExtParticipantRole = role;

    vm.extParticipant = null;
  }

  function getUsers() {
    return User.query({})
      .$promise.then(function (response) {
        let users = response.rows;
        vm.hgfUsers = users.filter(u => u.party === Parties.HGF);
        vm.extUsers = users.filter(u => u.party !== Parties.HGF);
      }).catch(new ErrorHandler('Misslyckades att hämta data.'));
  }

  getUsers();

  vm.ok = () => {
    let result = { negotiationIds: vm.selectedNegotiations.map(x => x.id) };
    if (vm.selectedHgfParticipantRole) {
      result.hgf = {
        newChiefId: vm.selectedHgfParticipant.id,
        newRole: vm.selectedHgfParticipantRole.code,
        unassignExistingChief: vm.unassignExistingHgfChief,
      };
    }
    if (vm.selectedExtParticipantRole) {
      result.ext = {
        newChiefId: vm.selectedExtParticipant.id,
        newRole: vm.selectedExtParticipantRole.code,
        unassignExistingChief: vm.unassignExistingExtChief,
      }
    }
    $uibModalInstance.close(result);
  };
  vm.cancel = function() { $uibModalInstance.dismiss('cancel') };
}
})();