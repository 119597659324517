(function () {
'use strict';

angular.module('hyresgastApp').controller('ShowNegotiationCallNegotiationsCtrl', ShowNegotiationCallNegotiationsCtrl);

function ShowNegotiationCallNegotiationsCtrl($scope, $routeParams, $location, NegotiationCall, ErrorHandler) {
  var vm = this;
  vm.gotoShow = gotoShow;

  vm.negotiationsPageLimit = 30;
  vm.negotiationsPageOffset = 0; // always show first items
  vm.queryNegotiations = queryNegotiations;
  vm.sortColumn = $location.search().order || 'createdAt'; // default
  vm.sortDirection = $location.search().direction || 'ASC'; // default
  vm.listColumns = [
    { title: 'Id', column: 'caseNumber' },
    { title: 'Namn', column: 'name' },
    { title: 'Kontor HGF', column: 'office.name' },
    { title: 'Status', column: 'state' },
    { title: 'Skapad den', column: 'createdAt' }
  ];

  queryNegotiations();

  $scope.$on('$routeUpdate', function() {
    if ($location.search().order) {
      // reset offset when sorting
      vm.negotiationsPageOffset = 0;
      queryNegotiations();
    }
  });

  /////////

  function gotoShow(id) {
    $location.search({});
    $location.path('/negotiations/' + id);
  }

  function queryNegotiations(filterMode) {
    vm.readyNegotiations = false;
    if (filterMode) {
      vm.negotiationsPageOffset = 0;
    }
    return NegotiationCall.queryNegotiation(
      {
        id: $routeParams.id,
        limit: vm.negotiationsPageLimit,
        offset: vm.negotiationsPageOffset,
        order: vm.sortColumn,
        direction: vm.sortDirection,
        'search[name]': vm.inputTextNegotiations
      },
      {}
    )
      .$promise.then(function(response) {
        vm.negotiations = response.rows;
        vm.negotiationsTotalCount = response.count;

        // Save initial count of records, to be distingueshed from the current count after
        // unsuccessful filtering. This will affect all messages, which are dependent on totalCount = 0.
        if (filterMode !== true) {
          vm.initialTotalCount = vm.negotiationsTotalCount;
        }
      })
      .catch(ErrorHandler('Misslyckades att hämta förhandling.'))
      .finally(function() {
        vm.readyNegotiations = true;
      });
  }
}

})();