(function () {
'use strict';

angular.module('hyresgastApp').factory('Reports', function($resource) {
  return $resource(
    '/api/reports/:id',
    { id: '@id' },
    {
      query: {
        url: '/api/reports',
        method: 'GET',
      },
      run: {
        url: '/api/reports/run',
        method: 'POST',
      },
      savePdf: {
        url: '/api/reports/save-pdf',
        method: 'POST',
      },
      saveXls: {
        url: '/api/reports/save-xls',
        method: 'POST',
      }
    }
  );
});

})();