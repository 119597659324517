(function () {
'use strict';

angular.module('hyresgastApp').controller('ChangeUserRoleModal', ChangeUserRoleModal);

function ChangeUserRoleModal($uibModalInstance, RoleLookup, Organization, Flash, organizationId, userId, foundAdmin) {
  var vm = this;
  vm.roles = RoleLookup;
  vm.foundAdmin = foundAdmin;

  vm.user = { role: 100 }; // Default: User role
  vm.selectRole = selectRole;
  vm.save = save;

  /////////

  function save() {
    vm.saving = true;
    Organization.updateUserRole({
      organizationId: organizationId,
      id: userId,
      role: vm.user.role
    })
      .$promise.then(
        function(response) {
          Flash.set('Användarens roll har ändrats', 'success');
          $uibModalInstance.close({ id: userId, role: vm.user.role });
        })
      .catch(function(reason) {
        if (reason.status === 403) {
          Flash.set('Måste finnas minst en Admin', 'error');
        } else {
          Flash.set('Användarens roll misslyckades ändras', 'error');
        }
        $uibModalInstance.dismiss('cancel');
      })
      .finally(function() {
        vm.saving = false;
      });
  }

  vm.cancel = function() {
    $uibModalInstance.dismiss('cancel');
  };

  function selectRole(role) {
    vm.user.role = role;
  }
}

})();