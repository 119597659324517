(function () {
'use strict';

// @desc Simple info block to limit duplication and increase
//   readabillity of html
//
// @example
//   <div>
//      <hy-info title="'Foo'" content="'bar'"></hy-info>
//   </div>

angular.module('hyresgastApp').directive('hyInfo', info);

function info() {
  var directive = {
    templateUrl: 'components/info/info.html',
    restrict: 'E',
    scope: {
      title: '@',
      content: '='
    }
  };
  return directive;
}

})();