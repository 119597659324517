(function () {
'use strict';

let xhrUploadFile = function(files, url, progressCb, doneCb) {
  let progresses = Array.from({ length: files.length }, () => 0);
  let results = Array.from({ length: files.length });

  for (let i = 0; i < files.length; i++) {
    let file = files[i];
    // eslint-disable-next-line no-undef
    var formdata = new FormData();

    formdata.append('file1', file);

    // eslint-disable-next-line no-undef
    let xhr = new XMLHttpRequest();
    let fileSize = file.size;

    xhr.upload.onprogress = function (e) {
      if (e.loaded <= fileSize) {
        progresses[i] = Math.round(e.loaded / fileSize * 100);
        let percent = progresses.reduce((acc, v, i, a) => (acc+v/a.length), 0);
        progressCb(percent);
      }
    };
    xhr.onload = function () {
      results[i] = xhr.response ? JSON.parse(xhr.response) : '';
      if (results.every(x => x)) {
        doneCb(results);
      }
    }
    xhr.onerror = function () {
      doneCb(null);
    }

    xhr.open('post', url);
    xhr.withCredentials = true;
    xhr.timeout = 4500000;
    xhr.send(formdata);
  }
};

let makeBuffers = function (files) {
  // eslint-disable-next-line no-undef
  return new Promise(resolve => {
    let result = [];
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      // eslint-disable-next-line no-undef
      let fr = new FileReader();
      fr.onload = function () {
        let data = fr.result;
        // eslint-disable-next-line no-undef
        let array = new Uint8Array(data);
        result.push({
          filename: file.name,
          buffer: Array.from(array)
        });
        if (result.length === files.length) {
          resolve(result);
        }
      };
      fr.readAsArrayBuffer(file);
    }
  });
};

angular.module('filePlate', []).directive('hyFilePlate', hyFilePlate);

function hyFilePlate($location, $timeout,
  LocalApiPort) {
  return {
    restrict: 'E',
    templateUrl: 'components/file-plate/file-plate.html',
    scope: {
      multiple: '=',
      uploadUrl: '@',
      uploadParams: '=',
      validFileTypes: '@',

      buffers: '=',
      blobIds: '=',
      metadata: '=',
      ready: '=',
    },
    // eslint-disable-next-line no-unused-vars
    link: (scope, element, attrs) => {
      scope.busy = false;
      scope.currentFile = null;

      let processFiles = files => {
        if (files.length) {
          if (scope.uploadUrl) {
            scope.currentFileString = files.length === 1
              ? files[0].name
              : `${files[0].name} (+${files.length-1})`;
            scope.currentFiles = files;
            scope.ready = true;
            scope.$apply();
          } else if (scope.buffers) {
            scope.buffers.length = 0;
            makeBuffers(files).then(result => {
              scope.buffers = result;
              scope.$apply();
            });
          }
        }
      };

      let url =  'localhost' === $location.host()
        ? 'http://localhost:' + LocalApiPort + scope.uploadUrl
        : scope.uploadUrl;

      element.bind('drop', event => {
        event.stopPropagation();
        event.preventDefault();

        let files = event.dataTransfer.files
          ? event.dataTransfer.files
          : event.dataTransfer.items.filter(x => x.kind === 'file').map(x => x.getAsFile());

        if (scope.validFileTypes) {
          let validExts = scope.validFileTypes.split(' ');
          files = [...files].filter(x => validExts.some(ext => x.name.includes(ext)));
        }

        processFiles(files);
      });

      // eslint-disable-next-line angular/document-service, no-undef
      let dialogEl = document.getElementById(scope.multiple ? 'file-plate-dialog-multiple' : 'file-plate-dialog');
      scope.$watch('validFileTypes', newValue => {
        if (newValue) {
          dialogEl.accept = '.' + newValue.split(' ').join(',.');
        }
      });
      dialogEl.onchange = e => {
        processFiles(e.target.files);
      };
      scope.select = function () {
        dialogEl.click();
      }

      scope.clear = function () {
        scope.currentFileString = null;
        scope.ready = false;
      }

      scope.$on('do-upload', () => {
        scope.busy = true;
        // eslint-disable-next-line angular/document-service, no-undef
        let plateEl = document.getElementById('file-plate-component');

        if (scope.uploadParams) {
          let paramDipstick = Object.keys(scope.uploadParams)
            .map(k => `${k}=${scope.uploadParams[k]}`).join('&');
          if (paramDipstick.length) {
            url = `${url}?${paramDipstick}`;
          }
        }

        xhrUploadFile(scope.currentFiles, url,
          percent => { plateEl.style.backgroundSize = `${percent}% 100%, 100% 100%` },
          results => {
            plateEl.style.backgroundSize = `100% 100%, 100% 100%`;
            scope.busy = false;
            scope.done = true;
            results.forEach(x => x.lastModified = new Date());
            scope.blobIds = results.map(x => x.blobId);
            scope.metadata = results;
            scope.$apply();
          }
        );
      });

      scope.$on('do-reset', () => {
        let plateEl = document.getElementById('file-plate-component');
        plateEl.style.backgroundSize = `0 100%, 100% 100%`;
        scope.currentFileString = '';
        scope.ready = false;
        dialogEl.value = '';
        scope.done = false;
        console.log('reset');
      });
    },
  };
}
})();