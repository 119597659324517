(function () {
'use strict';
angular.module('hyresgastApp').controller('MultiSelectModalCtrl', MultiSelectModalCtrl);

function MultiSelectModalCtrl($uibModalInstance, groups, selections, allOmit, idProperty, textProperty, title) {

  var vm = this;

  vm.groups = groups;
  vm.title = title;
  vm.idProperty = idProperty;
  vm.textProperty = textProperty;

  var omitted = value => allOmit && selections.length === 0 ? true : value;

  vm.selection = {};
  vm.selectionGroups = {};
  Object.keys(groups).forEach(key => {
    groups[key].forEach(value => {
      let checkboxKey = value[idProperty];
      vm.selection[checkboxKey] = {
        ticked: omitted(selections.includes(checkboxKey)),
        group: key,
      };
    });
    vm.selectionGroups[key] = omitted(selections.containsArray(groups[key].map(item => item[idProperty])));
  });

  let getCheckboxes = groupKey => Object.keys(vm.selection).filter(chkKey => vm.selection[chkKey].group === groupKey);

  vm.toggleGroup = (key, newValue) => {
    getCheckboxes(key).forEach(chkKey => vm.selection[chkKey].ticked = newValue);
  };

  vm.maybeResetGroup = (groupKey, key, newValue) => {
    if (!newValue) {
      vm.selectionGroups[groupKey] = false;
    // eslint-disable-next-line eqeqeq
    } else if (getCheckboxes(groupKey).every(chk => chk == key || vm.selection[chk].ticked)) {
      vm.selectionGroups[groupKey] = true;
    }
  };

  vm.ok = () => {
    let result = Object.keys(vm.selection).filter(checkboxKey => vm.selection[checkboxKey].ticked);
    $uibModalInstance.close(result);
  };
}
})();