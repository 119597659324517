(function () {
'use strict';

angular.module('hyresgastApp').controller('ListNegotiationAllCtrl', ListNegotiationAllCtrl);

function ListNegotiationAllCtrl(
  $scope,
  $location,
  $routeParams,
  Auth,
  Parties,
  Negotiation,
  CombinedNegotiationStates,
  Regions,
  NegotiationTypes,
  Limits,
  ErrorHandler
) {
  var vm = this;
  vm.statusChoices = angular.extend(
    {
      ALL: ''
    },
    CombinedNegotiationStates
  );
  vm.regionChoices = angular.extend(
    {
      ALL: ''
    },
    Regions
  );
  vm.typeChoices = angular.extend({}, NegotiationTypes);
  vm.myListOfNegotiations = [];
  vm.getMyListOfNegotiations = getMyListOfNegotiations;
  vm.gotoShow = gotoShow;
  vm.pageLimit = 30;
  vm.pageOffset = 0;
  vm.sortColumn = $location.search().order || 'callForNegotiation.negotiationDate';
  vm.sortDirection = $location.search().direction || 'ASC';

  Auth.heartbeat().then(function() {
    if (Auth.currentUser().party === Parties.HGF) {
      vm.isHgfUser = true;
      vm.listColumns = [
        {
          title: 'Id',
          column: 'caseNumber'
        },
        {
          title: 'Namn',
          column: 'name'
        },
        {
          title: 'Organisation',
          column: 'counterpart.name'
        },
        {
          title: 'Förhandlingstyp',
          column: 'type'
        },
        {
          title: 'Status',
          column: 'combinedState'
        },
        {
          title: 'Begärt ändringsdatum',
          column: 'rentEffectiveFrom'
        },
        {
          title: 'Önskat datum för förhandling',
          column: 'callForNegotiation.negotiationDate'
        }
      ];
    } else {
      vm.listColumns = [
        {
          title: 'Id',
          column: 'caseNumber'
        },
        {
          title: 'Namn',
          column: 'name'
        },
        {
          title: 'Organisation',
          column: 'counterpart.name'
        },
        {
          title: 'Förhandlingstyp',
          column: 'type'
        },
        {
          title: 'Status',
          column: 'combinedState'
        },
        {
          title: 'Ansvarig hos oss',
          column: 'extNegotiator.firstname'
        },
        {
          title: 'Ansvarig HGF',
          column: 'hgfNegotiator.firstname'
        },
        {
          title: 'Önskat datum för förhandling',
          column: 'callForNegotiation.negotiationDate'
        }
      ];
    }
  });

  $scope.$on('$routeUpdate', function() {
    if ($location.search().order) {
      // reset offset when sorting
      vm.pageOffset = 0;
      getMyListOfNegotiations();
    }
  });

  function gotoShow(id) {
    $location.search({});
    $location.path('/negotiations/' + id);
  }

  let getParams = (all, includeProperties) => {
    let statusValues = vm.statusId ? vm.statusId.split('|') : [null, null];
    return {
      limit: all ? Limits.InMemoryMiddleLimit : vm.pageLimit,
      offset: all ? 0 : vm.pageOffset,
      order: vm.sortColumn,
      direction: vm.sortDirection,
      'filters[caseNumber]': vm.inputTextId,
      'filters[office.regionId]': vm.regionId,
      'filters[state]': statusValues[0],
      'filters[blockingAuthority]': statusValues[1],
      'filters[type]': vm.typeId,
      'search[name]': vm.inputTextName,
      'search[counterpart.name]': vm.inputTextOrganisation,
      'search[office.name]': vm.inputTextOffice,
      includeProperties,
    };
  };

  vm.getAllNegotiations = (cb, includeProperties) => {
    Negotiation.query(getParams(true, includeProperties), {})
      .$promise.then(function (data) { cb(data) });
  };
  function getMyListOfNegotiations(filterMode) {
    vm.ready = false;
    if (filterMode) {
      vm.pageOffset = 0;
    }
    return Negotiation.query(getParams(), {})
      .$promise.then(function(data) {
        vm.myListOfNegotiations = data.rows;
        vm.myListOfNegotiationsCount = data.count;
        // Save initial count of records, to be distingueshed from the current count after
        // unsuccessful filtering. This will affect all messages, which are dependent on totalCount = 0.
        if (filterMode !== true) {
          vm.initialTotalCount = vm.myListOfNegotiationsCount;
        }
      })
      .catch(ErrorHandler('Misslyckades att hämta lista.'))
      .finally(function() {
        vm.ready = true;
      });
  }

  //start
  getMyListOfNegotiations();
}

})();