(function () {
'use strict';

angular.module('hyresgastApp').controller('ShowUserCtrl', ShowUserCtrl);

function ShowUserCtrl($location, $routeParams, Auth, HyUtils, User, Parties, Negotiation, RealEstate, ErrorHandler) {
  var vm = this;
  vm.user = {};
  vm.gotoShowOrganization = gotoShowOrganization;
  vm.gotoShowNegotiation = gotoShowNegotiation;
  vm.parties = Parties;
  vm.exportProperties = exportProperties;

  Auth.heartbeat().then(function() {
    if (parseInt($routeParams.id) === Auth.currentUser().id) {
      vm.meMode = true; // updating my own user
    }
    getUser();
  });

  ////////////

  function getUser() {
    return User.get({ id: $routeParams.id })
      .$promise.then(function(response) {
        vm.user = response;
        getProperties();
        // Convert response.offices object to an array for easily sorting and using length property
        var offices = [];
        for (var office in response.offices) {
          offices.push(response.offices[office]);
        }
        vm.user.offices = offices;
        if (vm.user.party === Parties.EXTERNAL) {
          getOrganizations();
        }
        if (vm.user.party === Parties.DELEGATE) {
          getDelegateParticipantNegotiations();
        }
      })
      .catch(ErrorHandler('Misslyckades att hämta användaren.'))
      .finally(function() {
        vm.ready = true;
      });
  }

  function getProperties() {
    RealEstate.queryPropertiesInMyNegotiations({
      id: vm.user.id
    }).$promise.then(properties => {
      vm.user.properties = properties.rows;
    });
  }

  function exportProperties() {
    User.hgfOverview({
      'filters[party]': Parties.HGF,
      enchancements: 'properties',
    }).$promise.then(result => {
      HyUtils.showDefaultModal('OtherUserSelectModalCtrl',
        'resources/user/show/other-user-select-modal.html', {
          users: result.users,
        }, result => {
          User.exportProperties({
            sourceUserId: result.id,
            targetUserId: vm.user.id,
          }).$promise.then(() => getProperties());
        }, null, null, { size: 'md' });
    });
  }

  function getOrganizations() {
    return User.queryOrganization({ id: $routeParams.id })
      .$promise.then(function(response) {
        vm.organizations = response;
      })
      .catch(ErrorHandler('Misslyckades att hämta organisation.'))
      .finally(function() {
        vm.readyOrganizations = true;
      });
  }

  function gotoShowOrganization(id) {
    $location.search({});
    $location.path('/organizations/' + id);
  }

  function getDelegateParticipantNegotiations() {
    vm.readyNegotiations = false;
    return Negotiation.query({
      participant: $routeParams.id,
    },
    {}
    )
      .$promise.then(function(data) {
        vm.negotiations = data.rows;
      })
      .catch(ErrorHandler('Misslyckades att hämta förhandlingar.'))
      .finally(function() {
        vm.readyNegotiations = true;
      });
  }

  function gotoShowNegotiation(id) {
    $location.search({});
    $location.path('/negotiations/' + id);
  }
}

})();