(function () {
'use strict';

angular.module('hyresgastApp').controller('ShowHelpFaqCtrl', ShowHelpFaqCtrl);

function ShowHelpFaqCtrl(Auth) {
  var vm = this;

  Auth.heartbeat().then(function() {
    vm.ready = true;
  });

  /////////
}

})();