(function () {
'use strict';

angular.module('hyresgastApp').controller('EditUserModalCtrl', EditUserModalCtrl);

function EditUserModalCtrl(
  $uibModalInstance, $timeout,
  userId,
  User, SpecUser, Organization,
  Flash, ErrorHandler
) {
  // Responsible for create, update and delete

  var vm = this;
  vm.tabs = [
    { title: 'Grundinformation', key: 'main' },
    { title: 'Organisation', key: 'organization' },
    { title: 'Behörigheter', key: 'permissions' },
  ];
  vm.user = {};
  vm.roles = [
    { id: 0,   text: 'Medlem (ej godkänd)' },
    { id: 100, text: 'Medlem' },
    { id: 200, text: 'Admin' },
  ];

  ////////////
  function queryOrganization(cb) {
    vm.loading = true;
    Organization.query({
      order: 'name',
      direction: 'ASC',
      'search[name]': vm.inputTextOrgName
    }).$promise.then(function (response) {
      vm.organizations = response.rows;
      vm.loading = false;
      if (cb) {
        return cb();
      }
    }).catch(new ErrorHandler('Misslyckades att hämta organisation.'))
      .finally(() => { vm.loading = false });
  }
  function getUser() {
    return User.get({ id: userId })
      .$promise.then(function (response) {
        vm.ready = true;
        vm.user = response;
        vm.user.roleByOrganization = vm.user.roleByOrganizationUnleashed;
        queryOrganization(() => {
          let organizationIds = Object.keys(vm.user.roleByOrganization);
          vm.selectedOrganizations = vm.organizations.filter(x => organizationIds.includes(x.id.toString()));
          vm.selectedOrganizations.forEach(x => x.role = vm.roles.find(r => r.id === vm.user.roleByOrganization[x.id]) );
        });
      })
      .catch(ErrorHandler('Misslyckades att hämta användaren.'));
  }

  vm.orgAfterType = () => {
    if (vm.orgTimer) {
      $timeout.cancel(vm.orgTimer);
    }
    vm.orgTimer = $timeout(queryOrganization, 500);
  };
  vm.selectOrganization = organization => {
    if (vm.selectedOrganizations.find(x => x.id === organization.id)) {
      return;
    }
    let newOrganization = Object.assign({ role: vm.roles[0] }, organization);
    vm.selectedOrganizations.push(newOrganization);
  };
  vm.deselectOrganization = organization => {
    vm.selectedOrganizations = vm.selectedOrganizations.filter(x => x.id !== organization.id);
  };

  vm.whyDisabled = () => {
    if (!vm.selectedOrganizations || vm.selectedOrganizations.length === 0) {
      return 'Minst en organisation måste väljas';
    }
    return '';
  };

  vm.changePassword = () => {

    vm.savingPassword = true;

    let credentials = {
      id: vm.user.id,
      password: vm.newPassword,
    };

    User.setPassword(credentials)
      // eslint-disable-next-line no-unused-vars
      .$promise.then(response => { Flash.set('Ändring av lösenord lyckades.', 'success') })
      .catch(ErrorHandler('Ändring av lösenord misslyckades. Kontrollera ditt nytt lösenord.'))
      .finally(() => {
        vm.newPassword = null;
        vm.newPasswordAgain = null;
        vm.unterpopupNewPassword = false;
        vm.savingPassword = false;
      });
  };

  vm.saving = false;
  vm.save = () => {
    vm.saving = true;
    if (vm.selectedOrganizations) {
      vm.user.roleByOrganization = vm.selectedOrganizations.reduce((acc, el) => {
        acc[el.id] = el.role.id;
        return acc;
      }, {});
    }

    SpecUser.updateUser({ id: userId, data: vm.user })
      .$promise.then(function (response) {
        Flash.set('Användaren har sparats', 'success');
        $uibModalInstance.close(response);
      })
      .catch(function () {
        Flash.set('Misslyckades att spara användare.', 'error');
        $uibModalInstance.dismiss('cancel');
      })
      .finally(function () {
        vm.saving = false;
      });
  };

  vm.cancel = () => { $uibModalInstance.dismiss() };

  //start
  getUser();
}

})();