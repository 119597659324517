(function () {
'use strict';

// https://github.com/witoldsz/angular-http-auth
//
// AuthInterceptor intecepts 401 and buffers the initial request. When user
// eventually log in the buffered request will be sent to the API.
//
// @config
//   Ignoring the 401 interceptor: Sometimes you might not want the interceptor
//   to intercept a 401. In a case like this you can add ignoreAuthModule: true
//   to the request config.

angular.module('httpAuthInterceptor', []).factory('AuthInterceptor', function($rootScope, $q, httpBuffer) {
  return {
    responseError: function(rejection) {
      var config = rejection.config || {};
      if (!config.ignoreAuthModule) {
        switch (rejection.status) {
          case 401:
            var deferred = $q.defer();
            var bufferLength = httpBuffer.append(config, deferred);
            if (bufferLength === 1) {
              $rootScope.$broadcast('event:auth-loginRequired', rejection);
            }
            return deferred.promise;
        }
      }
      // otherwise, default behaviour
      return $q.reject(rejection);
    }
  };
});

})();