(function () {
'use strict';

angular.module('hyresgastApp').controller('ShowOrganizationCtrl', ShowOrganizationCtrl);

function ShowOrganizationCtrl($location, $routeParams, Flash, Organization, ErrorHandler) {
  var vm = this;
  vm.organization = {};

  getOrganization();

  ////////////

  function getOrganization() {
    return Organization.get({ id: $routeParams.id })
      .$promise.then(function(response) {
        vm.organization = response;
      })
      .catch(ErrorHandler('Misslyckades att hämta organisation.'))
      .finally(function() {
        vm.ready = true;
      });
  }
}

})();