(function () {
'use strict';

angular.module('hyresgastApp').controller('EditRentalLevelModalCtrl', EditRentalLevelModalCtrl);

function EditRentalLevelModalCtrl($uibModalInstance, $timeout, $document,
  PortalSettings,
  isNewConstruction, nhRange) {
  var vm = this;
  vm.nhRange = nhRange;

  vm.ranges = [{ min: nhRange.min }];

  vm.percentMode = 'single';

  PortalSettings.getSuggestedRentByNHRange(ranges => {
    if (ranges && ranges.length) {
      let rangeMatch = ranges.find(r => nhRange.min >= r.min && nhRange.max <= r.max);
      if (rangeMatch) {
        vm.suggestedPercent = rangeMatch.number;
      }
    }
  });

  vm.tabs = isNewConstruction ? [] : [
    { title: 'Förändring', key:'relative' },
    { title: 'Ny hyra', key:'absolute' },
  ];

  vm.currentTab = isNewConstruction ? 'absolute' : 'relative';

  vm.months = 12;
  vm.toggleMonths = () => {
    vm.months = vm.months === 12 ? 10 : 12;
    vm.setMonthly();
  };

  var percentTimer = null;
  vm.setPercent = () => {
    if (percentTimer) {
      $timeout.cancel(percentTimer);
    }
    percentTimer = $timeout(() => {
      let el = $document[0].getElementById('percent');
      if (el) {
        let value = el.value;
        vm.percent = parseFloat(parseFloat(value).toFixed(2));
        if (isNaN(vm.percent)) {
          vm.percent = value;
        }
      }
    }, 1000);
  };

  vm.focusOn = () => {
    $timeout(() => {
      let el = $document[0].getElementById(vm.changeMethod === '0' ? 'percent' : 'monthly');
      if (el) {
        el.focus();
      }
    }, 300);
  }

  vm.setYearly = () => {
    // TODO: consider using unificated math function for rounding
    vm.monthly = Math.round((vm.yearly / vm.months + Number.EPSILON) * 100) / 100;
    vm.percent = null;
  };
  vm.setMonthly = () => {
    // TODO: consider using unificated math function for rounding
    vm.yearly = Math.round((vm.monthly * vm.months + Number.EPSILON) * 100) / 100;
    vm.percent = null;
  };

  let isRangeValid = range => range.min && range.max && range.number;
  vm.addRange = () => {
    if (!vm.ranges) {
      vm.ranges = [{ min: nhRange.min }];
      return;
    }
    let lastRow = vm.ranges[vm.ranges.length-1];
    if (isRangeValid(lastRow)) {
      vm.ranges.push({ min: lastRow.max + 1 });
    }
  };
  vm.deleteRange = range => { vm.ranges = vm.ranges.filter(x => x !== range) };

  vm.okToSave = () => {
    let changeMethod = parseInt(vm.changeMethod);
    switch (changeMethod) {
      case 0:
        var singlePercent = typeof(vm.percent) === "number" && vm.percent >= 0 && vm.percent <= 100;
        var range = vm.ranges && vm.ranges.every(range => isRangeValid(range));
        return vm.percentMode === 'single' ? singlePercent : range;
      case 1:
      case 2:
        return vm.monthly && vm.yearly;
    }
  };
  vm.ok = () => {
    $uibModalInstance.close({
      isRelative: vm.currentTab === 'relative',
      changeMethod: parseInt(vm.changeMethod),
      percent: vm.percentMode === 'single' ? vm.percent : null,
      percentByNhRange: vm.percentMode === 'nhrange' ? vm.ranges : null,
      yearly: vm.yearly,
      monthly: vm.monthly,
      months: vm.months,
    });
  }
  vm.cancel = () => { $uibModalInstance.dismiss(); };
}
})();