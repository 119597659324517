(function () {
'use strict';

angular.module('dateLocal', []).factory('DateLocal', DateLocal);

function DateLocal() {
  var service = {
    fromUTCtoLocal: fromUTCtoLocal
  };

  return service;

  ////////////

  // Remove timezone specific part of a datetime object.
  // Example: The timedate and timezone values will normally be submitted to
  // the server as separate data points, and then the server needs
  // to store them appropriately in the database.
  function fromUTCtoLocal(date) {
    if (date === null || date === undefined) {
      return null;
    }

    var ten = function(i) {
        return (i < 10 ? '0' : '') + i;
      },
      YYYY = date.getFullYear(),
      MM = ten(date.getMonth() + 1),
      DD = ten(date.getDate()),
      HH = ten(date.getHours()),
      II = ten(date.getMinutes()),
      SS = ten(date.getSeconds());

    return YYYY + '-' + MM + '-' + DD + 'T' + HH + ':' + II + ':' + SS;
  }
}

})();