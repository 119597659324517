(function () {
'use strict';

angular.module('hyresgastApp').filter('hyNegotiationTypes', function(NegotiationTypes) {
  return function(value) {
    return hyNegotiationTypes(value, NegotiationTypes);
  };
});

function hyNegotiationTypes(value, NegotiationTypes) {
  switch (value) {
    case NegotiationTypes.ANNUAL_PROPERTY:
      return 'Årlig hyresförhandling';
    case NegotiationTypes.STANDARD_PROPERTY:
      return 'Standardförändring';
    case NegotiationTypes.STANDARD_APARTMENT:
      return 'Standardförändring av lägenheter';
    case NegotiationTypes.REBUILD_PROPERTY:
      return 'Hela fastigheten ombyggnation/renovering';
    case NegotiationTypes.REBUILD_PROPERTY_2:
      return 'Enskild lägenhet ombyggnation/renovering';
    case NegotiationTypes.REBUILD_APARTMENT:
      return 'Ombyggnation/renovering av lägenheter';
    case NegotiationTypes.NEWLY_CONSTRUCTED_PROPERTY:
      return 'Nyproduktion (fastighet saknas i register)';
    case NegotiationTypes.NEWLY_CONSTRUCTED_APARTMENT:
      return 'Nyproduktion';
    case NegotiationTypes.UTILITY_VALUE_PROPERTY:
      return 'Bruksvärdesförhandling';
    case NegotiationTypes.UTILITY_VALUE_APARTMENT:
      return 'Bruksvärdesförhandling av lägenheter';
    default:
      return 'Ej specifierad förhandlingstyp';
  }
}

})();