(function () {
'use strict';

angular.module('PortalSettings', []).factory('PortalSettings', PortalSettings);

function PortalSettings(Settings, SettingTypes) {

  let settings = [];

  let load = cb => {
    Settings.load({})
      .$promise.then(result => {
        settings = result;
        cb();
      });
  }

  let maybeLoad = cb => {
    if (!settings.length) {
      load(() => { cb() });
    } else {
      cb();
    }
  };

  let findSetting = key => {
    let setting = settings.find(x => x.key === key);
    let value = null;
    if (!setting || !setting.value) {
      return value;
    }

    try {    
      switch (setting.type) {
        case SettingTypes.NUMBER:
          value = parseFloat(setting.value);
          break;
        case SettingTypes.BOOL:
          value = Boolean(setting.value);
          break;
        case SettingTypes.RANGE2NUMBER:
        case SettingTypes.JSON:
          value = JSON.parse(setting.value);
          break;

        default:
        case SettingTypes.STRING:
        case SettingTypes.TEXT:
          value = setting.value;
          break;
      }
    } catch (err) {
      //relax
    }

    return value;
  }

  let getSetting = (key, cb) => {
    maybeLoad(() => { cb(findSetting(key)) });
  };

  return {
    getSetting,

    getSuggestedRentByNHRange:  cb => getSetting('suggested-rent-by-nh-range', cb),
    getFooterInfo:              cb => {
      maybeLoad(() => {
        let result = {
          footerEmail: findSetting('footer-email'),
          footerPhone: findSetting('footer-phone'),
          footerHours: findSetting('footer-hours'),
        };
        cb(result);
      });
    },
  }
}
})();