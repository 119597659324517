(function () {
'use strict';

angular.module('hyresgastApp').controller('ListUserExternalCtrl', ListUserExternalCtrl);

function ListUserExternalCtrl($scope, $location,
  User,
  HyUtils, ErrorHandler) {
  var vm = this;
  vm.users = [];
  vm.getUsers = getUsers;
  vm.gotoShow = gotoShow;
  vm.pageLimit = 30;
  vm.pageOffset = 0;
  vm.sortColumn = $location.search().order || 'lastname'; // default
  vm.sortDirection = $location.search().direction || 'ASC'; // default

  vm.listColumns = [
    { title: 'Förnamn', column: 'firstname' },
    { title: 'Efternamn', column: 'lastname' },
    { title: 'Telefonnummer', column: 'phone' },
    { title: 'E-post', column: 'email' },
    { title: 'Part', column: 'party' }
  ];

  getUsers();

  $scope.$on('$routeUpdate', function() {
    if ($location.search().order) {
      // reset offset when sorting
      vm.pageOffset = 0;
      getUsers();
    }
  });

  ////////////

  function gotoShow(id) {
    $location.search({});
    $location.path('/users/' + id);
  }

  function getUsers(resetOffset) {
    vm.ready = false;
    if (resetOffset) {
      vm.pageOffset = 0;
    }
    return User.query({
      limit: vm.pageLimit,
      offset: vm.pageOffset,
      order: vm.sortColumn,
      direction: vm.sortDirection,
      'filters[party]': 0,
      'search[firstname]': vm.inputTextFirstName,
      'search[lastname]': vm.inputTextLastName,
      'search[email]': vm.inputTextEmail
    })
      .$promise.then(function(data) {
        vm.users = data.rows;
        vm.totalCount = data.count;
      })
      .catch(ErrorHandler('Misslyckades att hämta användaren.'))
      .finally(function() {
        vm.ready = true;
      });
  }

  vm.newUser = () => {
    HyUtils.showDefaultModal('NewUserModalCtrl',
      'resources/user/new/new-user-modal.html',
      { fullMode: true },
      () => {
        getUsers();
      });
  };

  vm.editUser = userId => {
    HyUtils.showDefaultModal('EditUserModalCtrl',
      'resources/user/edit/edit-user-modal.html',
      { userId },
      () => {
        getUsers();
      });
  };
}

})();