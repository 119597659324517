(function () {
'use strict';

/* jshint -W040 */

angular.module('modalWizard.ctrl', []).controller('ModalWizardCtrl', ModalWizardCtrl);

function ModalWizardCtrl($scope, $attrs, $location, $timeout) {
  var wizardVm = this;
  var firstEnabledOpened;

  wizardVm.hideHeader = 'hideHeader' in $attrs;
  wizardVm.alternativeWizard = $attrs.alternativeWizard;
  // This array keeps track of the wizard steps
  wizardVm.steps = [];
  wizardVm.currentStepNumber = 1;
  wizardVm.closeOthers = closeOthers;
  wizardVm.addStep = addStep;
  wizardVm.removeStep = removeStep;
  wizardVm.next = next;
  wizardVm.previous = previous;
  wizardVm.isFirstStep = isFirstStep;
  wizardVm.isLastStep = isLastStep;
  wizardVm.isCurrentStepValid = isCurrentStepValid;
  wizardVm.enabledSteps = [];
  wizardVm.setEnabledSteps = setEnabledSteps;
  wizardVm.hideNextButton = hideNextButton;

  /*$scope.$on('$locationChangeSuccess', function() {
    if ($location.search().step !== undefined) {
      gotoStepByName($location.search().step);
    }
  });*/

  $scope.$watch('gotoNext', function(value) {
    if (value) {
      //wrapped inside $timeout so newly enabled steps are included.
      $timeout(function() {
        next();
      });
      $scope.gotoNext = false;
    }
  });

  $scope.isCurrentStepValid = isCurrentStepValid;
  ////////////

  function setCurrentStepNumber(step) {
    wizardVm.currentStepNumber = step.headingStepNumber;
  }

  function isCurrentStepValid() {
    var foundStep;
    angular.forEach(wizardVm.steps, function(step) {
      if (step.isOpen) {
        foundStep = step;
      }
    });

    if (foundStep) {
      // If no validation function is provided, allow the user to enter the step
      if (foundStep.isValid === undefined) {
        return false;
      }
      // If validation is a boolean value instead of a function, return the value
      if (typeof foundStep.isValid === 'boolean') {
        return foundStep.isValid;
      }

      return foundStep.isValid();
    }

    return false;
  }

  // Open next step that is not disabled
  function next() {
    if ($scope.beforeGotoNext) {
      // eslint-disable-next-line angular/controller-as
      $scope.beforeGotoNext({ cb: () => { nextnext() }, currentStepNumber: wizardVm.currentStepNumber });
    } else {
      nextnext();
    }
  }

  function nextnext() {
    var nextStep;
    angular.forEach(wizardVm.enabledSteps, function(step, index) {
      if (step.isOpen && index < wizardVm.enabledSteps.length - 1) {
        nextStep = wizardVm.enabledSteps[index + 1];
      }
    });
    setCurrentStepNumber(nextStep);
    gotoStepByName(nextStep.name);
   //$location.search(angular.extend($location.search(), { step: nextStep.name }));
  }

  // Open previous step that is not disabled
  function previous() {
    var previousStep;

    angular.forEach(wizardVm.enabledSteps, function(step, index) {
      if (step.isOpen && index > 0) {
        previousStep = wizardVm.enabledSteps[index - 1];
      }
    });
    setCurrentStepNumber(previousStep);
    gotoStepByName(previousStep.name);
    //$location.search(angular.extend($location.search(), { step: previousStep.name }));
  }

  function gotoStepByName(name) {
    var foundStep;
    var foundAtIndex;
    angular.forEach(wizardVm.enabledSteps, function(step, index) {
      if (step.name === name) {
        foundStep = step;
        foundAtIndex = index;
      }
    });
    // Ensure all prior steps are valid if using browser buttons
    var foundInvalidStep;
    angular.forEach(wizardVm.enabledSteps, function(step, index) {
      if (!isStepValid(step) && index < foundAtIndex) {
        foundInvalidStep = true;
        foundStep = step;
      }
    });

    if (foundInvalidStep) {
      //$location.search(angular.extend($location.search(), { step: foundStep.name })); // goto invalid step instead
      //$location.replace();
    } else {
      foundStep.isOpen = true;
    }
    setCurrentStepNumber(foundStep);
  }

  function isStepValid(step) {
    // If no validation function is provided, allow the user to enter the step
    if (step.isValid === undefined) {
      return true;
    }
    // If validation is a boolean value instead of a function, return the value
    if (typeof step.isValid === 'boolean') {
      return step.isValid;
    }

    return step.isValid();
  }

  // Ensure that all the steps in this wizard are closed
  function closeOthers(openStep) {
    angular.forEach(this.steps, function(step) {
      if (step !== openStep) {
        step.isOpen = false;
      }
    });
  }

  // This is called from the wizard-step directive to add itself to the wizard
  function addStep(stepScope) {
    var that = this;
    this.steps.push(stepScope);

    // Open first step not being disabled
    if (!firstEnabledOpened && stepScope.isDisabled !== true) {
      //$location.search(angular.extend($location.search(), { step: stepScope.name }));
      //$location.replace();
      stepScope.isOpen = true; // TODO remove
      firstEnabledOpened = true;
    }

    setEnabledSteps();

    stepScope.$on('$destroy', function(event) {
      that.removeStep(stepScope);
    });
  }

  // This is called from the wizard-step directive when to remove itself
  function removeStep(step) {
    var index = this.steps.indexOf(step);
    if (index !== -1) {
      this.steps.splice(index, 1);
    }
  }

  function isFirstStep() {
    return wizardVm.enabledSteps.length > 0 && wizardVm.enabledSteps[0].isOpen === true;
  }

  function isLastStep() {
    return wizardVm.enabledSteps.length > 0 && wizardVm.enabledSteps[wizardVm.enabledSteps.length - 1].isOpen === true;
  }

  // It's in many cases not to care about disabled steps so filter them out
  // can make tasks simpler
  function setEnabledSteps() {
    wizardVm.enabledSteps = wizardVm.steps.filter(function(step) {
      return !step.isDisabled;
    });
  }

  function hideNextButton() {
    var found;

    angular.forEach(wizardVm.steps, function(step) {
      if (step.isOpen && step.hideNextButton) {
        found = true;
      }
    });

    return found;
  }
}

/* jshint +W040 */

})();