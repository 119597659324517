(function () {
'use strict';

angular.module('hyresgastApp').filter('enSwDictionary', function() {
  return function(value) {
    return enSwDictionary(value);
  };
});

function enSwDictionary(value) {
  switch (value) {
    case 'agreements':
      return 'Överenskommelse';
    case 'meetings':
      return 'Möte';
    default:
      return 'undefined';
  }
}

})();