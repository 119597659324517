(function () {
/* eslint-disable no-undefined */
'use strict';

angular.module('hyresgastApp').factory('Agreement', function($resource) {
  return $resource(
    '/api/negotiations/:negotiationId/agreements/:id',
    {
      negotiationId: '@negotiationId',
      agreementId: '@agreementId',
      id: '@id'
    },
    {
      downloadDocument: {
        url: '/api/negotiations/:negotiationId/agreements/:agreementId/documents/:id',
        method: 'GET',
        responseType: 'arraybuffer',
        transformResponse: function(data, headersGetter) {
          return {
            data: data,
            headers: headersGetter
          };
        }
      },
      deleteDocument: {
        url: '/api/negotiations/:negotiationId/agreements/:agreementId/documents/:id',
        method: 'DELETE'
      },
      queryDocument: {
        url: '/api/negotiations/:negotiationId/agreements/:id/documents',
        isArray: true
      },
      queryApartment: {
        url: '/api/negotiations/:negotiationId/agreements/:id/apartments'
      },
      saveApartment: {
        url: '/api/negotiations/:negotiationId/agreements/:id/apartments',
        method: 'POST'
      },
      deleteApartment: {
        url: '/api/negotiations/:negotiationId/agreements/:agreementId/apartments/:id/unassign',
        method: 'POST'
      },
      queryProperty: {
        url: '/api/negotiations/:negotiationId/agreements/:id/properties'
      },
      saveProperty: {
        url: '/api/negotiations/:negotiationId/agreements/:id/properties',
        method: 'POST'
      },
      deleteProperty: {
        url: '/api/negotiations/:negotiationId/agreements/:agreementId/properties/:id/unassign',
        method: 'POST'
      },
      importRentlist: {
        url: '/api/negotiations/:negotiationId/agreements/:id/rent-list/import',
        method: 'POST'
      },
      editRentlist: {
        url: '/api/negotiations/:negotiationId/agreements/:id/rent-list/edit',
        method: 'POST'
      },
      deleteAgreement: {
        url: '/api/negotiations/:negotiationId/agreements/:agreementId',
        method: 'DELETE'
      },
      getScriveStatus: {
        url: '/api/negotiations/:negotiationId/agreements/:agreementId/scrive-status',
        method: 'GET'
      },
      signAgreement: {
        url: '/api/negotiations/:negotiationId/agreements/:agreementId/sign-agreement',
        method: 'POST',
      },
      dropAgreement: {
        url: '/api/negotiations/:negotiationId/agreements/:agreementId/drop-agreement',
        method: 'GET',
      },
      pullOut: {
        url: '/api/agreements/:agreementId/pull-out',
        method: 'GET',
      },
      getPreview: {
        url: '/api/negotiations/:negotiationId/agreements/:agreementId/sign-agreement',
        method: 'POST',
        responseType: 'arraybuffer',
        transformResponse: function(data, headersGetter) {
          return {
            data: data,
            headers: headersGetter
          };
        }
      },
      getSignedAgreement: {
        url: '/api/negotiations/:negotiationId/agreements/:agreementId/export-agreement-pdf',
        method: 'GET',
        responseType: 'arraybuffer',
        transformResponse: function(data, headersGetter) {
          return {
            data: data,
            headers: headersGetter
          };
        }
      },
      getFigurants: {
        url: '/api/agreements/:agreementId/figurants',
        method: 'GET',
      },
      revertAgreement: {
        url: '/api/agreements/:agreementId/revert',
        method: 'GET',
      },
      resetCorrection: {
        url: '/api/agreements/:agreementId/reset-correction',
        method: 'GET',
      }
    }
  );
});

})();