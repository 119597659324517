(function () {
'use strict';

angular.module('stringUtils', []).factory('StringUtils', StringUtils);

function StringUtils() {
  // Multiple number formats
  //    Match: 3 or 3.3 or 3,3 or 3000 or 3 000;
  var multipleNumberFormatsReg = /^[\d\s]+([,\.]\d+)?$/;
  var numericFormatReg = /^-?[\d\s]+([,\.]\d+)?$/;

  var service = {
    getMultipleNumberFormatsReg: getMultipleNumberFormatsReg,
    getNumericFormatReg: getNumericFormatReg,
    sanitizeMultipleNumberFormats: sanitizeMultipleNumberFormats,
    stringSort: property => (a, b) => a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0,
  };

  return service;

  ////////////

  function getMultipleNumberFormatsReg() {
    return multipleNumberFormatsReg;
  }

  function getNumericFormatReg() {
    return numericFormatReg;
  }

  // Remove whitespace and replace comma with a dot
  function sanitizeMultipleNumberFormats(input) {
    if (typeof input === 'string' || input instanceof String) {
      input = input.replace(/[^0-9\.,]/g, '');
      input = input.replace(/,/g, '.');

      // backend does not like empty strings ""
      if (input.length > 0) {
        return input;
      }
      return null;
    }
    // if the input is a number
    return input;
  }
}

})();