(function () {
'use strict';

angular.module('hyresgastApp').factory('SpecUser', function($resource) {
  return $resource(
    '/api/specops/users', { id: '@id' },
    {
      createUser: {
        url: '/api/specops/users/create-user',
        method: 'POST'
      },
      updateUser: {
        url: '/api/specops/users/:id',
        method: 'POST'
      },
      deleteUser: {
        url: '/api/specops/users/:id',
        method: 'DELETE'
      },
    }
  );
});

})();