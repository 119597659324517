(function () {
'use strict';

angular
  .module('hyresgastApp')
  .controller('EditNegotiationRepresentativesModalCtrl', EditNegotiationRepresentativesModalCtrl);

function EditNegotiationRepresentativesModalCtrl(
  $uibModalInstance, $routeParams, $location, $q,
  Auth, Flash, ErrorHandler, HyUtils,
  negotiationObject, userParty, isFreeAgreement,
  User, Negotiation, FreeAgreement,
  Parties, NegotiationRoles) {

  var vm = this;
  vm.Parties = Parties;
  vm.NegotiationRoles = NegotiationRoles;

  vm.currentUserParty = Auth.currentUser().party;

  vm.editUserParty = userParty;
  vm.isFreeAgreement = isFreeAgreement;

  vm.invitationResource = vm.isFreeAgreement ? FreeAgreement : Negotiation;

  vm.canEdit = (vm.currentUserParty === vm.editUserParty) ||
               (vm.currentUserParty === vm.Parties.HGF) ||
               vm.isFreeAgreement;

  vm.inviteEmail = '';
  vm.id = $routeParams.id;
  if (!vm.id) {
    // eslint-disable-next-line no-undef
    vm.id = localStorage.getItem('newAvtalId');
  }
  vm.negotiation = {
    id: vm.id,
    party: vm.editUserParty
  };
  processNegotiationObject(negotiationObject);
  var toBeInvited = [];
  var toBeDeleted = [];

  //view actions
  vm.save = save;
  vm.cancel = cancel;
  vm.addParticipant = addParticipant;
  vm.removeParticipant = removeParticipant;
  vm.inviteParticipant = inviteParticipant;
  vm.removeDelegationParticipant = removeDelegationParticipant;

  vm.tagChief = tagChief;
  vm.clearOpposite = clearOpposite;

  //view helpers
  vm.findNegotiationRole = HyUtils.findNegotiationRole;
  vm.deChief = name => {
    if(!name) { return name; }
    var occ = name.indexOf(',');
    if (occ === -1) { return name; }
    return name.substring(0, occ);
  };
  vm.canBeChief = participant =>
    !(participant.roleObj && participant.roleObj.name.includes('Förtroendevald HGF'));
  vm.canDelete = participant => vm.canEdit && !participant.isChief;

  //start
  getUsers();


  function processNegotiationObject(bundle) {
    vm.negotiation.participants = vm.editUserParty === Parties.HGF
      ? angular.copy(bundle.hgfParticipants)
      : angular.copy(bundle.externalParticipants);

    let chief = vm.negotiation.participants.find(participant => participant.isChief === true);

    if (chief) {
      vm.chiefId = chief.id;
    }

    vm.delegationParticipants = angular.copy(bundle.delegationParticipants.filter(p => p.roleObj.party === vm.editUserParty));
    vm.waitingInvites = angular.copy(bundle.delegationInvitations);
    vm.waitingInvites = vm.waitingInvites.filter(wi => wi.roleObj.party === vm.editUserParty);
  }

  function getUsers() {
    return User.query({'filters[party]': vm.editUserParty
    }).$promise.then(function (response) {
      if(vm.editUserParty === vm.Parties.HGF) {
        vm.users = response.rows.filter(r => !r.disabled);
      } else {
        vm.users = response.rows;
      }

      if(vm.editUserParty === vm.Parties.EXTERNAL) {
        User.query({'filters[party]': vm.Parties.DELEGATE}).$promise.then(function(responseDelegates) {
          vm.users = vm.users.concat(responseDelegates.rows);
        });
      }
    }).catch(new ErrorHandler('Misslyckades att hämta data.'));
  }

  function save() {
    if (vm.isFreeAgreement) {
      saveInvites();
      $uibModalInstance.close({
        participants: vm.negotiation.participants,
        waitingInvites: vm.waitingInvites
      });
    } else {
      let joint = vm.negotiation.participants.concat(vm.delegationParticipants);
      let isChiefSelected = joint.length === 0 || joint.some(participant => participant.isChief);

      if (isChiefSelected) {
        vm.working = true;
        Negotiation.saveParticipants(
          { id: vm.id },
          {
            party: vm.editUserParty,
            participants: vm.negotiation.participants,
            delegates: vm.delegationParticipants.filter(d => d.roleObj.party===vm.editUserParty)
          }
        ).$promise.then(() => {
          Flash.set('Lyckades att spara representanterna.', 'success');
          if (vm.editUserParty === Parties.HGF) {
            negotiationObject.hgfParticipants = vm.negotiation.participants;
            negotiationObject.chiefNegotiatorHGF = vm.negotiation.participants.filter(function (participant) {
              return participant.isChief;
            })[0];
          } else if (vm.editUserParty === Parties.EXTERNAL) {
            negotiationObject.externalParticipants = vm.negotiation.participants;
            negotiationObject.chiefNegotiatorExternal = vm.negotiation.participants.filter(function (participant) {
              return participant.isChief;
            })[0];
          }

          saveInvites();
        }).catch(new ErrorHandler('Misslyckades att spara representanterna.'))
          .finally(() => {
            vm.working = false;
            $uibModalInstance.close();
          });
      } else {
        Flash.set('En ansvarig förhandlare måste väljas', 'error');
      }
    }
  }

  function cancel() {
    $uibModalInstance.dismiss('cancel');
  }

  function addParticipant(role) {
    let foundParticipant = vm.negotiation.participants.find(user => user.id === vm.selectedParticipant.id);

    // look through all participants to check if they had been added before or not
    if (!foundParticipant) {
      if (vm.negotiation.participants.length === 0) {
        // only one participant who becomes a chief
        vm.selectedParticipant.isChief = true;
        vm.chiefId = vm.selectedParticipant.id;
      } else {
        // keep the current chief not changed
        vm.selectedParticipant.isChief = false;
      }

      vm.selectedParticipant.roleObj = vm.findNegotiationRole(role.code, vm.selectedParticipant.isChief);

      vm.negotiation.participants.push(vm.selectedParticipant);
    }

    vm.selectedParticipant = null;
  }

  function removeParticipant(participant) {
    var index = vm.negotiation.participants.indexOf(participant);
    if (index >= 0) {
      vm.negotiation.participants.splice(index, 1);
    } else {
      index = vm.delegationParticipants.indexOf(participant);
      vm.delegationParticipants.splice(index, 1);
    }
  }

  /**
   * Chiefs selected participant and deChiefs all others
   * @param {Participant} selectedParticipant
   */
  function tagChief(selectedParticipant) {

    var chiefIndex = vm.negotiation.participants.concat(vm.delegationParticipants).indexOf(selectedParticipant);
    vm.chiefId = selectedParticipant.id;

    var firstLength = vm.negotiation.participants.length;

    var index;

    for(index = 0; index < firstLength; index++) {
      let participant = vm.negotiation.participants[index];
      participant.isChief = (index === chiefIndex);
      participant.roleObj = vm.findNegotiationRole(participant.roleObj.code, participant.isChief);
    }
    for(index = 0; index < vm.delegationParticipants.length; index++) {
      let participant = vm.delegationParticipants[index];
      participant.isChief = (index === (chiefIndex - firstLength));
      participant.roleObj = vm.findNegotiationRole(participant.roleObj.code, participant.isChief);
    }
  }

  function clearOpposite(field) {
    if (field === 1) {
      vm.inviteEmail = null;
    } else {
      vm.selectedParticipant = null;
    }
  }


  //==========INVITATION=================================
  function inviteParticipant(role) {

    var email = vm.inviteEmail;

    // look through all invitations to check if they had been added before or not
    var existingInvitationEmail = findDelegatesByEmail(vm.waitingInvites, email);
    var existingDelegationEmail = findDelegatesByEmail(vm.negotiation.participants, email);

    // avoid inviting twice with already existing email
    if (existingDelegationEmail.length === 0 && existingInvitationEmail.length === 0) {
      vm.working = true;
      vm.invitationResource.checkDelegation({
        email,
        id: vm.id,
      }).$promise.then(response => {
        response.roleObj = role;
        if (response.party) {
          vm.negotiation.participants.push(response);
        } else {
          vm.waitingInvites.push(response);
        }
        toBeInvited.push(response);
        vm.inviteEmail = '';
      }).catch(error => {
        var delegate = {};
        if (error.data.code === 1) {
          delegate = findDelegatesByEmail(negotiationObject.delegationParticipants, vm.delegateEmail)[0];
          if (delegate) {
            vm.delegationParticipants.push(delegate);
            removeDelegate(toBeDeleted, delegate);
            removeDelegate(toBeInvited, delegate);
          }
        } else if (error.data.code === 2) {
          delegate = findDelegatesByEmail(vm.waitingInvites, vm.delegateEmail)[0];
          if (delegate) {
            vm.waitingInvites.push(delegate);
            removeDelegate(toBeDeleted, delegate);
            removeDelegate(toBeInvited, delegate);
          }
        } else if (error.data.code === 3) {
          Flash.set('Du får inte bjuda in HGF eller FÄ användare.', 'error');
        } else if (error.status === 403) {
          $location.path('/403');
        } else {
          Flash.set('Misslyckades att bjuda in delegat.', 'error');
        }
      }).finally(() => { vm.working = false });
    } else {
      Flash.set('Denna mailadress finns redan registrerad på delegat i denna förhandling.', 'error');
    }
  }

  function removeDelegationParticipant(list, delegate) {
    removeDelegate(list, delegate);
    if (findDelegatesByEmail(toBeInvited, delegate.email).length > 0) {
      removeDelegate(toBeInvited, delegate);
    } else {
      toBeDeleted.push(delegate);
    }
  }

  function saveInvites() {
    if ((!toBeInvited || toBeInvited.length === 0) &&
        (!toBeDeleted || toBeDeleted.length === 0)) {
      return;
    }
    vm.working = true;
    $q.all([
      vm.invitationResource.inviteDelegation({
        id: vm.id,
        delegates: toBeInvited
      }).$promise,
      vm.invitationResource.deleteDelegation({
        id: vm.id,
        emails: toBeDeleted
      }).$promise
    ])
      .then(() => { Flash.set('Ändringen har registrerats.', 'success') })
      .catch(new ErrorHandler('Misslyckades att spara ändring.'));
  }


  /**
   * @private
   * @param {Array.<object>} list
   * @param {object} delegate
   */
  function removeDelegate(list, delegate) {
    var index = list.indexOf(delegate);
    list.splice(index, 1);
  }

  /**
   * @private
   * @param {Array.<object>} delegates
   * @param {string} email
   * @returns {object}
   */
  function findDelegatesByEmail(delegates, email) {
    return delegates.filter(delegate => delegate.email === email);
  }
}

})();