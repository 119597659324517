(function () {
'use strict';

angular.module('hyresgastApp').controller('FreeAgreementsListCtrl', FreeAgreementsListCtrl);

function FreeAgreementsListCtrl($scope, $location, $window, $uibModal, $routeParams,
  FreeAgreement,
  HyUtils, Auth, SubPage,
  FreeAgreementTypes, NegotiationRoles) {
  var vm = this;

  if (Auth.isHgfUser()) {
    vm.headerButtons = [
      {
        title: 'Skapa avtal',
        url: '/agreements/new',
        isVisible: () => Auth && Auth.canDo('createAgreement'),
        icon: 'agreement',
      }
    ];
  } else {
    vm.headerButtons = [
      {
        title: 'Nytt avtal',
        click: () => { vm.newAgreement() },
        url: '#',
        isVisible: () => Auth && Auth.canDo('createAgreement'),
        icon: 'agreement',
      }
    ];
  }
  vm.tablist = [
    { title: 'Mina', path: '/agreements' },
  ];

  if (Auth.isHgfUser()) {
    vm.tablist.push({ title: 'På mina kontor', path: '/agreements?view=my-office' });
    vm.tablist.push({ title: 'Alla', path: '/agreements?view=all' });
  }

  getCurrentSubpage();

  $scope.$on('$routeUpdate', function() {
    // avoid unecessary calling of getCurrentSubpage(); when sorting
    if (!$location.search().order) {
      // reset offset when sorting
      getCurrentSubpage();
    }
    vm.refreshList();
  });

  function getCurrentSubpage() {
    var defaultSubPage = 'my';
    var whiteList = ['my', 'my-office'];
    if (Auth.isHgfUser()) {
      whiteList.push('all');
    }
    vm.currentSubpage = SubPage.set($routeParams.view, whiteList, defaultSubPage);
  }

  vm.FreeAgreementTypes = Object.values(FreeAgreementTypes)
    .reduce((dict, el) => { dict[el.code] = el.name; return dict; }, {});

  function setUserString(agreements) {
    agreements.forEach(agreement => {
      var chiefHGF = agreement.users.find(u => NegotiationRoles.HGF_ASSOC_CHIEF.code === u.agreementRole);
      var chiefExternal = agreement.users.find(u => [NegotiationRoles.FA_POWNER_CHIEF.code, NegotiationRoles.OMBUD_FA_POWNER_CHIEF.code].includes(u.agreementRole));
      if (chiefHGF && chiefExternal) {
        agreement.user1 = {
          name: `${chiefHGF.firstname} ${chiefHGF.lastname}`,
          email: chiefHGF.email,
        };
        agreement.user2 = {
          name: `${chiefExternal.firstname} ${chiefExternal.lastname}`,
          email: chiefExternal.email,
        };
      }
    });
  }

  vm.refreshList = () => {
    let params = {};
    let isHgf = Auth.isHgfUser();

    if (vm.currentSubpage !== 'all') {
      params.userId = Auth.currentUser().id;
    }
    if (vm.currentSubpage === 'my-office') {
      params.myOffice = true;
    }

    FreeAgreement.list(params).$promise.then(results => {
      vm.agreements = results.agreements.filter(a => !a.signed);
      vm.signedAgreements = results.agreements.filter(a => a.signed);
      setUserString(vm.agreements);
      setUserString(vm.signedAgreements);

      params.showPending = true;
      FreeAgreement.list(params).$promise.then(results => {
        vm.pendingAgreements = results.agreements;
        setUserString(vm.pendingAgreements);
      });

      vm.canApprovePending = isHgf;
      vm.canDeletePending = isHgf || vm.currentSubpage === 'my';
    });
  };

  vm.deleteNew = id => FreeAgreement.deletePending({ agreementId: id });

  vm.newAgreement = () => {
    let initialUser = angular.copy(Auth.currentUser());
    initialUser.userId = initialUser.id;
    initialUser.roleObj = Auth.isHgfUser() ? NegotiationRoles.HGF_ASSOC_CHIEF : NegotiationRoles.FA_POWNER_CHIEF;

    FreeAgreement.create({}).$promise.then(createResult => {
      // eslint-disable-next-line no-undef
      localStorage.setItem('newAvtalId', createResult.id);
      $window.onbeforeunload = () => { vm.deleteNew(createResult.id) };
      let modal = $uibModal.open({
        animation: true,
        ariaLabelledBy: 'modal-title-top',
        ariaDescribedBy: 'modal-body-top',
        size: 'md',
        index: 1075,
        templateUrl: 'resources/agreement/free/new/modal/new-free-agreement-modal.html',
        windowClass: 'modal-content--white-border',
        backdrop: 'static',
        controller: 'NewFreeAgreementModalCtrl',
        controllerAs: 'vm',
        resolve: {
          users: () => [initialUser],
          invites: () => [],
        }
      });
      modal.result.then(result => {
        $window.onbeforeunload = () => {};
        if (result.cancel) {
          vm.deleteNew(createResult.id);
          $location.search({});
          $location.path('agreements');
        } else {
          FreeAgreement.finishCreate({
            id: createResult.id,
            userContainer: result.userContainer,
            type: result.type.code,
            message: result.message,
          }).$promise.then(result => {
            if (Auth.isHgfUser()) {
              vm.selectAgreement(result.agreementId);
            } else {
              $location.search({});
              $location.path('/agreement/free/new/thank-you-agreement');
            }
          });
        }
      });
    });
  };

  vm.selectAgreement = agreementId => {
    $location.search({});
    $location.path('/agreements/' + agreementId);
  };

  vm.unlockPending = agreement => {
    FreeAgreement.unlockPending({ agreementId: agreement.id })
      .$promise.then(vm.refreshList);
  };

  vm.deletePending = agreement => {
    let message = 'Är du säker?';

    HyUtils.showDefaultModal('ConfirmationModalWithMessageCtrl',
      'components/modal/confirmation/confirmationModalWithMessage.html',
      // eslint-disable-next-line no-unused-vars
      { message }, result => {
        FreeAgreement.deletePending({ agreementId: agreement.id })
          .$promise.then(vm.refreshList);
      }, null, null, { size: 'xsm' });
  };

  vm.downloadPDF = (agreementId) => {
    FreeAgreement.getSignedAgreement({ agreementId })
      .$promise.then(function(response) {
        let filename = `Avtal_${agreementId}.pdf`;
        HyUtils.spitOutAttachment(response.data, filename, 'application/pdf');
      });
  }

  vm.refreshList();

  if ($routeParams.new) {
    vm.newAgreement();
  }
}
})();