(function () {
'use strict';

angular.module('hyresgastApp').controller('FileUploadModalCtrl', FileUploadModalCtrl);

function FileUploadModalCtrl($uibModalInstance, $scope, $routeParams, settings) {
  var vm = this;
  vm.cancel = cancel;

  // Use settings to make modal reusable
  // {title: 'Upload file', uploadUrl: '/documents', validFileTypes: 'jpg gif'}
  vm.settings = settings;
  vm.settings.splitedValidFileTypes = settings.validFileTypes.split(' ');

  if (settings.types.length === 1) {
    vm.file = {};
    vm.file.type = settings.types[0].id;
    vm.selectedTypeName = settings.types[0].name;
  }

  $scope.$watch('vm.file.blobId', function(newValue) {
    if (newValue) {
      $uibModalInstance.close(vm.metadata);
    }
  });

  /////////

  function cancel() {
    $uibModalInstance.dismiss('cancel');
  }
}

})();