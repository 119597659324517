(function () {
'use strict';

// @desc
//   Donut chart
//
// @example

angular.module('hyresgastApp').directive('hyDonut', donut);

function donut($location) {
  var directive = {
    scope: {
      part: '<',
      total: '<',
      maxHeight: '@',
      fixedWidth: '@',
      statusFinished: '<'
    },
    restrict: 'E',
    templateUrl: 'components/donut/donut.html',
    link: link
  };

  return directive;

  function link(scope, element, attrs) {
    ////////////
    scope.$watch('part', function() {
      if (scope.total) {
        scope.partPercent = scope.part / scope.total * 100;
        scope.remainingPercent = 100 - scope.partPercent;
      }
    });
  }
}

})();