(function () {
'use strict';

// @desc
//   Manage users belonging to an organization. For example, list
//   users, invite new users to the organization.
//
//   Note: Users with flag invited set to TRUE is another way to say
//   a person has been invited but the person has not created a user account.
//
// @example
//   <list-user-by-organization
//      organization="vm.organization">
//   </list-user-by-organization>

angular.module('hyresgastApp').directive('listUserByOrganization', listUserByOrganization);

function listUserByOrganization($q,
  Flash, Auth, HyUtils, ErrorHandler,
  Roles,
  UserByOrganization, Organization) {
  var directive = {
    restrict: 'E',
    templateUrl: 'resources/organization/show/user/list-user-by-organization.html',
    scope: {
      organization: '=',
      foundAdmin: '='
    },
    link: link
  };

  return directive;

  function link(scope) {
    scope.add = add;
    scope.invite = invite;
    scope.delete = destroy;
    scope.deleteBinding = deleteBinding;
    scope.Auth = Auth;
    scope.changeUserRole = changeUserRole;
    scope.tabs = [
      { title: 'Förhandlare', key: 'users' },
      { title: 'Fastigheter', key: 'properties' },
    ];
    scope.$watch('organization.id', function(newValue) {
      if (newValue) {
        getUsers();
        getProperties();
      }
    });

    ////////////

    function getUsers() {
      var user = Auth.currentUser();

      var omitInvitedUsers = false;
      if (!Auth.isHgfUser()) {
        var role = user.roleByOrganization[scope.organization.id];
        var isOrganizationAdmin = role === Roles.ADMIN;
        omitInvitedUsers = !isOrganizationAdmin;
      }

      UserByOrganization.query(scope.organization.id, omitInvitedUsers)
        .then(function(response) {
          scope.users = response;
          scope.usersCount = response.length;
          scope.foundAdmin = scope.users.filter(user => user.role === Roles.ADMIN);
        })
        .catch(function() {
          Flash.set('Misslyckades att hämta användare.', 'error');
        })
        .finally(function() {
          scope.ready = true;
        });
    }

    function getProperties() {
      Organization.queryProperties({id: scope.organization.id})
        .$promise.then(result => {
          scope.properties = result.properties;
        });
    }

    function invite() {
      HyUtils.showDefaultModal('InviteUsersModalCtrl',
        'resources/organization/show/user/inviteUsersModal.html',
        {
          selectedOrganization: scope.organization,
          foundAdmin: scope.foundAdmin,
        },
        result => {
          scope.users.push(result);
        });
    }

    function changeUserRole(userId) {
      HyUtils.showDefaultModal('ChangeUserRoleModal',
        'resources/organization/show/user/changeUserRoleModal.html',
        {
          organizationId: scope.organization.id,
          userId: userId,
          foundAdmin: scope.foundAdmin,
        },
        result => {
          angular.forEach(scope.users, function(item, index) {
            if (item.id === result.id) {
              scope.users[index].role = result.role;
            }
          });
        },
        null,
        null,
        { size: 'sm' });
    }

    function destroy(user) {
      HyUtils.showDefaultModal('ConfirmationModalCtrl',
        'components/modal/confirmation/confirmationModal.html',
        {},
        () => {

          let killUser = killOrphan => {
            UserByOrganization.delete(scope.organization.id, user.id, user.invited, killOrphan).then(() => {
              scope.users = scope.users.filter(u => u.id !== user.id);
              let message = killOrphan
                ? 'Användare har tagits bort från organisationen och raderat'
                : 'Användare har tagits bort från organisationen';
              Flash.set(message, 'success');
            }).catch(err => {
              ErrorHandler(err);
            });
          };

          if (user.dedicated) {
            HyUtils.askQuestion('Användaren tillhör inte längre någon organisation, vill du radera användaren helt?', null, 2).then(res => {
              killUser(res === 'Ja');
            });
          } else {
            killUser();
          }
        },
        null,
        null,
        { size: 'xsm', isGreen: false });
    }

    function add() {
      HyUtils.showDefaultModal('NewUserModalCtrl',
        'resources/user/new/new-user-modal.html',
        {},
        result => {
          scope.users.push(result);
        });
    }

    function deleteBinding(property) {
      Organization.unbindProperty({
        id: scope.organization.id,
        propertyId: property.id,
        userIds: scope.users.map(u => u.id),
      }).$promise.then(result => {
        if (result.ongoingExists) {
          HyUtils.askQuestion('Förhandlingar pågår med denna organisation och fastighet', ['OK'], 1);
        } else {
          getProperties();
        }
      });
    }
  }
}

})();