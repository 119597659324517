(function () {
'use strict';

// LocalhostInterceptor intercepts requests to the API and change
// the request url from localhost:3000|3344/api/resource to
// localhost:{LocalApiPort}/api/resource

angular.module('hyresgastApp').factory('LocalhostInterceptor', function(LocalApiPort) {
  var localhost = function() {
    return window.location.hostname === 'localhost';
  };

  var startsWithApi = /^\/api\//;
  var apiCall = function(url) {
    return startsWithApi.test(url);
  };

  return {
    request: function(config) {
      // In development - API is consumed on port other than client.
      if (localhost() && apiCall(config.url)) {
        config.url = 'http://localhost:' + LocalApiPort + config.url;
        config.withCredentials = true; // without this, cookies won't be included with the request
      }
      return config;
    }
  };
});

})();