(function () {
'use strict';

// @desc
//   A select all checkbox to handle check all checkboxes and unselect all
//   checkboxes.
//
// @example
//   <hy-select-all-checkbox checkboxes="checkboxes"
//      name="identifier"
//      selected-ids="selectedIds"
//      is-at-least-one-selected="atleastOneSelected"
//      is-master-selected="isMasterSelected"
//      call-on-change="updateCounter()">
//   </hy-select-all-checkbox>

angular
  .module('selectAllCheckbox', []) // TODO add tooltip as a dependency
  .directive('hySelectAllCheckbox', selectAllCheckbox);

function selectAllCheckbox($location, $timeout) {
  var directive = {
    scope: {
      name: '@', // a unique identifier so, if needed, multiple lists can be used
      checkboxes: '=',
      selectedIds: '=',
      isAtLeastOneSelected: '=',
      isMasterSelected: '=',
      isDisabled: '<',
      totalCount: '=',
      callOnChange: '&?' // optional function binding called when a selection changes
    },
    restrict: 'E',
    templateUrl: 'components/select-all-checkbox/select-all-checkbox.html',
    link: link
  };

  return directive;

  function link(scope, element, attrs) {
    scope.toggleSelectMaster = toggleSelectMaster;

    // To add indeterminate property
    var inputElement = element[0].querySelector('input');

    ////////////

    function toggleSelectMaster() {
      angular.forEach(Object.keys(scope.selectedIds), function(id) {
        scope.selectedIds[id] = scope.isMasterSelected;
      });
    }

    scope.$watch('checkboxes', function(newValue) {
      angular.forEach(scope.checkboxes, function(checkbox) {
        if (scope.isMasterSelected && scope.selectedIds[checkbox.id] === undefined) {
          scope.selectedIds[checkbox.id] = true;
        } else if (scope.selectedIds[checkbox.id] === undefined) {
          scope.selectedIds[checkbox.id] = false;
        }
      });
    });

    scope.$watch(
      'selectedIds',
      function(newValue) {
        // if a function has been binded to call on change and newValue is not empty and
        // newValue is not empty object
        if (scope.callOnChange && newValue && !angular.equals(newValue, {})) {
          // wrapped in a timeout so all attributes such as is-at-least-one-selected
          // is having correct state if the called function wants to look at the value
          // of is-at-least-one-selected
          $timeout(function() {
            scope.callOnChange();
          });
        }

        var selectedStates = Object.keys(scope.selectedIds).map(function(id) {
          return scope.selectedIds[id];
        });

        function isFalse(val) {
          return !val;
        }
        function isTrue(val) {
          return Boolean(val);
        }
        var allSelectStatesKnown = selectedStates.length === scope.totalCount;
        var isAllSelected = selectedStates.every(isTrue) && allSelectStatesKnown;
        var isAllCleared = selectedStates.every(isFalse) && allSelectStatesKnown;

        inputElement.indeterminate = false;
        scope.isAtLeastOneSelected = false;
        if (isAllSelected) {
          scope.isMasterSelected = true;
          scope.isAtLeastOneSelected = true;
        } else if (isAllCleared) {
          scope.isMasterSelected = false;
        } else {
          if (
            (!scope.isMasterSelected && selectedStates.some(isTrue)) ||
            (scope.isMasterSelected && selectedStates.some(isFalse))
          ) {
            inputElement.indeterminate = true;
            scope.isAtLeastOneSelected = true;
          } else if (scope.isMasterSelected) {
            scope.isAtLeastOneSelected = true;
          }
        }
      },
      true
    );
  }
}

})();