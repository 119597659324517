(function () {
'use strict';

angular
  .module('hyresgastApp')
  .controller('NegotiationTableNegotiatorPropertiesListCtrl', NegotiationTableNegotiatorPropertiesListCtrl);

function NegotiationTableNegotiatorPropertiesListCtrl(
  $scope, $location, $routeParams,
  FeatureConfiguration, NegotiationStates, RealEstate,
  Auth, ErrorHandler
) {
  var vm = this;
  vm.FeatureConfiguration = FeatureConfiguration;
  vm.gotoShow = gotoShow;
  vm.pageLimit = 30;
  vm.pageOffset = 0;
  vm.propertiesPageLimit = 30;
  vm.propertiesPageOffset = 0;
  vm.getPropertiesInNegotiationsWithMe = getPropertiesInNegotiationsWithMe;
  vm.isHgfUser = Auth.isHgfUser();

  vm.sortColumn = $location.search().order || 'id'; // default
  vm.sortDirection = $location.search().direction || 'DESC'; // default
  vm.listColumns = [
    { title: 'Id', column: 'id' },
    { title: 'Namn', column: 'name' },
    { title: 'Typ', column: 'counterpart.name' },
    { title: 'Dagar kvar', column: 'rentEffectiveFrom' },
    { title: 'Datum för framställan', column: 'updatedAt' },
    { title: 'Status', column: 'state' },
    { title: 'Begärt ändringsdatum', column: 'updatedAt' }
  ];
  if (vm.FeatureConfiguration.ENABLE_MEETINGS) {
    vm.listColumns.splice(4, 0, { title: 'Nästa möte', column: 'updatedAt' });
  }

  $scope.$on('$routeUpdate', function() {
    if ($location.search().order) {
      // reset offset when sorting
      vm.pageOffset = 0;
    }
  });

  getPropertiesInNegotiationsWithMe();

  function gotoShow(propertyId) {
    if (Auth.isHgfUser()) {
      $location.search({});
      $location.path('/properties/' + propertyId);
    }
  }

  function getPropertiesInNegotiationsWithMe(resetOffset) {
    vm.readyProperties = false;
    if (resetOffset) {
      vm.propertiesPageOffset = 0;
    }
    return RealEstate.queryPropertiesInNegotiationsWithMe(
      {
        partnerId: $routeParams.partnerId,
        limit: vm.propertiesPageLimit,
        offset: vm.propertiesPageOffset,
        order: vm.sortColumn,
        direction: vm.sortDirection,
        'search[propertyDesignation]': vm.inputTextProperty,
        'search[municipality]': vm.inputTextMunicipality,
        'search[district]': vm.inputTextDistrict,
        'filters[id]': vm.inputTextId,
        'filters[state]': [
          NegotiationStates.IN_PROGRESS,
          NegotiationStates.NEW,
          NegotiationStates.SETTLEMENT,
          NegotiationStates.IN_PREPARATION,
          NegotiationStates.BLOCKED,
          NegotiationStates.PARTIALLY_BLOCKED
        ]
      },
      {}
    )
      .$promise.then(function(data) {
        vm.properties = data.rows;
        vm.propertyTotalCount = data.count;
      })
      .catch(ErrorHandler('Misslyckades att hämta lista.'))
      .finally(function() {
        vm.readyProperties = true;
      });
  }
}

})();