(function () {
'use strict';

angular.module('hyresgastApp').filter('hyParties', function(Parties) {
  return function(value) {
    return hyParties(value, Parties);
  };
});

function hyParties(value, Parties) {
  switch (value) {
    case Parties.EXTERNAL:
      return 'Fastighetsägare/Ombud';
    case Parties.DELEGATE:
      return 'Delegat';
    case Parties.HGF:
      return 'HGF';
    default:
      return 'saknas';
  }
}

})();