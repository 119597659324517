(function () {
'use strict';

angular.module('hyresgastApp').controller('ApartmentHistoryCtrl', ApartmentHistoryCtrl);

function ApartmentHistoryCtrl($scope, $timeout, $interval, $filter, $uibModalInstance,
  RealEstate,
  HyUtils,
  NegotiationTypes, ApartmentStatusTypes,
  apartment) {
  var vm = this;

  let iAmHere = HyUtils.whereAmI();
  let protocol = iAmHere.localhost ? 'http' : 'https';
  vm.host = iAmHere.isSP ? `${protocol}://${iAmHere.host.FP}` : '';

  vm.objectsExtra = apartment.objectsExtra ? JSON.parse(apartment.objectsExtra) : null;

  vm.apartment = apartment;
  if (!vm.apartment.rent && vm.apartment.currentMonthlyRent) {
    vm.apartment.rent = {
      monthlyRent: vm.apartment.currentMonthlyRent,
      effectiveFrom: vm.apartment.currentEffectiveFrom,
    };
  }
  vm.NegotiationTypes = NegotiationTypes;

  vm.statusString = Object.values(ApartmentStatusTypes)
    .filter(s => apartment.status & s.code)
    .map(s => s.longName)
    .join(', ');

  /**
   * sets objects extra floater. if index is not set, uses latest
   * @param {number} [index]
   */
  let setObjectsExtraFloater = index => {
    if (!index) {
      index = vm.history.length - 1;
    }
    let objectsExtra = vm.history[index].objectsExtra;
    vm.objectsExtraFloater = objectsExtra ? JSON.parse(objectsExtra) : null;
  }

  // eslint-disable-next-line no-unused-vars
  vm.chartHover = (points, evt) => {
    if (points.length) {
      setObjectsExtraFloater(points[0]._index);
      $scope.$apply();
      $timeout(setObjectsExtraFloater, 60000);
    }
  };

  let setCurrentLine = () => {
    if (vm.history.length > 0) {
      let currentDate = new Date(apartment.rent.effectiveFrom);
      let diffs = vm.labels.map(d => HyUtils.diffDays(currentDate, d));
      let minDiff = diffs.reduce((acc, el) => Math.min(acc, el), 9999);
      let nearestPointIndex = diffs.findIndex(d => d === minDiff);
      if (minDiff > 0) {
        nearestPointIndex++;
        vm.data[0].splice(nearestPointIndex, 0, apartment.rent.monthlyRent);
        vm.labels.splice(nearestPointIndex, 0, $filter('date')(apartment.rent.effectiveFrom, 'yyyy-MM-dd'));
        vm.history.splice(nearestPointIndex, 0, { type: -1, effectiveFrom: vm.labels[vm.labels.length - 1]});
      }
      vm.currentLineIndex = nearestPointIndex;
    }
  };

  let drawCurrentLine = () => {
    if (vm.currentLineIndex) {
      // eslint-disable-next-line no-undef
      Chart.helpers.each(Chart.instances, graph => {
        let entrails = Object.values(graph.data.datasets[0]._meta)[0];
        let point = entrails.dataset._children[vm.currentLineIndex]._model;
        let canvas = graph.chart.ctx;

        canvas.beginPath();
        canvas.moveTo(point.x, 0);
        canvas.strokeStyle = '#FF0000';
        canvas.lineTo(point.x, graph.scales['y-axis-0'].height);
        canvas.stroke();
      });
    }
  };

  RealEstate.getApartmentHistory({ id: vm.apartment.id })
    .$promise.then(function(response) {
      vm.data = [response.history.map(i => i.monthlyRent)];
      vm.labels = response.history.map(i => i.effectiveFrom);
      vm.series = [''];
      vm.options = {
        maintainAspectRatio: false,
        elements: {
          line: { tension: 0 }
        },
      };
      vm.history = response.history;

      console.log(structuredClone(response.history));
      setCurrentLine();
      console.log(structuredClone(response.history));
      var interval = $interval(drawCurrentLine, 500);
      // eslint-disable-next-line angular/controller-as
      $uibModalInstance.closed.then(() => { $interval.cancel(interval) });

      vm.historyReversed = response.history.slice().reverse();
      console.log(structuredClone(vm.historyReversed));
      setObjectsExtraFloater();
    });
}


})();