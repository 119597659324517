(function () {
'use strict';

angular.module('hyresgastApp').factory('RequestedAccount', function($resource) {
  return $resource(
    '/api/requested-accounts',
    { id: '@id', organizationId: '@organizationId' },
    {
      query: {
        url: '/api/organizations/requested',
        isArray: false
      }
    }
  );
});

})();