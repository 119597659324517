(function () {
'use strict';

angular.module('hyresgastApp').directive('hyTabcontrol', tabs);

function tabs() {
  var directive = {
    scope: {
      tablist: '<',
      currentTab: '=',
    },
    restrict: 'E',
    templateUrl: 'components/tabcontrol/tabcontrol.html',
    link: link
  };

  return directive;

  function link($scope) {
    $scope.currentTab = $scope.tablist[0].key;
    $scope.selectTab = function (key) {
      $scope.currentTab = key;
    }
  }
}

})();