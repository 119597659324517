(function () {
'use strict';

angular.module('flashService', []).factory('Flash', Flash);

function Flash($timeout) {
  var message;
  var service = {
    get: get,
    set: set,
    hide: destroy
  };

  return service;

  ////////////

  function get() {
    return message;
  }

  function set(content, type) {
    // type: success, error
    message = {
      content: content,
      type: type
    };
    autoRemove();
  }

  function destroy() {
    message = null;
  }

  //auto removes the message after 6 seconds
  function autoRemove() {
    $timeout(function() {
      destroy();
    }, 6000);
  }
}

})();