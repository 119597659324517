(function () {
'use strict';

angular.module('hyresgastApp').filter('hyOrgNr', function() {
  return function(text) {
    return (text && hyOrgNr(text)) || text;
  };
});

function hyOrgNr(orgNumber) {
  return orgNumber.slice(0, 6) + '-' + orgNumber.slice(6);
}

})();