(function () {
'use strict';

angular
  .module('hyresgastApp')
  .controller('EditNegotiationTypeModalCtrl', EditNegotiationTypeModalCtrl);

function EditNegotiationTypeModalCtrl(
  $uibModalInstance,
  $scope,
  $location,
  negotiationObject,
  Negotiation, User,
  NegotiationTypes,
  Flash
) {
  var vm = this;
  vm.cancel = cancel;
  vm.save = save;
  vm.NegotiationTypes = NegotiationTypes;

  vm.isPropertyBased =
  negotiationObject.type === NegotiationTypes.ANNUAL_PROPERTY ||
  negotiationObject.type === NegotiationTypes.STANDARD_PROPERTY ||
  negotiationObject.type === NegotiationTypes.REBUILD_PROPERTY ||
  negotiationObject.type === NegotiationTypes.REBUILD_PROPERTY_2 ||
  negotiationObject.type === NegotiationTypes.NEWLY_CONSTRUCTED_PROPERTY ||
  negotiationObject.type === NegotiationTypes.UTILITY_VALUE_PROPERTY;

  vm.name = negotiationObject.name;
  vm.type = negotiationObject.type;

  vm.counterpartOrganization = negotiationObject.counterpart;

  let chiefLandlord = negotiationObject.faSide.find(x => x.isChief);
  if (chiefLandlord) {
    User.queryOrganization({ id: chiefLandlord.id })
      .$promise.then(response => {
        vm.possibleCounterparts = response;
      });
  }

  /////////

  function save() {
    vm.saving = true;

    var negotiation= {
      id: negotiationObject.id,
      name: vm.name,
      type: vm.type,
      counterpartOrganizationId: vm.counterpartOrganization.id,
      canSkipRentlist: vm.canSkipRentlist,
    }

    Negotiation.save(negotiation)
      .$promise.then(
        function(response) {
          $uibModalInstance.close(response);
          negotiationObject.counterpart = vm.counterpartOrganization;
          Flash.set('Förhandlingsinformationen har sparats', 'success');
        }
      )
      .catch(function(reason) {
        $uibModalInstance.dismiss('error');
        if (reason.status === 403) {
          $location.path('/403')
        } else {
          Flash.set('Misslyckades att spara förhandlingsinformation', 'error');
        }
      })
      .finally(function() {
        vm.saving = false;
      });
  }

  function cancel() {
    $uibModalInstance.dismiss('cancel');
  }
}
})();