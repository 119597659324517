(function () {
'use strict';

angular.module('hyresgastApp').filter('hgfUserRoles', function() {
  return function(value) {
    switch (value) {
      case 'admin':
        return 'Handläggare';
      case 'assistant':
        return 'Assistent';
      case 'negotiator':
        return 'Förhandlare';
      case 'chiefNegotiator':
        return 'Chefsförhandlare';
      default:
        return 'Roll ej angiven';
    }
  };
});

})();