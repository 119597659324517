(function () {
'use strict';

angular.module('hyresgastApp').directive('hyApartmentSelector', apartmentSelector);

function apartmentSelector($timeout, $interval, HyUtils, ErrorHandler, $document) {
  var directive = {
    restrict: 'E',
    templateUrl: 'components/apartment-selector/apartment-selector.html',
    scope: {
      isModal: '=',
      allowEmptyProperties: '=',
      emptyProperties: '=',
      fGetProperties: '&',
      fGetApartments: '&',
      apartmentsArguments: '=',
      //isJointProperties: '=',
      incomingProperties: '=',
      model: '=',
      isInsane: '=',
    },
    link: link,
  };
  return directive;

  // eslint-disable-next-line no-unused-vars
  function link(scope, element, attrs) {

    scope.properties = [];
    scope.propertiesTimer = null;
    scope.tickedApartments = {};
    scope.tickedProperties = {};
    scope.propertiesLoading = false;
    scope.apartmentsLoading = false;

    scope.model = [];

    scope.canAddEmptyProperty = false;

    scope.canSearchCounterpart = !scope.incomingProperties;

    scope.vm = { apartmentFilter: '' };

    /**
     * gets all ticked ids
     * @param {Object} ticked 
     * @returns {Array<number>}
     */
    let getTicked = ticked => Object.keys(ticked).filter(k => ticked[k]).map(id => parseInt(id));
    /**
     * determines if there is something ticked
     * @param {Object} ticked
     * @returns {boolean} true if something is ticked
     */
    let hasTicked = ticked => !!getTicked(ticked).length;
    
    scope.hasSanePropertyCount = () => {
      let count = getTicked(scope.tickedProperties).length;
      return scope.isInsane === true ? true : (count < 50);
    };
    scope.hasTickedProperties = () => hasTicked(scope.tickedProperties);
    scope.hasTickedApartments = () => hasTicked(scope.tickedApartments);

    scope.apartmentsFilter = el => {
      if (!scope.vm.apartmentFilter || scope.vm.apartmentFilter.length === 0) {
        return true;
      }
      let phrase = scope.vm.apartmentFilter.toLowerCase();
      return (el.streetAddress+el.nationalNo+el.ownerNo).toLowerCase().includes(phrase);
    };

    //TODO: Generalize
    let separateByProperty = () => {
      let previous = scope.model[0];
      scope.model.forEach((row, index) => {
        if (index > 0) {
          previous = scope.model[index - 1];
          row.differentProperty = row.property.id !== previous.property.id;
        } else {
          row.differentProperty = true;
        }
      });
    };

    let sortModel = () => {
      let hash = a => `${a.property.district}${a.property.propertyDesignation}${a.streetAddress}${a.nationalNo}`;
      scope.model.sort((a, b) => hash(a) > hash(b) ? 1 : -1);
      separateByProperty();
    };
    let sortEmptyProperties = () => {
      scope.emptyProperties.sort((a, b) => a.id > b.id ? 1: -1);
    };

    /**
     * add apartments avoiding duplicates
     * @param {Array} newApartments
     */
    let addUniqueApartments = newApartments => {
      scope.model = HyUtils.unionArraysFn(scope.model, newApartments, (a, b) => {
        return a.streetAddress+a.nationalNo === b.streetAddress+b.nationalNo;
      });
    };

    let firstFieldEl = $document[0].getElementById('propertyFilter');
    let lastVisible = false;
    $interval(() => {
      let visible = HyUtils.isVisible(firstFieldEl);
      if (visible && !lastVisible) {
        firstFieldEl.focus();
      }
      lastVisible = visible;
    }, 500);

    scope.addEveryApartment = () => {
      var ticked = getTicked(scope.tickedProperties);
      if (!ticked.length) {
        return;
      }
      var propertiesDone = 0;
      var apartmentCollector = [];
      scope.busyAddEvery = true;
      scope.properties.forEach(p => {
        if (!ticked.includes(p.id)) return;
        let args = Object.assign({ selectedProperty: p }, scope.apartmentsArguments);
        scope.fGetApartments()(args)
          .$promise.then(response => {
            response.rows.forEach(el => el.property = p);
            apartmentCollector.push(...response.rows);
            propertiesDone++;

            if (propertiesDone === ticked.length) {
              addUniqueApartments(apartmentCollector);
              scope.busyAddEvery = false;
              scope.allPropertiesSelected = false;
              scope.tickedProperties = {};
              sortModel();
            }
          });
      });
    };

    scope.focusOnSingleProperty = () => {
      if (scope.properties.length === 1) {
        scope.selectProperty(scope.properties[0]);
      }
    };

    scope.selectProperty = property => {
      scope.selectedProperty = property;
      scope.getApartments();
      scope.canAddEmptyProperty = canAddEmptyProperty();
    };
    scope.propertiesAfterType = () => {
      if (scope.propertiesTimer) {
        $timeout.cancel(scope.propertiesTimer);
      }
      scope.propertiesTimer = $timeout(scope.getProperties, 500);
    };

    scope.toggleAllProperties = () => {
      if (getTicked(scope.tickedProperties).length < scope.properties.length) {
        scope.tickedProperties = scope.properties.reduce((acc, el) => { acc[el.id] = true; return acc; }, {});
      } else {
        scope.tickedProperties = {};
      }
      scope.canAddEmptyProperty = canAddEmptyProperty();
    };
    scope.toggleAllApartments = () => {
      if (getTicked(scope.tickedApartments).length < scope.apartments.length) {
        scope.tickedApartments = scope.apartments.reduce((acc, el) => { acc[el.id] = true; return acc; }, {});
      } else {
        scope.tickedApartments = {};
      }
    };

    let fixEmptyProperties = () => {
      if (scope.emptyProperties) {
        scope.emptyProperties = scope.emptyProperties.filter(p => !scope.model.find(a => a.property.id === p.id));
      }
    };
    let canAddEmptyProperty = () => {
      if (scope.allowEmptyProperties) {
        if (!scope.emptyProperties) {
          scope.emptyProperties = [];
        }
        let selectedIds = getTicked(scope.tickedProperties);
        if (!selectedIds.length && scope.selectedProperty) {
          selectedIds.push(scope.selectedProperty.id);
        }
        if (!selectedIds.length) {
          return false;
        }
        let presentInEmptyProperties = scope.emptyProperties.some(p => selectedIds.includes(p.id));
        let presentInApartments = scope.model.some(a => selectedIds.includes(a.property.id));
        return !presentInEmptyProperties && !presentInApartments;
      } else {
        return false;
      }
    };
    scope.addEmptyProperty = () => {
      let selectedProperties = getTicked(scope.tickedProperties)
        .map(id => scope.properties.find(p => p.id === id));
      if (!selectedProperties.length) {
        selectedProperties.push(scope.selectedProperty);
      }
      scope.emptyProperties = scope.emptyProperties.concat(selectedProperties);
      scope.tickedProperties = {};
      scope.allPropertiesSelected = false;
      sortEmptyProperties();
      scope.canAddEmptyProperty = canAddEmptyProperty();
    };
    scope.deleteEmptyProperty = id => {
      scope.emptyProperties = scope.emptyProperties.filter(p => p.id !== id);
      sortEmptyProperties();
      scope.canAddEmptyProperty = canAddEmptyProperty();
    };

    scope.addSelected = () => {
      let tickedApartments = getTicked(scope.tickedApartments);
      let newApartments = scope.apartments.filter(el => tickedApartments.includes(el.id));
      newApartments = newApartments.filter(el => scope.apartmentsFilter(el));
      newApartments.forEach(el => el.property = scope.selectedProperty);
      addUniqueApartments(newApartments);
      scope.tickedApartments = {};
      scope.allApartmentsSelected = false;
      sortModel();
      fixEmptyProperties();
    };
    scope.deleteSelected = id => {
      scope.model = scope.model.filter(row => row.id !== id);
      sortModel();
    };

    scope.getOwnersString = p => p.owners ? '<span class="ownercounterpart">Ägare: </span>' + p.owners.map(o => o.name).join(', ') : '';
    scope.getOrganizationsString = p => {
      if (!p.organizations || !p.organizations.length) return '';
      let names = p.organizations.map(o => o.name);
      if (names.length > 3) {
        names.splice(3);
        names.push('...');
      }
      return '<span class="ownercounterpart">Motpart: </span>' + names.join(', ');
    };

    scope.getProperties = () => {
      if (scope.incomingProperties && scope.incomingProperties.length) {
        let filter = scope.propertyFilter.toLowerCase();
        scope.properties = scope.incomingProperties.filter(p =>
          p.propertyDesignation.toLowerCase().includes(filter) ||
          p.municipality.toLowerCase().includes(filter));
        scope.allPropertiesSelected = false;
      } else {
        scope.propertiesLoading = true;
        scope.fGetProperties()({filter: scope.propertyFilter, counterpartFilter: scope.counterpartFilter})
          .$promise.then(response => {
            let myProperties = response.rows;
            /*if (scope.jointProperties) {
              let incomingProperiesExceptMine = scope.incomingProperties
                .filter(r => !myProperties.find(m =>
                  m.propertyDesigation+m.municipality === r.propertyDesigation+r.municipality));
              scope.properties = myProperties.concat(incomingProperiesExceptMine);
            } else {
              scope.properties = myProperties;
            }*/
            scope.properties = myProperties;
            scope.focusOnSingleProperty();
            scope.tickedProperties = {};
            scope.allPropertiesSelected = false;
          }).catch(ErrorHandler('Misslyckades att hämta fastigheter.'))
          .finally(() => { scope.propertiesLoading = false; });
      }
    };
    scope.getApartments = () => {
      scope.apartmentsLoading = true;
      let args = Object.assign({ selectedProperty: scope.selectedProperty }, scope.apartmentsArguments);
      scope.fGetApartments()(args)
        .$promise.then(response => { scope.apartments = response.rows })
        .catch(ErrorHandler('Misslyckades att hämta lägenheter.'))
        .finally(() => {
          scope.apartmentsLoading = false;
          $timeout(() => {
            scope.tickedApartments = {};
            scope.allApartmentsSelected = true;
            scope.toggleAllApartments();
          }, 555);
        });
    };

    if (scope.incomingProperties && scope.incomingProperties.length) {
      scope.properties = scope.incomingProperties;
      scope.focusOnSingleProperty();
    } else {
      scope.getProperties(); //le mikre zey endra sej
    }

    scope.$on("refreshProperties", function (event, args) {
      scope.getProperties();
    });
    scope.$on("refreshApartments", function (event, args) {
      scope.getApartments();
    });
  }
}
})();