(function () {
'use strict';

angular.module('hyresgastApp').controller('FileUploadExModalCtrl', FileUploadExModalCtrl);

function FileUploadExModalCtrl($uibModalInstance, $scope, $document,
  settings) {
  var vm = this;

  vm.settings = settings;
  vm.settings.splitedValidFileTypes = settings.validFileTypes.split(' ');

  if (settings.types.length === 1) {
    vm.file = {};
    vm.file.type = settings.types[0].id;
    vm.selectedTypeName = settings.types[0].name;
  }

  $scope.$watch('vm.file.blobIds', function(newValue) {
    if (newValue) {
      $uibModalInstance.close(vm.file.metadata);
    }
  });
  $scope.$watch('vm.type', function (newValue) {
    if (newValue) {
      vm.uploadParams = { type: newValue.id };
    }
  });

  vm.doUpload = () => {
    let filePlateEl = angular.element($document[0].querySelector("#file-plate"));
    filePlateEl.scope().$broadcast('do-upload');
  };
  vm.cancel = () => { $uibModalInstance.dismiss('cancel') }
}

})();