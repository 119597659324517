(function () {
'use strict';

// @desc
// Paging component
//
// @example
//     <hy-paginator [get-list="vm.queryFunc"] OR [enable-param-mode]
//         page-offset="vm.pageOffset"
//         page-limit="vm.pageLimit"
//         total-count="vm.totalCount">
//     </hy-paginator>
//
// Parameters:
//     - vm.queryFunc - reference to controller's query function, which retrieve records from DB
//     - enable-param-mode - use ?offset parameter in URL to pass state btw directive and controller.
//       Works only if controller reloads when changing URL parameters, i.e. reloadOnSearch: true
//     - vm.pageLimit - number of records to be displayed per page
//     - vm.pageOffset - page offset, which is changed by a step of -=/+= vm.pageLimit
//     - vm.totalCount - total number of table rows, returned from backend

angular.module('hyresgastApp').directive('hyPaginator', paginator);

function paginator($location, $timeout) {
  var directive = {
    templateUrl: 'components/paginator/paginator.html',
    restrict: 'E',
    link: link,
    scope: {
      pageOffset: '=',
      pageLimit: '=',
      totalCount: '=',
      getList: '&?' // not needed if using enable-param-mode
    }
  };
  return directive;

  function link(scope, element, attrs) {
    // Setting url parameters to enable browser back/forward buttons
    var enableParamMode = 'enableParamMode' in attrs;

    scope.pageOffset = Number(scope.pageOffset);
    scope.gotoPrevPage = gotoPrevPage;
    scope.gotoNextPage = gotoNextPage;
    scope.firstRecordInCurrentPage = scope.pageOffset + 1;
    scope.computeLastRecord = computeLastRecord;

    function gotoPrevPage() {
      if (scope.firstRecordInCurrentPage > scope.pageLimit) {
        scope.pageOffset = scope.pageOffset - scope.pageLimit;
        if (!enableParamMode) {
          scope.firstRecordInCurrentPage = scope.pageOffset + 1;
          scope.computeLastRecord = computeLastRecord;
        }
        // force angular to update parent scope parameters before calling fetch() function
        $timeout(fetch, 0);
      }
    }

    function gotoNextPage() {
      if (scope.totalCount - scope.firstRecordInCurrentPage >= scope.pageLimit) {
        scope.pageOffset = scope.pageOffset + scope.pageLimit;
        if (!enableParamMode) {
          scope.firstRecordInCurrentPage = scope.pageOffset + 1;
          scope.computeLastRecord = computeLastRecord;
        }
        $timeout(fetch, 0);
      }
    }

    function computeLastRecord() {
      if (scope.totalCount - scope.firstRecordInCurrentPage < scope.pageLimit - 1) {
        return scope.totalCount;
      } else {
        return scope.firstRecordInCurrentPage + scope.pageLimit - 1;
      }
    }

    function fetch() {
      if (enableParamMode) {
        // parent controller will reload and read parameters from URL
        $location.search(angular.extend($location.search(), { offset: scope.pageOffset }));
      } else {
        // not using parameters so call parent controller function
        scope.getList()();
      }
    }

    scope.$watch('pageOffset', function() {
      scope.firstRecordInCurrentPage = scope.pageOffset + 1;
    });
  }
}

})();