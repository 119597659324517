(function () {
'use strict';

angular.module('hyresgastApp').controller('HelpModalCtrl', HelpModal);

function HelpModal($uibModalInstance, settings) {
  var vm = this;
  vm.cancel = cancel;
  vm.confirm = confirm;
  vm.title = settings.title;
  vm.body = settings.body;
  /////////

  function confirm() {
    $uibModalInstance.close(true);
  }

  function cancel() {
    $uibModalInstance.close();
  }
}

})();