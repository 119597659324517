(function () {
'use strict';

angular
  .module('hyresgastApp')
  .controller('SkipRentlistModalCtrl', SkipRentlistModalCtrl);

function SkipRentlistModalCtrl(
  $uibModalInstance,
  $scope,
  $location,
  negotiationObject,
  hasApartments,
  Negotiation, User,
  NegotiationTypes,
  Flash
) {
  var vm = this;
  vm.cancel = cancel;
  vm.save = save;

  vm.canSkipRentlist = !negotiationObject.canSkipRentlist;
  vm.hasApartments = hasApartments;

  /////////

  function save() {
    vm.saving = true;

    var negotiation= {
      id: negotiationObject.id,
      name: negotiationObject.name,
      type: negotiationObject.type,
      counterpartOrganizationId: negotiationObject.counterpartOrganizationId,
      canSkipRentlist: vm.canSkipRentlist,
    }

    Negotiation.save(negotiation)
      .$promise.then(
        function(response) {
          $uibModalInstance.close(response);
          negotiationObject.canSkipRentlist = vm.canSkipRentlist;
          Flash.set('Förhandlingsinformationen har sparats', 'success');
        }
      )
      .catch(function(reason) {
        $uibModalInstance.dismiss('error');
        if (reason.status === 403) {
          $location.path('/403')
        } else {
          Flash.set('Misslyckades att spara förhandlingsinformation', 'error');
        }
      })
      .finally(function() {
        vm.saving = false;
      });
  }

  function cancel() {
    $uibModalInstance.close(negotiationObject);
  }
}
})();