(function () {
'use strict';

// 'Påkallan' in Swedish

angular.module('hyresgastApp').factory('NegotiationCall', function($resource) {
  return $resource(
    '/api/negotiation-calls/:id',
    { id: '@id', blobId: '@blobId' },
    {
      query: {
        url: '/api/negotiation-calls',
        isArray: false
      },
      queryProperty: {
        url: '/api/negotiation-calls/:id/properties',
        method: 'POST'
      },
      queryApartment: {
        url: '/api/negotiation-calls/:id/apartments',
        method: 'POST'
      },
      queryPropertyByRentList: {
        url: '/api/negotiation-calls/rent-list/:blobId/properties',
        method: 'GET'
      },
      queryPropertyByPropertyList: {
        url: '/api/negotiation-calls/property-list/:blobId/properties',
        method: 'GET'
      },
      queryPropertyListValidation: {
        url: '/api/negotiation-calls/property-list/:blobId/validate',
        method: 'GET',
        isArray: false
      },
      queryApartmentByRentList: {
        url: '/api/negotiation-calls/rent-list/:blobId/apartments',
        method: 'GET'
      },
      queryRentListValidation: {
        url: '/api/negotiation-calls/rent-list/:blobId/validate',
        method: 'GET',
        isArray: false
      },
      queryDistrict: {
        url: '/api/negotiation-calls/:id/districts'
      },
      createNegotiation: {
        url: '/api/negotiation-calls/:id/create-negotiation',
        method: 'POST'
      },
      returnNegotiation: {
        url: '/api/negotiation-calls/:id/respond',
        method: 'POST'
      },
      queryNegotiation: {
        url: '/api/negotiation-calls/:id/negotiations'
      },
      queryErrors: {
        url: '/api/negotiation-calls/:id/possible-errors',
        method: 'GET'
      },
      queryErrorsInProperties: {
        url: '/api/real-estate/properties/possible-negotiation-conflicts',
        method: 'POST'
      },
      queryErrorsInApartments: {
        url: '/api/real-estate/apartments/possible-negotiation-conflicts',
        method: 'POST'
      },
      listDocuments: {
        url: '/api/negotiation-calls/:id/documents',
        isArray: true
      },
      deleteDocument: {
        url: '/api/negotiation-calls/:id/documents/:blobId',
        method: 'DELETE'
      },
      getApartmentsSummaryBySelection: {
        url: '/api/negotiation-calls/:id/properties/summary',
        isArray: false,
        method: 'POST'
      },
      setOffice: {
        url: '/api/negotiation-calls/:id/office',
        method: 'POST'
      },
      updateHandler: {
        url: '/api/negotiation-calls/:id/update-handler',
        method: 'POST'
      },
      delete: {
        url: '/api/negotiation-calls/:id',
        method: 'DELETE',
      },
    }
  );
});

})();