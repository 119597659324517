(function () {
'use strict';

angular.module('hyresgastApp').filter('demandTypeToDescription', function() {
  return function(value) {
    switch (value) {
      case '0':
        return '%';
      case '1':
        return 'kr/kvm/år';
      case '2':
        return 'Fritext';
      default:
        return 'Typ ej angivet';
    }
  };
});

})();