(function () {
'use strict';

angular.module('hyresgastApp').factory('RealEstate', function($resource) {
  return $resource(
    '/api/real-estate/properties/:id',
    { id: '@id' },
    {
      query: {
        url: '/api/real-estate/properties',
        isArray: false,
      },
      querySimple: {
        url: '/api/real-estate/properties-simple',
        isArray: false,
      },
      queryPropertiesInMyNegotiations: {
        url: '/api/real-estate/properties/in-my-negotiations',
        method: 'GET',
      },
      queryPropertiesInNegotiationsWithMe: {
        url: '/api/real-estate/properties/in-my-negotiations/with-user/:partnerId',
        method: 'GET',
      },
      queryNegotiationsForProperty: {
        url: '/api/real-estate/properties/:id/negotiations',
        method: 'GET',
      },
      queryApartmentsInProperty: {
        url: '/api/real-estate/properties/:id/apartments',
        method: 'GET',
      },
      queryPropertiesStatusCount: {
        url: '/api/real-estate/properties/status-count',
        method: 'POST',
      },
      queryFreeAgreementsForProperty: {
        url: '/api/real-estate/properties/:id/agreements',
        method: 'GET',
      },
      getApartmentHistory: {
        url: '/api/real-estate/apartments/:id/history',
        method: 'GET',
      },
      getObjects: {
        url: '/api/real-estate/objects',
        method: 'GET'
      },
      queryOwners: {
        url: '/api/real-estate/owners',
        method: 'GET',
      },
      findByExternalId: {
        url: '/api/real-estate/properties/findByExternalId/:id',
        method: 'GET',
      },
      calculateNH: {
        url: '/api/real-estate/calc-nh',
        method: 'POST',
      },
    }
  );
});

})();