(function () {
'use strict';

angular.module('hyresgastApp').controller('BooleanConfirmationModalCtrl', BooleanConfirmationModalCtrl);

function BooleanConfirmationModalCtrl($uibModalInstance) {
  var vm = this;
  vm.cancel = cancel;
  vm.confirm = confirm;

  /////////

  function confirm() {
    $uibModalInstance.close(true);
  }

  function cancel() {
    $uibModalInstance.close();
  }
}

})();