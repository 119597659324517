(function () {
'use strict';

angular.module('hyresgastApp').controller('ShowAgreementOverviewCtrl', ShowAgreementOverviewCtrl);

function ShowAgreementOverviewCtrl(
  $scope,
  $uibModal,
  $routeParams,
  $location,
  Flash,
  Agreement,
  Auth,
  NegotiationTypes,
  Parties
) {
  var vm = this;
  vm.NegotiationTypes = NegotiationTypes;
  vm.haveWeAgreed = haveWeAgreed;
  vm.deleteAgreement = deleteAgreement;
  vm.isRentListFileType = isRentListFileType;

  var parentCtrlVM = $scope.$parent.vm;

  Auth.heartbeat().then(function() {
    vm.currentUserParty = Auth.currentUser().party;
    if (vm.currentUserParty === Parties.HGF) {
      vm.isHgfUser = true;
    }
  });

  function isRentListFileType (fileType) {
    return (fileType === 35 || fileType === 36);
  }

  /////////

  function haveWeAgreed(flag) {
    var modalInstance = $uibModal.open({
      animation: true,
      ariaLabelledBy: 'modal-title-top',
      ariaDescribedBy: 'modal-body-top',
      size: 'xsm',
      templateUrl: 'components/modal/confirmation/confirmationYesNoModal.html',
      controller: 'ConfirmationModalCtrl',
      controllerAs: 'vm'
    });

    modalInstance.result.then(function() {
      if (vm.isHgfUser) {
        parentCtrlVM.agreement.agreedByAssociation = flag;
      } else {
        parentCtrlVM.agreement.agreedByOwner = flag;
      }
      parentCtrlVM.saveAgreement();
    });
  }

  function deleteAgreement() {

    var warningMessage = parentCtrlVM.agreement.numApartments > 0 ?
      'ÖVERENSKOMMELSEN HAR EN HYRESLISTA UPPLADDAT. RADERA ÄNDÅ?' :
      'Är du säker på att du vill ta bort?';

    var modalInstance = $uibModal.open({
      animation: true,
      ariaLabelledBy: 'modal-title-top',
      ariaDescribedBy: 'modal-body-top',
      size: 'xsm',
      templateUrl: 'components/modal/confirmation/confirmationModalWithMessage.html',
      controller: 'ConfirmationModalWithMessageCtrl',
      controllerAs: 'vm',
      windowClass: 'modal-content--secondary',
      resolve: {
        message: function() { return warningMessage; }
      }
    });

    modalInstance.result.then(function() {
      var negotiationId = $routeParams.negotiationId;
      parentCtrlVM.Resource.deleteAgreement({
        negotiationId,
        agreementId: $routeParams.id})
        .$promise.then(function() {
          if (negotiationId) {
            $location.path('/negotiations/' + negotiationId).search('view', 'agreements');
            Flash.set('Lyckades att ta bort överenskommelsen', 'success');
          } else {
            $location.path('/agreements/');
            Flash.set('Lyckades att ta bort avtal', 'success');
          }
        })
        .catch(function() {
          if (negotiationId) {
            Flash.set('Misslyckades att ta bort överenskommelsen', 'error');
          } else {
            Flash.set('Misslyckades att ta bort avtal', 'error');
          }
        });
    });
  }
}

})();