(function () {
'use strict';

angular.module('hyresgastApp').controller('ChangeOfficeModalCtrl', ChangeOfficeModalCtrl);

function ChangeOfficeModalCtrl($uibModalInstance, ErrorHandler, Office) {
  var vm = this;

  vm.ok = ok;
  vm.cancel = cancel;

  queryOffice();

  ////////////

  function queryOffice() {
    return Office.query().$promise.then(function(response) {
      vm.offices = response;
    })
      .catch(ErrorHandler('Misslyckades att hämta kontor.'));
  }

  function ok() {
    $uibModalInstance.close(vm.selectedOffice);
  }

  function cancel() {
    $uibModalInstance.dismiss('cancel');
  }
}

})();