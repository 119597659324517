(function () {
'use strict';

angular
  .module('hyresgastApp')
  .controller('NegotiationTableNegotiatorMeetingListCtrl', NegotiationTableNegotiatorMeetingListCtrl);

function NegotiationTableNegotiatorMeetingListCtrl(
  $scope,
  $location,
  $routeParams,
  Flash,
  Meeting,
  NegotiationStates,
  SubPage,
  ErrorHandler
) {
  var vm = this;

  vm.gotoShow = gotoShow;
  vm.pageLimit = 30;
  vm.pageOffset = 0;
  vm.sortColumn = $location.search().order || 'caseNumber'; // default
  vm.sortDirection = $location.search().direction || 'DESC'; // default
  vm.listColumns = [
    { title: 'Id', column: 'caseNumber' },
    { title: 'Namn', column: 'name' },
    { title: 'Typ', column: 'counterpart.name' },
    { title: 'Dagar kvar', column: 'rentEffectiveFrom' },
    { title: 'Nästa möte', column: 'updatedAt' },
    { title: 'Datum för framställan', column: 'updatedAt' },
    { title: 'Status', column: 'state' },
    { title: 'Begärt ändringsdatum', column: 'updatedAt' }
  ];

  $scope.$on('$routeUpdate', function() {
    if ($location.search().order) {
      // reset offset when sorting
      vm.pageOffset = 0;
      getMeetingsWithMe();
    }
  });

  /////////
  getCurrentSubpage();
  getMeetingsWithMe();

  function gotoShow(id) {
    $location.search({});
    $location.path('/negotiations/' + id).search('view', 'meetings');
  }

  function getMeetingsWithMe() {
    vm.readyMeetings = false;

    return Meeting.queryMeetingsWithMe({
      partnerId: $routeParams.partnerId,
      limit: vm.pageLimit,
      offset: vm.pageOffset,
      order: vm.sortColumn,
      direction: vm.sortDirection
    })
      .$promise.then(function(data) {
        vm.myMeetings = data.rows;
        vm.totalCount = data.count;
      })
      .catch(ErrorHandler('Misslyckades att hämta lista.'))
      .finally(function() {
        vm.readyMeetings = true;
      });
  }

  function getCurrentSubpage() {
    var defaultSubPage = 'list';
    // create a validation list against manually change of URL '?view=' parameter
    var whiteList = ['meetings', 'properties', 'finished'];
    vm.currentSubpage = SubPage.set($routeParams.view, whiteList, defaultSubPage);
  }
}

})();