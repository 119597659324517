(function () {
// Example of usage:
// <span ng-bind-html="vm.yourText | preventDateBreak" ></span>

angular.module('hyresgastApp').filter('preventDateBreak', function($sce) {
  return function(input) {
    if (!input) return input;

    // pattern for multiple type of date formats, case insensitive
    var datePattern = /(\b\d{1,2}[-\/]\d{1,2}[-\/]\d{2,4}\b|\b\d{4}[-\/]\d{1,2}[-\/]\d{1,2}\b|\b\d{1,2}\s(?:jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec|maj|okt)\s\d{4}\b|\b(?:jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec|maj|okt)\s\d{1,2},\s\d{4}\b|\b\d{1,2}\/\d{1,2}\s\d{4}\b|\b\d{1,2}\s(?:jan|feb|mar|apr|maj|jun|jul|aug|sep|okt|nov|dec)\b|\b\d{1,2}\s(?:januari|februari|mars|april|maj|juni|juli|augusti|september|oktober|november|december)\b|\b(?:jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec)\s\d{1,2}\s\d{4}\b)/gi;

    var output = input.replace(datePattern, '<span style="white-space: nowrap;">$1</span>');

    return $sce.trustAsHtml(output);
  };
});
})();