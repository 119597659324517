(function () {
'use strict';

angular.module('hyresgastApp').controller('InviteUsersModalCtrl', InviteUsersModalCtrl);

function InviteUsersModalCtrl($uibModalInstance, $location, $timeout,
  RoleLookup,
  Organization, User, Flash, HyUtils,
  selectedOrganization, foundAdmin) {

  var vm = this;
  vm.organization = selectedOrganization.name;
  vm.roles = RoleLookup;
  vm.foundAdmin = foundAdmin;

  // the first invited user have to be admin (avoid dropdown)
  if (vm.foundAdmin && vm.foundAdmin.length > 0) {
    vm.user = { role: 100 }; // Default: User role
  } else {
    vm.user = { role: 200 }; // Default: Admin role
  }

  vm.startCheckEmail = startCheckEmail;
  vm.confirmingEmail = false;
  vm.isMailConfirmed = 'unknown';

  vm.tamePhoneNumber = () => {
    if (vm.user.phone) {
      $timeout(() => {
        let tamedPhone = HyUtils.tamePhoneNumber(vm.user.phone);
        vm.foreignPhone = tamedPhone.countryCode !== '+46';
        vm.user.phone = vm.foreignPhone ? tamedPhone.countryCode + tamedPhone.number :  tamedPhone.number;
      }, 3000);
    }
  };

  vm.selectRole = selectRole;
  vm.save = function() {
    vm.saving = true;
    vm.tamePhoneNumber();
    let invite = {
      id: selectedOrganization.id, //add the org id to the object sent to backend.
      email: vm.user.email,
      phone: vm.user.phone,
      role: vm.user.role
    };
    Organization.saveInvite(invite)
      .$promise.then(response => {
        Flash.set('Användaren inbjuden', 'success');
        $uibModalInstance.close(response);
      }).catch(reason => {
        if (reason.status === 409) {
          Flash.set('Användaren finns redan i organisationen', 'error');
        } else if (reason.status === 403) {
          $location.path('/403')
        } else if (reason.data.code === 10) {
          Flash.set('Kan inte bjuda in en HGF-användare till en extern organisation', 'error');
        } else {
          Flash.set('Misslyckades att bjuda in användare.', 'error');
        }
        $uibModalInstance.dismiss('cancel');
      }).finally(() => { vm.saving = false });
  };

  vm.cancel = function() {
    $uibModalInstance.dismiss('cancel');
  };

  function selectRole(role) {
    vm.user.role = role;
  }

  function startCheckEmail() {
    if (vm.emailCheckScheduled) {
      return;
    }

    vm.emailCheckScheduled = true;
    $timeout(() => {
      checkEmail();
      vm.emailCheckScheduled = false;
    }, 3000);
  }

  function checkEmail() {
    vm.confirmingEmail = true;
    User.checkEmail({ email: vm.user.email}).$promise.then(
      function(response) {
        vm.isMailConfirmed = response.isValid;
        vm.confirmingEmail = false;
      });
  }
}

})();