(function () {
'use strict';

angular.module('hyresgastApp').filter('hyNegotiationCallSuspectedErrors', function(NegotiationCallSuspectedErrors) {
  return function(value) {
    return hyNegotiationCallSuspectedErrors(value, NegotiationCallSuspectedErrors);
  };
});

function hyNegotiationCallSuspectedErrors(value, NegotiationCallSuspectedErrors) {
  switch (value) {
    case NegotiationCallSuspectedErrors.MISSING:
      return 'Saknas i registret.';
    case NegotiationCallSuspectedErrors.NO_OWNER:
      return 'Fler förhandlingar på denna fastighet finns registrerade men med annat ombud/motpart.';
    case NegotiationCallSuspectedErrors.NO_NEGOTIATION:
      return 'Saknar förhandlingsordning.';
    case NegotiationCallSuspectedErrors.ALREADY_CALLED:
      return 'Finns redan på en förhandlingsframställan.';
    case NegotiationCallSuspectedErrors.IN_NEGOTIATION:
      return 'Finns redan på en årsförhandling.';
    default:
      return 'Okänt fel.';
  }
}

})();