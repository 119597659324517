(function () {
'use strict';

angular.module('hyresgastApp').factory('DateCounter', DateCounter);

function DateCounter() {
  var service = {
    getFutureDate: getFutureDate,
    getTheFirstOfNextMonth: getTheFirstOfNextMonth
  };
  return service;

  ////////////

  /**
   * @param {number} days 
   * @param {boolean} skipWeekends
   * @returns {Date}
   */
  function getFutureDate(days, skipWeekends) {
    if (!skipWeekends) {
      return new Date(Date.now() + days * 24 * 3600 * 1000);
    } else {
      let date = new Date(Date.now());
      let i = 1;
      while (days > 0) {
        if (![0,6].includes(date.getDay())) {
          days--;
        }
        date = getFutureDate(i, false);
        i++;
      }
      return date;
    }
  }

  function getTheFirstOfNextMonth(dataObj) {
    var currentDate = dataObj.getDate();
    var currentMonth = dataObj.getMonth();
    var year = dataObj.getFullYear();

    if (currentDate === 1) {
      var month = currentMonth;
    } else {
      var month = (currentMonth + 1) % 12;
    }

    if (currentMonth === 11 && currentDate !== 1) {
      year = year + 1;
    }

    return new Date(year, month, 1);
  }
}

})();