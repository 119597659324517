(function () {
'use strict';

// @desc handle roles

angular.module('httpRole', []).value('Role', {
  SPECTATOR: 50,
  USER: 100,
  ADMIN: 200
});

})();