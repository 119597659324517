(function () {
'use strict';

angular.module('hyresgastApp').controller('ImportRentlistCtrl', ImportRentlistCtrl);

function ImportRentlistCtrl(
  $scope, $uibModalInstance,
  Negotiation, ErrorHandler,
  negotiation, amend
) {
  var vm = this;
  vm.negotiation = negotiation;
  vm.save = save;
  vm.showSaveButton = true;
  vm.cancel = cancel;
  vm.showPage = 'Start';
  vm.goToNextPage = goToNextPage;
  vm.getColumnTitle = title => title instanceof Array ? title[0] : title.split(',')[0].trim();

  // Use a file upload directive and the directive is validating the content
  // of uploaded files (excel). If the file upload directive receives errors from the backend
  // we want to open a modal to show the errors to the user
  $scope.$watch('vm.rentListValidationErrors', function(errors) {
    if (errors) {
      vm.errors = errors;
      vm.validationExcelUrl = '/api/negotiations/'+ vm.negotiation.id +'/rent-list/' + errors.blobId + '/validate-excel';
      goToNextPage('Error')
    }
  });

  //if the list is uploaded take the summary of the validation response and show in the summary page.
  $scope.$watch('vm.validationResponse', function(validationResponse) {
    if (validationResponse) {
      vm.response = validationResponse;
      vm.apartmentsSummary = {};
      vm.apartmentsSummary.apartments = validationResponse.summary.numApartments;
      vm.apartmentsSummary.municipalities = validationResponse.summary.numMunicipalities;
      vm.apartmentsSummary.properties = validationResponse.summary.numProperties;
      vm.validationExcelUrl = '/api/negotiations/'+ vm.negotiation.id +'/rent-list/' + validationResponse.blobId + '/validate-excel';
    }
  });

  ////////////

  function goToNextPage(page) {
    vm.showPage = page;
  }

  function save() {
    vm.saving = true;
    Negotiation.importRentList({
      id: vm.negotiation.id,
      rentListBlobId: vm.negotiation.rentListBlobId,
      amend,
    }).$promise.then(() => { $uibModalInstance.close('Hyreslistan har laddats upp till förhandlingen.'); })
      .catch(ErrorHandler('Misslyckades att ladda upp hyreslistan.'))
      .finally(() => { vm.saving = false });
  }

  function cancel() {
    vm.negotiation.rentListBlobId = null;
    $uibModalInstance.dismiss();
  }
}

})();