(function () {
'use strict';

angular
  .module('hyresgastApp')
  .controller('ListUserNegotiationCallsLastChangesCtrl', ListUserNegotiationCallsLastChangesCtrl);

function ListUserNegotiationCallsLastChangesCtrl($scope, $location, $routeParams, Flash, Negotiation, ErrorHandler) {
  var vm = this;
  vm.negotiations = [];
  vm.getLastChanges = getLastChanges;
  vm.gotoShow = gotoShow;

  vm.pageLimit = 30;
  vm.pageOffset = 0;
  vm.sortColumn = $location.search().order || 'updatedAt'; // default
  vm.sortDirection = $location.search().direction || 'DESC'; // default
  vm.listColumns = [
    { title: 'Id', column: 'caseNumber' },
    { title: 'Namn', column: 'name' },
    { title: 'Organisation', column: 'counterpart.name' },
    { title: 'Ändrades av', column: 'lastEditor.firstname' },
    { title: 'Ändringsdatum', column: 'updatedAt' }
  ];

  $scope.$on('$routeUpdate', function() {
    if ($location.search().order) {
      // reset offset when sorting
      vm.pageOffset = 0;
      getLastChanges();
    }
  });

  /////////

  getLastChanges();

  function gotoShow(id) {
    $location.search({});
    $location.path('/negotiations/' + id).search('view', 'history');
  }

  function getLastChanges(resetOffset) {
    vm.ready = false;
    if (resetOffset) {
      vm.pageOffset = 0;
    }
    return Negotiation.queryByLatestChange(
      {
        limit: vm.pageLimit,
        offset: vm.pageOffset,
        order: vm.sortColumn,
        direction: vm.sortDirection
      },
      {}
    )
      .$promise.then(function(data) {
        vm.negotiations = data.rows;
        vm.totalCount = data.count;
      })
      .catch(ErrorHandler('Misslyckades att hämta lista.'))
      .finally(function() {
        vm.ready = true;
      });
  }
}

})();