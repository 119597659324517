(function () {
'use strict';

angular.module('hyresgastApp').directive('hyAmendButton', amendButton);

function amendButton() {
  var directive = {
    restrict: 'E',
    templateUrl: 'components/amend-button/amend-button.html',
    scope: {
      title: '@',
      amendTitle: '@',
      buttonClass: '@',
      normalClick: '&',
      amendClick: '&',
      disabled: '=',
      amendDisabled: '=',
    },
  };
  return directive;
}
})();