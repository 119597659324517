(function () {
'use strict';
angular.module('hyresgastApp').controller('NewFreeAgreementModalCtrl', NewFreeAgreementModalCtrl);

function NewFreeAgreementModalCtrl($uibModalInstance,
  users, invites,
  FreeAgreementTypes, Parties) {
  var vm = this;

  vm.FreeAgreementTypes = FreeAgreementTypes;

  vm.userContainer = {
    hgfSide: [],
    faSide: [],
  };

  if (users) {
    let hgfSide = users.filter(u => u.party === Parties.HGF);
    hgfSide.forEach(p => p.isChief = p.roleObj.isChief);
    let faSide = users.filter(u => u.party !== Parties.HGF); //we want delegates too
    faSide.forEach(p => p.isChief = p.roleObj.isChief);

    vm.userContainer = {
      hgfSide,
      faSide,
      chiefNegotiatorHGF: hgfSide.find(p => p.isChief),
      chiefNegotiatorExternal: faSide.find(p => p.isChief),
      invites,
    };
  }

  vm.ok = () => {
    $uibModalInstance.close({
      userContainer: vm.userContainer,
      type: vm.type,
      message: vm.message
    });
  };
  vm.cancel = () => { $uibModalInstance.close({ cancel: true }) };
}
})();