(function () {
'use strict';

// Certain uploaded files are parsed and validated by API. I.e is a
// Excel-file missing a column. The parsing might take some time (~30s),
// this service can be used to poll the API in a defined interval
// The service returns a promise

angular.module('fileValidatorPolling', []).factory('FileValidatorPolling', FileValidatorPolling);

function FileValidatorPolling($http, $timeout, $q) {
  var stopNow;

  var service = {
    start: start,
    stop: stop
  };

  return service;

  ////////////

  function start(url) {
    stopNow = false;

    var delay = 5000; // 5 seconds
    var maxRetries = 60; // 60 * 5000 = 5 minutes
    var retries = 0;

    return validate(url, delay, retries, maxRetries);
  }

  // Stop eventually... (Keep It Simple Stupid)
  function stop() {
    stopNow = true;
  }

  function delayer(delay) {
    delay += Math.floor(Math.random() * 1000); // avoid synchronized waves
    return $timeout(function() {}, delay);
  }

  function fetch(url) {
    return $http
      .get(url, {
        timeout: 15000, // dont be stuck in pending longer than 15 sec if loosing internet connection
        withCredentials: true
      })
      .then(function(response) {
        return response.data;
      });
  }

  function validate(url, delay, retries, maxRetries) {
    return delayer(delay)
      .then(function() {
        if (stopNow) {
          return { status: 'UserAbort' };
        } else if (retries >= maxRetries) {
          return $q.reject('MaxRetries');
        } else {
          return fetch(url);
        }
      })
      .then(
        function(validation) {
          if (validation.status === 'ok' || validation.status === 'error') {
            return validation;
          } else if (validation.status === 'UserAbort') {
            return validation.status; // not used at the moment
          } else {
            return validate(url, delay, ++retries, maxRetries);
          }
        },
        function(reason) {
          if (reason === 'MaxRetries') {
            return $q.reject(reason);
          } else {
            return validate(url, delay, ++retries, maxRetries);
          }
        }
      );
  }
}

})();