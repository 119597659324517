(function () {
'use strict';

angular.module('hyresgastApp').controller('ApproveUserCtrl', ApproveUserCtrl);

function ApproveUserCtrl($location, $routeParams, $uibModal, Flash, Organization, User, Roles, ErrorHandler) {
  var vm = this;
  vm.organization = {};
  vm.save = save;
  vm.destroy = destroy;
  vm.user = {};

  getUser();

  ////////////

  function getUser() {
    return User.get({ id: $routeParams.id })
      .$promise.then(function(response) {
        vm.ready = true;
        vm.user = response;
      })
      .catch(new ErrorHandler('Misslyckades att hämta användaren.'));
  }

  function save() {
    var modalInstance = $uibModal.open({
      animation: true,
      ariaLabelledBy: 'modal-title-top',
      ariaDescribedBy: 'modal-body-top',
      size: 'sm',
      templateUrl: 'components/modal/confirmation-info/confirmationModalInfo.html',
      controller: 'ConfirmationModalInfoCtrl',
      controllerAs: 'vm',
      resolve: {
        text: function() {
          return {
            question: 'Är du säker på att du vill godkänna',
            subject: vm.user.fullName,
            additionalInfo: '',
            abort: 'Avbryt',
            confirm: 'Godkänn'
          };
        },
        buttonClassSecondary: function() {
          return false;
        }
      }
    });

    modalInstance.result.then(function() {
      Organization.updateUserRole({
        organizationId: Object.keys(vm.user.roleByOrganization)[0],
        id: vm.user.id,
        role: Roles.USER
      }).$promise.then(
        function() {
          Flash.set('Konto godkänt - bekräftelse skickad', 'success');
          $location.search({});
          $location.path('/users/' + vm.user.id);
        })
        .catch(new ErrorHandler('Misslyckades att godkänna konto.'))
    });
  }

  function destroy() {
    var modalInstance = $uibModal.open({
      animation: true,
      ariaLabelledBy: 'modal-title-top',
      ariaDescribedBy: 'modal-body-top',
      size: 'sm',
      templateUrl: 'components/modal/confirmation-info/confirmationModalInfo.html',
      controller: 'ConfirmationModalInfoCtrl',
      controllerAs: 'vm',
      resolve: {
        text: function() {
          return {
            question: 'Är du säker på att du vill neka',
            subject: vm.user.fullName,
            additionalInfo: '',
            abort: 'Avbryt',
            confirm: 'Neka'
          };
        },
        buttonClassSecondary: function() {
          return true;
        }
      }
    });

    modalInstance.result.then(function() {
      User.delete({
        id: vm.user.id
      }).$promise.then(
        function() {
          Flash.set('Konto nekat - bekräftelse skickad', 'success');
          $location.path('/requested-accounts');
        }
      )
        .catch(new ErrorHandler('Misslyckades att neka konto.'));
    });
  }
}

})();