(function () {
'use strict';

angular.module('hyresgastApp').controller('ShowNegotiationCallErrorsCtrl', ShowNegotiationCallErrorsCtrl);

function ShowNegotiationCallErrorsCtrl($scope, $routeParams, $location, NegotiationCall, ErrorHandler) {
  var vm = this;

  vm.errors = [];
  vm.queryErrors = queryErrors;

  vm.pageLimit = 30;
  vm.pageOffset = $location.search().offset || 0;

  vm.toggleBogusTooltip = (element) => {
    let id = element.error.id;
    let tooltip = document.getElementById('bogusTooltip'+id).firstElementChild;
    tooltip.style.visibility = tooltip.style.visibility==='visible' ? 'hidden' : 'visible';
  };

  queryErrors();

  /////////

  function queryErrors() {
    vm.readyErrors = false;

    return NegotiationCall.queryErrors({
      id: $routeParams.id,
      limit: vm.pageLimit,
      offset: vm.pageOffset
    })
      .$promise.then(function(data) {
        vm.errors = data.rows.unique();
        vm.errorsCount = vm.errors.length;
      })
      .catch(ErrorHandler('Misslyckades att hämta lista med fel.'))
      .finally(function() {
        vm.readyErrors = true;
      });
  }
}

})();