(function () {
'use strict';

angular.module('hyresgastApp').controller('ListNegotiationCallAllCtrl', ListNegotiationCallAllCtrl);

function ListNegotiationCallAllCtrl($scope, $location, NegotiationCall, Flash, NegotiationCallStates, NegotiationTypes, Office, ErrorHandler) {
  var vm = this;
  vm.NegotiationCallStates = NegotiationCallStates;
  vm.statusChoices = angular.extend({
    ALL: ''
  }, NegotiationCallStates);
  vm.searchBySelectedOffices = searchBySelectedOffices;
  vm.negotiationAllCalls = [];
  vm.gotoShow = gotoShow;
  vm.typeChoices = angular.extend({}, NegotiationTypes);
  vm.pageLimit = 30;
  vm.pageOffset = 0;
  vm.sortColumn = $location.search().order || 'sentAt'; // default
  vm.sortDirection = $location.search().direction || 'DESC'; // default
  vm.getNegotiationAllCalls = getNegotiationAllCalls;
  vm.listColumns = [{
    title: 'Namn',
    column: 'name'
  },
  {
    title: 'Organisation',
    column: 'counterpart.name'
  },
  {
    title: 'Skickad till kontor',
    column: 'office.name'
  },
  {
    title: 'Status',
    column: 'state'
  },
  {
    title: 'Bereds av',
    column: 'handledByUserId'
  },
  {
    title: 'Inskickad',
    column: 'sentAt'
  },
  {
    title: 'Id',
    column: 'caseNumber'
  }
  ];

  $scope.$on('$routeUpdate', function () {
    if ($location.search().order) {
      // reset offset when sorting
      vm.pageOffset = 0;
      getNegotiationAllCalls();
    }
  });

  queryOffice();

  ////////////

  function queryOffice() {
    return Office.query().$promise.then(function (response) {
      vm.allOffices = response;
      vm.officeNamesById = {};
      vm.allOffices.forEach(function (office) {
        vm.officeNamesById[office.id] = office.name;
      });
      getNegotiationAllCalls();
    })
      .catch(ErrorHandler('Misslyckades att hämta kontor.'));
  }

  function gotoShow(id) {
    $location.search({});
    $location.path('/negotiation-calls/' + id);
  }

  function searchBySelectedOffices() {
    vm.selectedOffices = [];
    for (var key in vm.offices) {
      if (vm.offices[key] === true) {
        vm.selectedOffices.push(key);
      }
    }
    getNegotiationAllCalls(true);
  }

  function getNegotiationAllCalls(filterMode) {
    vm.ready = false;
    if (filterMode) {
      vm.pageOffset = 0;
    }
    return NegotiationCall.query({
      limit: vm.pageLimit,
      offset: vm.pageOffset,
      order: vm.sortColumn,
      direction: vm.sortDirection,
      'filters[caseNumber]': vm.inputTextId,
      'filters[state]': vm.statusId,
      'filters[office.id]': vm.selectedOffices,
      'filters[type]': vm.typeId,
      'search[name]': vm.inputTextName,
      'search[counterpart.name]': vm.inputTextOrganisation,
      'search[creator.firstname,creator.lastname]': vm.inputTextCreator,
      'search[handler.firstname,handler.lastname]': vm.inputTextHandler
    }).$promise.then(function (data) {
      vm.negotiationAllCalls = data.rows;
      vm.totalCount = data.count;
      // Save initial count of records, to be distingueshed from the current count after
      // unsuccessful filtering. This will affect all messages, which are dependent on totalCount = 0.
      if (filterMode !== true) {
        vm.initialTotalCount = vm.totalCount;
      }
    }).catch(ErrorHandler('Misslyckades att hämta lista med påkallan.'))
      .finally(function () { vm.ready = true });
  }
}
})();