(function () {
/* eslint-disable no-unused-vars */
'use strict';

// @desc
//   Directive is placed inside <main> and will appear when
//   when API returns a 401. It will also hide main content.
//
// @example
//   <main>
//      <hy-login></hy-login>
//      <ng-view class="main-content"></ng-view>
//   </main>

angular.module('hyresgastApp').directive('hyLogin', login);

function login($document, $rootScope, $location, $timeout, Auth, Flash) {
  var directive = {
    restrict: 'E',
    templateUrl: 'components/login/login.html',
    scope: {},
    link: link
  };
  return directive;

  function link(scope, element, attrs) {
    var loginVisible;
    var mainContent = angular.element($document[0].querySelector('.main-content'));

    scope.login = login;
    scope.redirectToADFSIfHgf = redirectToADFSIfHgf;
    ////////////

    scope.$on('event:auth-loginRequired', function() {
      showLogin();
      scope.credentials = {};
      scope.loginForm.$setPristine();
      scope.loginForm.$setUntouched();
    });

    scope.$on('$locationChangeSuccess', function() {
      if (loginVisible) {
        cancelLogin();
      }
    });

    function login() {
      scope.saving = true;

      Auth.create(scope.credentials)
        .then(
          function(user) {
            hideLogin();
          },
          function(reason) {
            Flash.set('Kunde inte logga in. Kontrollera din e-post och ditt lösenord.', 'error');
            scope.credentials.password = null;
          }
        )
        .finally(function() {
          scope.saving = false;
          scope.loginForm.$setPristine();
          scope.loginForm.$setUntouched();
        });
    }

    function showLogin() {
      Auth.resetCurrentUser();
      var tooltip = angular.element($document[0].querySelector('.tooltip'));

      loginVisible = true;
      element.addClass('login--visible');
      mainContent.addClass('main-content--hidden');
      if (tooltip) {
        tooltip.remove();
      }
    }

    function cancelLogin() {
      Auth.cancel();
      scope.credentials = {};
      scope.loginForm.$setPristine();
      scope.loginForm.$setUntouched();
      hideLogin();
    }

    function hideLogin() {
      loginVisible = false;
      mainContent.removeClass('main-content--hidden');
      element.removeClass('login--visible');
    }

    function redirectToADFSIfHgf() {
      if (scope.credentials.email && scope.credentials.email.match(/@hyresgastforeningen\.se$/i)) {
        scope.redirecting = true;
        $timeout(function() {
          scope.redirecting = false;
          window.location.href =
            window.location.hostname === 'localhost' ? 'http://localhost:3200/api/sessions/auth' : '/api/sessions/auth';
        }, 1337);
      }
    }
  }
}

})();