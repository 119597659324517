(function () {
'use strict';

angular.module('hyresgastApp').controller('ListUserDelegateCtrl', ListUserDelegateCtrl);

function ListUserDelegateCtrl($scope, $location,
  Negotiation, User,
  Limits,
  HyUtils) {
  var vm = this;
  vm.Limits = Limits;
  vm.getUsers = getUsers;
  vm.gotoShow = gotoShow;

  vm.columns = [
    { title: 'Förnamn', column: 'firstname' },
    { title: 'Efternamn', column: 'lastname' },
    { title: 'Telefonnummer', column: 'phone' },
    { title: 'E-post', column: 'email' },
    { title: 'Roll', column: 'roll' }
  ];

  ////////////

  function gotoShow(user) {
    $location.search({});
    $location.path('/users/' + user.id);
  }

  function getUsers() {
    // eslint-disable-next-line no-undef
    return new Promise(resolve => {
      User.query({ 'filters[party]': 10 }).$promise.then(data => {
        let users = data;

        Negotiation.getDelegateRoles({}).$promise.then(function (users2negs) {
          for (let user of users.rows) {
            let user2neg = users2negs.users.find(un => un.userId === user.id);
            user.roll = user2neg
              ? HyUtils.findNegotiationRole(user2neg.roll).name
              : 'Delegat';
          }
          resolve(users);
        });
      });
    });
  }
}

})();