(function () {
'use strict';

angular.module('hyresgastApp').controller('InformationModalCtrl', InformationModalCtrl);

function InformationModalCtrl($uibModalInstance, OrderedNegotiationTypes) {
  var vm = this;

  vm.info = [
    {
      groupTitle: 'Beskrivning av förhandlingstyperna',
      items: [
        {
          code: OrderedNegotiationTypes.ANNUAL_PROPERTY,
          desc: 'Årlig allmän hyresförändring av samtliga lägenheter på en fastighet.'
        },
        {
          code: OrderedNegotiationTypes.STANDARD_PROPERTY,
          desc: 'En eller flera enstaka åtgärder i olika lägenheter på en eller flera fastigheter.'
        },
        {
          code: OrderedNegotiationTypes.REBUILD_PROPERTY,
          desc: 'Större ombyggnation på fastigheten som exempelvis stambyte. Där både allmänna utrymmen och samtliga lägenheter omfattas.'
        },
        {
          code: OrderedNegotiationTypes.REBUILD_PROPERTY_2,
          desc: 'Enskild lägenhet/lägenheter som totalrenoveras.'
        },
        {
          code: OrderedNegotiationTypes.NEWLY_CONSTRUCTED_APARTMENT,
          desc: 'Nyproducerade fastigheter eller enskilda lägenheter i befintligt bestånd.'
        },
        {
          code: OrderedNegotiationTypes.NEWLY_CONSTRUCTED_PROPERTY,
          desc: 'Nyproduktionsförhandlingar där fastigheten inte är klar eller inte byggts ännu och inte fått en fastighetsbeteckning som är registrerad.'
        },
        {
          code: OrderedNegotiationTypes.UTILITY_VALUE_PROPERTY,
          desc: 'Omprövning av hyran för alla lägenheter i en fastighet eller en eller flera lägenheter i en fastighet.'
        },
      ]
    }
  ]

  vm.close = function() {
    $uibModalInstance.close();
  };
}
})();