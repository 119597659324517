(function () {
'use strict';

angular.module('hyresgastApp').filter('hyNegotiationCallStates', function(NegotiationCallStates) {
  return function(value, isHgfUser) {
    return hyNegotiationCallStates(value, isHgfUser, NegotiationCallStates);
  };
});

function hyNegotiationCallStates(value, isHgfUser, NegotiationCallStates) {
  switch (value) {
    case NegotiationCallStates.WAITING_HGF:
      return 'Väntande hos HGF';
    case NegotiationCallStates.WAITING_EXT:
      return 'Återskickad från HGF';
    case NegotiationCallStates.PARTIALLY_ASSIGNED:
      if (isHgfUser) {
        return 'Delvis tilldelad';
      } else {
        return 'Under arbete hos HGF';
      }
    case NegotiationCallStates.ASSIGNED:
      return 'Tilldelad';
    default:
      return 'Alla statusar';
  }
}

})();