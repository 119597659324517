(function () {
'use strict';

//TODO: move to fe-common

angular.module('hyresgastApp').controller('WarningConfirmationModalCtrl', WarningConfirmationModalCtrl);

function WarningConfirmationModalCtrl($uibModalInstance, message, button, critical) {
  var vm = this;

  vm.message = message;
  vm.button = button;
  vm.critical = critical;

  vm.confirm = function() {
    $uibModalInstance.close();
  };

  vm.cancel = function() {
    $uibModalInstance.dismiss('cancel');
  };
}

})();