(function () {
'use strict';

angular.module('hyresgastApp').factory('Meeting', function($resource) {
  return $resource(
    '/api/meetings/:id',
    { negotiationId: '@negotiationId', id: '@id' },
    {
      queryByNegotiation: {
        url: '/api/negotiations/:negotiationId/meetings/:id',
        method: 'GET'
      },
      deleteProtocol: {
        url: '/api/meetings/:id/protocol/:blobId',
        method: 'DELETE'
      },
      queryMeetingsWithMe: {
        url: '/api/users/:partnerId/meetings-with/me',
        method: 'GET'
      },
      queryByUser: {
        url: '/api/users/:userId/meetings',
        method: 'GET',
        isArray: true
      }
    }
  );
});

})();