(function () {
'use strict';

angular.module('hyresgastApp').controller('BlockingDeletionModalCtrl', BlockingDeletionModalCtrl);

function BlockingDeletionModalCtrl($uibModalInstance, $location,
  ErrorHandler, Organization, Flash, blockingData) {
  var vm = this;

  /////////
  vm.ok = ok;
  vm.cancel = cancel;
  vm.delete = destroy;
  vm.organization = {};

  vm.users = blockingData['blockingUsers'];
  vm.apartments = blockingData['blockingApartments'];
  vm.negotiations = blockingData['blockingNegotiations'];
  vm.negotiatedApartments = blockingData['blockingNegotiatedApartments'];
  vm.negotiatedApartmentsForCalls = blockingData['blockingNegotiatedApartmentsForCalls'];
  vm.callsForNegotiations = blockingData['blockingCallsForNegotiations'];

  ////////////
  vm.tablist = [
    { title: 'Användare', key: 1, show: vm.users.length > 0 },
    { title: 'Lägenheter', key: 2, show: vm.apartments.length > 0 },
    { title: 'Framställningar', key: 3, show: vm.callsForNegotiations.length > 0},
    { title: 'Förhandlingar', key: 4, show: vm.negotiations.length > 0 },
    { title: 'Överenskomen lägenhetsinformation', key: 5, show: vm.negotiatedApartments.length > 0 },
    { title: 'Lägenhetsinformation från framställan', key: 6, show: vm.negotiatedApartmentsForCalls.length > 0 }
  ];

  vm.selectedTab = vm.tablist.find(obj => obj.show).key;
  ////////////
  function ok() {
    $uibModalInstance.close(vm.selectedOffice);
  }

  function cancel() {
    $uibModalInstance.dismiss('cancel');
  }

  function destroy() {
    Organization.delete(vm.organization).$promise.then(
      function() {
        Flash.set('Organisationen borttagen', 'success');
        $location.path('/organizations');
      })
      .catch(function() {
        new ErrorHandler('Du kan endast ta bort om det inte finns användare kopplade till organisationen.');
      });
  }
}


})();