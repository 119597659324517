(function () {
'use strict';

angular.module('hyresgastApp').controller('ShowNegotiationOverviewCtrl', ShowNegotiationOverviewCtrl);

function ShowNegotiationOverviewCtrl(
  $scope, $uibModal, $location, $routeParams, $timeout,
  Negotiation, RealEstate,
  Auth, ErrorHandler,
  FeatureConfiguration, NegotiationStates, NegotiationTypes, Parties
) {
  // ------------------------------------------------------------------------------------------------------------
  // In many places in this file, we deal with objects that can be either a property (fastighet) or an apartment.
  // For historical reasons, in most places the variables used are named as if these objects are always properties.
  // ------------------------------------------------------------------------------------------------------------

  var vm = this;
  vm.parentCtrl = $scope.$parent.vm;
  vm.Parties = Parties;
  vm.edit = edit;
  vm.editDelegates = editDelegates;
  vm.editNegotiation = editNegotiation;
  vm.readyOwners = {};
  vm.NegotiationStates = NegotiationStates;
  vm.queryRealEstate = queryRealEstate;
  vm.getRealEstateOwners = getRealEstateOwners;
  vm.moveItemsMode = false;
  vm.selectedItemIds = {};
  vm.toggleMoveItemsMode = toggleMoveItemsMode;
  vm.updateCheckBoxes = updateCheckBoxes;
  vm.itemIds = [];

  vm.isNewProduction = type => [NegotiationTypes.NEWLY_CONSTRUCTED_PROPERTY, NegotiationTypes.NEWLY_CONSTRUCTED_APARTMENT].includes(type);

  // TODO: get negotiations from same call id.
  vm.moveItems = moveItems;
  vm.recallItems = recallItems;

  vm.pageLimit = 30;
  vm.pageOffset = 0;
  vm.sortColumn = $location.search().order || 'propertyDesignation'; // default
  vm.sortDirection = $location.search().direction || 'ASC'; // default
  vm.gotoProperty = gotoProperty;
  vm.FeatureConfiguration = FeatureConfiguration;

  $scope.$on('$routeUpdate', function() {
    if ($location.search().order) {
      // reset offset when sorting
      vm.pageOffset = 0;
      queryRealEstate();
    }
  });

  $scope.$on('rentListImported', function() {
    queryRealEstate();
  });

  Auth.heartbeat().then(function() {
    if (Auth.currentUser().party === Parties.HGF) {
      vm.isHgfUser = true;
    }
  });

  function gotoProperty(itemId) {
    $location.search({});
    $location.path('/negotiations/' + $routeParams.id + '/properties/' + itemId);
  }

  vm.parentCtrl.negotiationQueryPromise.then(function() {
    if (vm.parentCtrl.apartmentMode) {
      vm.listColumns = [
        {
          title: 'Fastighetsbeteckning',
          column: 'propertyDesignation'
        },
        {
          title: 'Gatuaddress',
          column: 'streetAddress'
        },
        {
          title: 'Nationella lägenhetsnr',
          column: 'nationalNo'
        },
        {
          title: 'Lägenhetsnr',
          column: 'ownerNo'
        },
        {
          title: 'Kommun',
          column: 'municipality'
        },
        {
          title: 'Fastighetsägare',
          column: 'owners'
        }
      ];
    } else {
      vm.listColumns = [
        {
          title: 'Fastighetsbeteckning',
          column: 'propertyDesignation'
        },
        {
          title: 'Kommun',
          column: 'municipality'
        },
        {
          title: 'LM distrikt',
          column: 'district'
        },
        {
          title: 'Antal lägenheter',
          column: 'numNegotiatedApartments'
        },
        {
          title: 'Fastighetsägare',
          column: 'owners'
        }
      ];
    }
    return queryRealEstate();
  });

  /////////

  function closeAllOwnersLists() {
    for (var key in vm.toggleOwnersList) {
      vm.toggleOwnersList[key] = false;
    }
  }

  function getRealEstateOwners(realEstateId, isProperty) {
    var realEstate = vm.realEstates.filter(function(realEstate) {
      if (isProperty) {
        return realEstate.id === realEstateId;
      } else {
        return realEstate.property.id === realEstateId;
      }
    })[0];

    if (!realEstate.owners) {
      vm.readyOwners[realEstate.id] = false;
      return RealEstate.get(
        {
          id: realEstateId
        },
        {}
      )
        .$promise.then(function(response) {
          realEstate.owners = response.owners;
        })
        .catch(ErrorHandler('Misslyckades att hämta fastighetsägare.'))
        .finally(function() {
          vm.readyOwners[realEstateId] = true;
        });
    }
  }

  /**
   *
   * @param {string} modalSize size css class for modal
   * @param {int} userParty party to edit. 20 for HGF, 0 for FÄ
   */
  function edit(modalSize, userParty) {
    var modalInstance = $uibModal.open({
      animation: true,
      ariaLabelledBy: 'modal-title-top',
      ariaDescribedBy: 'modal-body-top',
      size: modalSize,
      index: 1075, // position above tooltip
      templateUrl: 'resources/negotiation/edit/edit-representatives-modal.html',
      controller: 'EditNegotiationRepresentativesModalCtrl',
      controllerAs: 'vm',
      windowClass: 'modal-content--white-border',
      resolve: {
        negotiationObject: function() {
          return vm.parentCtrl.negotiation;
        },
        isFreeAgreement: () => false,
        userParty: function() {
          return userParty
        }
      }
    });
    modalInstance.result.then(function() {
      $timeout(() => vm.parentCtrl.getNegotiation(), 1200);
    });
  }

  /**
   * code to remove
   * @param {string} modalSize size css class for modal
   */
  function editDelegates(modalSize) {
    var modalInstance = $uibModal.open({
      animation: true,
      ariaLabelledBy: 'modal-title-top',
      ariaDescribedBy: 'modal-body-top',
      size: modalSize,
      index: 1075, // position above tooltip
      templateUrl: 'resources/negotiation/edit/edit-delegates-modal.html',
      controller: 'EditNegotiationDelegatesModalCtrl',
      controllerAs: 'vm',
      windowClass: 'modal-content--white-border',
      resolve: {
        negotiationObject: function() {
          return vm.parentCtrl.negotiation;
        },
        isFreeAgreement: () => false,
      }
    });
    modalInstance.result.then(function() {
      vm.parentCtrl.getNegotiation();
    });
  }

  function editNegotiation(modalSize) {
    var modalInstance = $uibModal.open({
      animation: true,
      ariaLabelledBy: 'modal-title-top',
      ariaDescribedBy: 'modal-body-top',
      size: modalSize,
      index: 1075, // position above tooltip
      templateUrl: 'resources/negotiation/edit/edit-negotiationType-modal.html',
      controller: 'EditNegotiationTypeModalCtrl',
      controllerAs: 'vm',
      windowClass: 'modal-content--white-border',
      resolve: {
        negotiationObject: function() {
          return vm.parentCtrl.negotiation;
        },
        isFreeAgreement: () => false,
      }
    });
    modalInstance.result.then(function(negotiation) {
      vm.parentCtrl.negotiation.name = negotiation.name;
      vm.parentCtrl.negotiation.type = negotiation.type;
    });
  }

  function queryRealEstate(resetOffset) {
    vm.readyRealEstate = false;
    if (resetOffset) {
      vm.pageOffset = 0;
    }
    closeAllOwnersLists();

    var apartmentQueryParams = {
      id: $routeParams.id,
      limit: vm.pageLimit,
      offset: vm.pageOffset,
      order: vm.sortColumn,
      direction: vm.sortDirection,
      'search[property.propertyDesignation]': vm.inputTextProperty,
      'search[streetAddress]': vm.inputTextStreetAddress,
      'search[nationalNo]': vm.inputTextNationalNumber,
      'search[ownerNo]': vm.inputTextApartmentNumber,
      'search[municipality]': vm.inputTextMunicipality,
      'search[ownerName]': vm.inputTextOwner,
      include: ['demands', 'owners']
    };
    var propertyQueryParams = {
      id: $routeParams.id,
      limit: vm.pageLimit,
      offset: vm.pageOffset,
      order: vm.sortColumn,
      direction: vm.sortDirection,
      'search[propertyDesignation]': vm.inputTextProperty,
      'search[municipality]': vm.inputTextMunicipality,
      'search[district]': vm.inputTextDistrict,
      'search[ownerName]': vm.inputTextOwner,
      include: ['demands', 'owners']
    };

    if (vm.parentCtrl.isNewlyConstructedApartment) {
      vm.propertyQuery = Negotiation.queryProperty(propertyQueryParams, {}).$promise;
      return (vm.apartmentQuery = Negotiation.queryApartment(apartmentQueryParams, {})
        .$promise.then(function(response) {
          vm.realEstates = response.rows;
          vm.parentCtrl.realEstates = vm.realEstates;
          vm.totalCount = response.count;
          if (vm.totalCount > 0) {
            vm.apartmentMode = true;
          }
          vm.rentFile = response.rentFile;
          return vm.propertyQuery;
        })
        .then(function(response) {
          vm.properties = response.rows;
          if (vm.totalCount === 0) {
            vm.realEstates = vm.realEstates.concat(vm.properties);
            vm.totalCount = vm.properties.length;
          }
        })
        .catch(ErrorHandler('Misslyckades att hämta lägenheter.'))
        .finally(function() {
          vm.readyRealEstate = true;
        }));
    } else if (vm.parentCtrl.apartmentMode) {
      return (vm.apartmentQuery = Negotiation.queryApartment(apartmentQueryParams, {})
        .$promise.then(function(response) {
          vm.realEstates = response.rows;
          vm.totalCount = response.count;
          vm.rentFile = response.rentFile;
        })
        .catch(ErrorHandler('Misslyckades att hämta lägenheter.'))
        .finally(function() {
          vm.readyRealEstate = true;
        }));
    } else {
      return (vm.propertyQuery = Negotiation.queryProperty(propertyQueryParams, {})
        .$promise.then(function(response) {
          vm.realEstates = response.rows;
          vm.parentCtrl.realEstates = vm.realEstates;
          vm.totalCount = response.count;
          vm.rentFile = response.rentFile;
        })
        .catch(ErrorHandler('Misslyckades att hämta fastigheter.'))
        .finally(function() {
          vm.readyRealEstate = true;
        }));
    }
  }

  function updateCheckBoxes() {
    $timeout(() => {
      vm.itemIds = [];

      Object.keys(vm.selectedItemIds).forEach(function(itemId) {
        if (vm.selectedItemIds[itemId] === true) {
          vm.itemIds.push(parseInt(itemId));
        }
      });
    }, 500);
  }

  function toggleMoveItemsMode() {
    vm.selectedItemIds = {};
    vm.itemIds = [];
    vm.moveItemsMode = !vm.moveItemsMode;
  }

  function moveItems() {
    var modalInstance = $uibModal.open({
      animation: true,
      ariaLabelledBy: 'modal-title-top',
      ariaDescribedBy: 'modal-body-top',
      size: 'md',
      index: 1075, // position above tooltip
      templateUrl: 'resources/negotiation/show/modals/move-items/moveItemsModal.html',
      controller: 'MoveItemsCtrl',
      controllerAs: 'vm',
      windowClass: 'modal-content--white-border',
      backdrop: 'static',
      resolve: {
        itemIds: function() {
          return vm.itemIds;
        },
        currentNegotiation: function() {
          return vm.parentCtrl.negotiation;
        },
        apartmentMode: function() {
          return vm.parentCtrl.apartmentMode;
        }
      }
    });
    modalInstance.result.then(function() {
      vm.selectedItemIds = {};
      vm.itemIds = [];
      queryRealEstate();
      vm.parentCtrl.getNegotiation();
    });
  }

  function recallItems() {
    var modalInstance = $uibModal.open({
      animation: true,
      ariaLabelledBy: 'modal-title-top',
      ariaDescribedBy: 'modal-body-top',
      size: 'md',
      index: 1075, // position above tooltip
      templateUrl: 'resources/negotiation/show/modals/recall-items/recallItemsModal.html',
      controller: 'RecallItemsCtrl',
      controllerAs: 'vm',
      windowClass: 'modal-content--white-border',
      backdrop: 'static',
      resolve: {
        itemIds: function() {
          return vm.itemIds;
        },
        negotiationId: function() {
          return parseInt($routeParams.id);
        },
        apartmentMode: function() {
          return vm.parentCtrl.apartmentMode;
        }
      }
    });
    modalInstance.result.then(function() {
      vm.selectedItemIds = {};
      vm.itemIds = [];
      queryRealEstate();
      vm.parentCtrl.getNegotiation();
    });
  }
}

})();