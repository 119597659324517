(function () {
'use strict';

// @desc
//   Directive is placed inside <main> and will appear when
//   when API returns a 401. It will also hide main content.
//
// @example
//   <main>
//      <hy-resetPassword></hy-resetPassword>
//      <ng-view class="main-content"></ng-view>
//   </main>

angular.module('hyresgastApp').directive('hyResetPassword', ResetPassword);

function ResetPassword($document, $rootScope, $location, Auth, User, Flash) {
  var directive = {
    restrict: 'E',
    templateUrl: 'components/resetPassword/resetPassword.html',
    scope: {},
    link: link
  };
  return directive;

  function link(scope, element, attrs) {
    scope.vm = { email: '' };

    var resetPasswordVisible;
    var mainContent = angular.element($document[0].querySelector('.main-content'));

    scope.resetPassword = resetPassword;

    scope.$on('event:resetPassword', function() {
      show();
    });

    function show() {
      element.addClass('resetPassword--visible');
      mainContent.addClass('main-content--hidden');
    }

    function hide() {
      mainContent.removeClass('main-content--hidden');
    }

    function showLogin() {
      element.removeClass('resetPassword--visible');
      mainContent.addClass('main-content--hidden');
      $rootScope.$broadcast('event:auth-loginRequired', null);
    }

    function resetPassword() {
      scope.saving = true;

      User.resetPassword({
        email: scope.vm.email,
        phone: scope.vm.phone,
      }).$promise.then(
        function(response) {
          Flash.set(
            'Ett mail med en länk för att återställa lösenordet  har skickats till epost-adressen du angav.',
            'success'
          );
        },
        function(reason) {
          Flash.set('Misslyckades. Kontrollera din mailadress.', 'error');
        }
      )
        .finally(function() {
          scope.saving = false;
          scope.vm.resetpasswordForm.$setPristine();
          scope.vm.resetpasswordForm.$setUntouched();
        });
    }
  }
}

})();