(function () {
'use strict';

angular.module('uiTimepicker').controller('ShowNegotiationMeetingsCtrl', ShowNegotiationMeetingsCtrl);

function ShowNegotiationMeetingsCtrl($scope, $uibModal, $location, $routeParams, Flash, Negotiation, ErrorHandler) {
  var vm = this;
  vm.addMeeting = addMeeting;
  vm.gotoShowMeeting = gotoShowMeeting;
  var parentCtrlVM = $scope.$parent.vm;
  getMeetings();

  /////////

  function gotoShowMeeting(id) {
    $location.search({});
    $location.path('/negotiations/' + $routeParams.id + '/meetings/' + id);
  }

  function getMeetings() {
    vm.readyMeetings = false;
    return Negotiation.listMeetings({
      id: $routeParams.id
    })
      .$promise.then(function(response) {
        vm.meetings = response;
      })
      .catch(ErrorHandler('Misslyckades att hämta möten.'))
      .finally(function() {
        vm.readyMeetings = true;
      });
  }

  function addMeeting() {
    var modalInstance = $uibModal.open({
      animation: true,
      ariaLabelledBy: 'modal-title-top',
      ariaDescribedBy: 'modal-body-top',
      size: 'lg',
      index: 1075, // position above tooltip
      templateUrl: 'resources/meeting/edit/edit-meetings-modal.html',
      controller: 'EditMeetingModalCtrl',
      controllerAs: 'vm',
      windowClass: 'modal-content--white-border',
      backdrop: 'static',
      resolve: {
        meetingObj: function() {
          return null;
        },
        negotiationObj: function() {
          return parentCtrlVM.negotiation;
        }
      }
    });
    modalInstance.result.then(function(meeting) {
      $location.search({});
      $location.path('/negotiations/' + $routeParams.id + '/meetings/' + meeting.id);
    });
  }
}

})();