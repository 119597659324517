(function () {
'use strict';

angular.module('hyresgastApp').controller('FileValidationResultAgreementModalCtrl', FileValidationResultAgreementModalCtrl);

function FileValidationResultAgreementModalCtrl($uibModalInstance, validationErrors, validationResponse, agreement) {
  var vm = this;
  vm.errors = validationErrors;
  vm.response = validationResponse;
  vm.agreement = agreement;
  vm.cancel = cancel;

  var blobId = null;
  if (vm.response) {
    blobId = vm.response.blobId;
  } else if (vm.errors) {
    blobId = vm.errors.blobId;
  }

  vm.validationExcelUrl = '/api/negotiations/' + vm.agreement.negotiationId + '/agreements/' + vm.agreement.id + '/rent-list/' + blobId + '/validate-excel';

  function cancel() {
    $uibModalInstance.dismiss();
  }
}

})();