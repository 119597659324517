(function () {
'use strict';

angular.module('hyresgastApp').controller('NewDirectNegotiationCtrl', NewDirectNegotiationCtrl);

function NewDirectNegotiationCtrl(
  $scope, $location, $document,
  Flash, ErrorHandler, HyUtils, DateLocal,
  User, Negotiation, ManualChange, RealEstate,
  OrderedNegotiationTypes, PropertyNegotiationTypes, ApartmentNegotiationTypes, Limits) {
  var vm = this;
  vm.onlyMyProperties = true;
  vm.Limits = Limits;
  vm.NegotiationTypes = OrderedNegotiationTypes;
  vm.ApartmentNegotiationTypes = ApartmentNegotiationTypes;

  vm.negotiation = {
    participants: [],
    conditionTags: { isReadyToSend: true },
  };

  vm.selector = {
    fgetProperties: ManualChange.queryPropertiesInMyNegotiations,
    fgetApartments: ManualChange.queryApartments,
  };
  vm.changePropertiesMethod = onlyMyProperties => {
    vm.selector.fgetProperties = onlyMyProperties
      ? ManualChange.queryPropertiesInMyNegotiations
      : ManualChange.queryProperty;

    let selector = angular.element($document[0].querySelector("#apartmentSelector"));
    selector.scope().$broadcast('refreshProperties');
  };

  vm.partyDescription = 'hyresvärd/ombud';
  vm.showAddNegotiatorLink = true;

  vm.showNegotiationTypeInfo = () => {
    HyUtils.showDefaultModal('InformationModalCtrl',
      'components/modal/information/negotiationCallInfoModal.html',
      {}, null, null, null,
      { isGreen: true });
  };

  vm.negotiationTypeInfo = () => {
    HyUtils.showDefaultModal('InformationModalCtrl',
      'components/modal/information/negotiationTypeInfoModal.html',
      {}, null, null, null,
      { isGreen: true });
  }

  vm.allowPartialChoices = [
    { id: 0, name: 'Fastighetsförhandling' },
    { id: 1, name: 'Ägarförhandling' }
  ];

  User.queryOp({'filters[party]': 0})
    .$promise.then(result => {
      vm.users = result.rows.filter(u => u.organizations && u.organizations.length);
    });

  let changeChiefOrganizations = () => {
    let chief = vm.negotiation.participants.find(p => p.isChief);
    vm.chiefParticipantOrganizations = chief ? chief.organizations : null;
    if (vm.chiefParticipantOrganizations && vm.chiefParticipantOrganizations.length === 1) {
      vm.selectedOrganization = vm.chiefParticipantOrganizations[0];
    }else{
      vm.selectedOrganization = null;
    }
  };

  vm.tagChief = (taggedParticipant) => {
    vm.negotiation.participants.forEach(p => p.isChief = (p.id === taggedParticipant.id));
    vm.chiefId = taggedParticipant.id;
    changeChiefOrganizations();
  };

  vm.addParticipant = () => {
    let foundParticipant = vm.negotiation.participants.filter(user => user.id === vm.selectedParticipant.id);

    if (foundParticipant.length === 0) {
      if (vm.negotiation.participants.length === 0) {
        vm.selectedParticipant.isChief = true;
        vm.chiefId = vm.selectedParticipant.id;
      } else {
        vm.selectedParticipant.isChief = false;
      }
      vm.negotiation.participants.push(vm.selectedParticipant);
    }
    vm.selectedParticipant = null;
    changeChiefOrganizations();
  };

  vm.motivFGetData = function () {
    // eslint-disable-next-line no-undef
    return new Promise(resolve => {
      resolve({ rows: vm.properties, count: vm.properties.length });
    });
  };
  let refreshTableau = id => angular.element($document[0].querySelector("#"+id))
    .scope().$broadcast('refresh');
  vm.motivColumns = [
    { title: 'Namn', column: 'propertyDesignation' },
    { title: 'Kommun', column: 'municipality' },
    { title: 'Fastighetsägare', column: 'owners', type: 'array' },
    { title: 'Yrkande', column: 'formattedDemand' },
    { title: 'Motiv', column: 'demand.motivation' },
  ];
  vm.selectedProperties = [];
  vm.motivActions = [
    {
      title: 'Lägg till yrkande och motiv',
      action: () => { vm.editDemandAndMotivation(); }
    }
  ];
  $scope.$on('$routeUpdate', function() {
    if ($location.search().step === 'motiv') {
      refreshTableau('motivTableau');
    }
  });
  // eslint-disable-next-line eqeqeq
  vm.getFormattedDemand = demand => demand.unit == 2
    ? demand.stringValue
    // eslint-disable-next-line eqeqeq
    : `${demand.numericValue} ${demand.unit == 1 ? 'kr/kvm/år' : '%'}`;
  vm.editDemandAndMotivation = () => {
    HyUtils.showDefaultModal('EditMotivationModalCtrl',
      'resources/negotiation-call/new/modals/edit-motivation-modal.html', {
        propertiesArr: vm.selectedProperties,
        negotiationType: vm.negotiation.type,
      }, demand => {
        vm.selectedProperties.forEach(property => {
          angular.extend(property, { demand });
          property.formattedDemand = vm.getFormattedDemand(demand);
        });
        vm.demandsCreated = vm.properties.every(p => p.demand && p.demand.unit);
        refreshTableau('motivTableau');
      }, null, null, { size: 'md' });
  };

  $scope.$watch('vm.negotiationType', function() {
    let negotiationTypes = Object.values(PropertyNegotiationTypes);
    vm.isPropertyBased = negotiationTypes.includes(vm.negotiationType);
  });

  let isChiefSelected = () =>
    vm.negotiation.participants.some(participant => participant.isChief === true);

  vm.removeParticipant = (participant) => {
    var index = vm.negotiation.participants.indexOf(participant);
    vm.negotiation.participants.splice(index, 1);

    if (!isChiefSelected()) {
      if (vm.negotiation.participants.length > 0) {
        vm.negotiation.participants[0].isChief = true;
        vm.chiefId = vm.negotiation.participants[0].id;
      } else {
        vm.chiefId = null;
      }
    }
    changeChiefOrganizations();
  }

  vm.saving = false;
  vm.save = () => {
    HyUtils.showDefaultModal('SelectOfficeModalCtrl',
      'resources/negotiation/new-direct/modals/select-office-modal.html',
      {},
      res => {
        vm.negotiation.hgfOfficeId = res.selectedOffice.id;
        persistNegotiation();
      }, null, null, { size: 'sm' });
  }

  function persistNegotiation() {
    vm.saving = true;
    Negotiation.createDirect({
      name: vm.negotiationName,
      rentEffectiveFrom: DateLocal.fromUTCtoLocal(vm.rentEffectiveFrom),
      type: vm.negotiationType,
      hgfOfficeId: vm.negotiation.hgfOfficeId,
      message: vm.negotiationMessage,
      allowPartial: vm.negotiation.allowPartial,
      participants: vm.negotiation.participants,
      apartments: vm.selectedApartments,
      emptyProperties: vm.properties,
      selectedOrganization: vm.selectedOrganization,
      conditionTags: vm.negotiation.conditionTags,
    }).$promise.then(result => {
      Flash.set('Förhandling har skapats', 'success');
      $location.path("/negotiations/" + result.id);
    }).catch(ErrorHandler('Misslyckades skapa förhandling'))
      .finally(() => { vm.saving = false; });
  }

  vm.showAddNegotiatorModal = () => {
    HyUtils.showDefaultModal('NewUserModalCtrl',
      'resources/user/new/new-user-modal.html',
      { fullMode: false },
      result => {
        let newUser = result.user;
        if (vm.negotiation.participants.length === 0) {
          newUser.isChief = true;
          vm.chiefId = newUser.id;
        } else {
          newUser.isChief = false;
        }
        vm.negotiation.participants.push(newUser);
        changeChiefOrganizations();
      });
  }

  vm.searchProperties = () => {
    vm.toggleMenu = false;
    vm.searching = true;
    RealEstate.querySimple({ 'search[complex]': vm.inputTextComplex }, {})
      .$promise.then(response => {
        vm.propertiesTypeahead = response.rows;
        if (vm.propertiesTypeahead.length > 0) {
          vm.toggleMenu = true;
        }
      })
      .catch(new ErrorHandler('Fel'))
      .finally(() => { vm.searching = false });
  };
  vm.addProperty = property => {
    vm.readyProperties = true;
    if (!vm.properties) {
      vm.properties = [];
    }

    let exists = vm.properties.filter(item => item.id === property.id).length;
    if (!exists) {
      vm.properties.push(property);
    }
  };
}
})();