(function () {
'use strict';

angular.module('hyresgastApp').filter('agreementBlockedByType', function(AgreementBlockedByType) {
  return function(value) {
    switch (value) {
      case AgreementBlockedByType.PRIVATE:
        return 'Hyresnämnden';
      case AgreementBlockedByType.PUBLIC:
        return 'HMK';
      case AgreementBlockedByType.NONE:
        return 'Egensatt';
      case AgreementBlockedByType.MEDIATOR:
        return 'Skiljeman Hyresnämnd';
      default:
        return 'Typ ej angivet';
    }
  };
});

})();