(function () {
'use strict';

angular.module('fpConsts', [])
  .factory('FPConsts', FPConsts);

function FPConsts(ApartmentStatusTypes) {
  return {
    apartmentStatusesForNegotation: [
      ApartmentStatusTypes.PRESUMTIVE_RENT,
      ApartmentStatusTypes.STUCK,
      ApartmentStatusTypes.WITHOUT_NEGOTIATION,
      ApartmentStatusTypes.TRIBE_DECISION,
      ApartmentStatusTypes.M10,
      ApartmentStatusTypes.EG,
      ApartmentStatusTypes.B
    ],
    agreeableApartmentStatuses: [
      ApartmentStatusTypes.STUCK,
      ApartmentStatusTypes.WITHOUT_NEGOTIATION,
      ApartmentStatusTypes.TRIBE_DECISION,
      ApartmentStatusTypes.B
    ]
  };
}
})();