(function () {
'use strict';

angular.module('hyresgastApp').factory('ErrorHandler', function($location, Flash) {
  return function(msg) {
    return function(err) {
      if (err.status === 403) {
        $location.path('/403');
      } else {
        Flash.set(msg, 'error');
      }
    }
  }
});

})();