(function () {
'use strict';
angular.module('reportMultiSelect', ['uiModal'])
  .directive('reportMultiSelect', ['$uibModal', ($uibModal) => {
    return {
      scope: {
        param: '=',
        paramDictionary: '=',
        title: '@',
        idProperty: '@',
        textProperty: '@',
        selectionDone: '&',
      },
      restrict: 'E',
      templateUrl: 'components/multi-select/multi-select.html',
      link: function (scope) {
        scope.beginSelection = function () {
          var dataEmpty = !scope.param.data || scope.param.data === -1;
          var selections = dataEmpty ? [] : scope.param.data.map(item => item[scope.idProperty]);
          $uibModal.open({
            animation: true,
            ariaLabelledBy: 'modal-title-top',
            ariaDescribedBy: 'modal-body-top',
            size: 'lg',
            index: 1075,
            templateUrl: 'components/multi-select/multi-select-modal.html',
            windowClass: 'modal-content--white-border',
            backdrop: 'static',
            controller: 'MultiSelectModalCtrl',
            controllerAs: 'vm',
            resolve: {
              title: () => scope.title,
              allOmit: () => scope.param.allOmit,
              selections: () => selections,
              groups: () => scope.paramDictionary,
              idProperty: () => scope.idProperty,
              textProperty: () => scope.textProperty
            }
          }).result.then(function(res) {
            let result = Object.values(scope.paramDictionary)
              .flat()
              .filter(item => res.includes(item[scope.idProperty].toString()));
            scope.param.data = result;
            scope.param.isSet = true;
            scope.selectionDone();
          });
        }
      },
    };
  }]);
})();