(function () {
'use strict';

angular.module('hyresgastApp').controller('FileValidationResultModalCtrl', FileValidationResultModalCtrl);

function FileValidationResultModalCtrl($uibModalInstance, validationErrors, validationResponse, realEstateChoiceMethod) {
  var vm = this;
  vm.errors = validationErrors;
  vm.response = validationResponse;
  vm.cancel = cancel;

  var blobId = null;
  if (vm.response) {
    blobId = vm.response.blobId;
  } else if (vm.errors) {
    blobId = vm.errors.blobId;
  }

  if (realEstateChoiceMethod === 'RENT_LIST') {
    vm.validationExcelUrl = '/api/negotiation-calls/rent-list/' + blobId + '/validate-excel';
  } else {
    vm.validationExcelUrl = '/api/negotiation-calls/property-list/' + blobId + '/validate-excel';
  }

  function cancel() {
    $uibModalInstance.dismiss();
  }
}

})();