(function () {
'use strict';

angular.module('hyresgastApp').factory('Microblog', function($resource) {
  return $resource(
    '/api/blog',
    { id: '@id' },
    {
      list: {
        url: '/api/blog',
        isArray: false,
        method: 'GET',
      },
      create: {
        url: '/api/blog',
        isArray: false,
        method: 'POST',
      },
      update: {
        url: '/api/blog/edit',
        method: 'POST',
      },
      destroy: {
        url: '/api/blog',
        method: 'DELETE',
      },
    }
  );
});

})();