(function () {
'use strict';

angular
  .module('hyresgastApp')
  .controller('ShowNegotiationBlogCtrl', ShowNegotiationBlogCtrl);

function ShowNegotiationBlogCtrl($routeParams, ObjectTypes) {
  var vm = this;
  vm.objectId = $routeParams.id;
  vm.objectType = ObjectTypes.NEGOTIATION.code;
}
})();