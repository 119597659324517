(function () {
'use strict';

angular.module('hyresgastApp').controller('ApproveOrganizationCtrl', ApproveOrganizationCtrl);

function ApproveOrganizationCtrl($location, $routeParams, $uibModal, Flash, Organization, UserByOrganization, ErrorHandler) {
  var vm = this;
  vm.organization = {};
  vm.save = save;
  vm.destroy = destroy;

  getOrganization();

  ////////////

  function getOrganization() {
    return Organization.get({ id: $routeParams.id })
      .$promise.then(function(response) {
        vm.organization = response;
        getUsers();
      })
      .catch(new ErrorHandler('Misslyckades att hämta organisation.'))
      .finally(function() {
        vm.ready = true;
      });
  }

  function getUsers() {
    UserByOrganization.query(vm.organization.id)
      .then(function(response) {
        vm.users = response;
        vm.usersCount = response.length;
        vm.foundAdmin = vm.users.filter(function(user) {
          return user.role === 200;
        });
      })
      .catch(function() {
        Flash.set('Misslyckades att hämta användare.', 'error');
      })
      .finally(function() {
        vm.ready = true;
      });
  }

  function save() {
    vm.organization.approved = true;

    var modalInstance = $uibModal.open({
      animation: true,
      ariaLabelledBy: 'modal-title-top',
      ariaDescribedBy: 'modal-body-top',
      size: 'sm',
      templateUrl: 'components/modal/confirmation-info/confirmationModalInfo.html',
      controller: 'ConfirmationModalInfoCtrl',
      controllerAs: 'vm',
      resolve: {
        text: function() {
          return {
            question: 'Är du säker på att du vill godkänna',
            subject: vm.organization.name,
            additionalInfo: '',
            abort: 'Avbryt',
            confirm: 'Godkänn'
          };
        },
        buttonClassSecondary: function() {
          return false;
        }
      }
    });

    modalInstance.result.then(function() {
      Organization.approve(vm.organization).$promise.then(
        function(organization) {
          Flash.set('Konto godkänt - bekräftelse skickad', 'success');
          $location.path('/organizations/' + organization.id);
        })
        .catch(function(reason) {
          if (reason.status === 409) {
            Flash.set('Organisationsnumret existerar redan', 'error');
          } else if (reason.status === 403)  {
            $location.path('/403');
          } else {
            Flash.set('Kontrollera organisationsnumret', 'error');
          }
        });
    });
  }

  function destroy() {
    var modalInstance = $uibModal.open({
      animation: true,
      ariaLabelledBy: 'modal-title-top',
      ariaDescribedBy: 'modal-body-top',
      size: 'sm',
      templateUrl: 'components/modal/confirmation-info/confirmationModalInfo.html',
      controller: 'ConfirmationModalInfoCtrl',
      controllerAs: 'vm',
      resolve: {
        text: function() {
          return {
            question: 'Är du säker på att du vill neka',
            subject: vm.organization.name,
            additionalInfo: 'Organisationen kommer att tas bort från portalen.',
            abort: 'Avbryt',
            confirm: 'Neka'
          };
        },
        buttonClassSecondary: function() {
          return true;
        }
      }
    });

    modalInstance.result.then(function() {
      Organization.delete({
        id: vm.organization.id
      })
        .$promise.then(function() {
          Flash.set('Organisation nekad - bekräftelse skickad', 'success');
          $location.search({});
          $location.path('/requested-accounts');
        })
        .catch(new ErrorHandler('Misslyckades att neka organisation.'));
    });
  }
}

})();