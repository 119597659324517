(function () {
'use strict';

angular.module('hyresgastApp').factory('Negotiation', function($resource) {
  return $resource(
    '/api/negotiations/:id',
    { id: '@id' },
    {
      query: {
        url: '/api/negotiations',
        isArray: false
      },
      getDelegateRoles: {
        url: '/api/negotiations/delegate-roles',
        method: 'GET',
      },
      finish: {
        url: '/api/negotiations/:id/finish',
        isArray: false,
        method: 'POST'
      },
      finishAsHgfUser: {
        url: '/api/negotiations/:id/finish-hgf',
        isArray: false,
        method: 'POST'
      },
      reopen: {
        url: '/api/negotiations/:id/reopen',
        isArray: false,
        method: 'POST'
      },
      listMeetings: {
        url: '/api/negotiations/:id/meetings',
        isArray: true
      },
      listDocuments: {
        url: '/api/negotiations/:id/documents',
        isArray: false
      },
      deleteDocument: {
        url: '/api/negotiations/:negotiationId/documents/:id',
        method: 'DELETE'
      },
      saveParticipants: {
        url: '/api/negotiations/:id/participants',
        method: 'POST'
      },
      checkDelegation: {
        url: '/api/negotiations/:id/delegation/check',
        method: 'POST'
      },
      inviteDelegation: {
        url: '/api/negotiations/:id/delegation/invite',
        method: 'POST'
      },
      deleteDelegation: {
        url: '/api/negotiations/:id/delegation/remove',
        method: 'POST'
      },
      queryDistrict: {
        url: '/api/negotiations/:id/districts',
        isArray: false
      },
      queryProperty: {
        url: '/api/negotiations/:id/properties',
        isArray: false,
        method: 'POST'
      },
      // used in the agreement wizard, sends checkbox states in request body
      queryPropertyBySelection: {
        url: '/api/negotiations/:id/properties',
        isArray: false,
        method: 'POST'
      },
      queryHistory: {
        url: '/api/negotiations/:id/history',
        isArray: true
      },
      countApartment: {
        url: '/api/negotiations/:id/rent-list/apartment-count',
        method: 'GET'
      },
      queryApartment: {
        url: '/api/negotiations/:id/apartments',
        isArray: false,
        method: 'POST'
      },
      importRentList: {
        url: '/api/negotiations/:id/rent-list/import',
        method: 'POST'
      },
      forgeRentList: {
        url: '/api/negotiations/:id/rent-list/forge',
        method: 'POST',
      },
      queryByChief: {
        url: '/api/negotiations/by-chief-negotiator',
        method: 'GET'
      },
      queryByLatestChange: {
        url: '/api/negotiations/history',
        method: 'GET'
      },
      queryNegotiationsByParticipant: {
        url: '/api/negotiations/by-participants',
        method: 'GET'
      },
      savePropertyDemands: {
        url: '/api/negotiations/:id/properties/demands',
        method: 'POST'
      },
      queryWithoutRentList: {
        url: '/api/negotiations/:id/properties/without-rent-list',
        method: 'GET'
      },
      moveItems: {
        url: '/api/negotiations/:id/move-items',
        method: 'POST'
      },
      createNewNegotiationAndMoveItems: {
        url: '/api/negotiations/:id/move-items-create-new-negotiation',
        method: 'POST'
      },
      recallItems: {
        url: '/api/negotiations/:id/recall-items',
        method: 'POST'
      },
      addProperties: {
        url: '/api/negotiations/:id/add-properties',
        method: 'POST'
      },
      queryApartmentsInProperty: {
        url: '/api/negotiations/:negotiationId/properties/:id/apartments',
        method: 'GET'
      },
      createDirect: {
        url: '/api/negotiations/create-direct',
        method: 'POST'
      },
      delete: {
        url: '/api/negotiations/:id',
        method: 'DELETE',
      },
      unforgeRentlist: {
        url: '/api/negotiations/:id/rent-list/unforge',
        method: 'POST',
      },
      changeNegotiators: {
        url: '/api/negotiations/change-negotiators',
        method: 'POST',
      },
      getSeenbyCounters: {
        url: '/api/negotiations/counters-seenby',
        method: 'GET',
      },
      saveComments: {
        url: '/api/negotiations/comments',
        method: 'POST',
      }
    }
  );
});

})();