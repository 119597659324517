(function () {
'use strict';

angular.module('hyresgastApp').controller('NewAgreementRentlistCtrl', NewAgreementRentlistCtrl);

function NewAgreementRentlistCtrl($scope, $location, $routeParams, $uibModal, Agreement, Auth, Flash, ValidFileTypes, ErrorHandler) {
  var vm = this;
  vm.agreement = {
    negotiationId: $routeParams.negotiationId,
    id: $routeParams.id,
    documents: []
  };

  vm.save = save;
  vm.gotoNextStep = gotoNextStep;
  vm.getColumnTitle = title => title instanceof Array ? title[0] : title.split(',')[0].trim();

  // The wizard uses a file upload directive and the directive is validating the content
  // of uploaded files (excel). If the file upload directive receives errors from the backend
  // we want to open a modal to show the errors to the user
  $scope.$watch('vm.rentListValidationErrors', function(errors) {
    if (errors) {
      $uibModal.open({
        animation: true,
        ariaLabelledBy: 'modal-title-top',
        ariaDescribedBy: 'modal-body-top',
        size: 'lg',
        index: 1075,
        // Template is also used in negotiation call to keep code dry
        templateUrl: 'resources/rent-list-modals/_file-validation-error-modal.html',
        controller: 'FileValidationResultAgreementModalCtrl',
        controllerAs: 'vm',
        resolve: {
          validationErrors: function() {
            return errors;
          },
          validationResponse: function() {
            return null;
          },
          agreement: function() {
            return vm.agreement;
          },
        },
        windowClass: 'modal-content--secondary'
      });
    }
  });

  ////////////

  function save() {
    vm.saving = true;
    Agreement.importRentlist(vm.agreement)
      .$promise.then(
        function(response) {
          $location.search({}); // remove parameters set by wizard
          $location.path('/negotiations/' + vm.agreement.negotiationId + '/agreements/' + vm.agreement.id);
          Flash.set('Hyreslista har laddats upp', 'success');
        })
      .catch(new ErrorHandler('Misslyckades att ladda upp hyreslista.'))
      .finally(function() {
        vm.saving = false;
      });
  }

  function gotoNextStep() {
    vm.gotoNext = true;
  }
}

})();