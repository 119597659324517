(function () {
'use strict';
/*
 Takes the input array and splices it to start from given input index
*/

angular.module('hyresgastApp').filter('startFrom', function() {
  return function(input, start) {
    start = +start; //parse to int
    return input.slice(start);
  };
});

})();