(function () {
/* eslint-disable no-undefined */
'use strict';

//TODO: move to fe-common with apartment-selector-2

angular.module('hyresgastApp').directive('hyMulticombobox', multicombobox);

function multicombobox($timeout) {
  var directive = {
    scope: {
      items: '<',
      idProperty: '@',
      textProperty: '@',
      prompt: '@',
      buttonText: '@',
      stickyPrompt: '<',
      currentItemsChanged: '&',
      currentItems: '=?',
    },
    restrict: 'E',
    templateUrl: 'components/multicombobox/multicombobox.html',
    link: link
  };

  return directive;

  function getPresentedSelection(selection, idProperty, items) {
    let presentedSelection = idProperty && idProperty.length
      // eslint-disable-next-line eqeqeq
      ? Object.keys(selection).filter(x => selection[x] === true).map(x => items.find(xi => xi[idProperty] == x))
      : Object.keys(selection).filter(x => selection[x] === true);
    return presentedSelection;
  }
  function link(scope) {
    scope.selection = {};
    scope.totalSelected = 0;
    scope.presentSelection = function() {
      let presentedSelection = getPresentedSelection(scope.selection, scope.idProperty, scope.items);
      scope.currentItemsChanged({ selection: presentedSelection });
      scope.currentItems = presentedSelection;
    };
    scope.updateTotal = function() {
      $timeout(() => {
        scope.totalSelected = getPresentedSelection(scope.selection, scope.idProperty, scope.items).length;
      }, 200);
    };
    let setSelection = () => {
      if (scope.items && !!scope.items.length &&
          scope.currentItems && !!scope.currentItems.length) {
        scope.selection = {};
        scope.currentItems.forEach(ci => {
          let key = (scope.idProperty && scope.idProperty.length)
            ? ci[scope.idProperty]
            : ci;
          scope.selection[key] = true;
        });
        scope.updateTotal();
      }
    };
    scope.$watch('items', () => {
      setSelection();
    });
    scope.$watch('currentItems', () => {
      setSelection();
    });
  }
}
})();