(function () {
'use strict';

// @desc
//   Horizontal tab navigation between different subpages (partials) of a given page.
//
// @example
//   <hy-tabs tablist="vm.tablist"></hy-tabs>
//   vm.tablelist contains 2 parameters: title and URL path

angular.module('hyresgastApp').directive('hyTabs', tabs);

function tabs($location, $routeParams) {
  var directive = {
    scope: {
      tablist: '<'
    },
    restrict: 'E',
    templateUrl: 'components/tabs/tabs.html',
    link: link
  };

  return directive;

  function link(scope, element, attrs) {
    scope.isActive = isActive;

    scope.iconPack = {
      ok: 'icon--ticked-in-circle',
      warning: 'icon--message-warning', 
    };

    scope.getClass = item => {
      if (isActive(item.path)) {
        return 'tabs__item--active';
      } else if (item.irrelevant) {
        return 'tabs__item--irrelevant';
      }
      return '';
    };

    ////////////
    function isActive(viewLocation) {
      if ($routeParams.view === undefined) {
        return viewLocation === $location.path();
      } else {
        return viewLocation === $location.path() + '?view=' + $routeParams.view;
      }
    }
  }
}

})();