(function () {
'use strict';

// @desc check if a session already been established on app load
// @example <body hy-auth-heartbeat></body>

angular.module('httpAuthHeartbeat', []).directive('hyAuthHeartbeat', authHeartbeat);

function authHeartbeat(Auth) {
  var directive = {
    restrict: 'A',
    link: link
  };

  return directive;

  function link(scope, element, attrs) {
    var ignoreAuthModule = true; // don't show login screen if a 401 is returned
    Auth.heartbeat(ignoreAuthModule).then(function() {}, function() {});
  }
}

})();