(function () {
/* eslint-disable no-undef */
/* eslint-disable no-console */
'use strict';
angular.module('hyresgastApp').controller('ConversionErrorsModalCtrl', ConversionErrorsModalCtrl);

function ConversionErrorsModalCtrl($uibModalInstance,
  conversionErrors) {
  var vm = this;
  vm.conversionErrors = conversionErrors;

  console.log('CONVERSION ERRORS!\n');
  console.log(conversionErrors);

  vm.ok = () => $uibModalInstance.close();
}
})();