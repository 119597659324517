(function () {
'use strict';

angular.module('hyresgastApp').controller('ShowRealEstateFreeAgreementsCtrl', ShowRealEstateFreeAgreementsCtrl);

function ShowRealEstateFreeAgreementsCtrl($scope, $location, $routeParams,
  RealEstate, FreeAgreementTypes, FreeAgreement,
  HyUtils, ErrorHandler) {
  var vm = this;

  vm.gotoAgreement = gotoAgreement;
  vm.downloadAgreementFile = downloadAgreementFile;
  vm.toggleAttachmentsPane = toggleAttachmentsPane;

  vm.FreeAgreementTypes = Object.values(FreeAgreementTypes)
    .reduce((dict, el) => { dict[el.code] = el.name; return dict; }, {});

  $scope.$parent.$watch('vm.property', property => {
    if (property) {
      getFreeAgreementsForProperty();
    }
  });

  function getFreeAgreementsForProperty() {
    RealEstate.queryFreeAgreementsForProperty({
      id: $routeParams.id,
    })
      .$promise.then(function(response) {
        vm.agreements = response.rows;
        vm.agreementsCount = response.count;

        vm.documents = [];
        response.rows.forEach(agreement => {
          vm.documents.push(...agreement.documents);
        });
      })
      .catch(new ErrorHandler('Misslyckades att hämta avtal.'))
      .finally(function() {
        vm.ready = true;
      });
  }

  function gotoAgreement(agreementId) {
    $location.search({});
    $location.path('/agreements/' + agreementId);
  }

  function downloadAgreementFile(file) {
    FreeAgreement.downloadDocument({
      agreementId: file.agreementId,
      id: file.name
    }).$promise.then(function(response) {
      HyUtils.spitOutAttachment(response.data, file.filename);
    });
  }

  function toggleAttachmentsPane() {
    vm.showAgreementsPane = !vm.showAgreementsPane;
  }
}

})();