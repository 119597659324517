(function () {
'use strict';

angular.module('hyresgastApp').controller('ShowMeetingCtrl', ShowMeetingCtrl);

function ShowMeetingCtrl(
  $scope,
  $uibModal,
  $routeParams,
  $location,
  Auth,
  Meeting,
  Flash,
  Negotiation,
  NegotiationStates,
  ValidFileTypes,
  ErrorHandler
) {
  var vm = this;
  vm.saveMeeting = saveMeeting;
  vm.getNegotiation = getNegotiation;
  vm.NegotiationStates = NegotiationStates;
  vm.editMeeting = editMeeting;
  vm.deleteMeeting = deleteMeeting;
  vm.addEditProtocol = addEditProtocol;
  vm.addProtocolFile = addProtocolFile;

  vm.deleteProtocol = deleteProtocol;
  vm.Auth = Auth;

  vm.pdfPath = '';

  getNegotiation();
  getMeeting();

  ////////

  function getNegotiation() {
    return Negotiation.get({
      id: $routeParams.negotiationId
    })
      .$promise.then(function(response) {
        vm.negotiation = response;
      })
      .catch(ErrorHandler('Misslyckades att hämta förhandling.'))
      .finally(function() {
        vm.readyNegotiation = true;
      });
  }

  function getMeeting() {
    return Meeting.get({
      id: $routeParams.id
    })
      .$promise.then(function(response) {
        vm.meeting = response;
        setPdfPath();
      })
      .catch(ErrorHandler('Misslyckades att hämta möten.'))
      .finally(function() {
        vm.ready = true;
      });
  }

  function saveMeeting() {
    Meeting.save(vm.meeting)
      .$promise.then(function(response) {})
      .catch(ErrorHandler('Misslyckades att spara möten.'));
  }

  function editMeeting() {
    var modalInstance = $uibModal.open({
      animation: true,
      ariaLabelledBy: 'modal-title-top',
      ariaDescribedBy: 'modal-body-top',
      size: 'lg',
      index: 1075, // position above tooltip
      templateUrl: 'resources/meeting/edit/edit-meetings-modal.html',
      controller: 'EditMeetingModalCtrl',
      controllerAs: 'vm',
      windowClass: 'modal-content--white-border',
      backdrop: 'static',
      resolve: {
        meetingObj: function() {
          return vm.meeting;
        },
        negotiationObj: function() {
          return null;
        }
      }
    });

    modalInstance.result.then(function(meeting) {
      vm.meeting = meeting;
    });
  }

  function deleteMeeting() {
    var modalInstance = $uibModal.open({
      animation: true,
      ariaLabelledBy: 'modal-title-top',
      ariaDescribedBy: 'modal-body-top',
      size: 'xsm',
      templateUrl: 'components/modal/confirmation/confirmationModal.html',
      controller: 'ConfirmationModalCtrl',
      controllerAs: 'vm',
      windowClass: 'modal-content--secondary'
    });

    modalInstance.result.then(function() {
      Meeting.delete({
        id: $routeParams.id
      })
        .$promise.then(function(response) {
          $location.path('/negotiations/' + $routeParams.negotiationId).search('view', 'meetings');
          Flash.set('Lyckades att ta bort mötet', 'success');
        })
        .catch(new ErrorHandler('Misslyckades att ta bort möten.'));
    });
  }

  function deleteProtocol() {
    var modalInstance = $uibModal.open({
      animation: true,
      ariaLabelledBy: 'modal-title-top',
      ariaDescribedBy: 'modal-body-top',
      size: 'xsm',
      templateUrl: 'components/modal/confirmation/confirmationModal.html',
      controller: 'ConfirmationModalCtrl',
      controllerAs: 'vm',
      windowClass: 'modal-content--secondary'
    });

    modalInstance.result.then(function() {
      if (vm.meeting.protocolDocument) {
        Meeting.deleteProtocol({
          id: $routeParams.id,
          blobId: vm.meeting.protocolDocument.name
        })
          .$promise.then(function(response) {
            vm.meeting.protocolDocument = null;
            vm.meeting.hasProtocol = false;
            Flash.set('Lyckades att ta bort protokollet', 'success');
          })
          .catch(new ErrorHandler('Misslyckades att ta bort protokollet.'));
      } else {
        vm.meeting.hasProtocol = false;
        Meeting.save(vm.meeting)
          .$promise.then(function(response) {
            vm.meeting = response;
            Flash.set('Lyckades att ta bort protokollet', 'success');
          })
          .catch(new ErrorHandler('Misslyckades att ta bort protokollet.'));
      }
    });
  }

  function addProtocolFile() {
    var settings = {
      title: 'Ladda upp protokoll',
      uploadUrl: '/api/meetings/' + $routeParams.id + '/protocol',

      // valid extension to upload
      validFileTypes: ValidFileTypes,

      // a file is associated with one type
      types: [{ id: 15, name: 'Protokoll' }],

      typeTitle: 'Välj typ av underlag'
    };

    var modalInstance = $uibModal.open({
      animation: true,
      ariaLabelledBy: 'modal-title-top',
      ariaDescribedBy: 'modal-body-top',
      size: 'sm',
      templateUrl: 'components/file-upload-modal/file-upload-modal.html',
      controller: 'FileUploadModalCtrl',
      controllerAs: 'vm',
      windowClass: 'modal-content--white-border',
      resolve: {
        settings: function() {
          return settings;
        }
      }
    });
    modalInstance.result.then(function(file) {
      vm.meeting.protocolDocument = file;
      vm.meeting.hasProtocol = true;
      setPdfPath();
      Flash.set('Lyckades att lägga till protokoll', 'success');
    });
  }

  function addEditProtocol() {
    var modalInstance = $uibModal.open({
      animation: true,
      ariaLabelledBy: 'modal-title-top',
      ariaDescribedBy: 'modal-body-top',
      size: 'xlg',
      templateUrl: 'resources/meeting/edit/edit-protocol-modal.html',
      controller: 'EditProtocolModal',
      controllerAs: 'vm',
      backdrop: 'static',
      resolve: {
        meetingObj: function() {
          setPdfPath();
          return vm.meeting;
        },
        negotiationObj: function() {
          return vm.negotiation;
        }
      }
    });
  }
  function setPdfPath() {
    vm.meeting.protocolDocument
      ? (vm.pdfPath = 'javascript:void(0);')
      : (vm.pdfPath = '/api/meetings/' + vm.meeting.id + '/protocol-pdf');
  }
}

})();