(function () {
'use strict';

angular.module('hyresgastApp').controller('PropertyListCtrl', PropertyListCtrl);

function PropertyListCtrl(
  $document, $filter, $location, $timeout,
  RealEstate, Regions,
  Auth) {
  var vm = this;

  vm.isHgfUser = Auth.isHgfUser();
  vm.onlyInMyNegotiations = !vm.isHgfUser;
  vm.header = 'Fastigheter' + (vm.isHgfUser ? '' : ' i mina förhandlingar');
  vm.sortColumn = $location.search().order || 'propertyDesignation'; // default
  vm.sortDirection = $location.search().direction || 'ASC'; // default

  vm.regionOptions = Object.values(Regions).map(id => ({
    id,
    name: $filter('regionTypeToDescription')(id, Regions)
  }));

  vm.tableColumns = [
    {
      title: 'Fastigheter',
      column: 'propertyDesignation',
    },
    {
      title: 'Region',
      column: 'regionId',
      lookup: {
        idProperty: 'id',
        textProperty: 'name',
        dictionary: vm.regionOptions,
      },
    },
    {
      title: 'Kommun',
      column: 'municipality',
    },
    {
      title: 'LM distrikt',
      column: 'district',
    },
    {
      title: 'Gatuaddress',
      column: 'addresses',
      type: 'array',
    },
    {
      title: 'Ägare',
      column: 'owners',
      type: 'array',
    },
    {
      title: 'Motpart',
      column: 'counterparts',
      type: 'array',
    },
    {
      title: 'Antal lägenheter jag förhandlar',
      column: 'numNegotiatedApartments',
      filterType: 'none',
    },
    {
      title: 'Info',
      column: 'status',
      filterType: 'none',
    },
  ];

  let getTableau = () => angular.element($document[0].querySelector("#tableau"));
  let setTableFGetData = () => {
    vm.tableFGetData = vm.onlyInMyNegotiations
      ? RealEstate.queryPropertiesInMyNegotiations
      : RealEstate.query;
    getTableau().scope().$broadcast('refresh');
  }

  vm.checkboxTicked = () => $timeout(setTableFGetData, 300);
  setTableFGetData();

  vm.tableFPostprocessData = rows => {
    // eslint-disable-next-line no-undef
    return new Promise((resolve, reject) => {
      let propertyIds = rows.map(p => p.id);
      RealEstate.queryPropertiesStatusCount({ propertyIds })
        .$promise.then(response => {
          rows.forEach(property => {
            property.status = '';
            // eslint-disable-next-line eqeqeq
            if (response.statusCount.find(a => a.propertyId == property.id && a.p)) {
              property.status += 'P';
            }
            // eslint-disable-next-line eqeqeq
            if (response.statusCount.find(a => a.propertyId == property.id && a.i)) {
              property.status += 'I';
            }
          });
          resolve(rows);
        }).catch(() => reject());
    });
  };

  if ($location.search().owners) {
    vm.filters = { owners: $location.search().owners };
  }

  vm.gotoProperty = property => {
    $location.search({});
    $location.path('/properties/' + property.id);
  };
}

})();