(function () {
'use strict';

// Used in App.js to redirect users depending on role

angular.module('hyresgastApp').factory('UserRedirect', function($location, Auth, Parties) {
  return function() {
    Auth.heartbeat().then(function() {
      if (Auth.currentUser().party === Parties.HGF) {
        $location.path('/users/' + Auth.currentUser().id + '/welcome');
      } else if (Auth.currentUser().party === Parties.EXTERNAL) {
        // FÄ
        $location.path('/users/' + Auth.currentUser().id + '/welcome');
      } else {
        // Delegate
        $location.path('/negotiations');
      }
    });
  };
});

})();