(function () {
'use strict';

angular.module('hyresgastApp').controller('NewNegotiationCtrl', NewNegotiationCtrl);

function NewNegotiationCtrl(
  $scope, $location, $routeParams, $document, $uibModal,
  Flash, ErrorHandler,
  NegotiationCall, User,
  NegotiationTypes, Parties
) {
  var vm = this;
  vm.negotiation = {
    id: $routeParams.id, // this is actually the negotiationCall ID, but that is correct here
    isAllDistrictsSelected: true,
    isAllPropertiesSelected: true,
    selectedDistrictIds: {},
    selectedPropertyIds: {},
    conditionTags: { isReadyToSend: true },
  };

  let getTableau = id => angular.element($document[0].querySelector("#"+id));

  vm.setRadioModelByOutsideClick = setRadioModelByOutsideClick;
  function setRadioModelByOutsideClick(choice) {
    vm.choiceMethod = choice;
  }

  vm.rentListBasedNegotiationCall = $routeParams.rentListBased;
  if (vm.rentListBasedNegotiationCall === 'yes') {
    vm.negotiation.isAllApartmentsSelected = true;
    vm.negotiation.selectedApartmentIds = {};
  }

  vm.isPropertyBased =
    $routeParams.negotiationCallType === NegotiationTypes.ANNUAL_PROPERTY ||
    $routeParams.negotiationCallType === NegotiationTypes.STANDARD_PROPERTY ||
    $routeParams.negotiationCallType === NegotiationTypes.REBUILD_PROPERTY ||
    $routeParams.negotiationCallType === NegotiationTypes.REBUILD_PROPERTY_2 ||
    $routeParams.negotiationCallType === NegotiationTypes.NEWLY_CONSTRUCTED_PROPERTY ||
    $routeParams.negotiationCallType === NegotiationTypes.UTILITY_VALUE_PROPERTY;

  vm.apartmentsSummaryBySelection = {};
  vm.gotoNextStep = gotoNextStep;
  vm.save = save;
  vm.saving = false;
  vm.negotiationTypeInfo = negotiationTypeInfo;

  vm.listDistrictColumns = [
    { title: 'Kommun', column: 'municipality' },
    { title: 'LM distrikt', column: 'district' },
    { title: 'Antal fastigheter', column: 'noProperties', filterType: 'none' }
  ];

  vm.listPropertiesColumns = [
    { title: 'Fastigheter', column: 'propertyDesignation' },
    { title: 'Kommun', column: 'municipality' },
    { title: 'LM distrikt', column: 'district' },
    { title: 'Antal lägenheter', column: 'numNegotiatedApartments', filterType: 'none' },
    { title: 'Fastighetsägare', column: 'owners', type: 'array' }
  ];

  vm.listApartmentsColumns = [
    { title: 'Nationellt lägenhetsnr', column: 'nationalNo' },
    { title: 'Lägenhetsnr', column: 'ownerNo' },
    { title: 'Gatuadress', column: 'streetAddress' },
    { title: 'Fastighet', column: 'property.propertyDesignation' },
    { title: 'Kommun', column: 'municipality' },
    { title: 'LM distrikt', column: 'district' }
  ];

  vm.addParticipant = addParticipant;
  vm.removeParticipant = removeParticipant;
  vm.tagChief = tagChief;
  if (vm.isPropertyBased && $routeParams.negotiationCallType !== NegotiationTypes.NEWLY_CONSTRUCTED_PROPERTY) {
    vm.allowPartialChoices = [
      { id: 0, name: 'Fastighetsförhandling' },
      { id: 1, name: 'Ägarförhandling' }
    ];
  } else {
    vm.allowPartialChoices = [
      { id: 0, name: 'Fastighetsförhandling' }
    ];
    vm.negotiation.allowPartial = true;
    vm.selectedAllowPartialName = 'Fastighetsförhandling';
  }

  vm.negotiation.participants = [];
  queryUser();

  // Keep it simple - fetch the properties when navigating to the properties step
  // or when some of the other URL parameters for sorting changes
  // instead of i.e fetching every time a districts checkbox is checked
  $scope.$on('$routeUpdate', function() {
    if ($location.search().step === 'properties') {
      // aFlag is used because when we filter the result from queryProperties(),
      // we don't want selectedPropertyIds = {}; to be reseted.
      // We want reset only when the route is changed and response.count != vm.propertyTotalCount.
      vm.propertyFlag = true;
      getTableau('tableauProperties').scope().$broadcast('refresh');
    }

    if ($location.search().step === 'apartments') {
      vm.apartmentsFlag = true;
      getTableau('tableauApartments').scope().$broadcast('refresh');
    }
  });

  ////////////

  function gotoNextStep() {
    vm.gotoNext = true;
  }

  function queryUser() {
    return User.query({
      id: $routeParams.id,
      'filters[party]': Parties.HGF
    }).$promise.then(function(response) {
      vm.users = response.rows;
    })
      .catch(ErrorHandler('Misslyckades att hämta data.'));
  }

  vm.fGetDistricts = function () {
    // eslint-disable-next-line no-undef
    return new Promise(resolve => {
      NegotiationCall.queryDistrict({
        id: $routeParams.id,
        unassigned: true
      }).$promise.then(response => {
        vm.districts = response.rows;
        resolve(response);
      }).catch(ErrorHandler('Misslyckades att hämta område.'));
    });
  }

  vm.fGetProperties = () => {
    // eslint-disable-next-line no-undef
    return new Promise(resolve => {
      if (!vm.selectedDistricts || !vm.selectedDistricts.length) {
        resolve({ rows: [], count: 0 });
        return;
      }
      packNegotiationDistricts();
      NegotiationCall.queryProperty({
        id: $routeParams.id,
        unassigned: true // only list unassigned
      }, {
        selectedDistrictIds: vm.negotiation.selectedDistrictIds,
        isAllDistrictsSelected: vm.negotiation.isAllDistrictsSelected,
      }).$promise.then(response => {
        vm.properties = response.rows;
        resolve(response);
      });
    });
  };

  vm.fGetApartments = () => {
    // eslint-disable-next-line no-undef
    return new Promise(resolve => {
      let apartmentsDisabled = !vm.choiceMethod || vm.choiceMethod === 'ALL_PROPERTIES' || (vm.choiceMethod === 'SELECT_PROPERTIES' && vm.rentListBasedNegotiationCall==='no');
      if (apartmentsDisabled || !vm.selectedDistricts || !vm.selectedProperties) {
        resolve({ rows: [], count: 0 });
        return;
      }
      packNegotiationDistricts();
      packNegotiationProperties();
      NegotiationCall.queryApartment({
        id: $routeParams.id,
        unassigned: true
      }, {
        selectedDistrictIds: vm.negotiation.selectedDistrictIds,
        selectedPropertyIds: vm.negotiation.selectedPropertyIds,
        isAllDistrictsSelected: vm.negotiation.isAllDistrictsSelected,
        isAllPropertiesSelected: vm.negotiation.isAllPropertiesSelected
      }).$promise.then(function(response) {
        vm.apartments = response.rows;
        resolve(response);
      }).catch(ErrorHandler('Misslyckades att hämta lägenhet.'));
    });
  };

  function addParticipant() {
    var foundParticipant = vm.negotiation.participants.filter(function(user) {
      return user.id === vm.selectedParticipant.id;
    });

    // look through all participants to check if they had been added before or not
    if (foundParticipant.length === 0) {
      if (vm.negotiation.participants.length === 0) {
        // only one participant who becomes a chief
        vm.selectedParticipant.isChief = true;
        vm.chiefId = vm.selectedParticipant.id;
      } else {
        // keep the current chief not changed
        vm.selectedParticipant.isChief = false;
      }
      vm.negotiation.participants.push(vm.selectedParticipant);
    }

    // clear input field
    vm.selectedParticipant = null;
  }

  function tagChief(taggedParticipant) {
    vm.negotiation.participants.forEach(function(participant) {
      participant.isChief = false;
    });

    var index = vm.negotiation.participants.indexOf(taggedParticipant);
    vm.negotiation.participants[index].isChief = true;
    vm.chiefId = taggedParticipant.id;
  }

  function removeParticipant(participant) {
    var index = vm.negotiation.participants.indexOf(participant);
    vm.negotiation.participants.splice(index, 1);

    if (!isChiefSelected()) {
      if (vm.negotiation.participants.length > 0) {
        vm.negotiation.participants[0].isChief = true;
        vm.chiefId = vm.negotiation.participants[0].id;
      } else {
        vm.chiefId = null
      }
    }
  }

  function isChiefSelected() {
    return vm.negotiation.participants.some(function(participant) {
      return participant.isChief === true;
    })
  }

  let packEntity = (selectedArray, array) => {
    let selectedIds = array.reduce((acc, el) => {
      acc[el.id] = selectedArray.some(x => x.id === el.id);
      return acc;
    }, {});
    let isAllSelected = array.length === vm.negotiation.selectedDistrictIds.length;
    return { selectedIds, isAllSelected };
  };
  function packNegotiationDistricts() {
    let pack = packEntity(vm.selectedDistricts, vm.districts);
    vm.negotiation.selectedDistrictIds = pack.selectedIds;
    vm.negotiation.isAllDistrictsSelected = pack.isAllSelected;
  }
  function packNegotiationProperties() {
    let pack = packEntity(vm.selectedProperties, vm.properties);
    vm.negotiation.selectedPropertyIds = pack.selectedIds;
    vm.negotiation.isAllPropertiesSelected = pack.isAllSelected;
  }
  function packNegotiationApartments() {
    let pack = packEntity(vm.selectedApartments, vm.apartments);
    vm.negotiation.selectedApartmentIds = pack.selectedIds;
    vm.negotiation.isAllApartmentsSelected = pack.isAllSelected;
  }

  function save() {
    if (isChiefSelected()) {
      vm.saving = true;
      var selectPropertiesMode = vm.choiceMethod === 'SELECT_PROPERTIES' ? true : false;
      vm.negotiation.selectPropertiesMode = selectPropertiesMode;
      if (selectPropertiesMode) {
        packNegotiationDistricts();
        packNegotiationProperties();
      }
      if (vm.selectedApartments && vm.selectedApartments.length > 0) {
        packNegotiationApartments();
      }

      NegotiationCall.createNegotiation(vm.negotiation)
        // eslint-disable-next-line no-unused-vars
        .$promise.then(function(response) {
          $location.search({});
          $location.path('/negotiation-calls/' + $routeParams.id).search({ view: 'negotiations' });
          Flash.set('Lyckades skapa förhandling', 'success');
        })
        .catch(function() {
          Flash.set('Misslyckades att skapa förhandling', 'error');
        })
        .finally(function() {
          vm.saving = false;
        });
    } else {
      Flash.set('En ansvarig förhandlare måste väljas', 'error');
    }
  }

  function negotiationTypeInfo() {
    $uibModal.open({
      animation: true,
      ariaLabelledBy: 'modal-title-top',
      ariaDescribedBy: 'modal-body-top',
      size: 'md',
      index: 1075,
      templateUrl: 'components/modal/information/negotiationTypeInfoModal.html',
      controller: 'InformationModalCtrl',
      controllerAs: 'vm',
      windowClass: 'modal-content--green'
    });
  }
}

})();