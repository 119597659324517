(function () {
'use strict';

angular.module('hyresgastApp').controller('NewFreeAgreementCtrl', NewFreeAgreementCtrl);

function NewFreeAgreementCtrl(
  $scope, $location, Auth, Flash, ErrorHandler, NegotiationRoles, FreeAgreement,
  FreeAgreementTypes
) {
  var vm = this;

  vm.FreeAgreementTypes = FreeAgreementTypes;
  vm.realEstateChoiceMethod = 'DIRECT_PROPERTIES';

  let initialUser = angular.copy(Auth.currentUser());
  initialUser.userId = initialUser.id;
  initialUser.roleObj = NegotiationRoles.HGF_ASSOC_CHIEF;
  initialUser.isChief = true;

  vm.userContainer = {
    hgfSide: [initialUser],
    faSide: [],
    chiefNegotiatorHGF: initialUser,
    chiefNegotiatorExternal: null,
    invites: []
  };

  vm.setRadioModelByOutsideClick = setRadioModelByOutsideClick;
  vm.gotoNextStep = gotoNextStep;
  vm.save = save;
  vm.saving = false;
  vm.freeAgreementId;

  FreeAgreement.create({}).$promise.then(createResult => {
    // eslint-disable-next-line no-undef
    localStorage.setItem('newAvtalId', createResult.id);
    vm.freeAgreementId = createResult.id;
    vm.needClearGarbage = true;
  });

  $scope.$on("$destroy", function() {
    if (vm.freeAgreementId && vm.needClearGarbage) {
      // eslint-disable-next-line no-undef
      localStorage.removeItem('newAvtalId');
      FreeAgreement.deletePending({ agreementId: vm.freeAgreementId })
    }
  });


  ////////////

  function setRadioModelByOutsideClick(choice) {
    vm.realEstateChoiceMethod = choice;
  }

  function gotoNextStep() {
    vm.gotoNext = true;
  }

  function save() {
    vm.saving = true;
    vm.needClearGarbage = false;
    FreeAgreement.finishCreate({
      id: vm.freeAgreementId,
      userContainer: vm.userContainer,
      type: vm.avtalType.code,
      properties: vm.properties,
    // eslint-disable-next-line no-unused-vars
    }).$promise.then(result => {
      $location.search({});
      $location.path('/agreements/' + vm.freeAgreementId);
      Flash.set('Avtal har sparats', 'success');
    }).catch(new ErrorHandler('Misslyckades att skapa avtal.'))
      .finally(function() {
        vm.saving = false;
      });
  }
}

})();