(function () {
'use strict';

angular.module('hyresgastApp').controller('NewUserCtrl', NewUserCtrl);

function NewUserCtrl($location, $routeParams, $filter, $timeout, Auth, HyUtils, Flash, User, Organization, Office, ErrorHandler) {
  var vm = this;
  vm.user = { email: '', phone: '' };
  vm.selectedOffice = {};

  vm.startCheckEmail = startCheckEmail;
  vm.confirmingEmail = false;
  vm.isMailConfirmed = 'unknown';

  vm.save = save;
  vm.isFromInvite = false;

  if ($routeParams.email) {
    // when register a new user via link
    vm.user.email = $routeParams.email;
    vm.verifyEmail = $routeParams.email;
  }
  if ($routeParams.organizationNumber) {
    vm.user.organization = Object.assign(vm.user.organization || {}, {
      organizationNumber: $routeParams.organizationNumber
    });
    findOrganization();
  }

  if ($routeParams.token) {
    vm.user.token = $routeParams.token;
  }
  if ($routeParams.delegationParticipant) {
    vm.user.delegationParticipant = true;
  }

  function getPhone() {
    if ($routeParams.email && $routeParams.organizationNumber) {
      User.getPhoneByInvite({
        email: vm.user.email,
        organizationId: vm.user.organization.id
      }).$promise.then(response => {
        vm.user.phone = response.phone;
        tamePhoneProcess();
      });
    }
  }

  function findOrganization() {
    vm.readyOrganization = false;

    return Organization.queryByOrganizationNumber({
      organizationNumber: vm.user.organization.organizationNumber.replace('-', '')
    })
      .$promise.then(function(data) {
        if (data.id) {
          vm.user.organization = data;
          getPhone();
        }
        queryOffice();
        vm.newOrgNumber = !data.id;
      })
      .catch(function() {
        Flash.set('Misslyckades att hämta organisationer.', 'error');
      })
      .finally(function() {
        vm.readyOrganizations = true;
      });
  }

  vm.checkForExistingOrgNumber = checkForExistingOrgNumber;
  function checkForExistingOrgNumber() {
    findOrganization();
  }

  vm.tamePhoneNumber = () => {
    $timeout(() => {
      tamePhoneProcess();
    }, 3000);
  };

  function tamePhoneProcess() {
    let tamedPhone = HyUtils.tamePhoneNumber(vm.user.phone);
    vm.foreignPhone = tamedPhone.countryCode !== '+46';
    vm.user.phone = vm.foreignPhone ? tamedPhone.countryCode + tamedPhone.number :  tamedPhone.number;
  }

  function startCheckEmail() {
    if (vm.emailCheckScheduled) {
      return;
    }

    vm.emailCheckScheduled = true;
    $timeout(() => {
      checkEmail();
      vm.emailCheckScheduled = false;
    }, 3000);
  }

  function checkEmail() {
    vm.confirmingEmail = true;
    User.checkEmail({ email: vm.user.email}).$promise.then(
      function(response) {
        vm.isMailConfirmed = response.isValid;
        vm.confirmingEmail = false;
      });
  }

  function queryOffice() {
    return Office.query().$promise.then(function(response) {
      vm.offices = response;
    })
      .catch(ErrorHandler('Misslyckades att hämta kontor.'));
  }

  function save() {
    if (vm.user.email.match(/@hyresgastforeningen\.se$/i)) {
      Flash.set('Epostadress berkräftades inte', 'error');
      return;
    }

    if (vm.newOrgNumber && !vm.selectedOffice.id) {
      Flash.set('HGF kontor måste väljas', 'error');
    } else {

      if(vm.selectedOffice.id) {
        vm.user.organization.hgfOfficeId = vm.selectedOffice.id;
      }

      vm.saving = true;
      let tamedPhone = HyUtils.tamePhoneNumber(vm.user.phone);
      let userToSave = Object.assign({}, vm.user);
      userToSave.phone = tamedPhone.countryCode + tamedPhone.number;
      User.save(userToSave)
        .$promise.then(
          function(response) {
            if (response.isfrominvite || vm.user.delegationParticipant) {
              $location.path('users/new/thank-you-invite');
            } else {
              $location.path('users/new/thank-you');
            }
          })
        .catch(function(reason) {
          if (reason.status === 404) {
            Flash.set(
              'Länken du använder för registreringen är förbrukad. Vänligen be HGF om en ny inbjudan till portalen.',
              'error'
            );
          } else {
            if (reason.data.code) {
              switch (reason.data.code) {
                case 0:
                  Flash.set('Registrering misslyckades. Kontrollera din e-post och ditt lösenord.', 'error');
                  break;
                case 1:
                  Flash.set('Registrering misslyckades. Ogiltigt organsiationsnummer.', 'error');
                  break;
                case 2:
                  Flash.set('Registrering misslyckades. Kontakta Förhandlarportalen.', 'error');
                  $location.path('users/new/error');
                  break;
              }
            } else {
              Flash.set('Registrering misslyckades.', 'error');
            }
          }
        })
        .finally(function() {
          vm.saving = false;
        });
    }
  }
}

})();