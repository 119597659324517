(function () {
'use strict';

angular.module('hyresgastApp').controller('ShowAgreementRentlistCtrl', ShowAgreementRentlistCtrl);

function ShowAgreementRentlistCtrl($scope, $location, $routeParams, Agreement, Auth, Flash, ErrorHandler) {
  var vm = this;
  vm.agreement = {
    negotiationId: $routeParams.negotiationId,
    id: $routeParams.id,
    documents: []
  };
  vm.apartments = [];
  vm.getApartments = getApartments;

  vm.listColumns = [
    { title: 'Nationellt lägenhetsnr', column: 'nationalNo' },
    { title: 'Lägenhetsnr', column: 'ownerNo' },
    { title: 'Gatuadress', column: 'streetAddress' },
    { title: 'Fastighet', column: 'property.propertyDesignation' },
    { title: 'Kommun', column: 'municipality' },
    { title: 'Överenskommen ny hyra (kr/månad)', column: 'newMonthlyRent' },
  ];
  vm.sortColumn = vm.listColumns.some(function(c) {
    return c.column === $location.search().order;
  })
    ? $location.search().order
    : 'property.propertyDesignation'; // default
  vm.sortDirection = $location.search().direction || 'ASC'; // default

  $scope.$on('$routeUpdate', function() {
    if ($location.search().order) {
      // reset offset when sorting
      vm.currentPage = 0;
      getApartments();
    }
  });

  getApartments();

  vm.pageLimit = 30;
  vm.currentPage = 0;
  vm.firstRecordInCurrentPage = 1;

  function getApartments(filterMode) {
    vm.readyApartments = false;
    if (filterMode) {
      vm.currentPage = 0;
    }
    return Agreement.queryApartment({
      negotiationId: $routeParams.negotiationId,
      id: $routeParams.id,
      limit: vm.pageLimit,
      offset: vm.currentPage,
      order: vm.sortColumn,
      direction: vm.sortDirection,
      'search[nationalNo]': vm.inputTextNationalApartmentNo,
      'search[ownerNo]': vm.inputTextApartmentNo,
      'search[streetAddress]': vm.inputTextStreetAddress,
      'search[property.propertyDesignation]': vm.inputTextPropertyDesignation,
      'search[municipality]': vm.inputTextMunicipality
    })
      .$promise.then(function(response) {
        vm.apartments = response.rows;
        vm.apartmentsTotalCount = response.count;
        // Save initial count of records, to be distingueshed from the current count after
        // unsuccessful filtering. This will affect all messages, which are dependent on totalCount = 0.
        if (filterMode !== true) {
          vm.initialTotalCount = vm.apartmentsTotalCount;
        }
      })
      .catch(ErrorHandler('Misslyckades att hämta fastighetsbestånd.'))
      .finally(function() {
        vm.readyApartments = true;
      });
  }
}

})();