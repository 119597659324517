(function () {
'use strict';

angular.module('hyresgastApp').controller('VerifyEmailCtrl', VerifyEmailCtrl);

function VerifyEmailCtrl($scope, $location, $routeParams, Auth, User, Flash) {
  var vm = this;
  vm.user = {};
  vm.login = login;
  vm.loginVisible = true;
  vm.credentials = {};

  vm.credentials.token = $routeParams.token;
  vm.credentials.email = $routeParams.email;

  function login() {
    vm.saving = true;
    vm.credentials.password = vm.password;

    User.verifyEmail(vm.credentials)
      .$promise.then(function(user) {
        Auth.setCurrentUser(user);
        $location.search({});
        $location.path('/users/' + user.id + '/welcome');
      })
      .catch(function() {
        Flash.set('Kunde inte logga in. Kontrollera ditt lösenord', 'error');
        vm.password = null;
        vm.credentials.password = null;
      })
      .finally(function() {
        vm.saving = false;
      });
  }
}

})();