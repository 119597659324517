(function () {
'use strict';

angular.module('hyresgastApp').controller('EditStatusModalCtrl', EditStatusModalCtrl);

function EditStatusModalCtrl($uibModalInstance, validStatuses, currentStatuses) {
  var vm = this;

  let checkStatus = (statusObj, status) => (status & statusObj.code) === statusObj.code;
  vm.statuses = validStatuses.map(x => ({
    name: x.name,
    code: x.code,
    canSet: !currentStatuses.every(s => checkStatus(x, s)),
    canReset: currentStatuses.some(s => checkStatus(x, s)),
    setCount: currentStatuses.filter(s => checkStatus(x, s)).length,
  }));

  vm.set = code => $uibModalInstance.close({ set: true, code });
  vm.reset = code => $uibModalInstance.close({ reset: true, code });
  vm.cancel = () => $uibModalInstance.dismiss();
}
})();