(function () {
'use strict';

//TODO: flytta til fe+common

angular.module('hyresgastApp').filter('hyStatusString', function(FPConsts) {
  return function(value, statusSet) {
    let validStatusTypes = [];

    switch (statusSet) {
      // statuses, that are relevant in negotiation - all, excluding F and M
      case 1:
        validStatusTypes = FPConsts.apartmentStatusesForNegotation;
        break;
    }

    return validStatusTypes
      .filter(s => (value & s.code))
      .map(s => s.colouredName || s.name)
      .join(', ');
  };
});


})();