(function () {
'use strict';

angular
  .module('domainModals', [])
  .factory('DomainModals', DomainModals);

function DomainModals(HyUtils) {
  return {
    apartmentHistory: apartment => HyUtils.showDefaultModal('ApartmentHistoryCtrl',
      'resources/real-estate/apartment-history.html', { apartment })
  }
}
})();