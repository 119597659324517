(function () {
'use strict';

angular.module('hyresgastApp').controller('ShowNegotiationCallRemainingCtrl', ShowNegotiationCallRemainingCtrl);

function ShowNegotiationCallRemainingCtrl($scope, $location, $routeParams, NegotiationCall, ErrorHandler) {
  var vm = this;

  vm.propertiesPageLimit = 30;
  vm.propertiesPageOffset = 0; // always show first items
  vm.queryProperties = queryProperties;
  vm.sortColumn = $location.search().order || 'propertyDesignation'; // default
  vm.sortDirection = $location.search().direction || 'ASC'; // default
  vm.listColumns = [
    { title: 'Fastighetsbeteckning', column: 'propertyDesignation' },
    { title: 'Kommun', column: 'municipality' },
    { title: 'LM distrikt', column: 'district' },
    { title: 'Antal lägenheter', column: 'numNegotiatedApartments' }
  ];

  queryProperties();

  $scope.$on('$routeUpdate', function() {
    if ($location.search().order) {
      // reset offset when sorting
      vm.propertiesPageOffset = 0;
      queryProperties();
    }
  });

  /////////

  function queryProperties(resetOffset) {
    vm.readyProperties = false;
    if (resetOffset) {
      vm.propertiesPageOffset = 0;
    }
    return NegotiationCall.queryProperty(
      {
        id: $routeParams.id,
        limit: vm.propertiesPageLimit,
        offset: vm.propertiesPageOffset,
        order: vm.sortColumn,
        direction: vm.sortDirection,
        'search[propertyDesignation]': vm.inputTextProperty,
        'search[municipality]': vm.inputTextMunicipality,
        'search[district]': vm.inputTextDistrict,
        unassigned: true
      },
      {}
    )
      .$promise.then(function(response) {
        vm.properties = response.rows;
        vm.propertyTotalCount = response.count;
        vm.rentFile = response.rentFile;
      })
      .catch(ErrorHandler('Misslyckades att hämta fastighet.'))
      .finally(function() {
        vm.readyProperties = true;
      });
  }
}

})();