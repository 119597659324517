(function () {
'use strict';

angular.module('hyresgastApp').factory('Organization', function($resource) {
  return $resource(
    '/api/organizations/:id',
    { id: '@id', organizationId: '@organizationId', email: '@email' },
    {
      query: {
        url: '/api/organizations',
        isArray: false
      },
      approve: {
        url: '/api/organizations/:id/approve',
        method: 'POST'
      },
      queryUser: {
        url: '/api/organizations/:id/users',
        isArray: true
      },
      updateUserRole: {
        url: '/api/organizations/:organizationId/users/:id',
        method: 'POST'
      },
      deleteUser: {
        url: '/api/organizations/:organizationId/users/:id',
        method: 'DELETE'
      },
      queryInvite: {
        url: '/api/organizations/:id/users/invited',
        isArray: true
      },
      deleteInvite: {
        url: '/api/organizations/:organizationId/users/invited/:id',
        method: 'DELETE'
      },
      saveInvite: {
        url: '/api/organizations/:id/users',
        method: 'POST'
      },
      getApartmentsSummary: {
        url: '/api/organizations/:id/apartments/summary',
        isArray: false
      },
      getApartmentsSummaryBySelection: {
        url: '/api/organizations/:id/apartments/summary',
        isArray: false,
        method: 'POST'
      },
      queryDistrict: {
        url: '/api/organizations/:id/districts'
      },
      queryProperty: {
        url: '/api/organizations/:id/districts/properties',
        method: 'POST'
      },
      queryByOrganizationNumber: {
        url: '/api/organizations/by-organization-number'
      },
      queryProperties: {
        url: '/api/organizations/:id/properties',
        method: 'GET'
      },
      unbindProperty: {
        url: '/api/organizations/:id/properties/:propertyId',
        method: 'DELETE'
      }
    }
  );
});

})();