(function () {
'use strict';

function deepEqual(firstObject, secondObject) {
  for(var prop in firstObject) {
    // eslint-disable-next-line eqeqeq
    if (firstObject[prop] != secondObject[prop])
      return false;
  }
  return true;
}
Array.prototype.deepIncludes = function(needle) {
  for(var i = 0; i < this.length; i++) {
    var el = this[i];
    if (deepEqual(needle, el)) {
      return true;
    }
    return false;
  }
};

Array.prototype.deepCopy = function() {
  var result = [];
  for(var i = 0; i < this.length; i++) {
    result.push(Object.assign({}, this[i]));
  }
  return result;
}

Array.prototype.unique = function() {
  var arr = [];
  for(var i = 0; i < this.length; i++) {
    if(!arr.deepIncludes(this[i])) {
      arr.push(this[i]);
    }
  }
  return arr;
};

/**
 * Gives intersection between this and given array
 * @param {Array.<Object>} otherArray
 */
Array.prototype.intersect = function(otherArray) {
  return this.filter(value => -1 !== otherArray.indexOf(value));
};

/**
 * checks if otherArray is contained in given array
 * @param {Array.<Object>} otherArray
 */
Array.prototype.containsArray = function(otherArray) {
  if (otherArray.length === 0) {
    return false;
  }
  return this.intersect(otherArray).length === otherArray.length;
}

})();