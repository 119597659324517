(function () {
'use strict';

angular.module('hyresgastApp').controller('MoveItemsCtrl', MoveItemsCtrl);

function MoveItemsCtrl(
  $scope,
  $uibModal,
  $uibModalInstance,
  Negotiation,
  NegotiationCall,
  Flash,
  currentNegotiation,
  itemIds,
  apartmentMode,
  ErrorHandler
) {
  var vm = this;
  vm.parentCtrl = $scope.$parent;
  vm.cancel = cancel;
  vm.moveToNewNegotiation = false;
  vm.moveItems = moveItems;
  vm.currentNegotiation = currentNegotiation;
  vm.apartmentMode = apartmentMode;

  getNegotiationsByCallId();

  function getNegotiationsByCallId() {
    vm.ready = false;

    return Negotiation.query({
      id: currentNegotiation.id,
      'filters[callId]': currentNegotiation.callId
    })
      .$promise.then(function(data) {
        vm.targetNegotiations = data.rows;
        vm.targetNegotiations.push({ name: 'Flytta till ny förhandling', id: '_moveToNewNegotiation' });
      })
      .catch(ErrorHandler('Misslyckades att  hitta förhandlingar.'))
      .finally(function() {
        vm.ready = true;
      });
  }

  function moveItems() {
    var item = vm.apartmentMode ? 'Lägenhet' : 'Fastighet';
    var plural = itemIds.length > 1 ? 'er' : '';

    if (vm.selectedNegotiation.id === '_moveToNewNegotiation') {
      moveToNewNegotiation(item, plural);
    } else {
      moveToExistingNegotiation(item, plural);
    }
  }

  function moveToNewNegotiation(item, plural) {
    vm.saving = true;
    Negotiation.createNewNegotiationAndMoveItems({
      id: currentNegotiation.id,
      currentNegotiation: currentNegotiation,
      itemIds: itemIds
    })
      .$promise.then(function(response) {
        vm.saving = false;
        Flash.set(item + plural + ' har flyttats till ny förhandling', 'success');
      })
      .catch(ErrorHandler('Misslyckades att flytta ' + item + plural))
      .finally(function() {
        vm.saving = false;
        $uibModalInstance.close();
      });
  }

  function moveToExistingNegotiation(item, plural) {
    vm.saving = true;

    Negotiation.moveItems({
      id: currentNegotiation.id,
      newNegotiationId: vm.selectedNegotiation.id,
      itemIds: itemIds
    })
      .$promise.then(function() {
        Flash.set(item + plural + ' har flyttats', 'success');
      })
      .catch(ErrorHandler('Misslyckades att flytta ' + item + plural))
      .finally(function() {
        vm.saving = false;
        $uibModalInstance.close();
      });
  }

  function cancel() {
    $uibModalInstance.dismiss('cancel');
  }
}

})();