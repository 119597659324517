(function () {
'use strict';

// @desc
//   Timeline to show activities and provide routing to these
//   activites by a name attribute
//
// @example
//   <hy-timeline activities="activities"></hy-timeline>

angular
  .module('timeline', ['uiTooltip']) // TODO add tooltip as a dependency
  .directive('hyTimeline', timeline);

function timeline($location, $window) {
  var directive = {
    scope: {
      activities: '=',
      alternativeWizard: '='
    },
    restrict: 'E',
    templateUrl: 'components/timeline/timeline.html',
    link: link
  };

  return directive;

  function link(scope, element, attrs) {
    scope.progress = progress;
    scope.gotoActivity = gotoActivity;
    scope.currentOpenIdx = currentOpenIdx;
    scope.tooltipClass = scope.alternativeWizard
      ? 'timeline__tooltip alternative-timeline-tooltip'
      : 'timeline__tooltip';
    ////////////

    function progress() {
      return 100 / (scope.activities.length - 1) * currentOpenIdx();
    }

    function gotoActivity(activityName) {
      $location.search({ step: activityName });
    }

    function currentOpenIdx() {
      var idx = 0;
      var res = -1;
      angular.forEach(scope.activities, function(activity) {
        if (activity.isOpen === true) {
          res = idx;
        }
        idx++;
      });
      return res;
    }

    // Tooltip is positioned absolute and not relative because of a IE-bug
    // Reposition the tooltip if browser window is resized
    function repositionTooltip() {
      scope.$apply();
    }

    angular.element($window).on('resize', repositionTooltip);
    scope.$on('$destroy', function() {
      angular.element($window).off('resize', repositionTooltip);
    });
  }
}

})();