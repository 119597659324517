(function () {
'use strict';

// @desc
//   Sorting component (ASC and DESC order) working on hard-coded table's columns appearing in a dropdown menu
//
// @example
// Use in header <th> column of a table
// <hy-sorter
//   list-columns="vm.listColumns"
//   order = "vm.sortColumn"
//   direction = "vm.sortDirection
//   show-label="true" 
//   label-position="side">
// </hy-sorter>

angular.module('sorter', ['uiDropdown']).directive('hySorter', sorter);

function sorter($location, $timeout) {
  var directive = {
    scope: {
      listColumns: '=',
      order: '=',
      direction: '=',
      skipFetch: '=',
      changed: '&',      
      showLabel: '=?',  
      labelText: '@?', // Optional label other than default
      labelSize: '@?',   
      labelPosition: '@?' // Label position: 'side' or 'top'
    },
    restrict: 'E',
    templateUrl: 'components/sorter/sorter.html',
    link: link
  };
  return directive;

  function link(scope, element, attrs) {
    scope.sort = sort;

    if (scope.listColumns) {
      scope.itemName = scope.listColumns.filter(function(item) {
        return item.column === scope.order;
      })[0].title;
    } else {
      scope.itemName = scope.showLabel ? 'Välj sortering' : 'Sortera på';
    }

    ////////////

    function sort(sortColumn, columnTitle) {
      scope.itemName = columnTitle;
      scope.previousOrder = scope.order;
      scope.order = sortColumn;
      if (scope.order === scope.previousOrder) {
        toggleDirection();
      } else {
        scope.direction = 'ASC';
      }
      $timeout(fetch, 0);
    }

    function toggleDirection() {
      scope.direction === 'ASC' ? (scope.direction = 'DESC') : (scope.direction = 'ASC');
    }

    function fetch() {
      if (!scope.skipFetch) {
        // reset offset in the URL when sorting
        if ($location.search().offset !== undefined) {
          $location.search(angular.extend($location.search(), { offset: 0 }));
        }
        // parent controller will reload and read parameters from URL
        $location.search(angular.extend($location.search(), { order: scope.order, direction: scope.direction }));
      }
      if (scope.changed) {
        scope.changed();
      }
    }
  }
}

})();