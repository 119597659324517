(function () {
'use strict';

// Used in getCurrentSubpage() in order to avoid <ng-include> to include non-existing subpage,
// which can happen when the user manually set wrong '?view=' param in the URL.

angular.module('hyresgastApp').factory('SubPage', function($location) {
  var service = {
    set: set
  };

  return service;

  function set(routeParamsView, whiteList, defaultSubPage) {
    if (routeParamsView === undefined || !subPageValidation()) {
      // clear URL params in the case when the user manually
      // set in URL a non existing 'view' param for the current tab,
      // because the default subpage is hightlighted when 'view' is undefined.
      $location.search({});
      return defaultSubPage;
    } else {
      return routeParamsView;
    }

    function subPageValidation() {
      return whiteList.indexOf(routeParamsView) > -1;
    }
  }
});

})();