(function () {
'use strict';

angular.module('hyresgastApp').filter('hyRoles', function(Role) {
  return function(value) {
    return hyRoles(value, Role);
  };
});

function hyRoles(value, Role) {
  switch (value) {
    case Role.SPECTATOR:
      return 'Läsanvändare';
    case Role.USER:
      return 'Användare';
    case Role.ADMIN:
      return 'Admin';
    default:
      return 'Saknar behörighet';
  }
}

})();