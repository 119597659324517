(function () {
'use strict';

// @desc
// Progress indeterminate circular element,
// which goal is to show to the user that something
// is in progress of execution and they should wait
// until requested query is processed.
//
// @example
//     <hy-progress-circular size="20px"></hy-progress-circular>

angular.module('hyresgastApp').directive('hyProgressCircular', progressCircular);

function progressCircular() {
  var directive = {
    templateUrl: 'components/progress-circular/progressCircular.html',
    restrict: 'E',
    link: link,
    scope: {
      size: '@'
    }
  };
  return directive;

  function link(scope, element, attrs) {
    if (attrs.hasOwnProperty('centerOnPage')) {
      element.addClass('progress-circular--center-on-page');
    }
    if (attrs.hasOwnProperty('centerOnPageFixed')) {
      element.addClass('progress-circular--center-on-page-fixed');
    }
  }
}

})();