(function () {
'use strict';

// @desc
//   Wizard to handle a big task in smaller steps
//
// @example
//   <hy-wizard>
//      <hy-step></hy-step>
//   </hy-wizard>

angular.module('wizard', ['wizard.ctrl', 'wizard.step']).directive('hyWizard', wizard);

function wizard() {
  var directive = {
    controller: 'WizardCtrl',
    controllerAs: 'wizardVm',
    transclude: true,
    scope: {
      wzSubmit: '&',
      submitText: '@?',
      submitDisabled: '=',
      gotoNext: '=',
      beforeGotoNext: '&?',
      isCurrentStepValid: '=',
      headerText: '@',
      alternativeWizard: '='
    },
    restrict: 'E',
    templateUrl: 'components/wizard/wizard.html'
  };
  return directive;
}

})();