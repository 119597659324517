(function () {
'use strict';

angular.module('reportParameters', []).directive('reportParameters', ['ReportParameterTypes', reportParameters]);

function reportParameters(ReportParameterTypes) {
  var directive = {
    scope: {
      rowIndex: '@',
      params: '=',
      paramDictionaries: '=',
      isValid: '=',
    },
    restrict: 'E',
    templateUrl: 'resources/reports/parameters.html',
    link: link
  };

  return directive;

  function link($scope) {
    let objectHasData = obj => !!Object.values(obj).filter(x => x).length;
    
    $scope.paramData = {};

    $scope.paramTypes = ReportParameterTypes;

    $scope.checkCanRunReport = function () {
      if (!$scope.params) {
        return;
      }
      let unsetParameters = $scope.params.filter(p => {
        if (p.allOmit) {
          return false;
        }

        let hasData = false;
        if (!!p.data) {
          switch (typeof p.data) {
            case 'number':
              hasData = p.data !== 0;
              break;
            case 'object':
              hasData = objectHasData(p.data);
              break;
            default:
              hasData = true;
              break;
          }
        }
        if (hasData) {
          return false;
        }

        let optionalSelected = !p.mandatory && p.isSet;
        return p.mandatory || optionalSelected;
      });
      $scope.isValid = !unsetParameters.length;
    };

    $scope.checkCanRunReportDelayed = () => {
      setTimeout(() => {
        $scope.checkCanRunReport();
        $scope.$apply();
      }, 300);
    };

    $scope.setDropdownValue = function(param, item) {
      param.data = item;
      param.isSet = (item.id !== -1);
      $scope.checkCanRunReport();
    };

    $scope.setupParams = function () {
      $scope.paramData = {};
      $scope.params.forEach(parameter => {
        if (!parameter.mandatory) {
          parameter.isSet = false;
        }

        $scope.paramData[parameter.name] = Object.assign([], $scope.paramDictionaries[parameter.type]);

        if (parameter.allOmit) {
          parameter.isSet = false;
          //id-value
          if (parameter.type === $scope.paramTypes.HGF_OFFICE ||
              parameter.type === $scope.paramTypes.NEGOTIATION_STATUS ||
              parameter.type === $scope.paramTypes.NEGOTIATION_STATUS_GROUP ||
              parameter.type === $scope.paramTypes.NEGOTIATION_TYPE ||
              parameter.type === $scope.paramTypes.REGION) {
            $scope.paramData[parameter.name].splice(0, 0, { id: -1, name: 'Alla' });
            parameter.data = $scope.paramData[parameter.name][0];
          }
          //raw values
          if (parameter.type === $scope.paramTypes.MUNICIPALITY) {
            $scope.paramData[parameter.name].splice(0, 0, 'Alla');
            parameter.data = $scope.paramData[parameter.name][0];
          }
          if (parameter.type === $scope.paramTypes.HGF_OFFICE_MULTIPLE) {
            parameter.data = -1;
          }
        }
      });
      $scope.checkCanRunReportDelayed();
    }

    let maybeSetup = () => {
      if ($scope.params && $scope.paramDictionaries) {
        $scope.setupParams();
      }
    };

    $scope.$watch('params', maybeSetup);
    $scope.$watch('paramDictionaries', maybeSetup);
    $scope.$on('checkParams', function ($event, data) {
      if (data.rowIndex == $scope.rowIndex) {
        $scope.checkCanRunReport();
      };
    });

    $scope.noParentRefs = param => !param.parentRef;
    $scope.anyNonParentParameters = () => $scope.params && !!$scope.params.filter(param => !param.parentRef).length;
  }
}

})();