(function () {
'use strict';

angular.module('hyresgastApp').filter('hyNegotiationWithBlockingStates', function(NegotiationStates, AgreementBlockedByType) {
  return function(value, blockingAuthority) {
    return hyNegotiationWithBlockingStates(value, blockingAuthority, NegotiationStates, AgreementBlockedByType);
  };
});

function hyNegotiationWithBlockingStates(value, blockingAuthority, NegotiationStates, AgreementBlockedByType) {
  switch (value) {
    case NegotiationStates.NEW:
      return 'Ny';
    case NegotiationStates.IN_PREPARATION:
      return 'Bereds';
    case NegotiationStates.IN_PROGRESS:
      return 'Pågår';
    case NegotiationStates.SETTLEMENT:
      return 'Uppgörelse';
    case NegotiationStates.BLOCKED:
      switch (blockingAuthority) {
        case AgreementBlockedByType.PRIVATE:
          return 'Strandad HN';
        case AgreementBlockedByType.PUBLIC:
          return 'Strandad HMK';
        case AgreementBlockedByType.NONE:
          return 'Strandad Egensatt';
        case AgreementBlockedByType.MEDIATOR:
          return 'Strandad Skiljeman HN';
        default:
          return 'Strandad';
      }
    case NegotiationStates.PARTIALLY_BLOCKED:
      switch (blockingAuthority) {
        case AgreementBlockedByType.PRIVATE:
          return 'Delvis strandad HN';
        case AgreementBlockedByType.PUBLIC:
          return 'Delvis strandad HMK';
        case AgreementBlockedByType.NONE:
          return 'Delvis strandad Egensatt';
        case AgreementBlockedByType.MEDIATOR:
          return 'Delvis strandad Skiljeman HN';
        default:
          return 'Delvis strandad';
        }
    case NegotiationStates.FINISHED_OUTSIDE_PORTAL:
      return 'Felaktigt avslutad förhandling - Vänligen återöppna';
    case NegotiationStates.FINISHED:
      return 'Avslutad';
    default:
      return 'Alla statusar';
  }
}

})();