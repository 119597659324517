(function () {
'use strict';

angular.module('hyresgastApp').controller('ShowNegotiationHistoryCtrl', ShowNegotiationHistoryCtrl);

function ShowNegotiationHistoryCtrl($scope, $location, $routeParams, Negotiation, ErrorHandler) {
  // Don't want client to break if API returns new types
  // we have not created a html partial for
  var ALLOWED_TYPES = ['agreement', 'meeting', 'negotiation', 'negotiationParticipant', 'rentFile', 'property', 'apartment'];

  var vm = this;
  vm.isAllowed = isAllowed;

  queryHistory();

  /////////

  function queryHistory() {
    vm.ready = false;

    return Negotiation.queryHistory(
      {
        id: $routeParams.id
      },
      {}
    )
      .$promise.then(function(response) {
        vm.events = response;
      })
      .catch(ErrorHandler('Misslyckades att hämta historik.'))
      .finally(function() {
        vm.ready = true;
      });
  }

  function isAllowed(type) {
    return ALLOWED_TYPES.indexOf(type) > -1;
  }
}

})();