(function () {
'use strict';

/*
  Used to render a simple help modal. Inject in controller and call renderHelpModal() with
  a title and a body. Example:
  Help.renderHelpModal('Help title', 'Help text that explains something');
*/

angular.module('hyresgastApp').factory('Help', function($uibModal) {
  this.renderHelpModal = function(title, body) {
    var settings = {
      title: title,
      body: body
    };
    var modalInstance = $uibModal.open({
      animation: true,
      ariaLabelledBy: 'modal-title-top',
      ariaDescribedBy: 'modal-body-top',
      size: 'md',
      index: 1075,
      templateUrl: 'components/help-modal/helpModal.html',
      controller: 'HelpModalCtrl',
      controllerAs: 'vm',
      resolve: {
        settings: function() {
          return settings;
        }
      }
    });
  };
  return this;
});

})();