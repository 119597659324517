(function () {
'use strict';

// @desc
//   Wizard to handle a big task in smaller steps
//
// @example
//   <hy-modal-wizard>
//      <hy-modal-wizard-step></hy-modal-wizard-step>
//   </hy-modal-wizard>

angular.module('modalWizard', ['modalWizard.ctrl', 'modalWizard.step']).directive('hyModalWizard', modalWizard);

function modalWizard() {
  var directive = {
    controller: 'ModalWizardCtrl',
    controllerAs: 'modalWizardVm',
    transclude: true,
    scope: {
      wzSubmit: '&',
      wzCancel: '&',
      submitText: '@?',
      submitDisabled: '=',
      gotoNext: '=',
      beforeGotoNext: '&?',
      currentStepNumber: '=',
      isCurrentStepValid: '=',
      headerText: '@',
      alternativeWizard: '='
    },
    restrict: 'E',
    templateUrl: 'components/modalwizard/modal-wizard.html'
  };
  return directive;
}

})();