(function () {
'use strict';

angular.module('hyresgastApp').factory('Seenby', function($resource) {
  return $resource(
    '/api/seenby', {
      objectType: '@objectType',
      objectId: '@objectId',
    }, {
      // POST /api/seenby/:objectType/:objectId
      set: {
        url: '/api/seenby/:objectType/:objectId',
        method: 'POST',
      },
      // POST /api/seenby/:objectType
      markAsRead: {
        url: '/api/seenby/:objectType',
        method: 'POST',
      }
    }
  );
});

})();