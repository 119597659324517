(function () {
'use strict';

angular
  .module('httpAuth', [
    'httpAuthInterceptor',
    'httpAuthBuffer',
    'httpAuthSession',
    'httpAuthHeartbeat',
    'httpRole',
    'httpRoleLookup'
  ])
  .factory('Auth', Auth);

function Auth($q, Session, httpBuffer, 
  Role, Parties) {
  var user;
  var service = {
    create: create,
    destroy: destroy,
    cancel: cancel,
    setCurrentUser: set,
    resetCurrentUser: reset,
    currentUser: show,
    heartbeat: heartbeat,
    isAdmin: isAdmin,
    isSpectator: isSpectator,
    canDo: canDo,
    isHgfUser: isHgfUser,
    isDelegateUser: isDelegateUser,
    isExternalUser: isExternalUser,
    getName: getCurrentUserName,
    userHasPermissionForView: userHasPermissionForView
  };

  return service;

  ////////////

  function create(credentials) {
    return Session.create(credentials).then(function(data) {
      retryDeferredRequests(); // TODO - probably add a CSRF-token to buffered request header
      user = data;
      return user;
    });
  }

  function set(userData) {
    user = userData;
    return user;
  }

  function reset() {
    user = null;
  }

  // Call this function to indicate that authentication should not proceed.
  // All deferred requests will be abandoned or rejected (if reason is provided).
  // @param
  //    reason - if provided, the requests are rejected; abandoned otherwise.
  function cancel(reason) {
    httpBuffer.rejectAll(reason);
  }

  function show() {
    return user;
  }

  function isAdmin() {
    return user && user.role === Role.ADMIN;
  }

  function isSpectator() {
    return user && user.role === Role.SPECTATOR;
  }

  function canDo(actionKey) {
    return !!(user && user.actionsAllowed && user.actionsAllowed.includes(actionKey));
  }

  function isHgfUser() {
    return user && user.party === Parties.HGF;
  }

  function isExternalUser() {
    return user && user.party === Parties.EXTERNAL;
  }

  function isDelegateUser() {
    return user && user.party === Parties.DELEGATE;
  }

  function destroy() {
    return Session.destroy().then(function() {
      user = null;
      return user;
    });
  }

  function userHasPermissionForView(permissions) {
    if(!user) {
      return false;
    }
    if(!permissions || permissions.length === 0) {
      return true;
    }
    var found = false;
    angular.forEach(permissions, function(permission) {
      if (user.party === permission) {
        found = true;
        return;
      }
    });
    return found;
  }

  function heartbeat(ignoreAuth) {
    if (user) {
      // less requests to API
      return $q.resolve();
    } else {
      return Session.show(ignoreAuth).then(
        function(data) {
          user = data;
          return user;
        },
        function() {
          // i.e session timed out
          user = null;
          return $q.reject('No session');
        }
      );
    }
  }

  function getCurrentUserName() {
    if (user === null || typeof user === 'undefined') {
      return '';
    }

    return user.firstname + ' ' + user.lastname;
  }

  // Called on successfull authentication and triggers a retry of all deferred
  // requests.
  // @param
  //      configUpdater - an optional transformation function that can modify the
  //      requests that are retried after having logged in.  This can be used for
  //      example to add an authentication token. It must return the request.
  function retryDeferredRequests(configUpdater) {
    var updater =
      configUpdater ||
      function(config) {
        return config;
      };
    httpBuffer.retryAll(updater);
  }
}

})();