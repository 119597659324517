(function () {
'use strict';

angular.module('hyresgastApp').controller('ListNegotiationCallNewCtrl', ListNegotiationCallNewCtrl);

function ListNegotiationCallNewCtrl(
  $scope,
  $location,
  $routeParams,
  Auth,
  User,
  Parties,
  NegotiationCall,
  NegotiationCallStates,
  NegotiationTypes,
  ErrorHandler
) {
  var vm = this;

  vm.NegotiationCallNewStates = {
    WAITING_HGF: NegotiationCallStates.WAITING_HGF,
    PARTIALLY_ASSIGNED: NegotiationCallStates.PARTIALLY_ASSIGNED
  };

  vm.statusChoices = angular.extend({
    ALL: ''
  }, vm.NegotiationCallNewStates);
  vm.negotiationNewCalls = [];
  vm.searchBySelectedOffices = searchBySelectedOffices;
  vm.gotoShow = gotoShow;
  vm.typeChoices = angular.extend({}, NegotiationTypes);
  vm.pageLimit = 30;
  vm.pageOffset = 0;
  vm.sortColumn = $location.search().order || 'sentAt'; // default
  vm.sortDirection = $location.search().direction || 'DESC'; // default
  vm.getNegotiationNewCalls = getNegotiationNewCalls;
  vm.listColumns = [{
    title: 'Namn',
    column: 'name'
  },
  {
    title: 'Organisation',
    column: 'counterpart.name'
  },
  {
    title: 'Skickad till kontor',
    column: 'office.name'
  },
  {
    title: 'Status',
    column: 'state'
  },
  {
    title: 'Bereds av',
    column: 'handler.firstname'
  },
  {
    title: 'Inskickad',
    column: 'sentAt'
  },
  {
    title: 'Id',
    column: 'caseNumber'
  }
  ];

  vm.user = {};
  vm.parties = Parties;

  Auth.heartbeat().then(function () {
    getUserOffices();
  });

  $scope.$on('$routeUpdate', function () {
    if ($location.search().order) {
      // reset offset when sorting
      vm.pageOffset = 0;
      getNegotiationNewCalls();
    }
  });

  getNegotiationNewCalls();

  ////////////

  function searchBySelectedOffices() {
    vm.selectedOffices = [];
    for (var key in vm.offices) {
      if (vm.offices[key] === true) {
        vm.selectedOffices.push(key);
      }
    }
    getNegotiationNewCalls(true);
  }

  function getNegotiationNewCalls(filterMode) {
    vm.ready = false;
    if (filterMode) {
      vm.pageOffset = 0;
    }

    var state = [];
    if (!vm.statusId) {
      state = [vm.NegotiationCallNewStates.WAITING_HGF, vm.NegotiationCallNewStates.PARTIALLY_ASSIGNED];
    } else {
      state = vm.statusId;
    }

    return NegotiationCall.query({
      limit: vm.pageLimit,
      offset: vm.pageOffset,
      order: vm.sortColumn,
      direction: vm.sortDirection,
      mine: 'true',
      'filters[caseNumber]': vm.inputTextId,
      'filters[office.id]': vm.selectedOffices,
      'filters[type]': vm.typeId,
      'filters[state]': state,
      'search[name]': vm.inputTextName,
      'search[counterpart.name]': vm.inputTextOrganisation,
      'search[handler.firstname,handler.lastname]': vm.inputTextHandler
    })
      .$promise.then(function (data) {
        vm.negotiationNewCalls = data.rows;
        vm.totalCount = data.count;
        // Save initial count of records, to be distingueshed from the current count after
        // unsuccessful filtering. This will affect all messages, which are dependent on totalCount = 0.
        if (filterMode !== true) {
          vm.initialTotalCount = vm.totalCount;
        }
      })
      .catch(ErrorHandler('Misslyckades att hämta lista med påkallan.'))
      .finally(function () { vm.ready = true });
  }

  function getUserOffices() {
    return User.get({
      id: Auth.currentUser().id
    })
      .$promise.then(function (response) {
        vm.user = response;
        // Convert response.offices object to an array for easily sorting and using length property
        vm.userOffices = [];
        for (var key in response.offices) {
          vm.userOffices.push({
            id: key,
            name: response.offices[key]
          });
        }
      })
      .catch(ErrorHandler('Misslyckades att hämta data.'))
      .finally(function () { vm.ready = true });
  }

  function gotoShow(id) {
    $location.search({});
    $location.path('/negotiation-calls/' + id);
  }
}
})();