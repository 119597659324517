(function () {
'use strict';

angular.module('hyresgastApp').controller('ListUserHgfCtrl', ListUserHgfCtrl);

function ListUserHgfCtrl($scope, $location, $routeParams, Flash, User, Regions, ErrorHandler) {
  var vm = this;
  vm.users = [];
  vm.getUsers = getUsers;
  vm.gotoShow = gotoShow;
  vm.pageLimit = 30;
  vm.pageOffset = 0;
  vm.sortColumn = $location.search().order || 'lastname'; // default
  vm.sortDirection = $location.search().direction || 'ASC'; // default
  vm.listColumns = [
    { title: 'Förnamn', column: 'firstname' },
    { title: 'Efternamn', column: 'lastname' },
    { title: 'Telefonnummer', column: 'phone' },
    { title: 'E-post', column: 'email' },
    { title: 'Part', column: 'party' }
  ];
  vm.regionChoices = angular.extend({ ALL: '' }, Regions);

  getUsers();

  $scope.$on('$routeUpdate', function() {
    if ($location.search().order) {
      // reset offset when sorting
      vm.pageOffset = 0;
      getUsers();
    }
  });

  ////////////

  function gotoShow(id) {
    $location.search({});
    $location.path('/users/' + id);
  }

  function getUsers(filterMode) {
    vm.ready = false;
    if (filterMode) {
      vm.pageOffset = 0;
    }
    if (vm.regionId) {
      return User.queryByRegion({
        regionId: vm.regionId,
        limit: vm.pageLimit,
        offset: vm.pageOffset,
        order: vm.sortColumn,
        direction: vm.sortDirection,
        'filters[party]': 20,
        'search[firstname]': vm.inputTextFirstName,
        'search[lastname]': vm.inputTextLastName,
        'search[email]': vm.inputTextEmail
      })
        .$promise.then(function(data) {
          vm.users = data.rows;
          vm.totalCount = data.count;
        })
        .catch(ErrorHandler('Misslyckades att hämta användaren.'))
        .finally(function() {
          vm.ready = true;
        });
    } else {
      return User.query({
        limit: vm.pageLimit,
        offset: vm.pageOffset,
        order: vm.sortColumn,
        direction: vm.sortDirection,
        'filters[party]': 20,
        'search[firstname]': vm.inputTextFirstName,
        'search[lastname]': vm.inputTextLastName,
        'search[email]': vm.inputTextEmail
      })
        .$promise.then(function(data) {
          vm.users = data.rows;
          vm.totalCount = data.count;
        })
        .catch(ErrorHandler('Misslyckades att hämta användaren.'))
        .finally(function() {
          vm.ready = true;
        });
    }
  }
}

})();