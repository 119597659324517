(function () {
'use strict';

angular.module('hyresgastApp').filter('hyNegotiationProtocolStates', function(NegotiationProtocolStates) {
  return function(value) {
    return hyNegotiationProtocolStates(value, NegotiationProtocolStates);
  };
});

function hyNegotiationProtocolStates(value, NegotiationProtocolStates) {
  switch (value) {
    case NegotiationProtocolStates.DRAFT:
      return 'Utkast';
    case NegotiationProtocolStates.SAVED:
      return 'Sparad';
    case NegotiationProtocolStates.CONFIRMED:
      return 'Bekräftad';
    case NegotiationProtocolStates.SIGNED:
      return 'Signerad';
  }
}

})();