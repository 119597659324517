(function () {
'use strict';

angular.module('hyresgastApp').controller('ShowAgreementDocumentsCtrl', ShowAgreementDocumentsCtrl);

function ShowAgreementDocumentsCtrl($scope, $routeParams, $uibModal, Flash, ValidFileTypes, Agreement, HyUtils,
  AttachmentTypes,
  ErrorHandler) {
  var vm = this;
  vm.attachmentTypes = AttachmentTypes;
  vm.deleteAgreementFile = deleteAgreementFile;
  vm.addAgreementFile = addAgreementFile;
  vm.downloadAgreementFile = downloadAgreementFile;

  var parentCtrlVM = $scope.$parent.vm;

  /////////

  function addAgreementFile() {
    let negotiationId = $routeParams.negotiationId;
    let agreementId = $routeParams.id;
    let settings = {
      title: 'Ladda upp ett dokument',
      uploadUrl: negotiationId
        ? `/api/negotiations/${negotiationId}/agreements/${agreementId}/documents`
        : `/api/agreements/${agreementId}/documents`,

      // valid extension to upload
      validFileTypes: ValidFileTypes,

      // a file is associated with one type
      types: [vm.attachmentTypes.AGREEMENT_ATTACHMENT,
        vm.attachmentTypes.NEGOTIATION_ATTACHMENT,
        vm.attachmentTypes.DRAFT_AGREEMENT],

      typeTitle: 'Välj typ av underlag'
    };

    var modalInstance = $uibModal.open({
      animation: true,
      ariaLabelledBy: 'modal-title-top',
      ariaDescribedBy: 'modal-body-top',
      size: 'sm',
      templateUrl: 'components/file-upload-ex-modal/file-upload-ex-modal.html',
      controller: 'FileUploadExModalCtrl',
      controllerAs: 'vm',
      windowClass: 'modal-content--white-border',
      resolve: {
        settings: function() {
          return settings;
        }
      }
    });

    modalInstance.result.then(function(result) {
      parentCtrlVM.agreement.documents.push(...result);
      parentCtrlVM.agreement.numDocuments = result.agreementObj ? result.agreementObj.numDocuments : 0;
      if (result.type === 12) {
        parentCtrlVM.agreement.blobName = result.name;
        parentCtrlVM.agreement.fileName = result.filename;
        parentCtrlVM.agreement.fileUploadedByUserParty = result.agreementObj ? result.agreementObj.fileUploadedByUserParty : null;
        //parentCtrlVM.saveAgreement(); // TODO: remove after backend update signed = true when uploading
        Flash.set('Lyckades lägga till signerad överenskommelse', 'success');
      }
    });
  }

  // removes the signed uploaded file and sets signed bool to false
  function deleteAgreementFile(file) {
    var modalInstance = $uibModal.open({
      animation: true,
      ariaLabelledBy: 'modal-title-top',
      ariaDescribedBy: 'modal-body-top',
      size: 'xsm',
      templateUrl: 'components/modal/confirmation/confirmationModal.html',
      controller: 'ConfirmationModalCtrl',
      controllerAs: 'vm',
      windowClass: 'modal-content--secondary'
    });

    modalInstance.result.then(function() {
      parentCtrlVM.Resource.deleteDocument({
        negotiationId: $routeParams.negotiationId,
        agreementId: $routeParams.id,
        id: file.name
      })
        .$promise.then(function(response) {
          let index = parentCtrlVM.agreement.documents.indexOf(file);
          parentCtrlVM.agreement.documents.splice(index, 1);
          if (file.type === 12) {
            parentCtrlVM.agreement.signed = false;
            parentCtrlVM.agreement.blobName = null;
          }
          parentCtrlVM.agreement.numDocuments = response.numDocuments;
          Flash.set('Lyckades att ta bort dokument', 'success');
        })
        .catch(new ErrorHandler('Misslyckades att ta bort dokument.'));
    });
  }

  function downloadAgreementFile(file) {
    parentCtrlVM.Resource.downloadDocument({
      negotiationId: $routeParams.negotiationId,
      agreementId: $routeParams.id,
      id: file.name
    }).$promise.then(function(response) {
      HyUtils.spitOutAttachment(response.data, file.filename, 'application/pdf');
      vm.saving = false;
    });
  }
}

})();