(function () {
'use strict';

angular.module('hyresgastApp').controller('ListOrganizationsPendingCtrl', ListOrganizationsPendingCtrl);

function ListOrganizationsPendingCtrl($scope, $location, RequestedAccount, Flash, Auth, Office, ErrorHandler) {
  var vm = this;
  vm.Auth = Auth;
  vm.user = {};
  vm.organizations = [];
  vm.getOrganizations = getOrganizations;
  vm.gotoShow = gotoShow;
  vm.pageLimit = 30;
  vm.pageOffset = 0;
  vm.sortColumn = $location.search().order || 'officeName'; // default
  vm.sortDirection = $location.search().direction || 'ASC'; // default

  vm.listColumns = [
    { title: 'Kontor', column: 'officeName' }, 
    { title: 'Företagsnamn', column: 'name' },
    { title: 'Organisationsnummer', column: 'organizationNumber' },
    { title: 'Förnamn', column: 'firstname' }, 
    { title: 'Efternamn', column: 'lastname' },
    { title: 'E-post', column: 'email' }
  ];

  getOrganizations();

  $scope.$on('$routeUpdate', function() {
    if ($location.search().order) {
      // reset offset when sorting
      vm.pageOffset = 0;
      getOrganizations();
    }
  });

  ////////////

  function gotoShow(id) {
    $location.search({});
    $location.path('/requested-accounts/organization/' + id + '/approve');
  }

  function getOrganizations(resetOffset) {
    vm.ready = false;
    if (resetOffset) {
      vm.pageOffset = 0;
    }
    return RequestedAccount.query({
      limit: vm.pageLimit,
      offset: vm.pageOffset,
      order: vm.sortColumn,
      direction: vm.sortDirection,
      'search[officeName]': vm.inputTextOfficeName,
      'search[name]': vm.inputTextOrgName,
      'search[organizationNumber]': vm.inputTextOrgNumber,
      'search[firstname]': vm.inputTextFirstName,
      'search[lastname]': vm.inputTextLastName,
      'search[email]': vm.inputTextEmail
    })
      .$promise.then(function(data) {
        vm.organizations = data.rows;
        vm.totalCount = data.count;
      })
      .catch(ErrorHandler('Misslyckades att hämta organisationer.'))
      .finally(function() {
        vm.ready = true;
      });
  }
}

})();