(function () {
'use strict';

// @desc Part of the layout
//
// @example
//   <body>
//      <hy-navigation></hy-navigation>
//   </body>

angular.module('hyresgastApp').directive('hyNavigation', navigation);

function navigation($location, $window, $rootScope, $interval, $timeout, Auth, User, Roles, Flash) {
  var directive = {
    link: link,
    templateUrl: 'components/navigation/navigation.html',
    restrict: 'E'
  };
  return directive;

  // eslint-disable-next-line no-unused-vars
  function link(scope, element, attrs) {
    scope.Auth = Auth;
    scope.isActive = isActive;
    scope.isOrganizationAdmin = isOrganizationAdmin;
    scope.logout = logout;

    scope.$window = $window;

    scope.counters = {
      freeAgreements: 0,
      pendingOrganizations: 0,
      pendingUsers: 0,
      negotiationCalls: 0,
    }
    var stop;

    $timeout(() => {
      startSync();
    }, 500);

    ////////////

    function isActive(viewLocation) {
      return viewLocation === $location.path();
    }

    function isOrganizationAdmin(user) {
      if (!user || !user.roleByOrganization) {
        return false;
      }
      var isAdmin = false;
      for (var key in user.roleByOrganization) {
        // eslint-disable-next-line no-prototype-builtins
        if (!user.roleByOrganization.hasOwnProperty(key)) {
          continue;
        }
        if (user.roleByOrganization[key] === Roles.ADMIN) {
          isAdmin = true;
          break;
        }
      }
      return isAdmin;
    }

    function logout() {
      Auth.destroy().then(
        function() {
          $location.path('/'); // clear sensitive content on page

          // redirect to login page
          Flash.set('Du har blivit utloggad.', 'success');
          $rootScope.$broadcast('event:auth-loginRequired', null);
        },
        function() {}
      );
    }

    function getMyCounters() {
      if (!Auth.currentUser()) {
        return;
      }
      User.getMyCounters({}).$promise.then(response => {
        Object.assign(scope.counters, response);
      });
    }

    function startSync() {
      getMyCounters();
      // Don't start a new update counter if we are already counting
      if (angular.isDefined(stop)) return;
      stop = $interval(getMyCounters, 180000);
    }

    function stopSync() {
      if (angular.isDefined(stop)) {
        $interval.cancel(stop);
        // eslint-disable-next-line no-undefined
        stop = undefined;
      }
    }

    scope.$on('$destroy', function() {
      // Make sure that the interval is destroyed too
      stopSync();
    });

    // stop and start sync when i.e going to different tab in Chrome
    function handleVisibilityChange() {
      if ($window.document.hidden) {
        stopSync();
      } else {
        startSync();
      }
    }

    $window.document.addEventListener('visibilitychange', handleVisibilityChange, false);

    scope.$watch('Auth.currentUser()', function(newValue, oldValue) {
      // call sync once when a user signs in to update the counter directly
      // (instead of waiting on interval)
      if (newValue && !oldValue) {
        getMyCounters();
      }
    });

  }
}

})();