(function () {
/* eslint-disable no-undefined */
'use strict';

angular.module('hyresgastApp').filter('nationalNoControl', function() {
  return function(nationalNo) {
    return nationalNo === '0000' ? '📜' : nationalNo;
  };
});

angular.module('hyresgastApp').filter('nationalNoTitleControl', function() {
  return function(nationalNo) {
    return nationalNo === '0000' ? 'Unik adress' : '';
  };
});
})();