(function () {
'use strict';

angular.module('hyresgastApp').controller('SubreportCtrl', SubreportCtrl);

function SubreportCtrl(reportResult) {
  var vm = this;
  vm.reportResult = reportResult;

  vm.offset = 0;
  vm.limit = 10;
  vm.total = vm.reportResult.rows.length;

  vm.flipPage = () => {
    vm.rows = vm.reportResult.rows.slice(vm.offset, vm.offset + vm.limit);
  };

  vm.flipPage();
}
})();