(function () {
'use strict';
angular.module('hyresgastApp').controller('AlterRoleModalCtrl', AlterRoleModalCtrl);


function AlterRoleModalCtrl($uibModalInstance, possibleRoles, selectedRole) {

    var vm = this;

    vm.possibleRoles = possibleRoles;
    vm.selectedRole = selectedRole;
    vm.selectRole = role => { vm.selectedRole = role; };
    vm.ok = () => { $uibModalInstance.close(vm.selectedRole); };
}
})();