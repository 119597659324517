(function () {
'use strict';

// @desc
//   Show messages directly under the navigation. Such as
//   error or success messages. It supports the types
//   'default', 'error, 'success' and 'fullscreen' hcing
//   corresponding CSS styling
//
// @example
//   <main>
//      <hy-flash></hy-flash>
//      <ng-view class="main-content"></ng-view>
//   </main>

angular.module('flash', ['flashService']).directive('hyFlash', flash);

function flash($anchorScroll, $document, $timeout, Flash) {
  var directive = {
    restrict: 'E',
    templateUrl: 'components/flash/flash.html',
    scope: {},
    link: link
  };

  return directive;

  ////////////

  function link(scope, element, attrs) {
    var modifiers = ['flash--visible', 'flash--default', 'flash--success', 'flash--error'];

    scope.Flash = Flash;

    var mainContent = angular.element($document[0].querySelector('.main-content'));

    scope.close = function() {
      Flash.hide();
    };

    scope.$watch(
      'Flash.get()',
      function(newValue) {
        element.removeClass(modifiers.join(' '));

        if (newValue) {
          $anchorScroll(); // scroll to top
          element.addClass('flash--visible');
          element.addClass('flash--' + newValue.type);
        }
      },
      true
    );
  }
}

})();