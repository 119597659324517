(function () {
'use strict';

angular.module('hyresgastApp').controller('PropertyShowCtrl', PropertyShowCtrl);

function PropertyShowCtrl(
  $scope, $location, $routeParams,
  Flash, HyUtils, RealEstate, SubPage,
  Auth, ErrorHandler) {
  var vm = this;
  vm.isHgf = Auth.isHgfUser();

  vm.tablist = [
    {
      title: 'Aktuella förhandlingar',
      path: '/properties/' + $routeParams.id
    },
    {
      title: 'Avslutade förhandlingar',
      path: '/properties/' + $routeParams.id + '?view=finished-negotiations'
    },
    {
      title: 'Lägenheter i fastigheten',
      path: '/properties/' + $routeParams.id + '?view=apartments'
    }
  ];
  if (vm.isHgf) {
    vm.tablist.push({
      title: 'Anteckningar',
      path: '/properties/' + $routeParams.id + '?view=blog'
    });
    vm.tablist.push({
      title: 'Avtal',
      path: '/properties/' + $routeParams.id + '?view=agreements'
    });
    vm.tablist.push({
      title: 'Interna dokument',
      path: '/properties/' + $routeParams.id + '?view=artifacts'
    });
  }

  vm.gotoNegotiation = gotoNegotiation;

  $scope.$on('$routeUpdate', function() {
    // avoid unecessary calling of getCurrentSubpage() when sorting
    if (!$location.search().order) {
      // reset offset when sorting
      getCurrentSubpage();
    }
  });

  getCurrentSubpage();
  getProperty();

  ////////////

  function getProperty() {
    return RealEstate.get({ id: $routeParams.id })
      .$promise.then(function(response) {
        vm.property = response;
        let propertyIds = [...vm.property.newPropertyIds, parseInt($routeParams.id)];
        getStatusCount(propertyIds);
        setFormattedPropsForTooltip();
      })
      .catch(ErrorHandler('Misslyckades att hämta fastigheten.'))
      .finally(function() {
        vm.ready = true;
      });
  }

  function setFormattedPropsForTooltip() {
    if(!vm.property) return;

    if(vm.property.owners) {
      vm.formattedOwners = vm.property.owners.map(function(owner) {
        return owner.name + ', orgnr: ' + owner.organizationNumber;
      });
    }

    if(vm.property.landlords) {
      vm.formattedLandlords = vm.property.landlords.map(function(landlord) {
        return landlord.name;
      });
    }
    if(vm.property.address || vm.property.addresses) {
      vm.formattedAddresses = ([vm.property.address]
        .concat(vm.property.addresses))
        .filter(x => x)
        .map(a => HyUtils.renderStreetAddress(a));
    }
  }

  function getStatusCount(propertyIds) {
    RealEstate.queryPropertiesStatusCount({ propertyIds })
      .$promise.then((response) => {
        let findStatus = k => {
          // eslint-disable-next-line eqeqeq
          let o = response.statusCount.find(a => a.propertyId == $routeParams.id && a[k]);
          return o ? o[k] : 0;
        };
        vm.statusCount = {
          p: findStatus('p'),
          i: findStatus('i'),
        };
      });
  }

  function getCurrentSubpage() {
    var defaultSubPage = 'active-negotiations';
    // create a validation list against manually change of URL '?view=' parameter
    var whiteList = vm.isHgf
      ? ['finished-negotiations', 'apartments', 'blog', 'artifacts', 'agreements']
      : ['finished-negotiations', 'apartments'];
    vm.currentSubpage = SubPage.set($routeParams.view, whiteList, defaultSubPage);
  }

  function gotoNegotiation(negotiationId) {
    $location.search({});
    $location.path('/negotiations/' + negotiationId);
  }

}

})();