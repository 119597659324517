(function () {
'use strict';

angular.module('hyresgastApp').directive('hyMicroblog', hyMicroblog);

function hyMicroblog(Microblog, HyUtils, Auth) {
  var directive = {
    restrict: 'E',
    templateUrl: 'components/microblog/microblog.html',
    scope: {
      objectId: '=',
      objectType: '=',
    },
    // eslint-disable-next-line no-unused-vars
    link: (scope, element, attrs) => {
      scope.Auth = Auth;
      scope.gd = twit => new Date(twit.updatedAt ? twit.updatedAt : twit.createdAt);

      scope.sortTwits = function() {
        scope.twits.sort((a, b) => scope.gd(b) - scope.gd(a));
      }

      scope.hover = function(twit) {
        for(let t of scope.twits) {
          t.hovered = false;
        }
        twit.hovered = true;
      }

      scope.saveNew = () => {
        Microblog.create({
          objectType: scope.objectType,
          objectId: scope.objectId,
          text: scope.newTwit.text,
        }).$promise.then(response => {
          scope.newTwit.text = '';
          scope.twits.push(response);
          scope.sortTwits();
        });
      };

      scope.update = twit => {
        Microblog.update({
          id: twit.id,
          text: twit.text,
        }).$promise.then(() => {
          twit.updatedAt = new Date();
          twit.edited = false;
          scope.sortTwits();
        });
      };

      scope.destroy = twit => {

        let message = `Vill du verkligen radera anteckningen?
        Radera inte andras anteckningar utan att informera berörd person.
        Väljer du att radera anteckningen tas den bort från databasen och kan inte återställas.`;

        HyUtils.showDefaultModal('WarningConfirmationModalCtrl',
          'components/modal/confirmation/warningConfirmationModal.html',
          {
            message,
            button: 'Ta bort',
            critical: true,
          },
          // eslint-disable-next-line no-unused-vars
          result => {

            Microblog.destroy({ id: twit.id })
              .$promise.then(() => {
                scope.twits = scope.twits.filter(x => x.id !== twit.id);
                scope.sortTwits();
              });
          }, null, null, { size: 'md' });
      };

      scope.getTwits = function () {
        Microblog.list({
          objectId: scope.objectId,
        }).$promise.then(response => {
          scope.twits = response.rows;
          scope.sortTwits();
        });
      }

      scope.getTwits();
    }
  }
  return directive;
}
})();