(function () {
'use strict';

angular.module('hyresgastApp').filter('rentalLevelTypeToDescription', function() {
  return function(value) {
    switch (value) {
      case '0':
        return 'Förändring i procent %';
      case '1':
        return 'Förändring i kr/mån';
      case '2':
        return 'Helt ny hyra i kr/mån';
      default:
        return 'Typ ej angivet';
    }
  };
});

})();