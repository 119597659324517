(function () {
'use strict';

angular.module('hyresgastApp').controller('ListUserNegotiationCtrl', ListUserNegotiationCtrl);

function ListUserNegotiationCtrl($scope, $location, $routeParams, SubPage) {
  var vm = this;

  vm.tablist = [
    { title: 'Aktuella', path: '/users/' + $routeParams.id + '/negotiations' },
    { title: 'Avslutade', path: '/users/' + $routeParams.id + '/negotiations?view=finished' },
    { title: 'Senaste ändringar', path: '/users/' + $routeParams.id + '/negotiations?view=last-changes' },
    { title: 'Strandade', path: '/users/' + $routeParams.id + '/negotiations?view=blocked' }
  ];

  getCurrentSubpage();

  $scope.$on('$routeUpdate', function() {
    // avoid unecessary calling of getCurrentSubpage(); when sorting
    if (!$location.search().order) {
      // reset offset when sorting
      getCurrentSubpage();
    }
  });

  /////////

  function getCurrentSubpage() {
    var defaultSubPage = 'current';
    // create a validation list against manually change of URL '?view=' parameter
    var whiteList = ['finished', 'last-changes', 'blocked'];
    vm.currentSubpage = SubPage.set($routeParams.view, whiteList, defaultSubPage);
  }
}

})();