(function () {
'use strict';

angular.module('hyresgastApp').controller('ReceivingOfficeModalCtrl', ReceivingOfficeModalCtrl);

function ReceivingOfficeModalCtrl($uibModalInstance, ErrorHandler,
  Auth, Office,
  NegotiationTypes, NegotiationCallStates, NegotiationLandlordSides,
  negotiationCallObj) {
  var vm = this;
  vm.isHgf = Auth.isHgfUser();
  vm.editOfficeOnly = vm.isHgf;

  vm.canSave = () => vm.editOfficeOnly
    ? vm.selectedOffice && !!vm.selectedOffice.id
    // eslint-disable-next-line eqeqeq
    : vm.selectedOffice && vm.selectedOffice.id && vm.checkOne && vm.checkTwo && vm.landlordSide != -1;

  vm.ok = ok;
  vm.cancel = cancel;

  vm.LandlordSides = NegotiationLandlordSides;
  vm.landlordSide = -1;

  vm.NegotiationTypes = NegotiationTypes;
  vm.negotiationCallType = negotiationCallObj.type;

  vm.showCheckTwo = false;
  if (vm.negotiationCallType === vm.NegotiationTypes.STANDARD_PROPERTY ||
      vm.negotiationCallType === vm.NegotiationTypes.STANDARD_APARTMENT ||
      vm.negotiationCallType === vm.NegotiationTypes.REBUILD_PROPERTY ||
      vm.negotiationCallType === vm.NegotiationTypes.REBUILD_PROPERTY_2 ||
      vm.negotiationCallType === vm.NegotiationTypes.REBUILD_APARTMENT) {
    vm.showCheckTwo = true;
    vm.checkTwo = false;
  } else {
    vm.checkTwo = true;
  }

  if (negotiationCallObj.state === NegotiationCallStates.WAITING_EXT) {
    vm.editMode = true;
    vm.checkOne = true;
    vm.selectedOffice = {
      id: negotiationCallObj.office.id,
      name: negotiationCallObj.office.name
    };
  }

  queryOffice();

  ////////////

  function queryOffice() {
    return Office.query().$promise.then(function(response) {
      vm.offices = response;
    })
      .catch(ErrorHandler('Misslyckades att hämta kontor.'));
  }

  function ok() {
    $uibModalInstance.close({
      selectedOffice : vm.selectedOffice,
      landlordSide : vm.editOfficeOnly ? NegotiationLandlordSides.LANDLORD : vm.landlordSide
    });
  }

  function cancel() {
    $uibModalInstance.dismiss('cancel');
  }
}

})();