(function () {
'use strict';

angular.module('hyresgastApp').controller('EditCommentModalCtrl', EditCommentModalCtrl);

function EditCommentModalCtrl($uibModalInstance, comment, multipleComments) {
  var vm = this;

  vm.multipleComments = multipleComments;
  vm.hadSomething = !!comment || multipleComments;
  vm.comment = comment;

  vm.delete = () => $uibModalInstance.close({ delete: true });
  vm.ok = () => $uibModalInstance.close({ comment: vm.comment });
  vm.cancel = () => $uibModalInstance.dismiss();
}
})();