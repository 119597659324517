(function () {
'use strict';

angular.module('hyresgastApp').controller('EditMotivationModalCtrl', EditMotivationModalCtrl);

function EditMotivationModalCtrl($uibModalInstance, propertiesArr, StringUtils) {
  var vm = this;
  vm.cancel = cancel;
  vm.add = add;
  vm.StringUtils = StringUtils;

  vm.demand = {
    unit: '0',
    numericValue: null,
    stringValue: null,
    motivation: ''
  };

  var firstCheckBox = propertiesArr[0];
  // check if all selected properties have same demand and motivation.
  // If so => run in EDIT mode.
  // compare in relation to the first in the group of selected checkboxes
  var checkForSameDemandAndMotivation = propertiesArr.every(function(property) {
    return (
      property.demand && property.demand.unit &&
      property.demand.unit === firstCheckBox.demand.unit &&
      property.demand.numericValue === firstCheckBox.demand.numericValue &&
      property.demand.stringValue === firstCheckBox.demand.stringValue &&
      property.demand.motivation === firstCheckBox.demand.motivation
    );
  });

  if (checkForSameDemandAndMotivation) {
    vm.demand = {
      unit: firstCheckBox.demand.unit,
      numericValue: firstCheckBox.demand.numericValue,
      stringValue: firstCheckBox.demand.stringValue,
      motivation: firstCheckBox.demand.motivation
    };
  }

  function add() {
    if (vm.demand.numericValue && typeof vm.demand.numericValue === 'string') {
      vm.demand.numericValue = Number(vm.demand.numericValue.replace(/ /g, '').replace(/,/g, '.'));
    }
    $uibModalInstance.close(vm.demand);
  }

  function cancel() {
    $uibModalInstance.dismiss('cancel');
  }
}

})();