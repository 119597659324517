(function () {
'use strict';

angular.module('hyresgastApp').directive('hyArrayDisplay', hyArrayDisplay);

function hyArrayDisplay($sce) {
  var directive = {
    restrict: 'E',
    templateUrl: 'components/array-display/array-display.html',
    scope: {
      model: '=',
    },
    // eslint-disable-next-line no-unused-vars
    link: (scope, element, attrs) => {
      const maxRows = 18;
      // to avoid overflow issues
      const maxDisplayItems = 120;
      
      function insertSoftHyphens(text, length) {
        return text.replace(new RegExp(`(.{${length}})`, 'g'), '$1&shy;');
      }
      
      let split = newValue => {
        if (newValue instanceof Array) {          
          const wordMaxLengthBeforeHyphen = 24;
          const totalItems = newValue.length;
          let omittedCount = 0;
          scope.first = newValue[0];
          
          const restItemsCount = Math.min(maxDisplayItems, totalItems) - 1;
          omittedCount = totalItems - 1 - restItemsCount;
    
          scope.columnCount = Math.min(5, Math.ceil((restItemsCount) / maxRows));

          let restHtml = '';
          if (restItemsCount > 0) {
            const displayedItemsCount = restItemsCount + 1
            if (omittedCount > 0) {
              restHtml += `<div style="margin-bottom: 0.25rem"><strong>Visar ${displayedItemsCount} av ${totalItems - 1}</strong></div>`;
            }
            restHtml += `<div style='column-count: ${scope.columnCount}; text-align: left;'>`;
    
            for (let i = 1; i <= displayedItemsCount; i++) {
              let style = '';

              if(newValue[i]){
                if (newValue[i].length <= wordMaxLengthBeforeHyphen) {
                  style = 'white-space: nowrap;';
                }else{              
                  newValue[i] = insertSoftHyphens(newValue[i], wordMaxLengthBeforeHyphen);
                  style = `
                  overflow-wrap: break-word;
                  word-wrap: break-word;
                  `;
                }
                restHtml += `<span style="${style}">${newValue[i]}</span><br/>`;
              }
            }

            restHtml += '</div>';
    
          }
          scope.rest = $sce.trustAsHtml(restHtml);
        } else {
          scope.first = newValue;
          scope.rest = '';
        }
      };

      scope.$watch('model', newValue => {
        if (newValue) {
          split(newValue);
        }
      });
    },
  };
  return directive;


}
})();