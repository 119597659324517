(function () {
/* eslint-disable no-undefined */
'use strict';

angular.module('hyresgastApp').directive('hyCombobox', combobox);

function combobox() {
  var directive = {
    scope: {
      items: '<',
      textProperty: '@',
      prompt: '@',
      stickyPrompt: '<',
      currentItemChanged: '&',
      currentItem: '=?',
    },
    restrict: 'E',
    templateUrl: 'components/combobox/combobox.html',
    link: link
  };

  return directive;

  function link(scope) {
    scope.selectedItem = scope.currentItem;
    scope.$watch('currentItem', function(newVal, oldVal) {
      if (newVal !== oldVal) {
        scope.selectedItem = newVal;
      }
    });
    scope.selectItem = function(item) {
      scope.selectedItem = item;
      scope.currentItemChanged({selection: item});
      scope.currentItem = item;
    }
  }
}
})();