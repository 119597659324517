(function () {
'use strict';

// @desc upload files with a nice progressbar and optionally validate files
// @example <hy-file-upload></hy-file-upload>

angular
  .module('fileUpload', ['angularFileUpload', 'fileValidatorPolling', 'flash'])
  .directive('hyFileUpload', fileUpload);

function fileUpload($timeout, FileValidatorPolling, FileUploader, Flash, LocalApiPort) {
  var directive = {
    restrict: 'A',
    scope: {
      blobId: '=', // A successful upload will result in a blob id
      metadata: '=', // Metadata (about the uploaded file) returned from backend
      validFileTypes: '@', // A space separated string 'jpeg gif'
      maxFileSize: '@',
      uploadUrl: '@',
      uploader: '=',
      type: '=?', // Optional type as a parameter
      state: '=?', // State as a parameter
      errorInvalidFile: '=?', // Error message will show in modal window, if upload is from modal window

      // Attempting to generalize the uploader directive by exposing the errors to the parent controller.
      // $watch the validationErrors from parent controller to decide how to present them (ie. a modal).
      validationErrors: '=?',
      validationResponse: '=' // TODO probably merge validationErrors and validationResponse (remember to update $watch in parent controller)
    },
    link: link
  };

  return directive;

  function link(scope, element, attrs) {
    // For upload to work on localhost
    var uploadUrl;
    if (window.location.hostname === 'localhost') {
      uploadUrl = 'http://localhost:' + LocalApiPort + scope.uploadUrl;
    } else {
      uploadUrl = scope.uploadUrl;
    }

    // Some uploaded files are parsed and validated by backend,
    // if validateUpload attribute exist, a polling service started
    var validateUpload = 'validateUpload' in attrs;

    // Choose a file and upload directly
    var autoUpload = 'autoUpload' in attrs;

    var disableFlash = 'disableFlash' in attrs;

    var uploader = new FileUploader({
      queueLimit: 1,
      withCredentials: true,
      url: uploadUrl
    });

    scope.uploader = uploader;

    scope.$on('$destroy', function() {
      uploader.cancelUpload();
    });

    ////////////
    if (scope.validFileTypes !== '') {
      uploader.filters.push({
        name: 'fileTypeFilter',
        fn: function(item /*{File|FileLikeObject}*/, options) {
          var type = '|' + item.name.slice(item.name.lastIndexOf('.') + 1) + '|';
          var extensions = '|' + scope.validFileTypes.split(' ').join('|') + '|'; // "foo bar" => "|foo|bar|"
          return extensions.indexOf(type.toLowerCase()) !== -1;
        }
      });
    }

    uploader.filters.push({
      name: 'fileSizeFilter',
      fn: function(item /*{File|FileLikeObject}*/, options) {
        if (item.size < parseInt(scope.maxFileSize)) {
          return true;
        } else {
          return false;
        }
      }
    });

    uploader.onWhenAddingFileFailed = function(item /*{File|FileLikeObject}*/, filter, options) {
      if (filter.name === 'fileTypeFilter') {
        if (disableFlash) {
          // If in module, disable Flash error message
          scope.errorInvalidFile = 'Felaktigt filformat - försök igen';
        } else {
          Flash.set('Felaktigt filformat', 'error');
        }
      }

      if (filter.name === 'fileSizeFilter') {
        if (disableFlash) {
          scope.errorInvalidFile = 'Filen är för stor - försök igen';
        }
        Flash.set('Filen är för stor - försök igen', 'error');
      }
    };

    uploader.onAfterAddingFile = function(fileItem) {
      uploader.currentFileName = fileItem.file.name;
      scope.validationErrors = null;
      scope.errorInvalidFile = null;
      if (autoUpload) {
        uploader.uploadAll();
      }
    };

    uploader.onInterruptedItem = function(fileItem, response, status, headers) {
      Flash.set('Uppladdningsfel', 'error');
    };

    uploader.onSuccessItem = function(fileItem, response, status, headers) {
      var blobId = response.blobId;
      scope.metadata = response;

      // Backend is not returning lastModified on create but later
      // when the file is queried, thus added for consistency
      scope.metadata.lastModified = new Date();

      // API will parse the uploaded file content which might take
      // up to ~30s
      if (validateUpload) {
        uploader.isValidating = true;
        var validationUrl = uploadUrl + '/' + blobId + '/validate';
        FileValidatorPolling.start(validationUrl)
          .then(
            function(response) {
              if (response.status === 'ok') {
                scope.blobId = blobId;
                scope.validationResponse = response;
                scope.validationResponse.blobId = blobId;
              } else if (response.status === 'error') {
                // $watch validationErrors from parent controller
                // and show i.e in a modal
                scope.validationErrors = response;
                scope.validationErrors.blobId = blobId;
                uploader.cancelUpload();
              }
            },
            function(reason) {
              Flash.set('Uppladdningsfel', 'error');
            }
          )
          .finally(function() {
            uploader.isValidating = false;
          });
      } else {
        scope.blobId = response.blobId; // this flow without validation is not currently used
      }
    };

    uploader.onBeforeUploadItem = function(fileItem) {
      if (scope.type) {
        fileItem.url = fileItem.url + '?type=' + scope.type;
      }
      if (scope.state) {
        fileItem.url = fileItem.url + '?state=' + scope.state;
      }
    };

    uploader.onErrorItem = function(fileItem, response, status, headers) {
      Flash.set('Uppladdningsfel', 'error');
    };

    uploader.onCancelItem = function(fileItem, response, status, headers) {
      uploader.clearQueue();
    };

    uploader.cancelUpload = function() {
      FileValidatorPolling.stop(); // might be running
      uploader.isValidating = false;
      scope.blobId = null;
      uploader.currentFileName = null;
      uploader.cancelAll();
      uploader.clearQueue();
    };
  }
}

})();