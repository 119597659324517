(function () {
'use strict';

angular.module('hyresgastApp').directive('hyStdDisplay', hyStdDisplay);

function hyStdDisplay() {
  var directive = {
    restrict: 'E',
    templateUrl: 'components/std-display/std-display.html',
    scope: {
      model: '@',
    },
    // eslint-disable-next-line no-unused-vars
    link: (scope, element, attrs) => {
      scope.getCost = () => {
        let objects = JSON.parse(scope.model);
        return objects.map(o => o.value).reduce((acc, el) => (acc += el), 0);
      };

      scope.now = new Date();

      scope.getTitle = () => {
        let objects = JSON.parse(scope.model);
        let getRow = el => `
          <span class='object-name'>
            ${el.name} ${(el.quantity && el.quantity > 1) ? `x${el.quantity}`: ''}
            ${(el.expirationDate && el.expirationDate < scope.now) ? '<b class="red-text">!</b>' : ''}
          </span>
          <span class='object-value'>
            ${el.value}
          </span> 
          <br/>`;
        return objects.reduce((acc, el) => (acc += getRow(el)), '');
      };
    },
  };
  return directive;


}
})();