(function () {
'use strict';

// @desc
//   Manage deletion blocking users belonging to an organization.
//
//
// @example
//   <list-blocking-negotiated-apartments
//      organization="vm.organization">
//      list="vm.negotiatedApartments">
//      cancel="vm.cancel()">
//   </list-blocking-negotiated-apartments>

angular.module('hyresgastApp').directive('listBlockingNegotiatedApartments', listBlockingNegotiatedApartments);

function listBlockingNegotiatedApartments($uibModal, Flash, UserByOrganization, Auth, HyUtils, Roles) {
  var directive = {
    restrict: 'E',
    templateUrl: 'resources/organization/show/blocking-data/list-blocking-negotiated-apartments.html',
    scope: {
      organization: '=',
      list: '=',
      cancel: '='
    },
    link: link
  };

  return directive;

  function link(scope) {
    scope.delete = destroy;
    scope.Auth = Auth;
    scope.roles = Roles;
    ////////////
    function destroy(negotiatedApartment) {
      HyUtils.askQuestion('Are you sure you want to delete this negotiatedApartment?', null, 2)
        .then(pRes => {
          if(pRes === 'Ja') {
            //TODO: DELETE NEGOTIATIONS APARTMENTS
          }
        });
    }
  }
}



})();