(function () {
'use strict';

angular.module('hyresgastApp').controller('EditMeetingModalCtrl', EditMeetingModalCtrl);

function EditMeetingModalCtrl(
  $uibModalInstance,
  $scope,
  $routeParams,
  $location,
  Auth,
  DateLocal,
  Flash,
  Negotiation,
  Meeting,
  meetingObj,
  negotiationObj,
  User,
  ErrorHandler
) {
  var vm = this;
  vm.meeting = meetingObj;
  vm.negotiationListReady = true;

  if (vm.meeting === null && negotiationObj !== null) {
    vm.meeting = {
      negotiationId: negotiationObj.id,
      participants: [],
      negotiations: []
    };

    // When creating a new meeting and the modal is opened on top of a negotiation page =>
    // populate the participants and the current negotiation.
    vm.meeting.participants = negotiationObj.hgfParticipants.concat(negotiationObj.externalParticipants);

    var currentParticipantUserIds = vm.meeting.participants.map(function(participant) {
      return participant.id;
    });
    var currentNegotiation = {
      id: negotiationObj.id,
      name: negotiationObj.name,
      participantUserIds: currentParticipantUserIds,
      state: negotiationObj.state,
      type: negotiationObj.type,
      caseNumber: negotiationObj.caseNumber
    };
    vm.meeting.negotiations.push(currentNegotiation);
    // Time picker component should not be used with the same model
    // as the datepicker.
    // https://github.com/angular-ui/bootstrap/issues/5485
    vm.startAt = new Date();
    vm.startTimeAt = initDate(8);
    vm.endTimeAt = initDate(9);
    vm.negotiationList = [];
  } else if (vm.meeting === null && negotiationObj === null) {
    // for case when we come from negotiation table
    vm.meeting = {
      participants: [Auth.currentUser()],
      negotiations: []
    };
    vm.startAt = new Date();
    vm.startTimeAt = initDate(8);
    vm.endTimeAt = initDate(9);
  } else {
    vm.editMode = true;
    vm.meeting = angular.copy(meetingObj);
    vm.startAt = new Date(vm.meeting.startAt);
    vm.startTimeAt = new Date(vm.meeting.startAt);
    vm.endTimeAt = new Date(vm.meeting.endAt);
  }

  vm.save = save;
  vm.cancel = cancel;
  vm.addParticipant = addParticipant;
  vm.removeParticipant = removeParticipant;
  vm.addNegotiation = addNegotiation;
  vm.removeNegotiation = removeNegotiation;
  vm.isNegotiationSelected = isNegotiationSelected;
  vm.hasUserRelatedNegotiation = hasUserRelatedNegotiation;

  getNegotiationList(vm.meeting.participants);
  getNegotiationPartners();

  /////////

  function getNegotiationPartners() {
    return User.queryNegotiationPartners({}).$promise.then(function(response) {
      vm.users = response.rows;
      vm.users.push(Auth.currentUser());
    })
      .catch(ErrorHandler('Misslyckades att hämta data.'));
  }

  function initDate(hour) {
    var date = new Date();
    date.setHours(hour, 0, 0, 0);
    return date;
  }

  function setTime(date, time) {
    var to = new Date(date);
    to.setHours(time.getHours(), time.getMinutes(), 0, 0);
    return to;
  }

  function addParticipant() {
    var foundParticipant = vm.meeting.participants.filter(function(user) {
      return user.id === vm.selected.id;
    });

    // look through all participants to check if they had been added before or not
    if (foundParticipant.length === 0) {
      vm.meeting.participants.push(vm.selected);
      getNegotiationList(vm.meeting.participants);
    }

    // clear
    vm.selected = null;
  }

  function removeParticipant(participant) {
    var index = vm.meeting.participants.indexOf(participant);
    vm.meeting.participants.splice(index, 1);
    getNegotiationList(vm.meeting.participants); // update the list after delete a participant
  }

  function getNegotiationList(participants) {
    var participantsId = participants.map(function(item) {
      return item.id;
    });

    // don't make a query in case where there are no participants
    if (participantsId.length === 0) {
      vm.negotiationList = [];
      vm.meeting.negotiations = [];
      return;
    }
    vm.negotiationListReady = false;
    return Negotiation.queryNegotiationsByParticipant({
      'filter[userId]': participantsId
    }).$promise.then(function(response) {
      vm.negotiationList = response.rows;
      vm.negotiationListReady = true;

      // check if all selected negotiations exist in list of selectable negotiations in dropdown.
      // remove selected negotiations that are no longer selectable.
      vm.meeting.negotiations = vm.meeting.negotiations.filter(function(selectedNegotiation) {
        return vm.negotiationList.some(function(negotiation) {
          return negotiation.id === selectedNegotiation.id;
        });
      });

      // add participant information to vm.meeting.negotiations - it is missing when first loading
      // an existing meeting
      vm.meeting.negotiations = vm.meeting.negotiations.map(function(negotiation) {
        var selectableNegotiation = vm.negotiationList.filter(function(otherNegotiation) {
          return otherNegotiation.id === negotiation.id;
        })[0];
        negotiation.participantUserIds = selectableNegotiation.participantUserIds;
        return negotiation;
      });
    })
      .catch(ErrorHandler('Misslyckades att hämta förhandling.'));
  }

  function addNegotiation(negotiation) {
    var foundNegotiation = vm.meeting.negotiations.filter(function(item) {
      return item.id === negotiation.id;
    });

    // look through all negotiations to check if they had been added before or not
    if (foundNegotiation.length === 0) {
      vm.meeting.negotiations.push(negotiation);
    }
  }

  function removeNegotiation(negotiation) {
    var index = vm.meeting.negotiations.indexOf(negotiation);
    vm.meeting.negotiations.splice(index, 1);
  }

  function isNegotiationSelected(negotiationId) {
    return Boolean(
      vm.meeting.negotiations.filter(function(negotiation) {
        return negotiation.id === negotiationId;
      })[0]
    );
  }

  function hasUserRelatedNegotiation(participant, selectedNegotiations) {
    function isOurParticipant(userId) {
      return userId === participant.id;
    }

    function isParticipantInNegotiation(negotiation) {
      return negotiation.participantUserIds && negotiation.participantUserIds.some(isOurParticipant);
    }

    return selectedNegotiations.some(isParticipantInNegotiation);
  }

  function save() {
    if (vm.meeting.negotiations.length > 0) {
      vm.saving = true;

      // Both dates use startAt as base because View does not have
      // an endAt datepicker.
      vm.meeting.startAt = setTime(vm.startAt, vm.startTimeAt);
      vm.meeting.endAt = setTime(vm.startAt, vm.endTimeAt);

      // Set endAt to tomorrow if endTime is less than startTime
      if (
        vm.endTimeAt.getHours() < vm.startTimeAt.getHours() ||
        (vm.endTimeAt.getHours() === vm.startTimeAt.getHours() &&
          vm.endTimeAt.getMinutes() < vm.startTimeAt.getMinutes())
      ) {
        vm.meeting.endAt.setDate(vm.meeting.endAt.getDate() + 1);
      }

      // Remove timezone part of date
      vm.meeting.startAt = DateLocal.fromUTCtoLocal(vm.meeting.startAt);
      vm.meeting.endAt = DateLocal.fromUTCtoLocal(vm.meeting.endAt);

      Meeting.save(vm.meeting).$promise.then(
        function(response) {
          vm.saving = false;
          Flash.set('Mötet har sparats', 'success');
          $uibModalInstance.close(response);
        })
        .catch(function(reason) {
          ErrorHandler('Misslyckades att spara mötet')(reason);
          $uibModalInstance.dismiss('error');
        });
    } else {
      Flash.set('Minst en förhandling måste väljas', 'error');
    }
  }

  function cancel() {
    $uibModalInstance.dismiss('cancel');
  }
}

})();