(function () {
'use strict';

angular.module('hyresgastApp').controller('ForgingRentlistModalCtrl', ForgingRentlistModalCtrl);

function ForgingRentlistModalCtrl(
  $scope,
  negotiation, negotiationRealEstates, isNewProperty, amend, apartmentCount,
  ManualChange, Negotiation, RealEstate, ErrorHandler,
  $uibModalInstance) {
  var vm = this;
  vm.rows = [];
  vm.negotiation = negotiation;
  vm.apartmentCount = apartmentCount;

  vm.selector = {
    incomingProperties: negotiationRealEstates,
    fgetProperties: RealEstate.queryPropertiesInMyNegotiations,
    fgetApartments: ManualChange.queryApartments,
  };

  vm.doneSelecting = () => {
    let rows = vm.selectedApartments.map(x => x.id);
    vm.busy = true;
    Negotiation.forgeRentList({
      amend,
      id: vm.negotiation.id,
      rows,
    }).$promise.then(result => {
      vm.busy = false;
      $uibModalInstance.close(result);
    }).catch(() => {
      new ErrorHandler('Misslyckades att välj lägenheter');
      $uibModalInstance.close();
    });
  };

  vm.cancel = () => { $uibModalInstance.dismiss('cancel'); };
}

})();