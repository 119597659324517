(function () {
'use strict';

angular.module('checkbox', [])
  .directive('hyCheckbox', () => {
    return {
      scope: {
        name: '@',
        title: '@',
        model: '=',
        ticked: '&',
        changed: '&',
        disabled: '=',
      },
      restrict: 'E',
      templateUrl: 'components/checkbox/checkbox.html',
    };
  });
})();