(function () {
'use strict';

angular.module('hyresgastApp').filter('hyCombinedNegotiationStates', function(CombinedNegotiationStates) {
  return function(value) {
    return hyCombinedNegotiationStates(value, CombinedNegotiationStates);
  };
});

function hyCombinedNegotiationStates(value, CombinedNegotiationStates) {
  switch (value) {
    case CombinedNegotiationStates.NEW:
      return 'Ny';
    case CombinedNegotiationStates.IN_PREPARATION:
      return 'Bereds';
    case CombinedNegotiationStates.IN_PROGRESS:
      return 'Pågår';
    case CombinedNegotiationStates.SETTLEMENT:
      return 'Uppgörelse';
    case CombinedNegotiationStates.BLOCKED_PRIVATE:
      return 'Strandad HN';
    case CombinedNegotiationStates.BLOCKED_PUBLIC:
      return 'Strandad HMK';
    case CombinedNegotiationStates.BLOCKED_NONE:
      return 'Strandad Egensatt';
    case CombinedNegotiationStates.BLOCKED_MEDIATOR:
      return 'Strandad Skiljeman HN';
    case CombinedNegotiationStates.PARTIALLY_BLOCKED_PRIVATE:
      return 'Delvis strandad HN';
    case CombinedNegotiationStates.PARTIALLY_BLOCKED_PUBLIC:
      return 'Delvis strandad HMK';
    case CombinedNegotiationStates.PARTIALLY_BLOCKED_NONE:
      return 'Delvis strandad Egensatt';
    case CombinedNegotiationStates.PARTIALLY_BLOCKED_MEDIATOR:
      return 'Delvis strandad Skiljeman HN';
    case CombinedNegotiationStates.FINISHED_OUTSIDE_PORTAL:
      return 'Felaktigt avslutad förhandling - Vänligen återöppna';
    case CombinedNegotiationStates.FINISHED:
      return 'Avslutad';
    default:
      return 'Alla statusar';
  }
}

})();