(function () {
'use strict';

angular.module('hyresgastApp').controller('ShowUserWelcomeCtrl', ShowUserWelcomeCtrl);

function ShowUserWelcomeCtrl(
  $location, $routeParams, $uibModal,
  FreeAgreement, Meeting, Negotiation, NegotiationCall, User,
  Auth, ErrorHandler, Flash,
  FeatureConfiguration, FreeAgreementTypes, NegotiationCallStates, Parties
) {
  var vm = this;
  vm.user = {};
  vm.myMeetings = [];
  vm.agreements = [];
  vm.NegotiationCallStates = NegotiationCallStates;
  vm.addMeeting = addMeeting;
  vm.parties = Parties;
  vm.Auth = Auth;
  vm.isOrphan = false;
  vm.FreeAgreementTypes = Object.values(FreeAgreementTypes)
    .reduce((dict, el) => { dict[el.code] = el.name; return dict; }, {});
  vm.FeatureConfiguration = FeatureConfiguration;

  vm.headerButtons = [
    {
      title: 'Skapa framställan',
      url: '/negotiation-calls/new',
      isVisible: () => (Auth && Auth.canDo('createCall')),
      icon: 'call'
    },
    {
      title: 'Skapa förhandling',
      url: '/negotiation/new-direct',
      isVisible: () => (Auth && Auth.canDo('createDirectNegotiation') && !vm.isOrphan),
      icon: 'negotiation'
    },
    {
      title: 'Skapa avtal',
      url: Auth.isHgfUser() ? '/agreements/new' : '/agreements?new',
      isVisible: () => Auth && Auth.canDo('createAgreement'),
      icon: 'agreement'
    },
  ];

  vm.dateOptions = {
    customClass: function(options) {
      return hasDayMeeting(options.date).length > 0 ? 'active-date' : '';
    }
  };

  getUser();
  getNegotiationCalls();
  getNegotiations();
  if(vm.FeatureConfiguration.ENABLE_MEETINGS) {
    getMyMeetings();
  }else{
    //set in order to work with html validation, when meetings are disabled
    vm.meetingsReady = true;
  }
  getMyAgreements();

  ////////////
  vm.gotoMeeting = gotoMeeting;
  vm.gotoNegotiationCall = gotoNegotiationCall;
  vm.gotoNegotiation = gotoNegotiation;
  function gotoMeeting(negotiationId, meetingId) {
    $location.path('/negotiations/' + negotiationId + '/meetings/' + meetingId);
  }

  function gotoNegotiationCall(negotiationCallId) {
    $location.path('/negotiation-calls/' + negotiationCallId);
  }

  function gotoNegotiation(negotiationId) {
    $location.path('/negotiations/' + negotiationId);
  }

  function hasDayMeeting(date) {
    return vm.myMeetings.filter(function(meeting) {
      return (
        meeting.startAt.getDate() === date.getDate() &&
        meeting.startAt.getMonth() === date.getMonth() &&
        meeting.startAt.getFullYear() === date.getFullYear()
      );
    });
  }

  function dateForNextMeeting() {
    var now = new Date();
    var futureMeetings = vm.myMeetings.filter(function(meeting) {
      return meeting.startAt > now;
    });
    if (futureMeetings.length > 0) {
      // copy the date object so the date picker won't change it behind us
      return new Date(futureMeetings[0].startAt.getTime());
    }
    return now;
  }

  function getMyMeetings() {
    vm.meetingsReady = false;
    return Meeting.queryByUser({
      userId: $routeParams.id,
      order: 'startAt',
      direction: 'ASC'
    })
      .$promise.then(function(data) {
        vm.myMeetings = data;
        vm.myMeetings.forEach(function(meeting) {
          meeting.startAt = new Date(meeting.startAt);
        });
        vm.dateOptions.initDate = dateForNextMeeting();
      })
      .catch(ErrorHandler('Misslyckades att hämta mina möten.'))
      .finally(function() {
        vm.meetingsReady = true;
      });
  }

  function getMyAgreements() {
    let userId = Auth.currentUser().id;
    FreeAgreement.list({
      userId,
      minaSidor: true
    }).$promise.then(result => {
      vm.agreements = result.agreements;
    });
  }

  vm.selectAgreement = agreementId => {
    $location.search({});
    $location.path('/agreements/' + agreementId);
  };

  vm.getMyDailyMeetings = getMyDailyMeetings;
  function getMyDailyMeetings(date) {
    vm.toggleMenu = true;
    vm.myDailyMeetings = hasDayMeeting(date);
  }

  function getUser() {
    vm.userReady = false;
    return User.get({ id: $routeParams.id })
      .$promise.then(function(response) {
        vm.user = response;
        let noOffices = !vm.user.offices || !Object.keys(vm.user.offices).length;
        if (vm.user.party === Parties.HGF && noOffices) {
          vm.isOrphan = true;
          Flash.set('Ditt användarkonto är inte kopplat till kontor. Det innebär begränsningar i Portalen', 'error');
        }
      })
      .catch(ErrorHandler('Misslyckades att hämta användaren.'))
      .finally(function() {
        vm.userReady = true;
      });
  }

  function getNegotiationCalls() {
    vm.negotiationCallsReady = false;

    return NegotiationCall.query({
      limit: 3,
      order: 'sentAt',
      direction: 'DESC',
      welcome: true
    })
      .$promise.then(function(data) {
        vm.negotiationCalls = data.rows;
      })
      .catch(ErrorHandler('Misslyckades att hämta framställan.'))
      .finally(function() {
        vm.negotiationCallsReady = true;
      });
  }

  function getNegotiations() {
    vm.negotiationsReady = false;
    return Negotiation.query({
      limit: 3,
      order: 'createdAt',
      direction: 'DESC',
      mine: true
    })
      .$promise.then(function(data) {
        vm.negotiations = data.rows;
      })
      .catch(ErrorHandler('Misslyckades att hämta förhandlingar.'))
      .finally(function() {
        vm.negotiationsReady = true;
      });
  }

  function addMeeting() {
    var modalInstance = $uibModal.open({
      animation: true,
      ariaLabelledBy: 'modal-title-top',
      ariaDescribedBy: 'modal-body-top',
      size: 'lg',
      index: 1075, // position above tooltip
      templateUrl: 'resources/meeting/edit/edit-meetings-modal.html',
      controller: 'EditMeetingModalCtrl',
      controllerAs: 'vm',
      windowClass: 'modal-content--white-border',
      backdrop: 'static',
      resolve: {
        meetingObj: function() {
          return null;
        },
        negotiationObj: function() {
          return null;
        }
      }
    });
    modalInstance.result.then(() => getMyMeetings());
  }
}

})();