(function () {
'use strict';

angular.module('hyresgastApp').controller('RecallItemsCtrl', RecallItemsCtrl);

function RecallItemsCtrl(
  $scope,
  $uibModal,
  $uibModalInstance,
  Flash,
  Negotiation,
  negotiationId,
  itemIds,
  apartmentMode,
  ErrorHandler
) {
  var vm = this;
  vm.cancel = cancel;
  vm.recallItems = recallItems;
  vm.apartmentMode = apartmentMode;

  function recallItems() {
    vm.saving = true;
    var item = vm.apartmentMode ? 'lägenhet' : 'fastighet';
    var plural = itemIds.length > 1 ? 'er' : '';

    Negotiation.recallItems({
      id: negotiationId,
      itemIds: itemIds
    })
      .$promise.then(function() {
        Flash.set(itemIds.length + ' ' + item + plural + ' har återkallats från förhandlingen', 'success');
      })
      .catch(ErrorHandler('Misslyckades att flytta ' + item + plural))
      .finally(function() {
        vm.saving = false;
        $uibModalInstance.close();
      });
  }

  function cancel() {
    $uibModalInstance.dismiss('cancel');
  }
}

})();