(function () {
'use strict';
angular.module('hyParticipants', ['uiModal'])
  .directive('hyParticipants', ['$uibModal', 'Parties', ($uibModal, Parties) => {
    return {
      scope: {
        userContainer: '=',
      },
      restrict: 'E',
      templateUrl: 'resources/agreement/free/participants/hy-participants.html',
      link: function (scope) {

        scope.toggleOwnersList = [false, false, false];
        scope.Parties = Parties;

        scope.edit = (modalSize, userParty) => {
          var modalInstance = $uibModal.open({
            animation: true,
            ariaLabelledBy: 'modal-title-top',
            ariaDescribedBy: 'modal-body-top',
            size: modalSize,
            index: 1075, // position above tooltip
            templateUrl: 'resources/negotiation/edit/edit-representatives-modal.html',
            controller: 'EditNegotiationRepresentativesModalCtrl',
            controllerAs: 'vm',
            windowClass: 'modal-content--white-border',
            resolve: {
              negotiationObject: () => ({
                delegationParticipants: [],
                delegationInvitations: scope.userContainer.invites,
                hgfParticipants: scope.userContainer.hgfSide,
                externalParticipants: scope.userContainer.faSide,
                chiefNegotiatorHGF: scope.userContainer.chiefNegotiatorHGF,
                chiefNegotiatorExternal: scope.userContainer.chiefNegotiatorExternal,
              }),
              isFreeAgreement: () => true,
              userParty: () => userParty
            }
          });
          modalInstance.result.then(result => {
            let setUserId = arr => arr.forEach(el => { el.userId = el.userId ? el.userId : el.id });
            if (userParty === Parties.HGF) {
              scope.userContainer.hgfSide = result.participants;
              scope.userContainer.chiefNegotiatorHGF = result.participants.find(p => p.isChief);
              setUserId(scope.userContainer.hgfSide);
            } else {
              scope.userContainer.faSide = result.participants;
              scope.userContainer.chiefNegotiatorExternal = result.participants.find(p => p.isChief);
              setUserId(scope.userContainer.faSide);
            }
            scope.userContainer.invites = result.waitingInvites;
          });
        };

      },
    };
  }]);
})();