(function () {
'use strict';

angular.module('hyresgastApp').factory('Blocking', function($resource) {
  return $resource(
    '/api/negotiations/:negotiationId/blockings/:id',
    { negotiationId: '@negotiationId', agreementId: '@agreementId', id: '@id' },
    {
      queryApartment: {
        url: '/api/negotiations/:negotiationId/blockings/:id/apartments'
      },
      deleteApartment: {
        url: '/api/negotiations/:negotiationId/blockings/:agreementId/apartments/:id/unassign',
        method: 'POST'
      },
      saveApartment: {
        url: '/api/negotiations/:negotiationId/blockings/:id/apartments',
        method: 'POST'
      },
      resume: {
        url: '/api/negotiations/:negotiationId/blockings/:id/resume',
        method: 'POST'
      }
    }
  );
});

})();