(function () {
angular.module('hyresgastApp').directive('hyCompareFormFields', compareFormFields);

function compareFormFields() {
  var directive = {
    require: 'ngModel',
    link: link,
    scope: {
      secondModelValue: '=hyCompareFormFields'
    }
  };
  return directive;

  function link(scope, element, attrs, ngModel) {
    ngModel.$validators.compareFormFields = function(modelValue) {
      return modelValue === scope.secondModelValue;
    };

    scope.$watch('secondModelValue', function() {
      ngModel.$validate();
    });
  }
}

})();