(function () {
'use strict';

angular.module('hyresgastApp').controller('WithoutRentListCtrl', WithoutRentListCtrl);

function WithoutRentListCtrl($scope, $location, $uibModal, $uibModalInstance, withoutRentlist, isNewlyConstructedApartment) {
  var vm = this;
  vm.withoutRentlist = withoutRentlist;
  vm.isNewlyConstructedApartment = isNewlyConstructedApartment;
  vm.close = close;

  function close() {
    $uibModalInstance.dismiss();
  }
}

})();