(function () {
'use strict';

// @desc
//   Counter, used for the navigation on the top menu.
//
// @example
//   <hy-counter></hy-counter>

angular.module('hyresgastApp').directive('hyCounter', counter);

function counter(Auth) {
  var directive = {
    scope: {
      countValue: '@',
      tooltipText: '@',
    },
    templateUrl: 'components/counter/counter.html',
    restrict: 'E',
    link: link,
  };
  return directive;

  // eslint-disable-next-line no-unused-vars
  function link(scope, element, attrs) {
    scope.Auth = Auth;
  }
}

})();