(function () {
'use strict';

angular.module('hyresgastApp').controller('ShowNegotiationCallCtrl', ShowNegotiationCallCtrl);

function ShowNegotiationCallCtrl(
  $scope, $location, $routeParams, $uibModal,
  Auth, Flash, SubPage, HyUtils,
  Parties, NegotiationCallStates, NegotiationTypes,
  NegotiationCall, Seenby, ObjectTypes,
  Help, ErrorHandler
) {
  var vm = this;

  vm.statistics = {
    municipalities: 0,
    properties: 0,
    apartments: 0
  };

  vm.updateOffice = updateOffice;
  vm.NegotiationCallStates = NegotiationCallStates;
  vm.returnNegotiationCall = returnNegotiationCall;
  vm.NegotiationTypes = NegotiationTypes;
  vm.handleToggle = handleToggle;
  vm.Auth = Auth;
  vm.renderHelpModal = renderHelpModal;
  vm.gotoNewNegotiation = gotoNewNegotiation;

  Auth.heartbeat().then(function() {
    vm.currentUserId = Auth.currentUser().id;
    if (Auth.currentUser().party === Parties.HGF) {
      vm.isHgfUser = true;
      vm.tablist = [
        {
          title: 'Bestånd',
          path: '/negotiation-calls/' + $routeParams.id
        },
        {
          title: 'Kvar att tilldela',
          path: '/negotiation-calls/' + $routeParams.id + '?view=remaining'
        },
        {
          title: 'Misstänkta fel',
          path: '/negotiation-calls/' + $routeParams.id + '?view=errors',
          hasErrors: false
        },
        {
          title: 'Underlag',
          path: '/negotiation-calls/' + $routeParams.id + '?view=documents'
        },
        {
          title: 'Förhandlingar',
          path: '/negotiation-calls/' + $routeParams.id + '?view=negotiations'
        }
      ];
    } else {
      vm.tablist = [
        {
          title: 'Bestånd',
          path: '/negotiation-calls/' + $routeParams.id
        },
        {
          title: 'Misstänkta fel',
          path: '/negotiation-calls/' + $routeParams.id + '?view=errors',
          hasErrors: false
        },
        {
          title: 'Underlag',
          path: '/negotiation-calls/' + $routeParams.id + '?view=documents'
        },
        {
          title: 'Förhandlingar',
          path: '/negotiation-calls/' + $routeParams.id + '?view=negotiations'
        }
      ];
    }
  });

  $scope.$on('$routeUpdate', function() {
    // avoid unecessary calling of getCurrentSubpage() when sorting
    if (!$location.search().order) {
      // reset offset when sorting
      getCurrentSubpage();
    }
  });
  queryErrors();
  getCurrentSubpage();
  getNegotiationCall();
  ////////////
  function queryErrors() {
    vm.readyErrors = false;

    return NegotiationCall.queryErrors({
      id: $routeParams.id,
      limit: vm.pageLimit,
      offset: vm.pageOffset
    })
      .$promise.then(function(data) {
        let errorTab = vm.tablist.find(element => element.title === 'Misstänkta fel');
        if (errorTab) {
          errorTab.hasErrors = !!data.rows.length;
        }
      })
      .catch(ErrorHandler('Misslyckades att hämta lista med fel.'))
      .finally(function() {
        vm.readyErrors = true;
      });
  }

  function handleToggle() {
    vm.saving = true;
    vm.negotiationCall.handledByUserId = vm.toggleValue ? vm.currentUserId : null;

    NegotiationCall.updateHandler(vm.negotiationCall)
      .$promise.then(function() {
        setTogglerText();
      })
      .catch(ErrorHandler('Misslyckades att  bereda förhandling.'))
      .finally(function() {
        vm.saving = false;
      });
  }

  function setTogglerText() {
    if (!vm.negotiationCall.handledByUserId) {
      vm.togglerText = 'Bered denna framställan (lås för andra)';
    } else if (vm.negotiationCall.handledByUserId === vm.currentUserId) {
      vm.togglerText = 'Jag bereder denna framställan (låst för andra)';
    } else {
      vm.toggleValue = false;
      vm.togglerText = vm.negotiationCall.handler.fullName + ' bereder denna framställan';
    }
  }

  function getNegotiationCall() {

    vm.negotiationCallPromise = NegotiationCall.get({
      id: $routeParams.id
    }).$promise.then(function(response) {
      vm.negotiationCall = response;

      vm.isPropertyBased =
        vm.negotiationCall.type === NegotiationTypes.ANNUAL_PROPERTY ||
        vm.negotiationCall.type === NegotiationTypes.STANDARD_PROPERTY ||
        vm.negotiationCall.type === NegotiationTypes.REBUILD_PROPERTY ||
        vm.negotiationCall.type === NegotiationTypes.REBUILD_PROPERTY_2 ||
        vm.negotiationCall.type === NegotiationTypes.NEWLY_CONSTRUCTED_PROPERTY ||
        vm.negotiationCall.type === NegotiationTypes.UTILITY_VALUE_PROPERTY;

      vm.negotiationCall.isNewlyConstructedApartment =
        vm.negotiationCall.type === NegotiationTypes.NEWLY_CONSTRUCTED_APARTMENT;

      if (vm.negotiationCall.type === NegotiationTypes.NEWLY_CONSTRUCTED_PROPERTY) {
        vm.negotiationCall.isNewlyConstructedProperty = true;
        vm.tablist = [
          {
            title: 'Underlag',
            path: '/negotiation-calls/' + $routeParams.id + '?view=documents'
          },
          {
            title: 'Förhandlingar',
            path: '/negotiation-calls/' + $routeParams.id + '?view=negotiations'
          }
        ];
        $location.search({
          view: 'documents'
        });
      }

      vm.toggleValue = response.handler && response.handler.id === vm.currentUserId;
      setTogglerText();
    })
      .catch(ErrorHandler('Misslyckades att hämta förhandlingsframställan.'))
      .finally(function() {
        vm.ready = true;
        Seenby.set({
          objectType: ObjectTypes.CALL.code,
          objectId: $routeParams.id,
        });
      });
    return vm.negotiationCallPromise;
  }

  function updateOffice() {
    var modalInstance = $uibModal.open({
      animation: true,
      ariaLabelledBy: 'modal-title-top',
      ariaDescribedBy: 'modal-body-top',
      size: 'sm',
      index: 1075,
      templateUrl: 'resources/negotiation-call/show/modals/change-office-modal.html',
      controller: 'ChangeOfficeModalCtrl',
      controllerAs: 'vm',
    });

    modalInstance.result.then(function(selectedOffice) {
      // Update the object with the office chosen in the modal
      vm.negotiationCall.hgfOfficeId = selectedOffice.id;
      vm.negotiationCall.office = {
        name: selectedOffice.name,
        id: selectedOffice.id
      };
      NegotiationCall.setOffice(vm.negotiationCall)
        .$promise.then(function() {
          Flash.set('Ändring av kontor lyckades', 'success');
        })
        .catch(ErrorHandler('Misslyckades att ändra kontor.'));
    });
  }

  function returnNegotiationCall() {
    var modalInstance = $uibModal.open({
      animation: true,
      ariaLabelledBy: 'modal-title-top',
      ariaDescribedBy: 'modal-body-top',
      size: 'md',
      index: 1075,
      templateUrl: 'resources/negotiation-call/show/modals/return-negotiation-call-modal.html',
      controller: 'ReturnNegotiationCallModal',
      controllerAs: 'vm'
    });
    modalInstance.result.then(function(message) {
      NegotiationCall.returnNegotiation({
        id: vm.negotiationCall.id,
        message: message
      }).$promise.then(function() {
        Flash.set('Skicka tillbaka framställan lyckades', 'success');
        return getNegotiationCall();
      })
        .catch(ErrorHandler('Misslyckades att skicka tillbaka framställan.'));
    });
  }

  vm.deleteCall = NegotiationCall.delete;

  function getCurrentSubpage() {
    var defaultSubPage = 'properties';
    // create a validation list against manually change of URL '?view=' parameter
    var allowList = [];
    if (vm.isHgfUser) {
      allowList = ['remaining', 'errors', 'documents', 'negotiations'];
    } else {
      allowList = ['errors', 'documents', 'negotiations'];
    }
    vm.currentSubpage = SubPage.set($routeParams.view, allowList, defaultSubPage);
  }

  function renderHelpModal(title, bodyText) {
    Help.renderHelpModal(title, bodyText);
  }

  function gotoNewNegotiation(id) {
    var type =
      vm.negotiationCall.isNewlyConstructedProperty || vm.negotiationCall.isNewlyConstructedApartment
        ? 'newly-constructed-property'
        : 'new';
    var rentListBasedNegotiationCall = vm.negotiationCall.realEstateSummary.apartments > 0 ? 'yes' : 'no'
    $location.path('/negotiation-calls/' + id + '/negotiations/' + type).search({
      negotiationCallType: vm.negotiationCall.type,
      rentListBased: rentListBasedNegotiationCall
    });
  }
}

})();