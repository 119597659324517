(function () {
'use strict';

angular.module('hyresgastApp').factory('ApartmentSelector2', function($resource) {
  return $resource(
    '/api/real-estate/apartment-selector/',
    {
      id: '@id',
      dictionaryType: '@dictionaryType',
    },
    {
      getProperties: {
        url: '/api/real-estate/apartment-selector/properties',
        isArray: false,
        method: 'POST',
      },
      getDictionaries: {
        url: '/api/real-estate/apartment-selector/dictionaries',
        method: 'POST',
      },
      getDictionary: {
        url: '/api/real-estate/apartment-selector/dictionary/:dictionaryType/dictionary',
        isArray: true,
        method: 'POST',
      },
      getApartments: {
        url: '/api/real-estate/apartment-selector/property/:propertyId/apartments',
        isArray: true,
        method: 'GET',
      }
    }
  );
});

})();