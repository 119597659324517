(function () {
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable no-undefined */
/* eslint-disable strict */

if ('NodeList' in window && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = function (callback, thisArg) {
    thisArg = thisArg || window;
    for (var i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this);
    }
  };
}

if (!Array.prototype.find) {
  Array.prototype.find = function(predicate) {
    if (this == null) {
      throw new TypeError('Array.prototype.find called on null or undefined');
    }
    if (typeof predicate !== 'function') {
      throw new TypeError('predicate must be a function');
    }
    var list = Object(this);
    var length = list.length >>> 0;
    var thisArg = arguments[1];
    var value;

    for (var i = 0; i < length; i++) {
      value = list[i];
      if (predicate.call(thisArg, value, i, list)) {
        return value;
      }
    }
    return undefined;
  };
}

if (!Object.values) {
  Object.values = function(o) {
    return Object.keys(o).map(function(k) {
      return o[k];
    });
  }
}

if (!String.prototype.startsWith) {
  Object.defineProperty(String.prototype, 'startsWith', {
    value: function(search, rawPos) {
      var pos = rawPos > 0 ? rawPos|0 : 0;
      return this.substring(pos, pos + search.length) === search;
    }
  });
}

if (typeof Object.assign !== 'function') {
  // Must be writable: true, enumerable: false, configurable: true
  Object.defineProperty(Object, "assign", {
    value: function assign(target, varArgs) { // .length of function is 2
      'use strict';
      if (target === null || target === undefined) {
        throw new TypeError('Cannot convert undefined or null to object');
      }

      var to = Object(target);

      for (var index = 1; index < arguments.length; index++) {
        var nextSource = arguments[index];

        if (nextSource !== null && nextSource !== undefined) {
          for (var nextKey in nextSource) {
            // Avoid bugs when hasOwnProperty is shadowed
            if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
              to[nextKey] = nextSource[nextKey];
            }
          }
        }
      }
      return to;
    },
    writable: true,
    configurable: true
  });
}


Array.prototype.flat || Object.defineProperty(Array.prototype, "flat", {
  configurable:!0, value:function r() {
    var t = isNaN(arguments[0]) ? 1:Number(arguments[0]);return t?Array.prototype.reduce.call(this, function(a, e) {
      return Array.isArray(e)?a.push.apply(a, r.call(e, t-1)):a.push(e), a
    }, []):Array.prototype.slice.call(this)
  }, writable:!0}), Array.prototype.flatMap||Object.defineProperty(Array.prototype, "flatMap", {
  configurable:!0, value:function(r) {
    return Array.prototype.map.apply(this, arguments).flat()
  }, writable:!0})
})();