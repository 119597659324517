(function () {
'use strict';

angular.module('hyresgastApp').controller('ConfirmationModalInfoCtrl', ConfirmationModalInfoCtrl);

function ConfirmationModalInfoCtrl($uibModalInstance, text, buttonClassSecondary) {
  var vm = this;
  vm.text = text;
  vm.buttonClassSecondary = buttonClassSecondary;

  vm.confirm = function() {
    $uibModalInstance.close();
  };

  vm.cancel = function() {
    $uibModalInstance.dismiss('cancel');
  };
}

})();