(function () {
'use strict';

angular.module('hyresgastApp').controller('EditProtocolModal', EditProtocolModal);

function EditProtocolModal(
  $uibModalInstance,
  $scope,
  $routeParams,
  $location,
  $window,
  DateLocal,
  Flash,
  Meeting,
  meetingObj,
  negotiationObj,
  NegotiationStates,
  ErrorHandler
) {
  var vm = this;
  vm.NegotiationStates = NegotiationStates;
  vm.counterpartName = negotiationObj.counterpart.name;
  vm.negotiationState = negotiationObj.state;
  vm.meeting = angular.copy(meetingObj);

  if (meetingObj.hasProtocol) {
    vm.protocolDate = new Date(vm.meeting.protocolDate);
  } else {
    // init new protocol
    vm.protocolDate = new Date(vm.meeting.startAt);
    vm.meeting.protocolLocation = meetingObj.location;
    vm.meeting.protocolParticipants = !meetingObj.participants
      ? ''
      : meetingObj.participants
          .map(function(participant) {
            return participant.firstname + ' ' + participant.lastname;
          })
          .join(', ');
    vm.meeting.protocolAttestors = null;
    vm.meeting.protocolChairPerson = null;
    vm.meeting.protocolDecisionPoints = [];
    vm.meeting.protocolDiscussionPoints = [];
    vm.meeting.protocolSecretary = null;
  }

  vm.cancelProtocol = cancelProtocol;
  vm.saveProtocol = saveProtocol;
  vm.addDiscussionItem = addDiscussionItem;
  vm.closeDiscussion = closeDiscussion;

  /////////

  function addDiscussionItem() {
    vm.discussionSubject = vm.discussionSubject;
    vm.meeting.protocolDiscussionPoints.push({
      subject: vm.discussionSubject,
      text: vm.discussionText || ' '
    });
    vm.discussionSubject = vm.discussionText = null;
  }

  function closeDiscussion() {
    vm.discussionSubject = vm.discussionText = null;
  }

  function saveProtocol(hideFlash) {
    vm.saving = true;
    vm.meeting.protocolDate = DateLocal.fromUTCtoLocal(vm.protocolDate);

    // TODO - probably move to backend, not up to client to decide, ie.
    // what happens if saving fails?
    vm.meeting.hasProtocol = true;

    return Meeting.save(vm.meeting)
      .$promise.then(
        function(response) {
          angular.extend(meetingObj, response);
          if (!hideFlash) {
            Flash.set('Lyckades att spara protokoll', 'success');
          }
          $uibModalInstance.dismiss('close');
        })
      .catch(function(reason) {
        ErrorHandler('Misslyckades att spara protokoll')(reason);
        $uibModalInstance.dismiss('error');
      })
      .finally(function() {
        vm.saving = false;
      });
  }

  function cancelProtocol() {
    $uibModalInstance.dismiss('cancel');
  }
}

})();