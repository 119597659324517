(function () {
'use strict';

angular.module('hyresgastApp').controller('EditOrganizationCtrl', EditOrganizationCtrl);

function EditOrganizationCtrl($filter, $location, $routeParams, $uibModal,
  Flash, Organization, Office, ErrorHandler, HyUtils) {
  // Responsible for create, update and delete

  var vm = this;
  vm.organization = {};
  vm.save = save;
  vm.delete = destroy;
  vm.displayBlockingData = displayBlockingData;

  get();

  if ($routeParams.id) {
    vm.editMode = true;
    get();
    getOffices();
  } else {
    vm.ready = true;
    getOffices();
  }

  ////////////

  function get() {
    Organization.get({ id: $routeParams.id })
      .$promise.then(function(response) {
        vm.ready = true;
        vm.organization = response;
        vm.organization.organizationNumber = $filter('hyOrgNr')(vm.organization.organizationNumber);
      })
      .catch(ErrorHandler('Misslyckades att hämta organisation.'))
  }

  function getOffices() {
    Office.query().$promise.then(function(response) {
      vm.offices = response;
    })
      .catch(ErrorHandler('Misslyckades att hämta kontor.'));
  }

  function save() {
    vm.saving = true;

    Organization.save(vm.organization)
      .$promise.then(
        function(response) {
          Flash.set('Informationen har sparats.', 'success');
          $location.path('/organizations/' + response.id);
        })
      .catch(function(reason) {
        if (reason.status === 409) {
          Flash.set('Organisationsnumret existerar redan', 'error');
        } else if (reason.status === 403) {
          $location.path('/403')
        } else {
          Flash.set('Kontrollera organisationsnumret', 'error');
        }
      })
      .finally(function() {
        vm.saving = false;
      });
  }

  function destroy() {
    var modalInstance = $uibModal.open({
      animation: true,
      ariaLabelledBy: 'modal-title-top',
      ariaDescribedBy: 'modal-body-top',
      size: 'xsm',
      templateUrl: 'components/modal/confirmation/confirmationModal.html',
      controller: 'ConfirmationModalCtrl',
      controllerAs: 'vm',
      windowClass: 'modal-content--secondary'
    });

    modalInstance.result.then(function() {
      Organization.delete(vm.organization).$promise.then(
        function(response) {
          Flash.set('Organisationen borttagen', 'success');
          $location.path('/organizations');
        })
        .catch(function(response) {
          if(response.data === undefined || Object.keys(response.data).length === 0) {
            new ErrorHandler('Okänt fel.');
          }

          for (const [key, value] of Object.entries(response.data)) {
            if(value.length > 0 && response.status === 403) {
              displayBlockingData(response.data);
              break;
            }
          }
        });
    });
  }

  function displayBlockingData(blockingData) {
    HyUtils.showDefaultModal('BlockingDeletionModalCtrl',
      'resources/organization/show/modals/blocking-deletion-modal.html', { blockingData });
  }
}

})();