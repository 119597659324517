(function () {
'use strict';

angular.module('reportCellValue', []).directive('reportCellValue', [reportCellValue]);

function reportCellValue() {
  var directive = {
    scope: {
      value: '=',
      type: '@',
    },
    restrict: 'E',
    templateUrl: 'resources/reports/report-cell-value.html',
    link: function (scope) {
      if (scope.value instanceof Array) {
        let value = scope.value[0];
        let theRest = scope.value.slice(1);
        scope.plusN = theRest.length;
        scope.tooltip = theRest.join('<br/>');
        scope.value = value;
      }
    }
  };

  return directive;
}

})();