(function () {
'use strict';

angular.module('hyresgastApp').controller('RequestedAccountsCtrl', RequestedAccountsCtrl);

function RequestedAccountsCtrl($scope, $location, $routeParams, Auth, RequestedAccount, Parties, SubPage) {
  var vm = this;
  vm.userParties = Parties;

  getCurrentSubpage();

  $scope.$on('$routeUpdate', function() {
    // avoid unecessary calling of getCurrentSubpage(); when sorting
    if (!$location.search().order) {
      // reset offset when sorting
      getCurrentSubpage();
    }
  });

  function getCurrentSubpage() {
    var defaultSubPage = 'organizations';
    // create a validation list against manually change of URL '?view=' parameter
    var whiteList = ['external'];
    vm.currentSubpage = SubPage.set($routeParams.view, whiteList, defaultSubPage);
  }
}

})();