(function () {
'use strict';

// BaseInterceptor contains very basic changes of requests and responses.
// More complex changes to requests should be in its own file

angular.module('hyresgastApp').factory('BaseInterceptor', function($q, $location) {
  return {
    responseError: function(rejection) {
      switch (rejection.status) {
        case 404:
          if ($location.path().indexOf('/resetpassword') !== -1 || $location.path().indexOf('/users/new') !== -1) {
            break;
          }
          $location.path('404').replace(); // back button
          break;
      }

      // otherwise, default behaviour
      return $q.reject(rejection);
    }
  };
});

})();