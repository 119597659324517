(function () {
'use strict';

// @desc
//   Wizard steps
//
// @example
//   <hy-modal-wizard wizard-submit="save()">
//      <hy-modal-wizard-step></hy-modal-wizard-step>
//   </hy-modal-wizard>

angular.module('modalWizard.step', []).directive('hyModalWizardStep', modalWizardStep);

function modalWizardStep() {
  var directive = {
    require: '^hyModalWizard', // We need this directive to be inside a wizard
    transclude: true, // It transcludes the contents of the directive into the template
    restrict: 'E',
    scope: {
      heading: '@', // Interpolate the heading attribute onto this scope
      headingStepNumber: '@',
      headingShort: '@', // To fit in the timeline
      name: '@', // Used in query parameter ?step=
      isDisabled: '=?',
      isValid: '=', // Validation function that return true to move to next step
      alertHeader: '@',
      alertText: '=',
      alternativeWizard: '='
    },
    templateUrl: 'components/modalwizard/modal-wizard-step.html',
    link: link
  };

  return directive;

  ////////////
  function link(scope, element, attrs, wizardCtrl) {
    wizardCtrl.addStep(scope);

    scope.hideNextButton = attrs.hasOwnProperty('hideNextButton');
    scope.$watch('isOpen', function(value) {
      element.toggleClass(scope.openClass, !!value);
      if (value) {
        wizardCtrl.closeOthers(scope);
      }
    });

    scope.$watch('isDisabled', function(value) {
      wizardCtrl.setEnabledSteps();
    });

    scope.toggleOpen = function($event) {
      if (!scope.isDisabled) {
        if (!$event || $event.which === 32) {
          scope.isOpen = !scope.isOpen;
        }
      }
    };

    // ARIA
    scope.wizardId = 'modal-wizard-step' + scope.$id + '-' + Math.floor(Math.random() * 10000);
  }
}

})();