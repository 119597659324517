(function () {
'use strict';

angular.module('hyresgastApp').factory('Settings', function($resource) {
  return $resource(
    '/api/specops/settings', {},
    {
      load: {
        url: '/api/specops/settings/load',
        method: 'GET',
        isArray: true,
      },
      loadAll: {
        url: '/api/specops/settings/loadAll',
        method: 'GET',
        isArray: true,
      },
      alter: {
        url: '/api/specops/settings/alter',
        method: 'POST',
      }
    }
  );
});

})();