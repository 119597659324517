(function () {
'use strict';

angular.module('hyresgastApp').filter('hyNegotiationAgreementTypes', function() {
  return function(input, hasNegotiationAgreement) {
    var output;
    if (hasNegotiationAgreement) {
      switch (input) {
        case null:
        case '':
          output = 'Saknar FO';
          break;
        case '2A':
          output = '2-parts Annan';
          break;
        case '2H':
          output = '2-parts HBK';
          break;
        case '2O':
          output = '2-parts Ombud';
          break;
        case '2S':
          output = '2-parts Sabo/HMK';
          break;
        case '2M':
          output = '2-parts Skiljeman';
          break;
        case '3A':
          output = '3-parts A';
          break;
        case '3B':
          output = '3-parts B';
          break;
        case '3C':
          output = '3-parts 15';
          break;
        case '3O':
          output = '3-parts Ombud';
          break;
        default:
          output = 'Ej angivet';
          break;
      }
    } else {
      output = 'Ej angivet';
    }
    return output;
  };
});

})();