(function () {
'use strict';

// @desc
//   Breadcrumb navigation is configuered through app.js with a title property.
//   Example from app.js: $routeProvider.when('/negotiations', { title: 'Alla Förhandlingar', ... })
// @example
//   <hy-breadcrumb></hy-breadcrumb>
//   <hy-breadcrumb hide-id></hy-breadcrumb> - doesn't show id (numbers) in breadcrumb's link

angular.module('breadcrumb', []).directive('hyBreadcrumb', breadcrumb);

function breadcrumb($rootScope, $routeParams, $route, $location) {
  var directive = {
    scope: {},
    restrict: 'E',
    templateUrl: 'components/breadcrumb/breadcrumb.html',
    link: link
  };
  return directive;

  function link(scope, element, attrs) {
    var urlPath = $location.path();
    urlPath = urlPath.split('/');
    urlPath.splice(0, 1); // remove first element from url which is ""
    var urlPart = '';
    var breadcrumb = [];
    scope.breadcrumb = breadcrumb;

    angular.forEach(urlPath, function(item) {
      urlPart += '/' + item;
      angular.forEach($route.routes, function(route, key) {
        if (isMatchingRoute(key, urlPart)) {
          // check if there is a number after the current urlPart
          var id = urlPart.match(/\/(\d+)$/);
          if (id) {
            id = ' ' + id[1];
          } else {
            id = '';
          }
          // breadcrumb.push({ title: route.title + id, path: urlPart }); // USING ID
          breadcrumb.push({ title: route.title, path: urlPart });
        }
      });
    });

    //////////

    function isMatchingRoute(route, urlPart) {
      route = route.replace(/:\w+/g, '0');
      urlPart = urlPart.replace(/\d+/g, '0');
      return route === urlPart;
    }
  }
}

})();