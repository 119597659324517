(function () {
'use strict';

angular
  .module('hyresgastApp')
  .controller('EditNegotiationDelegatesModalCtrl', EditNegotiationDelegatesModalCtrl);

function EditNegotiationDelegatesModalCtrl(
  $uibModalInstance,
  $routeParams,
  $location,
  Flash,
  negotiationObject,
  Negotiation,
  User,
  Auth,
  Parties,
  NegotiationRoles,
  $q,
  ErrorHandler
) {
  var vm = this;
  vm.cancel = cancel;
  vm.saveDelegation = saveDelegation;
  vm.addDelegateInvitation = addDelegateInvitation;
  vm.chooseRole = chooseRole;
  vm.removeDelegationParticipant = removeDelegationParticipant;
  vm.delegateRole = { name: 'Rollen', code: -1 };

  vm.delegationParticipants = angular.copy(negotiationObject.delegationParticipants);
  vm.delegationInvitations = angular.copy(negotiationObject.delegationInvitations);

  vm.rolesList = NegotiationRoles;

  var roleUnfurling = function(del) {
    var roleCode = del.role;
    if (del.negotiationsUsers) { roleCode = del.negotiationsUsers.role; }

    //this should please UglifyJS
    del.role = {};
    del.role.code = roleCode;
    for (var r in vm.rolesList) {
      var rObj = vm.rolesList[r];
      if(rObj.code===roleCode) {
        del.role.name = rObj.name;
      }
    }
  };

  vm.delegationInvitations.forEach(roleUnfurling);
  vm.delegationParticipants.forEach(roleUnfurling);

  var toBeInvited = [];
  var toBeDeleted = [];

  Auth.heartbeat().then(function() {
    if (Auth.currentUser().party === Parties.HGF) {
      setInviterFullName();
    }
  });

  /////////

  function chooseRole(name, code) {
    vm.delegateRole = { name: name, code: code };
  }

  function setInviterFullName() {
    vm.getInvitors = true;
    vm.delegationParticipants.forEach(function(delegate) {
      return User.get({ id: delegate.negotiationsUsers.inviterId })
        .$promise.then(function(response) {
          delegate.negotiationsUsers.fullName = response.fullName;
        })
        .finally(function() {
          vm.getInvitors = false;
        });
    });
  }

  function removeDelegationParticipant(list, delegate) {
    removeDelegate(list, delegate);
    if (findDelegateByEmail(toBeInvited, delegate.email).length > 0) {
      removeDelegate(toBeInvited, delegate);
    } else {
      toBeDeleted.push(delegate);
    }
  }

  function removeDelegate(list, delegate) {
    var index = list.indexOf(delegate);
    list.splice(index, 1);
  }

  function findDelegateByEmail(list, email) {
    return list.filter(function(delegate) {
      return delegate.email === email;
    });
  }

  function addDelegateInvitation() {

    // look through all invitations to check if they had been added before or not
    var existingInvitationEmail = findDelegateByEmail(vm.delegationInvitations, vm.delegateEmail);
    var existingDelegationEmail = findDelegateByEmail(vm.delegationParticipants, vm.delegateEmail);

    // avoid inviting twice with already existing email
    if (existingDelegationEmail.length === 0 && existingInvitationEmail.length === 0) {
      vm.inviting = true;
      Negotiation.checkDelegation({
        id: $routeParams.id,
        email: vm.delegateEmail,
      })
        .$promise.then(function(response) {
          var delegate = response;
          delegate.role = vm.delegateRole;
          if (delegate.party) {
            vm.delegationParticipants.push(delegate);
          } else {
            vm.delegationInvitations.push(delegate);
          }
          toBeInvited.push(delegate);
          // vm.delegateEmail=""
        })
        .catch(function(error) {
          var delegate = {};
          if (error.data.code === 1) {
            delegate = findDelegateByEmail(negotiationObject.delegationParticipants, vm.delegateEmail)[0];
            if (delegate) {
              vm.delegationParticipants.push(delegate);
              removeDelegate(toBeDeleted, delegate);
              removeDelegate(toBeInvited, delegate);
            }
          } else if (error.data.code === 2) {
            delegate = findDelegateByEmail(negotiationObject.delegationInvitations, vm.delegateEmail)[0];
            if(delegate) {
              vm.delegationInvitations.push(delegate);
              removeDelegate(toBeDeleted, delegate);
              removeDelegate(toBeInvited, delegate);
            }
          } else if (error.data.code === 3) {
            Flash.set('Du får inte bjuda in HGF eller FÄ användare.', 'error');
          } else if (error.status === 403) {
            $location.path('/403');
          } else {
            Flash.set('Misslyckades att bjuda in delegat.', 'error');
          }
        })
        .finally(function() {
          vm.inviting = false;
        });
    } else {
      Flash.set('Denna mailadress finns redan registrerad på delegat i denna förhandling.', 'error');
    }
  }

  function saveDelegation() {
    vm.saving = true;
    $q.all([
      Negotiation.inviteDelegation({
        id: $routeParams.id,
        delegates: toBeInvited
      }).$promise,
      Negotiation.deleteDelegation({
        id: $routeParams.id,
        emails: toBeDeleted
      }).$promise
    ])
      .then(function() {
        $uibModalInstance.close();
        Flash.set('Ändringen har registrerats.', 'success');
      })
      .catch(new ErrorHandler('Misslyckades att spara ändring.'))
      .finally(function() {
        vm.saving = false;
        $uibModalInstance.dismiss('cancel');
      });
  }

  function cancel() {
    $uibModalInstance.dismiss('cancel');
  }


}

})();