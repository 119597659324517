(function () {
'use strict';

angular.module('hyresgastApp').filter('documentTypes', function() {
  return function(value) {
    return documentTypes(value);
  };
});

function documentTypes(value) {
  value = parseInt(value);
  switch (value) {
    case 1:
      return 'Allmänna ekonomiska läget';
    case 2:
      return 'Budget kommande år';
    case 3:
      return 'Budget/utfall föregående år';
    case 4:
      return 'Förvaltningskvalitet och service';
    case 5:
      return 'Läge och standard';
    case 6:
      return 'Medlems- och kundundersökning';
    case 7:
      return 'Prognos för innevarande år';
    case 8:
      return 'Underhållsplan';
    case 9:
      return 'Övrigt';
    case 10:
      return 'Bilaga';
    case 11:
      return 'Överenskommelse (utkast)';
    case 12:
      return 'Överenskommelse (signerad)';
    case 13:
      return 'Inlaga';
    case 14:
      return 'Dagordning';
    case 15:
      return 'Protokoll';
    case 30:
      return 'Förhandlingsframställan kvitto';
    case 35:
      return 'Hyreslista (källfil)';
    case 36:
      return 'Fastighetslista (källfil)';
    case 77:
      return 'Bilaga överenskommelse';
    default:
      return 'Underlag';
  }
}

})();