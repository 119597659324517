(function () {
'use strict';

angular.module('hyresgastApp').controller('QuestionCtrl', QuestionCtrl);

function QuestionCtrl($uibModalInstance, message, answers) {
  var vm = this;

  vm.message = message;
  vm.answers = answers;

  vm.answer = function(answer) {
    $uibModalInstance.close(answer);
  };

  vm.cancel = function() {
    $uibModalInstance.dismiss('cancel');
  };
}
})();