(function () {
'use strict';

angular.module('hyresgastApp').controller('ConditionsModalCtrl', ConditionsModalCtrl);

function ConditionsModalCtrl($uibModalInstance,
  newRealEstate, isReadyToSend) {
  var vm = this;

  vm.newRealEstate = newRealEstate;
  vm.isReadyToSend = isReadyToSend;

  vm.confirm = function() {
    $uibModalInstance.close({
      isReadyToSend: vm.isReadyToSend,
      isPresumtiveRent: vm.isPresumtiveRent,
      isInvestmentProperty: vm.isInvestmentProperty,
    });
  };
  vm.cancel = function() { $uibModalInstance.dismiss('cancel') };
}

})();