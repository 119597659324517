(function () {
'use strict';

angular.module('hyresgastApp').controller('ListUsersPendingCtrl', ListUsersPendingCtrl);
// external = list of users wanting in to organization
function ListUsersPendingCtrl($scope, $location, $routeParams, Auth, Flash, User, ErrorHandler) {
  var vm = this;
  vm.gotoShow = gotoShow;
  vm.getUsers = getUsers;
  vm.pageLimit = 30;
  vm.pageOffset = 0;
  vm.sortColumn = $location.search().order || 'lastname'; // default
  vm.sortDirection = $location.search().direction || 'ASC'; // default

  vm.listColumns = [
    { title: 'Förnamn', column: 'firstname' },
    { title: 'Efternamn', column: 'lastname' },
    { title: 'E-post', column: 'email' }
  ];

  Auth.heartbeat().then(function () {
    vm.organizationId = Object.keys(Auth.currentUser().roleByOrganization)[0];
    getUsers();
  });

  $scope.$on('$routeUpdate', function () {
    if ($location.search().order) {
      // reset offset when sorting
      vm.pageOffset = 0;
      getUsers();
    }
  });

  ////////////

  function gotoShow(id) {
    $location.search({});
    $location.path('/requested-accounts/user/' + id + '/approve');
  }

  function getUsers(filterMode) {
    vm.ready = false;
    if (filterMode) {
      vm.pageOffset = 0;
    }
    return User.queryRequestedByOrganization({
      organizationId: vm.organizationId,
      limit: vm.pageLimit,
      offset: vm.pageOffset,
      order: vm.sortColumn,
      direction: vm.sortDirection,
      'search[firstname]': vm.inputTextFirstName,
      'search[lastname]': vm.inputTextLastName,
      'search[email]': vm.inputTextEmail
    })
      .$promise.then(function (response) {
        vm.users = response.rows;
        vm.totalCount = response.count;
      })
      .catch(ErrorHandler('Misslyckades att hämta användaren.'))
      .finally(function () {
        vm.ready = true;
      });
  }
}

})();