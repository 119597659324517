(function () {
'use strict';

angular.module('hyresgastApp').directive('hyHeader', header);

function header($location) {
  var directive = {
    templateUrl: 'components/header/header.html',
    restrict: 'E',
    scope: {
      title: '@',
      tabs: '=',
      buttons: '=',
      buttonPaneCols: '=',
    },
    link: scope => {
      scope.getLeftCols = () => scope.buttonPaneCols ? 12 - scope.buttonPaneCols : 6;
      scope.getRightCols = () => scope.buttonPaneCols ? scope.buttonPaneCols : 6;
      scope.getWidth = button => ` calc(${button.grid}% - 7px)`;
      scope.go = button => button.click ? button.click() : $location.url(button.url);
    },
  };
  return directive;
}
})();