(function () {
'use strict';

angular.module('hyresgastApp').controller('NegotiationTableNegotiatorListCtrl', NegotiationTableNegotiatorListCtrl);

function NegotiationTableNegotiatorListCtrl(
  $scope, $location, $routeParams, $uibModal,
  FeatureConfiguration, Organization, SubPage, User,
  Auth, ErrorHandler
) {
  var vm = this;
  vm.gotoShow = gotoShow;
  vm.addMeeting = addMeeting;
  vm.pageLimit = 30;
  vm.pageOffset = 0;
  vm.Auth = Auth;

  vm.negotiationUserId = $routeParams.partnerId;
  vm.FeatureConfiguration = FeatureConfiguration;

  vm.tablist = [
    {
      title: 'Förhandlingar',
      path: '/users/' + $routeParams.id + '/negotiator/' + $routeParams.partnerId + '/organization/' + $routeParams.organizationId + '/welcome/negotiation-table/negotiator'
    },
    {
      title: 'Fastigheter',
      path:
        '/users/' +
        $routeParams.id +
        '/negotiator/' +
        $routeParams.partnerId +
        '/organization/' +
        $routeParams.organizationId +
        '/welcome/negotiation-table/negotiator?view=properties'
    },
    {
      title: 'Avslutat',
      path:
         '/users/' +
          $routeParams.id +
          '/negotiator/' +
          $routeParams.partnerId +
          '/organization/' +
          $routeParams.organizationId +
          '/welcome/negotiation-table/negotiator?view=finished'
    }
  ];

  if (vm.FeatureConfiguration.ENABLE_MEETINGS) {
    vm.tablist.splice(2, 0, {
      title: 'Möten',
      path:
          '/users/' +
          $routeParams.id +
          '/negotiator/' +
          $routeParams.partnerId +
          '/organization/' +
          $routeParams.organizationId +
          '/welcome/negotiation-table/negotiator?view=meetings'
    });
  }

  $scope.$on('$routeUpdate', function() {
    if ($location.search().order) {
      // reset offset when sorting
      vm.pageOffset = 0;
    }
  });

  $scope.$on('$routeUpdate', function() {
    // avoid unecessary calling of getCurrentSubpage(); when sorting
    if (!$location.search().order) {
      // reset offset when sorting
      getCurrentSubpage();
    }
  });

  getCurrentSubpage();
  getUser();
  getOrganization();

  /////////

  function getOrganization() {
    return Organization.get({ id: $routeParams.organizationId })
      .$promise.then(function(response) {
        vm.organization = response;
      })
      .catch(ErrorHandler('Misslyckades att hämta organisation.'))
  }

  function getUser() {
    return User.queryNegotiationPartners({ id: $routeParams.partnerId })
      .$promise.then(function(response) {
        vm.user = response.rows.filter(function(user) {
          return user.id === Number($routeParams.partnerId)
        })[0];

        if (!vm.user) {
          throw new Error("User not found");
        }
      })
      .catch(ErrorHandler('Misslyckades att hämta användaren.'))
      .finally(function() {
        vm.userReady = true;
      });
  }

  function gotoShow(id) {
    $location.search({});
    $location.path('/negotiations/' + id);
  }

  function getCurrentSubpage() {
    var defaultSubPage = 'list';
    // create a validation list against manually change of URL '?view=' parameter
    var whiteList = ['properties', 'finished'];
    if (vm.FeatureConfiguration.ENABLE_MEETINGS) {
      whiteList.push('meetings');
    }
    vm.currentSubpage = SubPage.set($routeParams.view, whiteList, defaultSubPage);
  }

  function addMeeting() {
    // fake object in order to pass two users
    var userObj = {
      externalParticipants: [],
      hgfParticipants: [Auth.currentUser(), vm.user]
    };
    var modalInstance = $uibModal.open({
      animation: true,
      ariaLabelledBy: 'modal-title-top',
      ariaDescribedBy: 'modal-body-top',
      size: 'lg',
      index: 1075, // position above tooltip
      templateUrl: 'resources/meeting/edit/edit-meetings-modal.html',
      controller: 'EditMeetingModalCtrl',
      controllerAs: 'vm',
      windowClass: 'modal-content--white-border',
      backdrop: 'static',
      resolve: {
        meetingObj: function() {
          return null;
        },
        negotiationObj: function() {
          return userObj;
        }
      }
    });
    // eslint-disable-next-line no-unused-vars
    modalInstance.result.then(function(meeting) {});
  }
}

})();