(function () {
'use strict';

angular.module('hyresgastApp').filter('agreementTypeToDescription', function() {
  return function(value) {
    switch (value) {
      case 0:
        return 'Överenskommelse';
      case 1:
        return 'Strandning';
      default:
        return 'Typ ej angivet';
    }
  };
});

})();