(function () {
'use strict';

angular.module('hyresgastApp').controller('ShowBlockingCtrl', ShowBlockingCtrl);

function ShowBlockingCtrl($scope, $routeParams, Blocking, Flash, Negotiation, NegotiationStates, ErrorHandler) {
  var vm = this;
  vm.NegotiationStates = NegotiationStates;

  vm.getNegotiation = getNegotiation;
  getBlocking();
  getNegotiation();

  ////////

  function getNegotiation() {
    return Negotiation.get({
      id: $routeParams.negotiationId
    })
      .$promise.then(function(response) {
        vm.negotiation = response;

        vm.negotiation.isFinished = vm.negotiation.state === NegotiationStates.FINISHED ||
          vm.negotiation.state === NegotiationStates.FINISHED_OUTSIDE_PORTAL;
      })
      .catch(new ErrorHandler('Misslyckades att hämta förhandling.'))
      .finally(function() {
        vm.readyNegotiation = true;
      });
  }

  function getBlocking() {
    return Blocking.get({
      negotiationId: $routeParams.negotiationId,
      id: $routeParams.id
    })
      .$promise.then(function(response) {
        vm.blocking = response;
      })
      .catch(new ErrorHandler('Misslyckades att hämta strandning.'))
      .finally(function() {
        vm.ready = true;
      });
  }
}

})();