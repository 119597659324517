(function () {
'use strict';

angular.module('hyresgastApp').controller('SetPasswordWithTokenCtrl', SetPasswordWithTokenCtrl);

function SetPasswordWithTokenCtrl(Flash, User, $routeParams, $location, $rootScope) {
  var vm = this;
  vm.user = {};
  vm.title = 'Ändra lösenord';
  vm.setPassword = setPassword;
  vm.ready = true;
  vm.credentials = {};
  ////////////

  function setPassword() {
    vm.credentials.token = $routeParams.token;
    vm.credentials.id = $routeParams.userId;

    vm.saving = true;
    User.setPasswordWithToken(vm.credentials)
      .$promise.then(
        function(response) {
          Flash.set('Ändring av lösenord lyckades.' + ' Varsågod att logga in med ditt nya lösenord.', 'success');
          $location.url('/');
          $rootScope.$broadcast('event:auth-loginRequired', null);
        },
        function(reason) {
          if (reason.status === 404) {
            Flash.set(
              'Länken du använder har gått ut. ' + 'Vänligen generera en ny genom att återställa lösenordet.',
              'error'
            );
            $location.url('/emailreset');
            $rootScope.$broadcast('event:auth-loginRequired', null);
          } else if (reason.status === 403) {
            Flash.set(
              'Lösenordet har redan ändrats via den här länken. ' +
                'Vänligen generera en ny länk om du har glömt bort ditt nya lösenord.',
              'error'
            );
            $location.url('/emailreset');
            $rootScope.$broadcast('event:auth-loginRequired', null);
          } else {
            Flash.set('Ändring av lösenord misslyckades. Försök igen.', 'error');
          }
        }
      )
      .finally(function() {
        vm.credentials = {};
        vm.passwordForm.$setPristine();
        vm.passwordForm.$setUntouched();
        vm.saving = false;
      });
  }
}

})();