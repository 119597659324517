(function () {
/* eslint-disable no-undefined */
'use strict';

angular.module('hyresgastApp').filter('doneTasksCounter', function(AgreementTypes, NegotiationTypes) {

  return function(agreement, negotiationType, isFreeAgreement) {
    if (!agreement || (negotiationType === undefined && !isFreeAgreement)) {
      return;
    }

    let agreedByBothPart = agreement.agreedByAssociation && agreement.agreedByOwner;
    let agreementSteps = [];
    
    if (agreement.type === AgreementTypes.AGREEMENT) {
      agreementSteps = [
        agreement.rentEffectiveFrom,
        agreement.releaseDate,
        agreement.name,
        agreement.numApartments,
        agreement.rentFileId || agreement.numApartments && agreement.numApartments === agreement.numAdjustedApartments,
        agreedByBothPart
      ];
    }

    if (agreement.type === AgreementTypes.AGREEMENT_WITHOUT_RENTLIST) {
      agreementSteps = [
        agreement.rentEffectiveFrom,
        agreement.releaseDate,
        agreement.name,
        agreement.numProperties,
        agreement.numDocuments,
        agreedByBothPart
      ];
    }

    if (negotiationType === NegotiationTypes.NEWLY_CONSTRUCTED_PROPERTY) {
      agreementSteps = [
        agreement.rentEffectiveFrom,
        agreement.releaseDate,
        agreement.name,
        agreement.rentFileId || agreement.numApartments && agreement.numApartments === agreement.numAdjustedApartments,
        agreedByBothPart
      ];
    }

    if (isFreeAgreement) {
      agreementSteps = [
        agreement.rentEffectiveFrom,
        agreement.name,
        agreedByBothPart
      ];
    }
    
    let total = agreementSteps.length;
    let done = agreementSteps.filter(step => step).length;
    let lastStep = ((total - done) === 1);
    let ready = (done === total);

    return {
      total,
      done,
      ready,
      lastStep
    };
  };
});

})();