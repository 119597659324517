(function () {
'use strict';

angular.module('hyresgastApp').controller('NewUserModalCtrl', NewUserModalCtrl);

function NewUserModalCtrl(
  $uibModalInstance,
  $timeout,
  fullMode,
  Organization,
  RoleLookup,
  Office,
  Flash,
  SpecUser,
  ErrorHandler
) {
  var vm = this;

  vm.saving = false;
  vm.fullMode = fullMode;
  if (!fullMode) {
    vm.selectedRole = { key: 'NONE', value: 0 };
  }
  vm.orgTimer = null;

  vm.save = () => {
    vm.saving = true;

    SpecUser.createUser({
      user: vm.user,
      selectedOrganization: vm.selectedOrganization
        ? { id: vm.selectedOrganization.id, name: vm.selectedOrganization.name }
        : vm.selectedOrganization,
      newOrganizationData: vm.organization,
      role: vm.selectedRole
    })
      .$promise.then(function (response) {
        Flash.set('Användaren har sparats', 'success');
        $uibModalInstance.close(response);
      })
      .catch(function () {
        Flash.set('Misslyckades att spara användare.', 'error');
        $uibModalInstance.dismiss('cancel');
      })
      .finally(function () {
        vm.saving = false;
      });
  };

  vm.cancel = () => {
    $uibModalInstance.dismiss();
  };

  vm.setRadioModelByOutsideClick = setRadioModelByOutsideClick;
  function setRadioModelByOutsideClick(choice) {
    vm.choiceMethod = choice;
  }

  queryOrganization();

  function queryOrganization() {
    vm.loading = true;
    return Organization.query({
      order: 'name',
      direction: 'ASC',
      'search[name]': vm.inputTextOrgName
    })
      .$promise.then(function (response) {
        vm.loading = false;
        vm.organizations = response.rows;
        if (vm.organizations.length === 1) {
          vm.selectedOrganization = response.rows[0];
        }
      })
      .catch(new ErrorHandler('Misslyckades att hämta organisation.'))
      .finally(() => {
        vm.loading = false;
      });
  }

  queryOffice();

  function queryOffice() {
    return Office.query()
      .$promise.then(function (response) {
        vm.offices = response;
      })
      .catch(new ErrorHandler('Misslyckades att hämta kontor.'));
  }

  vm.roles = RoleLookup;

  vm.selectOrganization = org => {
    vm.selectedOrganization = org;
    vm.inputTextOrgName = null;
  };

  vm.orgAfterType = () => {
    if (vm.orgTimer) {
      $timeout.cancel(vm.orgTimer);
    }
    vm.orgTimer = $timeout(queryOrganization, 500);
  };
}

})();