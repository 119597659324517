(function () {
'use strict';

angular.module('httpAuthSession', []).factory('Session', Session);

function Session($http) {
  var service = {
    create: create,
    show: show,
    destroy: destroy
  };

  return service;

  ////////////

  function create(credentials) {
    return $http.post('/api/sessions', credentials).then(function(response) {
      return response.data;
    });
  }

  function show(ignoreAuth) {
    var config = {
      url: '/api/sessions/heartbeat',
      method: 'GET',
      ignoreAuthModule: ignoreAuth // true: don't show login page when api returns 401
    };
    return $http(config).then(function(response) {
      return response.data;
    });
  }

  function destroy() {
    return $http.delete('/api/sessions');
  }
}

})();