(function () {
'use strict';

angular.module('hyresgastApp').controller('OwnersListCtrl', OwnersListCtrl);

function OwnersListCtrl($scope, $location, $filter,
  RealEstate) {
  var vm = this;

  vm.tableColumns = [
    {
      title: 'Namn',
      column: 'name',
    },
    {
      title: 'Organisationsnummer',
      column: 'organizationNumber',
    }
  ];
  vm.tableFGetData = RealEstate.queryOwners;

  vm.gotoOwner = owner => {
    $location.path('/properties').search({ owners: owner.name });
  };
}
})();